<template>
	<lba-dialog
		:name="name"
		:title="$t('formCheck')"
		:showClose="false"
		:closeOnEscape="false"
		@open="dialogOpened = true"
		@close="dialogOpened = false"
	>
		<div class="popup-body">
			<p class="mt-0">{{ $t('youDidNotFillFollowingRecommendedFields') }}</p>
			<template v-for="(field, index) in fields">
				<strong :key="index">{{ field }}</strong>
				<br :key="`br-${index}`">
			</template>
			<p>{{ $t('doYouReallyWishToLeaveThemEmpty') }}</p>
		</div>
		<div class="popup-footer">
			<button @click="onSubmit">{{ $t('yes') }}</button>
			<button @click="closeDialog">{{ $t('no') }}</button>
		</div>
	</lba-dialog>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true,
		},
		fields: Array,
	},
	data() {
		return {
			dialogOpened: false,
		};
	},
	created() {
		this.$root.$listen('keydown-enter', this.onEnter, this);
	},
	methods: {
		onEnter() {
			if (this.dialogOpened) {
				this.onSubmit();
			}
		},
		onSubmit() {
			this.$emit('submit');
			this.closeDialog();
		},
		closeDialog() {
			this.$emit('close');
			this.$root.$emit('dialog-close', this.name);
		},
	},
};
</script>
