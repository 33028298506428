export default {
	data() {
		return {
			offsetTop: 0,
			lastOffsetTop: 0,
			lazyOffsetTop: 0,
			lastScrollDirection: 1,
			lastScrollLeft: 0,
			scrollCounter: 0,
			scrolling: false,
			scrollingFast: false,
			scrollLeft: null,
			scrollbarVerticalMove: false,
			scrollbarVerticalMoved: false,
			scrollbarWidth: 0,
			scrollDebouncer: null,
			lastRequestTs: null,
			emptyRow: {},
			usedEmptyRow: false,
			emptyRowIndexFirst: 0,
		};
	},
	computed: {
		scrollbarOffsetX() {
			return this.$refs.viewport.offsetWidth - this.scrollbarWidth;
		},
	},
	mounted() {
		this.setScrollbarRectangle();
		this.$refs.viewport.addEventListener('mousedown', this.onMouseDown);
		document.addEventListener('mouseup', this.onMouseUp, true);
	},
	beforeDestroy() {
		document.removeEventListener('mouseup', this.onMouseUp);
	},
	methods: {
		setScrollbarRectangle() {
			const scrollBar = document.createElement('div');
			scrollBar.setAttribute('style',
				`position:absolute;
				top:-10000px;
				left:-10000px;
				width:100px;
				height:100px;
				overflow:scroll;`);
			document.body.append(scrollBar);
			this.scrollbarWidth = scrollBar.offsetWidth - scrollBar.clientWidth;
			scrollBar.remove();
		},
		onMouseDown(event) {
			// holds mouse over right(vertical) scrollbar
			if (event.offsetX > this.scrollbarOffsetX) {
				this.scrollbarVerticalMove = true;
				// this.scrollbarVerticalMoved = true;
			}
		},
		onMouseUp() {
			if (this._inactive) return;
			this.$emit('actions-style-updated');
			this.mouseUpHandler();
			this.scrollbarVerticalMove = false;

			if (this.$refs.viewport) {
				this.$refs.viewport.dispatchEvent(new CustomEvent('scroll'));
			}
		},
		scroll(event) {
			if (this.lastScrollLeft !== event.target.scrollLeft) {
				this.lastScrollLeft = event.target.scrollLeft;
				this.headerLeft = -1 * event.target.scrollLeft;

				this.$refs.header.setAttribute(
					'style',
					`left: ${this.headerLeft}px; width: ${this.headerWidth}px;`
				);
				this.$emit('actions-style-updated');
			}
		},
	},
};
