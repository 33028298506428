<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ data[key] }}
	</component>
	<s
		v-else
		:class="classes"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
			<slot name="actions" :customValue="this" :parentComponentId="currentComponentId"></slot>
		</small>
		<ValidationProvider
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${$generateUID()}`"
			:rules="rules"
			:ref="inputRef"
			v-slot="{ invalid, errors }"
			:data-required-recommended="requiredRecommended"
		>
			<lba-code
				v-model="data[key]"
				:class="{
					'code-editor': true,
					'lba-invalid': (invalid && dirty),
					'showWarning': (showRecommendation)
				}"
				:styles="options.style"
				lineNumbers
				:language="options.language"
				:parentComponentId="currentComponentId"
				:readOnly="readOnly"
				@input="onEvent('input', parseString)"
				@change="onEvent('change', parseString)"
				@blur="$emit('blur')"
			></lba-code>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</ValidationProvider>
	</s>
</template>

<style>
.height-200{
	height: 200px
}

.code-editor {
	/* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
	background: #f5f5f5;
	color: #2d2d2d;

	/* you must provide font-family font-size line-height. Example:*/
	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
	outline: none;
}

.prism-editor-wrapper textarea {
	max-height: none !important;
	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace !important;
}
</style>

<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputCode',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
	methods: {
		parseString() {
			if (!this.data[this.key]) {
				this.data[this.key] = null;
			}
		},
		calculateHeight() {
			if (this.height == null) {
				return this.data[this.key].split('\n').length * this.lineHeight + 8;
			}
			return this.height;
		},
	},
};
</script>
