<script>
export default {
	name: 'LbaResizableTextarea',
	methods: {
		resizeTextarea(event) {
			// event.target.style.height = 'auto';
			// event.target.style.height = event.target.scrollHeight + 'px';
			this.updateHeight(event.target);
		},
		updateHeight(el) {
			el.style.height = 'auto';
			const styles = window.getComputedStyle(el);
			if (parseInt(el.scrollHeight) < parseInt(styles.maxHeight)) {
				el.setAttribute(
					'style',
					'height:' + el.scrollHeight + 'px;overflow-y:hidden;'
				);
			} else {
				el.setAttribute(
					'style',
					'height:' + styles.maxHeight
				);
			};
		},
	},
	mounted() {
		this.$nextTick(() => {
			// DOM updated
			this.updateHeight(this.$el);
			this.$el.addEventListener('input', this.resizeTextarea);
		});
	},
	beforeDestroy() {
		this.$el.removeEventListener('input', this.resizeTextarea);
	},
	render() {
		return this.$slots.default[0];
	},
};
</script>
