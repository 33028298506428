// https://developer.mozilla.org/en-US/docs/Web/API/Notification

export default class {
	constructor() {
		this.vm = null;
		this.registrator = null;
		this.isInitialized = false;

		this.init = this.init.bind(this);
		this.onMessage = this.onMessage.bind(this);
		this.closeNotification = this.closeNotification.bind(this);
	}

	async init() {
		if (!window.Notification) {
			return new Error('Notification API is not available');
		}

		if (['default', 'denied'].includes(window.Notification.permission)) {
			await window.Notification.requestPermission();

			if (window.Notification.permission !== 'granted') {
				return new Error('Notification permission not granted');
			}
		}

		if (navigator.serviceWorker) {
			if (navigator.serviceWorker.controller) {
				try {
					this.registrator = await navigator.serviceWorker.getRegistration('/sw.js');
				} catch (error) {
					return error;
				}
			} else {
				try {
					this.registrator = await navigator.serviceWorker.register('/sw.js');
				} catch (error) {
					return error;
				}
			}
		} else {
			return new Error('ServiceWorker API is not available');
		}

		navigator.serviceWorker.addEventListener('message', this.onMessage);
		this.vm.$listen('desktop-notification.close', this.closeNotification, this.vm);
		this.isInitialized = true;
	}

	onMessage(message) {
		this.vm.$emit('desktop-notification.action', message);
	}

	async closeNotification(tag) {
		if (!this.isInitialized) {
			const error = await this.init();
			if (error) return error;
		}

		const notifications = await this.registrator.getNotifications({ tag });
		notifications.forEach((notification) => {
			notification.close();
		});
	}

	async notify(title, params = {}) {
		if (!this.isInitialized) {
			const error = await this.init();
			if (error) return error;
		}

		if (!params.data) {
			params.data = {};
		}
		params.data.url = params.data.url || window.location.href;
		const options = { lang: this.vm.$i18n.locale.toUpperCase(), ...params };

		if (typeof LBTray === 'object') {
			LBTray.showNotification(title);
		} else {
			this.registrator.showNotification(title, options);
		}
	}
}
