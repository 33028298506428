<template>
	<div
		:class="{inputRange: true, vertical, input: true}"
		:data-cy="`${currentComponentId}__wrapper`"
		@mouseover="onMouseOver('wrapper')"
		@mouseleave="onMouseLeave('wrapper')"
	>
		<i
			:class="stateIcon"
			:data-cy="`${currentComponentId}__icon`"
			@mouseover.stop="onMouseOver('icon')"
			@mouseleave.stop="onMouseLeave('icon')"
		></i>
		<input
			:data-cy="`${currentComponentId}__inputRange`"
			v-show="canShow"
			v-model="internalValue"
			type="range"
			:min="min"
			:max="max"
			@mouseover.stop="onMouseOver('slider')"
			@mouseleave.stop="onMouseLeave('slider')"
		>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: [Number, String],
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: 100,
		},
		statesIcons: Array,
		vertical: {
			type: Boolean,
			default: false,
		},
		showDelay: {
			type: Number,
			default: 0,
		},
		hideDelay: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		internalValue: {
			get() {
				return parseInt(this.value, 10);
			},
			set(value) {
				this.$emit('input', parseInt(value, 10));
			},
		},
		stateIcon() {
			for (let i = 0; i < this.statesIcons.length; i += 1) {
				const stateIcon = this.statesIcons[i];

				if (stateIcon.match(this.internalValue)) {
					return stateIcon.icon;
				}
			}
			return null;
		},
	},
	data() {
		return {
			canShow: false,
			mouseOverIcon: false,
			mouseOverSlider: false,
			mouseOverWrapper: false,
		};
	},
	methods: {
		mouseOver() {
			return this.mouseOverIcon || this.mouseOverSlider || this.mouseOverWrapper;
		},
		toggle(type, state) {
			if (type === 'wrapper') this.mouseOverWrapper = state;
			if (type === 'icon') this.mouseOverIcon = state;
			if (type === 'slider') this.mouseOverSlider = state;
		},
		show() {
			if (this.mouseOver()) {
				this.canShow = true;
			}
		},
		hide() {
			if (!this.mouseOver()) {
				this.canShow = false;
			}
		},
		onMouseOver(type) {
			this.toggle(type, true);

			if (!this.canShow) {
				if (this.showDelay > 0) {
					setTimeout(this.show, this.showDelay);
				} else {
					this.show();
				}
			}
		},
		onMouseLeave(type) {
			this.toggle(type, false);

			if (this.canShow) {
				if (this.hideDelay > 0) {
					setTimeout(this.hide, this.hideDelay);
				} else {
					this.hide();
				}
			}
		},
	},
};
</script>
