<template>
	<span
		class="inputPass userSelectNone input"
		v-bind:class="{
			passStrength: showPasswdStrength,
			passWeak: weakPasswd,
			passFair: mediumPasswd,
			passStrong: strongPasswd,
			passVisible: !showPasswd,
			passReadable: passwdReadable,
			passEditable: passwdEditable,
			passDisabled: passwdDisabled
		}"
		style="max-width: 250px;"
	>
		<button type="button" v-bind:title="title" v-on:click="toggle" :data-cy="`${currentComponentId}__toggleShowHide`">
			<i class="icon-pass-show"></i>
			<i class="icon-pass-hide"></i>
		</button>
		<button
			:data-cy="`${currentComponentId}__generatePassword`"
			class="inputPassGenerate"
			type="button"
			:title="$t('generatePass')"
			v-on:click="generatePassword"
			v-on:click.once="onTouch"
		>
			<i class="icon-pass-generate"></i>
		</button>
		<span v-if="!isPin" class="inputPassStrength">
			<i class="icon-pass-weak" title="Weak password"></i>
			<i class="icon-pass-fair" title="Fair password"></i>
			<i class="icon-pass-strong" title="Strong password"></i>
		</span>
			<input
				:data-cy="`${currentComponentId}__inputPassword`"
				v-bind:type="type"
				v-on:focus="inputFocus"
				v-on:blur="inputBlur"
				v-on:blur.once="onTouch"
				v-on:input="checkPasswdStrength"
				v-on:input.once="onTouch"
				v-model="input"
				v-bind:disabled="passwdInputDisabled"
				ref="input"
				v-bind:class="{
					'lba-invalid': invalid
				}"
				:autocomplete="autocomplete"
				:placeholder="placeholder"
			>
		<span  v-if="hasPassword" class="passOverlay passOverlayRead" v-on:click="read">
			<i class="con-pass-show"></i> {{ $t('showPassword') }}
		</span>
		<span v-if="hasPassword" class="passOverlay passOverlayEdit" v-on:click="edit">
			<i class="icon-edit"></i>{{ $t('changePassword') }}
		</span>
	</span>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: {
			type: String,
			required: false,
		},
		readable: {
			type: Boolean,
			require: false,
		},
		writeable: {
			type: Boolean,
			require: false,
		},
		hasPassword: {
			type: Boolean,
			require: false,
		},
		required: {
			type: Boolean,
			required: false,
			default: true,
		},
		validator: {
			type: Object,
			required: false,
			default: null,
		},
		isDirty: Boolean,
		isPin: {
			type: Boolean,
			required: false,
			default: false,
		},
		invalid: {
			type: Boolean,
			required: false,
			default: false,
		},
		initGenPasswd: {
			type: Boolean,
			default: false,
		},
		autocomplete: String,
		noDots: {
			type: Boolean,
			required: false,
			default: false,
		},
		rules: {
			type: Object,
			required: false,
			default() {
				return {
					lowercase: true,
					uppercase: true,
					number: true,
					special: false,
					length: 8,
					exceptions: [],
				};
			},
		},
	},
	computed: {
		password: {
			get() {
				return this.value || '';
			},
			set(value) {
				let newValue = value;

				if (value == null || value === '') {
					newValue = undefined;
				}

				this.$emit('input', newValue);
			},
		},
	},
	watch: {
		value() {
			this.input = this.value;
		},
		isDirty(value) {
			if (value) {
				this.dirty = true;
			}
		},
	},
	data() {
		return {
			input: this.value || '',
			type: 'password',
			placeholder: '******',

			showPasswd: false,
			title: this.$t('showPassword'),

			showPasswdStrength: false,
			weakPasswd: false,
			mediumPasswd: false,
			strongPasswd: false,
			passwdStrengthTimeout: null,

			passwdReadable: false,
			passwdEditable: false,
			passwdDisabled: false,
			passwdInputDisabled: false,

			dirty: false,
		};
	},
	mounted() {
		if (this.readable && this.hasPassword) {
			this.toggleState(false);
		}

		if (this.writeable === undefined) {
			this.writeable = true;
		}

		if (this.writeable) {
			if (this.hasPassword) {
				this.passwdEditable = true;
			}
		} else {
			if (this.readable) this.passwdReadable = true;
			else this.passwdDisabled = true;
			this.passwdInputDisabled = true;
		}

		if (this.initGenPasswd && _.isEmpty(this.password) && _.isEmpty(this.input)) this.generatePassword();
	},
	methods: {
		onTouch() {
			this.dirty = true;
			// this.invalid = false;

			if (this.validator) {
				this.validator.$touch();
			}
		},
		inputFocus() {
			if (this.input === this.placeholder) {
				this.password = '';
				this.input = '';
			}
		},
		inputBlur() {
			if (!this.showPasswd && this.input === '') {
				this.password = this.input;
				this.input = this.placeholder;
			}
			this.$emit('blur');
		},
		checkPasswdStrength() {
			const medium = this.isMediumPasswd(this.input);
			const strong = this.isStrongPasswd(this.input);

			if (this.passwdStrengthTimeout) {
				clearTimeout(this.passwdStrengthTimeout);
			}

			this.passwdStrengthTimeout = setTimeout(() => {
				this.showPasswdStrength = false;
			}, 1500);

			this.showPasswdStrength = !!this.input;
			this.weakPasswd = !medium && !strong;
			this.mediumPasswd = medium && !strong;
			this.strongPasswd = strong;
			this.password = this.input;
		},
		edit() {
			this.toggle(null, !!this.readable);
		},
		read() {
			this.passwdDisabled = true;
			this.toggle(null, true);
		},
		toggle(event, state) {
			this.toggleState(state);
			this.$refs.input.focus();

			this.passwdReadable = false;
			this.passwdEditable = false;
		},
		toggleState(state) {
			this.showPasswd = (state !== undefined) ? state : !this.showPasswd;

			if (this.showPasswd) {
				this.type = 'text';
				this.title = this.$t('hidePassword');
			} else {
				this.type = 'password';
				this.title = this.$t('showPassword');
			}
		},

		isMediumPasswd(pass) {
			return (/[a-zA-Z]/.test(pass) && /.{10,}/.test(pass)) ||
              (/[a-zA-Z]/.test(pass) && /\d/.test(pass) && /.{8,}/.test(pass));
		},
		isStrongPasswd(pass) {
			return (/[a-zA-Z]/.test(pass) && /\d/.test(pass) && /.{10,}/.test(pass)) ||
              (/[a-zA-Z]/.test(pass) && /\d/.test(pass) && /\W/.test(pass) && /.{8,}/.test(pass));
		},
		pickChars(all, min, max) {
			let n;
			let chars = '';

			if (typeof max === 'undefined') {
				n = min;
			} else {
				n = min + Math.floor(Math.random() * (max - min));
			}

			for (let i = 0; i < n; i += 1) {
				chars += all.charAt(Math.floor(Math.random() * all.length));
			}

			return chars;
		},
		shuffleChars(all) {
			const array = all.split('');
			let tmp;
			let current;
			let top = array.length;

			if (top) {
				while (top > 0) {
					top -= 1;
					current = Math.floor(Math.random() * (top + 1));
					tmp = array[current];
					array[current] = array[top];
					array[top] = tmp;
				}
			}

			return array.join('');
		},
		passwordCellGetRandomChar(lower, upper, number, special, dot) {
			// -- some characters apear multiple times, so that there is better chance,
			// that character will be randomly selected from within the whole group
			const numberChars =	'01234567890123456789';
			const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
			const upperChars = lowerChars.toUpperCase();
			const specialChars = '!@#$%&*^()\-_+!@#$%&*';
			const dotChar = '....';

			let charSet = '';

			if (number) charSet += numberChars;
			if (lower) charSet += lowerChars;
			if (upper) charSet += upperChars;
			if (special) charSet += specialChars;
			if (dot) charSet += dotChar;

			if (this.rules.exceptions && this.rules.exceptions.length) {
				_.forEach(this.rules.exceptions, (e) => {
					charSet = charSet.replaceAll(e, '');
				});
			}

			// console.log(charSet);

			return charSet.charAt(Math.floor(Math.random() * charSet.length));
		},
		generatePassword() {
			let passwd = [];
			// -- for VOIP passwords, we have special algoritm - pw should look like XXXNXXXNXXXN
			// -- some updates to pawword for SIP: https://rt.linuxbox.cz/Ticket/Display.html?id=35016

			let length = 8;
			let lower = true;
			let upper = true;
			let number = true;
			let special = false;

			if (this.isPin) {
				length = 4;
				lower = false;
				upper = false;
				number = true;
				special = false;

			} else if (this.rules.length && this.rules.lowercase && this.rules.uppercase && this.rules.number && this.rules.special) {
				length = this.rules.length;
				lower = this.rules.lowercase;
				upper = this.rules.uppercase;
				number = this.rules.number;
				special = this.rules.special;
			}

			// -- generate password with requested length
			for (let i = 0; i < length; i++) {
				passwd.push(this.passwordCellGetRandomChar(lower, upper, number, special, (!this.noDots && !this.isPin)));
			}
			// console.log(passwd);

			// -- random-sort the array, effectively mix order of all elements
			passwd = _.shuffle(passwd);
			// -- remove first two elements - we're going to add first and last at the ond of this section
			passwd.shift();
			passwd.shift();

			// -- to make sure all requested char-classes are included
			if (lower) {
				passwd.shift();
				passwd.push(this.passwordCellGetRandomChar(true, false, false, false, false));
			}
			if (upper) {
				passwd.shift();
				passwd.push(this.passwordCellGetRandomChar(false, true, false, false, false));
			}
			if (number) {
				passwd.shift();
				passwd.push(this.passwordCellGetRandomChar(false, false, true, false, false));
			}
			if (special) {
				passwd.shift();
				passwd.push(this.passwordCellGetRandomChar(false, false, false, true, false));
			}

			// -- random-sort again, twice
			passwd = _.shuffle(passwd);
			passwd = _.shuffle(passwd);

			// -- make sure generated password begin and end with lower/upper/number class character
			if (lower) {
				passwd.unshift(this.passwordCellGetRandomChar(true, false, false, false));
				passwd.push(this.passwordCellGetRandomChar(true, false, false, false));
			} else if (upper) {
				passwd.unshift(this.passwordCellGetRandomChar(false, true, false, false));
				passwd.push(this.passwordCellGetRandomChar(false, true, false, false));
			} else if (number) {
				passwd.unshift(this.passwordCellGetRandomChar(false, false, true, false));
				passwd.push(this.passwordCellGetRandomChar(false, false, true, false));
			} else {
				passwd.unshift(this.passwordCellGetRandomChar(true, false, false, false));
				passwd.push(this.passwordCellGetRandomChar(true, false, false, false));
			}

			passwd = passwd.join('');
			this.input = passwd;
			this.password = passwd;
			this.toggleState(true);
			this.checkPasswdStrength();
			this.$emit('change', this.password);
		},
	},
};
</script>
