<template>
	<component
		v-if="timeFormat === 'relative'"
		:is="rootTag"
		v-tooltip="(date && $d(new Date(date), format)) || ''"
	>
		{{ since }}
	</component>
	<component
		v-else
		:is="rootTag"
		v-tooltip="((date && since) || '')"
	>
		{{ $d(new Date(date), format) }}
	</component>
</template>

<script>
export default {
	name: 'LbaGridDate',
	props: {
		date: String,
		rootTag: {
			type: String,
			default: 'span',
		},
		format: {
			type: String,
			default: 'fullDigitsMedium',
		},
	},
	data() {
		return {
			timeFormat: 'absolute',
			interval: null,
			since: null,
		};
	},
	created() {
		if (
			this.$user &&
			this.$user.grid_time_format &&
			this.$user.grid_time_format === 'relative'
		) {
			this.timeFormat = this.$user.grid_time_format;
		} else {
			this.timeFormat = 'absolute';
		}

		this.since = this.timeSince(new Date(this.date), false);
		this.interval = setInterval(() => {
			this.since = this.timeSince(new Date(this.date), false);
		}, 15000);
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	methods: {
		timeSince(date, short = true) {
			const seconds = Math.floor((new Date() - date) / 1000);
			const divTable = [
				{
					value: 31536000,
					label: 'years',
				},
				{
					value: 2592000,
					label: 'months',
				},
				{
					value: 86400,
					label: 'days',
				},
				{
					value: 3600,
					label: 'hours',
				},
				{
					value: 60,
					label: 'minutes',
				},
			];
			let step = 0;
			let since = seconds;

			while (divTable[step]) {
				const interval = seconds / divTable[step].value;
				if (interval > 1) {
					since = Math.floor(interval);
					break;
				}
				step += 1;
			}

			const localePath = `timeSince.${short ? 'short' : 'long'}.${divTable[step] ? divTable[step].label : 'seconds'}`;

			if (short) {
				return `${since}${this.$t(localePath)}`;
			}
			return this.$tc(localePath, since);
		},
	},
};
</script>
