<script>
export default {
	inserted(el, bindings, vnode) {
		el.addEventListener('click', (event) => {
			vnode.context.$root.$emit('dialog-open', {
				name: bindings.arg || bindings.value,
				modifiers: bindings.modifiers,
			});
			event.stopPropagation();
		});
	},
};
</script>
