/* eslint-disable no-underscore-dangle */
export default {
	data() {
		return {
			scrollElementsInfo: [{ selector: '.page-content', element: null, scrollTop: 0 }],
			componentInitialized: false,
			elementsScrolled: false,
		};
	},
	activated() {
		if (!this.componentInitialized) {
			this.elementsScrolled = true;
			return;
		}

		this.scrollElements();
	},
	deactivated() {
		this.componentInitialized = true;
		this.elementsScrolled = false;
	},
	beforeDestroy() {
		this.scrollElementsInfo.forEach((info) => {
			if (_.isEmpty(info.element) && !_.isEmpty(info.selector)) {
				info.element = document.querySelector(info.selector);
			}
			if (!_.isEmpty(info.element)) {
				info.element.removeEventListener('scroll', this.elementOnScroll);
			}
		});
	},
	methods: {
		async initTabSwitchScroll() {
			await this.$nextTick();

			if (!this.componentInitialized) {
				this.scrollElementsInfo.forEach((info) => {
					if (_.isEmpty(info.element) && !_.isEmpty(info.selector)) {
						info.element = document.querySelector(info.selector);
					}

					if (_.isEmpty(info.element)) {
						console.error('[mixins.TabSwitchScroll](initTabSwitchScroll) element not found:', info);
					} else {
						info.element.addEventListener('scroll', this.elementOnScroll);
						info.scrollTop = info.element.scrollTop;
					}
				});
				this.componentInitialized = true;
			}
		},
		elementOnScroll(event) {
			if (this._inactive || !this.elementsScrolled) return;
			const elementInfo = this.scrollElementsInfo.find((info) => info.element === event.target);
			elementInfo.scrollTop = event.target.scrollTop;
		},
		registerScrollElement(element) {
			element.addEventListener('scroll', this.elementOnScroll);
			this.scrollElementsInfo.push({ element, scrollTop: element.scrollTop });
		},
		async scrollElements() {
			await this.$nextTick();
			this.scrollElementsInfo.forEach((info) => {
				info.element.scrollTo(0, info.scrollTop);
			});
			this.elementsScrolled = true;
		},
	},
};
