<template>
	<div class="page-controls">
		<button
			v-if="btnPin"
			:data-cy="`${currentComponentId}__pin`"
			name="pageControls-buttonPin"
			type="button"
			class="controlsPin"
			v-tooltip="$t('pin')"
			@click="pin">
			<i class="icon-pin"></i>
		</button>

		<button
			v-if="btnPin"
			:data-cy="`${currentComponentId}__unpin`"
			name="pageControls-buttonUnpin"
			type="button"
			class="controlsUnpin"
			v-tooltip="$t('unpin')"
			@click="unpin">
			<i class="icon-pinned"></i>
		</button>

		<!--
				removed in behalf of closeTabDialog
		<span class="iconButtonDialog">
				<span class="close icon-cancel" v-on:click="toggleSaveChangesDialog(false)"></span>
				<span class="title">{{ $t('saveChanges') }}</span>
				<span class="label">{{ $t('continueWithoutSaving') }}</span>
		</span>
		-->

		<slot name="controlsBefore" :parentComponentId="`${currentComponentId}__slotControlsBefore`"></slot>
		<div class="page-controls-buttons">
			<button
				v-if="btnShowInCard"
				:data-cy="`${currentComponentId}__showInCard`"
				name="pageControls-showInCard"
				type="button"
				v-tooltip="$t('showInCard')"
				@click="showInCard">
				<i class="icon-new-tab"></i>
			</button>

			<button
				v-if="btnPrev"
				:data-cy="`${currentComponentId}__previous`"
				name="pageControls-previous"
				:disabled="btnPrev.disabled"
				type="button"
				v-tooltip="$t('previous')"
				@click="prev">
				<i class="icon-arrow-left"></i>
			</button>

			<button
				v-if="btnNext"
				:data-cy="`${currentComponentId}__next`"
				name="pageControls-next"
				:disabled="btnNext.disabled"
				type="button"
				v-tooltip="$t('next')"
				@click="next">
				<i class="icon-arrow-right"></i>
			</button>

			<button
				v-if="btnCancel"
				:data-cy="`${currentComponentId}__close`"
				name="pageControls-close"
				type="button"
				class="buttonTransparent button-cancel"
				v-tooltip="$t('close')"
				@click="cancel">
				<span>{{ $t('close') }}</span>
			</button>
		</div>
		<button
			v-if="btnDelete"
			:data-cy="`${currentComponentId}__remove`"
			name="pageControls-remove"
			type="button"
			class="buttonTransparent"
			@click="remove">
			<span>{{ $t('remove') }}</span>
		</button>

		<button
			v-if="btnReload"
			:data-cy="`${currentComponentId}__reload`"
			name="pageControls-reload"
			type="button"
			class="buttonTransparent"
			@click="reload">
			<span>{{ $t('reload') }}</span>
		</button>

		<button
			v-if="btnNew"
			:data-cy="`${currentComponentId}__new`"
			name="pageControls-new"
			type="button"
			class="button"
			@click="createNew">
			<span>{{ $t('new') }}</span>
		</button>

		<button
			v-if="btnOk"
			:data-cy="`${currentComponentId}__save`"
			name="pageControls-save"
			type="button"
			class="button"
			v-bind:class="{ showDialog: showSaveChangesDialog }"
			@click="ok">
			<span>{{ $t('save') }}</span>
		</button>

		<slot name="controlsAfter" :parentComponentId="`${currentComponentId}__slotControlsAfter`"></slot>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		btnOk: Boolean,
		btnNew: Boolean,
		btnPin: Boolean,
		btnCancel: Boolean,
		btnDelete: Boolean,
		btnReload: Boolean,
		btnPrev: Object,
		btnNext: Object,
		btnShowInCard: Boolean,
		cancelCallback: {
			type: Function,
			default: (vm) => {
				if (vm.$route.matched.length < 2) {
					vm.$routerWrap.push(vm.$route.matched[0].path);
				} else {
					const matched = vm.$route.matched[0];
					const resolved = vm.$router.resolve({ name: matched.name, params: vm.$route.params });
					vm.$routerWrap.push(resolved.route.path);
				}
			},
		},
	},
	data() {
		return {
			showSaveChangesDialog: false,
		};
	},
	/* mounted() {
        this.$root.$listen('toggle-save-changes', this.toggleSaveChangesDialog, this);
    }, */
	updated() {
		this.$root.$emit('content.updated');
	},
	methods: {
		pin() {
			this.$root.$emit('content.pin');
		},
		unpin() {
			this.$root.$emit('content.unpin');
		},
		createNew() {
			this.$emit('new');
			this.$root.$emit('content.new');
		},
		ok() {
			this.$emit('submit');
			this.$root.$emit('content.submit', this.$route.params.id);
		},
		cancel() {
			this.$emit('cancel');
			this.$root.$emit('content.cancel');
			this.cancelCallback(this);
			this.$emit('cancel-done');
			this.$root.$emit('content.cancel.done');
		},
		remove() {
			this.$emit('remove');
			this.$root.$emit('content.remove', this.$route.params.id);
		},
		reload() {
			this.$root.$emit('content.reload');
		},
		prev() {
			this.$root.$emit('content.previous');
		},
		next() {
			this.$root.$emit('content.next');
		},
		showInCard() {
			this.$root.$emit('content.show.card');
		},
		/* toggleSaveChangesDialog(options) {
            if (options.state != null) {
                this.showSaveChangesDialog = options.state;
            } else {
                this.showSaveChangesDialog = !this.showSaveChangesDialog;
            }

            if (options.setEditted) {
                this.$route.meta.editted = false;
            }
        }, */
	},
};
</script>
