/* eslint-disable no-param-reassign */
async function setKey(vm) {
	if (vm._inactive) {
		await vm.$nextTick();
	}
	/* eslint-disable-next-line no-underscore-dangle */
	if (!vm._inactive) {
		if (vm.routeKeyFn != null) {
			vm.routeKey = vm.routeKeyFn();
		} else {
			let path = vm.$route.path;
			if (vm.$root.app.tabsReloading) {
				const uid = vm.$generateUID();
				path += uid;
				setTimeout(async () => {
					await vm.$nextTick();
					vm.$root.$emit('keep-alive.remove-cache', path);
					await vm.$nextTick();
					vm.tabsReloading = false;
				}, 0);
			}
			vm.routeKey = path;
		}
		if (vm.routeKeyChanged != null) {
			vm.routeKeyChanged();
		}
	}
}

export default {
	props: {
		routeKeyFn: {
			type: Function,
			required: false,
		},
	},
	data() {
		return {
			routeKey: null,
		};
	},
	created() {
		setKey(this);
	},
	watch: {
		$route() {
			setKey(this);
		},
	},
};
