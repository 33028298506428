<script>
export default {
	inserted(element, binding, vnode) {
		const options = binding.value;

		if (options.movable) {
			// if element contains header
			const controls = element.querySelector('.window-header');
			const dragElement = controls || element;
			const position = options.position || { x: 0, y: 0 };
			const savePosition = options.savePosition || false;
			const id = options.id || null;
			const resizable = (position.width && position.width != null && position.height && position.height != null);
			let clickedOnElement = false;
			let dragging = false;
			let overlapX = 0;
			let overlapY = 0;
			let style = null;

			if (position.x != null && position.y != null) {
				style = `
					left: ${position.x}px;
					top: ${position.y}px;
				`;
				element.setAttribute('style', style.trim());
			}

			if (resizable) {
				style = style + `
					width: ${position.width}px;
					height: ${position.height}px;
				`;
				element.setAttribute('style', style.trim());
			}

			dragElement.addEventListener('mousedown', (event) => {
				clickedOnElement = true;
				dragging = true;
				document.body.classList.add('user-select-none');

				overlapX = event.clientX - element.offsetLeft;
				overlapY = event.clientY - element.offsetTop;
			});

			document.addEventListener('mousedown', () => {
				if (clickedOnElement) {
					element.classList.add('focused');
				} else {
					dragging = false;
					element.classList.remove('focused');
				}

				clickedOnElement = false;
			});

			document.addEventListener('mouseup', () => {
				dragging = false;
				document.body.classList.remove('user-select-none');
			});

			document.addEventListener('mousemove', (event) => {
				if (dragging) {
					let rect = {
						x: event.clientX - overlapX,
						y: event.clientY - overlapY,
						width: element.offsetWidth,
						height: element.offsetHeight,
					};

					const collision = vnode.context.$detectRectWindowCollision(rect);
					rect = vnode.context.$handleRectWindowCollision(rect, collision);

					position.x = rect.x;
					position.y = rect.y;

					let style2 = `
						left: ${position.x}px;
						top: ${position.y}px;
					`;

					if (resizable) {
						style2 = style2 + `
							width: ${rect.width - 2}px;
							height: ${rect.height - 2}px;
						`;
					}

					if (savePosition && id != null) {
						localStorage.setItem(`${id}-positionX`, position.x);
						localStorage.setItem(`${id}-positionY`, position.y);
						if (resizable) {
							localStorage.setItem(`${id}-width`, rect.width - 2);
							localStorage.setItem(`${id}-height`, rect.height - 2);
						}
					}
					element.setAttribute('style', style2.trim());
				}
			});
		}
	},
};
</script>

<style>
.user-select-none {
  user-select: none;
}
</style>
