export default class {
	constructor($http) {
		this.$http = $http;
	}

	getModules() {
		return this.$http.get(`lbadmin/modules`);
	}

	getObjectPermissions(objectUid) {
		return this.$http.get(`lbadmin/object-permissions/${objectUid}`);
	}

	getGroupRoles() {
		return this.$http.get(`lbadmin/group-roles`);
	}

	getProfiles() {
		return this.$http.get(`lbadmin/profile-list`);
	}

}
