import _ from 'lodash';
import GridModel from '../models/Grid';

export default {
	props: {
		gridName: {
			type: String,
			required: false,
		},
		resources: {
			type: Object,
			validator(value) {
				return (
					!_.isEmpty(value) &&
					'columnsSettings' in value &&
					'filterSettings' in value
				);
			},
		},
		perPageOptions: {
			type: Array,
			default: () => {
				return [20, 50, 100, 500];
			},
		},
		perPage: {
			type: Number,
			default: 50,
		},
	},
	data() {
		return {
			gridModel: null,
			columnsSettingsRequest: null,
			settingsDebouncer: null,
			columnsSettings: [],
			columnsOrder: null,
			columnsSettingsInitPromise: null,
			columnsSettingsInitResolve: null,

			importExportValidationSchema: null,
			importExportColumns: null,
			importExportLoaded: false,
			internalResourcesPromise: Promise.resolve(),
			internalResources: null,

			currentPerPage: 50,
			currentPage: 1,
		};
	},
	async created() {
		this.gridModel = new GridModel(this.$http, this.gridName);

		if (!_.isEmpty(this.resources)) {
			this.internalResources = this.resources;
		} else {
			const filterAttributesModule = _.get(this.filterAttributes, 'module', null);
			if (!_.isEmpty(this.gridName) || !_.isEmpty(filterAttributesModule)) {
				this.internalResourcesPromise = new Promise((resolve, reject) => {
					this.gridModel.getResources(filterAttributesModule, this.importEnabled, this.exportEnabled)
						.then((response) => {
							this.internalResources = response.data;
							resolve();
						})
						.catch(reject);
				});
			}
		}

		if (this.gridName) {
			this.settingsDebouncer = new this.$Debouncer(this, this.saveColumnsSettings, null, 1500);
		}

		this.columnsSettingsInitPromise = new Promise((resolve) => {
			this.columnsSettingsInitResolve = resolve;
		});
	},
	methods: {
		async initColumnsSettings() {
			let columnsSettings = null;

			if (this.gridName) {
				await this.internalResourcesPromise;
				if (!_.isEmpty(this.internalResources) && !_.isEmpty(this.internalResources.columns)) {
					columnsSettings = this.internalResources.columns;
				}
			}

			if (columnsSettings && columnsSettings.length > 0) {
				const settings = columnsSettings[0].settings && columnsSettings[0].settings.columns;

				if (settings) {
					this.columnsOrder = columnsSettings[0].settings && columnsSettings[0].settings.order;
					this.currentPerPage = (columnsSettings[0].settings && columnsSettings[0].settings.perPage) || this.perPage;
					Object.keys(this.columnsProperties).forEach((columnKey) => {
						const columnSettings = settings[columnKey];
						const columnProps = this.columnsProperties[columnKey];

						if (columnSettings != null) {
							columnProps.pos = columnSettings.position;
							columnProps.visible = !!columnSettings.visible;

							if (columnSettings.width != null) {
								columnProps.width = columnSettings.width;
								columnProps.absoluteWidth = columnSettings.width;
								columnProps.dragStartWidth = columnSettings.width;
								columnProps.minWidth = columnProps.minWidth || columnSettings.width;
							}
						}

						if (
							columnKey !== 'checkbox' &&
							columnKey !== 'actions' &&
							!columnProps.persitent &&
							!columnProps.removed
						) {
							this.columnsSettings.push(columnProps.id);
						}
					});
				}
			} else {
				Object.keys(this.columnsProperties).forEach((columnKey) => {
					const columnProps = this.columnsProperties[columnKey];

					if (
						columnKey !== 'checkbox' &&
						columnKey !== 'actions' &&
						!columnProps.persitent &&
						!columnProps.removed
					) {
						this.columnsSettings.push(columnProps.id);
					}
				});
			}

			this.sortColumnsSettings();
		},
		sortColumnsSettings() {
			this.columnsSettings.sort((a, b) => {
				if (this.columnsProperties[a].pos < this.columnsProperties[b].pos) {
					return -1;
				}

				if (this.columnsProperties[a].pos > this.columnsProperties[b].pos) {
					return 1;
				}

				return 0;
			});
			_.forEach(this.columnsProperties, (column) => {
				column.pos = this.columnsSettings.indexOf(column.id) + 1;
			});
		},
		onColumnsSettingsChange() {
			this.columnsSettings.forEach((setting, index) => {
				this.columnsProperties[setting].pos = index + this.multiEnabled;
			});
			this.sortColumnsSettings();

			if (this.settingsDebouncer) {
				this.settingsDebouncer.emit();
			}
			this.setCssRules();
		},
		async saveColumnsSettings() {
			if (this.gridName) {
				const columnsSettings = {};
				const { columnsWidths } = this.getCSSRulesAndWidths();

				this.getSortedColumnsKeys().forEach((columnKey) => {
					if (columnKey !== 'checkbox' && columnKey !== 'actions') {
						columnsSettings[columnKey] = {
							position: this.columnsProperties[columnKey].pos,
							visible: this.columnsProperties[columnKey].visible,
							width: columnsWidths[columnKey],
						};
					}
				});

				try {
					await this.gridModel.putColumns({
						columns: columnsSettings,
						order: this.columnsOrder,
						perPage: this.currentPerPage,
					});
					this.$root.$emit('content.saved', { reload: false });
				} catch (error) {
					this.$root.$emit('content.saveFailed');
				}
			}
		},
		async resetColumnSettings() {
			this.columnsSettings.forEach((setting) => {
				const column = this.columnsProperties[setting];
				column.width = column.defaultWidth;
				column.absoluteWidth = column.defaultAbsoluteWidth;
				column.minWidth = column.defaultMinWidth;
				column.pos = column.defaultPos;
				column.visible = column.defaultVisibility;
			});
			if (this.currentPerPage !== 50) {
				this.currentPerPage = 50;
				this.refreshPages();
				this.loadData();
			}
			let changed = false;
			if (
				this.collection?.createdOptions?.params?._order &&
				this.collection?.params?._order !== this.collection?.createdOptions?.params?._order
			) {
				changed = true;
				this.collection.params._order = this.collection.createdOptions.params._order;
				this.orderBy = this.collection.createdOptions.params._order;
				this.columnsOrder.orderBy = this.collection.createdOptions.params._order;
			}
			if (
				this.collection?.createdOptions?.params?._order_dir &&
				this.collection?.params?._order_dir !== this.collection?.createdOptions?.params?._order_dir
			) {
				changed = true;
				this.collection.params._order_dir = this.collection.createdOptions.params._order_dir;
				this.orderDirection = this.collection.createdOptions.params._order_dir;
				this.columnsOrder.orderDirection = this.collection.createdOptions.params._order_dir;
			}
			this.sortColumnsSettings();
			this.setCssRules(false, this.showSearchRow);
			this.settingsDebouncer.emit();
			if (changed) this.reload(this.id);
		},
		updateColumnVisibility(column) {
			this.columnsProperties[column].visible = !this.columnsProperties[column].visible;
			this.$forceUpdate();

			if (this.settingsDebouncer) {
				this.settingsDebouncer.emit();
			}
			this.setCssRules(false, this.showSearchRow);
		},
		updateColumnOrder(settings) {
			if (!settings) {
				return;
			}
			this.columnsOrder = {
				orderBy: settings.orderBy,
				orderDirection: settings.orderDirection,
			};
			if (this.settingsDebouncer) {
				this.settingsDebouncer.emit();
			}
		},
	},
};
