<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ valueLabel }}
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<div class="half" v-if="init" :key="inputId">
			<select class="units" style="width: 70px" v-model="unit">
				<option v-for="(option, index) in options.units"
					:key="index"
					:data-cy="`${currentComponentId}__select__option${index}`"
					:value="option.value"
					:selected="data[key] === option.value">
					{{ $getLocale(option.label) }}
				</option>
			</select>
			<ValidationProvider
				v-slot="{ invalid, errors }"
				:name="label"
				:rules="rules"
			>
				<input
					type="number"
					min="0"
					v-model="value"
					style="padding-right: 70px;"
					:class="{
						'lba-invalid': invalid,
						'showWarning': (showRecommendation)
					}"
				>
				<span
					v-for="(err, index) in errors"
					:key="index"
					class="lba-messages"
				>{{ err }}</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</ValidationProvider>
		</div>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputValueWithUnit',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			switchLabel1: this.$t('off'),
			switchLabel2: this.$t('on'),
			unit: null,
			value: 0,
			init: false,
			dataMultipliers: {},
			rules: {
				required: true,
				min: 0,
				numeric: true,
				regex: /^[0-9]*$/,
			},
			minBit: 0,
			maxBit: 1024,
			inputId: 'input_id',
		};
	},
	watch: {
		unit() {
			if (!this.init) return;
			this.refreshDataRules();
			this.calculateValue();
		},
		value() {
			if (!this.init) return;
			this.calculateValue();
		},
	},
	computed: {
		valueLabel() {
			const unit = _.find(this.options.units, (s) => s.value === this.unit);
			return `${this.value} ${this.$getLocale(unit.label)}`;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();

		if (this.options.output_type === 'string') {
			if (!this.data[this.key]) this.data[this.key] = '';
			const val = this.data[this.key].match(/[a-zA-Z]+|[0-9]+/g);
			this.value = val[0];

			const un = _.find(this.options.units, (unit) => unit.value === val[1]);
			if (!un) {
				this.options.units.push({ value: val[1], label: val[1] });
			}
			this.unit = val[1];

		} else if (this.options.output_type === 'time') {
			if (!this.options.units) {
				this.options.units = [
					{ label: this.$t('settings.days'), value: 86400000 },
					{ label: this.$t('settings.hours'), value: 3600000 },
					{ label: this.$t('settings.minutes'), value: 60000 },
					{ label: this.$t('settings.seconds'), value: 1000 },
				];
			}

			if (this.data[this.key]) {
				for (let i = 0; i < this.options.units.length; i++) {
					const mp = this.options.units[i];
					if (this.data[this.key] / mp.value >= 1) {
						this.unit = mp.value;
						this.value = Math.round(this.data[this.key] / mp.value);
						break;
					}
				}
			}

		} else if (this.options.output_type === 'data') {
			if (!this.options.units) {
				this.options.units = [
					{ label: this.$t('settings.tb'), value: 1024 * 1024 * 1024 * 1024 },
					{ label: this.$t('settings.gb'), value: 1024 * 1024 * 1024 },
					{ label: this.$t('settings.mb'), value: 1024 * 1024 },
					{ label: this.$t('settings.kb'), value: 1024 },
				];
			}

			this.dataMultipliers = {
				KiB: 1024,
				MiB: 1024 * 1024,
				GiB: 1024 * 1024 * 1024,
				TiB: 1024 * 1024 * 1024 * 1024,
			};

			if (!this.options.origUnit) this.options.origUnit = 'KiB';

			this.minBit = this.options.minValue * this.dataMultipliers[this.options.origUnit] || 0;
			this.maxBit = this.options.maxValue * this.dataMultipliers[this.options.origUnit] || 1024;

			if (this.data[this.key]) {
				for (let i = 0; i < this.options.units.length; i++) {
					const mp = this.options.units[i];
					if ((this.data[this.key] * this.dataMultipliers[this.options.origUnit]) / mp.value >= 1) {
						this.unit = mp.value;
						this.value = Math.round((this.data[this.key] * this.dataMultipliers[this.options.origUnit]) / mp.value);
						break;
					}
				}
			}

			this.refreshDataRules();
		}

		this.init = true;
	},
	methods: {
		refreshDataRules() {
			this.rules.min_value = this.options.minValue ? Math.floor(this.minBit / this.unit) : 0;
			this.rules.max_value = this.options.maxValue ? Math.floor(this.maxBit / this.unit) : 1024;
			this.inputId = this.$generateUID();
		},
		calculateValue() {
			if (!this.value || !this.unit) {
				this.data[this.key] = null;
			} else if (this.options.output_type === 'string') {
				this.data[this.key] = `${this.value}${this.unit}`;
			} else if (this.options.output_type === 'time') {
				this.data[this.key] = this.value * this.unit;
			} else if (this.options.output_type === 'data') {
				this.data[this.key] = (this.value * this.unit) / this.dataMultipliers[this.options.origUnit];
			}
		},
	},
};
</script>
