<template>
	<button
		type="button"
		class="button button-save"
		:class="{
			'is-busy': isAnimating,
			'buttonRed': notSaved,
			disabled: isDisabled,
		}"
		v-tooltip="notSaved ? internalTooltip : tooltip"
		@click="onClick"
	>
		<slot
			name="default"
			:buttonText="buttonText"
			:isAnimating="isAnimating"
			:isDisabled="isDisabled"
			:onClick="onClick"
		>
			{{ buttonText }}
		</slot>
	</button>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
		},
		disabled: {
			type: Boolean,
		},
		inputDirty: {
			type: Boolean,
			required: true,
		},
		saveLabel: {
			type: String,
		},
		tooltip: null,
		failedTooltip: null,
	},
	data() {
		return {
			notSaved: false,
			isBusy: false,
			buttonText: this.saveLabel || this.$t('save'),
			internalTooltip: null,
			keepAnimating: false,
			animationPromise: null,
			onSavePromise: null,
		};
	},
	computed: {
		isAnimating() {
			return this.isBusy || this.keepAnimating;
		},
		isDisabled() {
			return (this.disabled || this.notSaved || !this.inputDirty) && !this.isAnimating;
		},
	},
	watch: {
		async inputDirty() {
			if (this.inputDirty) {
				if (this.animationPromise) {
					await this.animationPromise;
					this.animationPromise = null;
				}
				if (this.onSavePromise) {
					await this.onSavePromise;
					this.onSavePromise = null;
				}
				// console.debug('[LbaButtonSave](inputDirty)', this.id);
				this.buttonText = this.saveLabel || this.$t('save');
				this.notSaved = false;
				this.internalTooltip = null;
			}
		},
	},
	created() {
		this.$root.$listen('content.validationFailed', (info) => this.onSaveFailed(info, 'validation'), this);
		this.$root.$listen('content.saveFailed', (info) => this.onSaveFailed(info, 'server'), this);
		this.$root.$listen('content.saveCanceled', (info) => this.onSaveCanceled(info), this);
		this.$root.$listen('content.saved', this.onSaveSuccess, this);
	},
	methods: {
		async onSaveSuccess(info) {
			if (
				(!_.isEmpty(this.id) && this.id !== info.id) ||
				(!_.isEmpty(_.get(info, 'id')) && this.id !== info.id)
			) {
				return;
			}
			let resolveOnSavePromise = null;
			this.onSavePromise = new Promise((resolve) => {
				resolveOnSavePromise = resolve;
			});
			if (this.animationPromise) {
				await this.animationPromise;
				this.animationPromise = null;
			}
			// console.debug('[LbaButtonSave](onSaveSuccess)', this.id);
			this.isBusy = false;
			this.buttonText = this.$t('saved');
			resolveOnSavePromise();
			this.onSavePromise = null;
		},
		async onSaveFailed(info, type) {
			if (
				(!_.isEmpty(this.id) && this.id !== info.id) ||
				(!_.isEmpty(_.get(info, 'id')) && this.id !== info.id)
			) {
				return;
			}
			let resolveOnSavePromise = null;
			this.onSavePromise = new Promise((resolve) => {
				resolveOnSavePromise = resolve;
			});
			if (this.animationPromise) {
				await this.animationPromise;
				this.animationPromise = null;
			}
			// console.debug('[LbaButtonSave](onSaveFailed)', this.id);
			this.isBusy = false;
			this.buttonText = this.$t('notSaved');
			this.notSaved = true;

			if (type === 'validation') {
				this.internalTooltip = this.$t('form.validation_failed');
			} else if (type === 'server') {
				this.internalTooltip = this.$t('form.save_failed');
			} else {
				this.internalTooltip = this.failedTooltip || null;
			}
			resolveOnSavePromise();
			this.onSavePromise = null;
		},
		async onSaveCanceled(info) {
			if (
				(!_.isEmpty(this.id) && this.id !== info.id) ||
				(!_.isEmpty(_.get(info, 'id')) && this.id !== info.id)
			) {
				return;
			}
			let resolveOnSavePromise = null;
			this.onSavePromise = new Promise((resolve) => {
				resolveOnSavePromise = resolve;
			});
			if (this.animationPromise) {
				await this.animationPromise;
				this.animationPromise = null;
			}
			// console.debug('[LbaButtonSave](onSaveCanceled)', this.id);
			this.isBusy = false;
			this.notSaved = false;
			this.buttonText = this.saveLabel || this.$t('save');
			resolveOnSavePromise();
			this.onSavePromise = null;
		},
		onClick(event) {
			if (this.disabled || !this.inputDirty || this.notSaved || this.isBusy || this.keepAnimating) {
				return;
			}
			// console.debug('[LbaButtonSave](onClick)', this.id);
			this.buttonText = this.$t('saving');
			this.isBusy = true;
			this.keepAnimating = true;
			this.animationPromise = new Promise((resolve) => {
				setTimeout(() => {
					this.cancelAnimation();
					resolve();
				}, 500);
			});
			this.$emit('click', event);
			return true;
		},
		cancelAnimation() {
			// console.debug('[LbaButtonSave](cancelAnimation)', this.id);
			this.keepAnimating = false;
		},
	},
};
</script>
