function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export default {
	install(Vue) {
		Vue.prototype.$sleep = sleep;
		window.$sleep = sleep;
	},
};
