<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<span>
			{{ (data[key] || []).join(', ') }}
		</span>
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
			<multiselect
				v-model="valuesList"
				:data-cy="`${currentComponentId}__multiselect`"
				v-bind="bindOptions()"
				:options="options.options"
				@search-change="searchChange"
				@select="addEntry"
				@deselect="removeEntry"
				@clear="clear"
				:loading="isLoading"
				:openDirection="'bottom'"
				:placeholder="$t('multiselect.placeholder')"
				:noOptionsText="$t('multiselect.noOptions')"
				:disabled="readOnly"
			>
			</multiselect>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputMultiSelect',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			initialized: false,
			isLoading: false,
			searchDebouncer: null,
			multiselectOptions: {},
			valuesList: [],
			valueListView: [],
			searchable: true,
			taggable: true,
			multiple: true,
			searchDelay: 300,
		};
	},
	watch: {
		valuesList(value) {
			this.data[this.key] = value;
			this.$emit('input', this.data[this.key]);
		},
	},
	created() {
		this.prepareComponent();
		this.prepareValues();
		this.prepareMultiselect();

		this.searchDebouncer = new this.$Debouncer(
			this,
			this.search,
			null,
			this.searchDelay
		);
		this.initialized = true;
	},
	methods: {
		prepareMultiselect() {
			if (this.options) {
				if (this.checkProperty(this.options, 'multiple')) {
					this.multiple = this.options.multiple;
				}
				if (this.checkProperty(this.options, 'searchable')) {
					this.searchable = this.options.searchable;
				}
				if (this.checkProperty(this.options, 'taggable')) {
					this.taggable = this.options.taggable;
				}
			}
		},
		bindOptions() {
			const options = {
				searchable: this.searchable,
				mode: (this.taggable) ? 'tags' : ((this.multiple) ? 'multiple' : 'single'),
				createTag: this.taggable,
				closeOnSelect: false,
			};

			return options;
		},
		prepareValues() {
			if (!this.options.options) {
				this.options.options = [];
			}

			if (this.data[this.key] != null) {
				const constructor = this.data[this.key].constructor;
				if (constructor === Array) {
					_.each(this.data[this.key], (value) => {
						const valueFixed = value.toString().trim();
						if (valueFixed) {
							this.valueListView.push(valueFixed);
						}
					});

				} else if (constructor === String) {
					this.valueListView.push(this.data[this.key].trim());

				} else if (constructor === Object) {
					_.forEach(this.data[this.key], (value) => this.valueListView.push(value));

				}
			}

			if (this.valueListView.length > 0) {
				_.each(this.valueListView, (entry) => {
					const found = _.find(this.options.options, (e) => e.value === entry);
					if (!found) {
						this.options.options.push({
							value: entry,
							label: entry,
						});
					}

					this.valuesList.push(entry);
				});
			}
		},
		async searchChange(value) {
			this.searchDebouncer.emit(value);
		},
		async search(value) {
		},
		async addEntry(value) {
			await this.$nextTick();
			this.onEvent('change');
		},
		async removeEntry(value) {
			await this.$nextTick();
			this.onEvent('change');
		},
		async clear() {
			await this.$nextTick();
			this.onEvent('change');
		},
	},
};
</script>
