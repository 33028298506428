export default class {
	constructor($http, gridName) {
		this.$http = $http;
		this.gridName = gridName;
	}

	getResources(filterAttributesModule, importEnabled, exportEnabled) {
		return this.$http.get(`lbadmin/grid-resources`, {
			params: {
				gridName: this.gridName,
				filterAttributesModule,
				importEnabled,
				exportEnabled,
			},
		});
	}

	getColumns() {
		return this.$http.get(`lbadmin/grid-settings/columns/${this.gridName}`);
	}

	putColumns(params) {
		return this.$http.put(`lbadmin/grid-settings/columns/${this.gridName}`, { params });
	}

	getFilters(gridName) {
		return this.$http.get(`lbadmin/grid-settings/${gridName}`);
	}

	insertFilter(gridName, params) {
		return this.$http.put(`lbadmin/grid-settings/${gridName}`, params);
	}

	updateFilter(gridName, uid, params) {
		return this.$http.post(`lbadmin/grid-settings/${gridName}/${uid}`, params);
	}

	removeFilter(gridName, uid) {
		return this.$http.delete(`lbadmin/grid-settings/${gridName}/${uid}`);
	}
}
