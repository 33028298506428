<template>
	<content-options>
		<template v-slot:content>
		<div class="page-header">
				<h1>{{ $t('settings.menu.activity') }}</h1>
		</div>
		<div class="page-content">
			<lba-grid
				ref="grid"
				id="settings-activity"
				:gridName="'lbadmin.users_activity'"
				:collection="activity"
				:rowId="['session_id', 'ip', 'user_name', 'last_activity']"
				hideableColumns
				:filterAttributes="filterAttributes"
				:dynamicHeight="false"
			>
				<template v-slot:columns="props">
					<lba-grid-column
						id="user_name"
						:data-cy="`${props.parentComponentId}__user_name`"
						:label="$t('settings.user.login')"
						searchable
						sortable
						width="250"
					>
						{{ props.row.user_name }}
					</lba-grid-column>

					<lba-grid-column
						id="name"
						:data-cy="`${props.parentComponentId}__name`"
						:label="$t('settings.activity.name')"
						searchable
						sortable
						width="180"
					>
						{{ props.row.name }}
					</lba-grid-column>

					<lba-grid-column
						id="last_activity"
						:data-cy="`${props.parentComponentId}__last_activity`"
						:label="$t('settings.activity.lastActivity')"
						sortable
						width="180"
					>
						{{ props.row.last_activity ? $d(new Date(props.row.last_activity), 'fullDigitsLong') : null }}
					</lba-grid-column>

					<lba-grid-column
						id="login"
						:data-cy="`${props.parentComponentId}__login`"
						:label="$t('settings.activity.login')"
						sortable
						width="180"
					>
						{{ props.row.login ? $d(new Date(props.row.login), 'fullDigitsLong') : null }}
					</lba-grid-column>

					<lba-grid-column
						id="logout"
						:data-cy="`${props.parentComponentId}__logout`"
						:label="$t('settings.activity.logout')"
						sortable
						width="180"
					>
						{{ props.row.logout ? $d(new Date(props.row.logout), 'fullDigitsLong') : null }}
						<a
							v-if="props.row.logout == null && !props.row.current_browser && props.row.last_activity && allowLogout"
							:data-cy="`${props.parentComponentId}__logout__logoutUser`"
							v-on:click="logout(props.row)"
							class="settings-activity-logout"
						>
							<i class="icon-logout"></i>
							<span>{{ $t('settings.activity.kickout') }}</span>
						</a>
						<span v-if="props.row.current_browser">
						{{ $t('settings.activity.current_browser') }}
						</span>
					</lba-grid-column>

					<lba-grid-column
						id="ip"
						:data-cy="`${props.parentComponentId}__ip`"
						:label="$t('settings.activity.ip')"
						sortable
						searchable
						width="120"
					>
						{{ props.row.ip }}
					</lba-grid-column>

					<lba-grid-column
						id="browser"
						:data-cy="`${props.parentComponentId}__browser`"
						:label="$t('settings.activity.browser')"
						width="200"
						v-tooltip.bottom-center="props.row.browser"
					>
						<span v-if="props.row.browser">
						<i class="icon-browser-chrome" v-if="getBrowser(props.row.browser).chrome"></i>
						<i class="icon-browser-firefox" v-if="getBrowser(props.row.browser).firefox"></i>
						<i class="icon-browser-ie" v-if="getBrowser(props.row.browser).msie"></i>
						<i class="icon-browser-safari" v-if="getBrowser(props.row.browser).safari"></i>
						<i class="icon-browser-opera" v-if="getBrowser(props.row.browser).opera"></i>
						<i class="icon-browser-mobile" v-if="getBrowser(props.row.browser).mobile"></i>
						<i class="icon-browser-unrecognized"
							v-if="!getBrowser(props.row.browser).mobile
							&& !getBrowser(props.row.browser).chrome
							&& !getBrowser(props.row.browser).firefox
							&& !getBrowser(props.row.browser).msie
							&& !getBrowser(props.row.browser).safari
							&& !getBrowser(props.row.browser).opera"
						></i>
						{{ getBrowser(props.row.browser).name }}
						{{ getBrowser(props.row.browser).version }}
						<i class="icon-info icon14"></i>
						</span>
					</lba-grid-column>

					<lba-grid-column
						id="session_id"
						:data-cy="`${props.parentComponentId}__session_id`"
						:label="$t('settings.activity.sessionId')"
						sortable
						searchable
						width="400"
					>
						{{ props.row.session_id }}
					</lba-grid-column>
				</template>
			</lba-grid>
		</div> <!-- /.page-content -->
		</template>
	</content-options>
</template>

<script>
import ActivityModel from '../models/Activity';

export default {
	name: 'Activity',
	data() {
		return {
			activity: [],
			allowLogout: false,
			filterAttributes: {},
		};
	},
	created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.filterAttributes = {
			module: 'settings.lbadmin-activity',
			columns: [
				{
					name: 'user_name',
					label: this.$t('settings.user.login'),
					type: 'text',
				},
				{
					name: 'name',
					label: this.$t('settings.activity.name'),
					type: 'text',
				},
				{
					name: 'last_activity',
					label: this.$t('settings.activity.lastActivity'),
					type: 'datetime',
				},
				{
					name: 'login',
					label: this.$t('settings.activity.login'),
					type: 'datetime',
				},
				{
					name: 'logout',
					label: this.$t('settings.activity.logout'),
					type: 'datetime',
				},
				{
					name: 'ip',
					label: this.$t('settings.activity.ip'),
					type: 'text',
				},
				{
					name: 'session_id',
					label: this.$t('settings.activity.sessionId'),
					type: 'text',
				},
			],
		};

		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.activityModel = new ActivityModel(this.$http);
		this.activity = new this.$Collection(this.activityModel, {
			params: {
				_limit: 200,
				_offset: 0,
				_order: 'last_activity',
				_order_dir: 'desc',
			},
		});
		this.activity.attach(this, 'activityModel');
		this.permissionsReload();
	},
	methods: {
		permissionsReload() {
			this.allowLogout = this.$permissions.checkPermission('lbadmin.force-logout.enable');
		},
		isActive(id) {
			return this.$route.params.id === id;
		},
		async logout(row) {
			// console.log('logging out ', row.session_id);
			await this.activityModel.logout(row.session_id);
			this.$root.$emit('grid.update-row', {
				gridName: 'lbadmin.users_activity',
				row: row,
				reload: true,
			});
		},
		getFirstMatch(regex, userAgent) {
			const match = userAgent.match(regex);
			return (match && match.length > 1 && match[1]) || '';
		},
		detect(userAgent) {
			const t = true;
			const iosdevice = this.getFirstMatch(/(ipod|iphone|ipad)/i, userAgent).toLowerCase();
			const likeAndroid = /like android/i.test(userAgent);
			const android = !likeAndroid && /android/i.test(userAgent);
			const versionIdentifier = this.getFirstMatch(/version\/(\d+(\.\d+)?)/i, userAgent);
			const tablet = /tablet/i.test(userAgent);
			const mobile = !tablet && /[^-]mobi/i.test(userAgent);
			let result = {};

			if (/opera|opr/i.test(userAgent)) {
				result = {
					name: 'Opera',
					opera: t,
					version: versionIdentifier || this.getFirstMatch(/(?:opera|opr)[\s/](\d+(\.\d+)?)/i, userAgent),
				};
			} else if (/windows phone/i.test(userAgent)) {
				result = {
					name: 'Windows Phone',
					windowsphone: t,
					msie: t,
					version: this.getFirstMatch(/iemobile\/(\d+(\.\d+)?)/i, userAgent),
				};
			} else if (/msie|trident/i.test(userAgent)) {
				result = {
					name: 'Internet Explorer',
					msie: t,
					version: this.getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i, userAgent),
				};
			} else if (/chrome|crios|crmo/i.test(userAgent)) {
				result = {
					name: 'Chrome',
					chrome: t,
					version: this.getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i, userAgent),
				};
			} else if (iosdevice) {
				if (iosdevice === 'iphone') {
					result = { name: 'iPhone' };
				} else if (iosdevice === 'ipad') {
					result = { name: 'iPad' };
				} else {
					result = { name: 'iPod' };
				}

				// WTF: version is not part of user agent in web apps
				if (versionIdentifier) {
					result.version = versionIdentifier;
				}
			} else if (/sailfish/i.test(userAgent)) {
				result = {
					name: 'Sailfish',
					sailfish: t,
					version: this.getFirstMatch(/sailfish\s?browser\/(\d+(\.\d+)?)/i, userAgent),
				};
			} else if (/seamonkey\//i.test(userAgent)) {
				result = {
					name: 'SeaMonkey',
					seamonkey: t,
					version: this.getFirstMatch(/seamonkey\/(\d+(\.\d+)?)/i, userAgent),
				};
			} else if (/firefox|iceweasel/i.test(userAgent)) {
				result = {
					name: 'Firefox',
					firefox: t,
					version: this.getFirstMatch(/(?:firefox|iceweasel)[ /](\d+(\.\d+)?)/i, userAgent),
				};
				if (/\((mobile|tablet);[^)]*rv:[\d.]+\)/i.test(userAgent)) {
					result.firefoxos = t;
				}
			} else if (/silk/i.test(userAgent)) {
				result = {
					name: 'Amazon Silk',
					silk: t,
					version: this.getFirstMatch(/silk\/(\d+(\.\d+)?)/i, userAgent),
				};
			} else if (android) {
				result = {
					name: 'Android',
					version: versionIdentifier,
				};
			} else if (/phantom/i.test(userAgent)) {
				result = {
					name: 'PhantomJS',
					phantom: t,
					version: this.getFirstMatch(/phantomjs\/(\d+(\.\d+)?)/i, userAgent),
				};
			} else if (/blackberry|\bbb\d+/i.test(userAgent) || /rim\stablet/i.test(userAgent)) {
				result = {
					name: 'BlackBerry',
					blackberry: t,
					version: versionIdentifier || this.getFirstMatch(/blackberry[\d]+\/(\d+(\.\d+)?)/i, userAgent),
				};
			} else if (/(web|hpw)os/i.test(userAgent)) {
				result = {
					name: 'WebOS',
					webos: t,
					version: versionIdentifier || this.getFirstMatch(/w(?:eb)?osbrowser\/(\d+(\.\d+)?)/i, userAgent),
				};

				if (/touchpad\//i.test(userAgent)) {
					result.touchpad = t;
				}
			} else if (/bada/i.test(userAgent)) {
				result = {
					name: 'Bada',
					bada: t,
					version: this.getFirstMatch(/dolfin\/(\d+(\.\d+)?)/i, userAgent),
				};
			} else if (/tizen/i.test(userAgent)) {
				result = {
					name: 'Tizen',
					tizen: t,
					version: this.getFirstMatch(/(?:tizen\s?)?browser\/(\d+(\.\d+)?)/i, userAgent) ||
            versionIdentifier,
				};
			} else if (/safari/i.test(userAgent)) {
				result = {
					name: 'Safari',
					safari: t,
					version: versionIdentifier,
				};
			}

			// set webkit or gecko flag for browsers based on these engines
			if (/(apple)?webkit/i.test(userAgent)) {
				result.name = result.name || 'Webkit';
				result.webkit = t;

				if (!result.version && versionIdentifier) {
					result.version = versionIdentifier;
				}
			} else if (!result.opera && /gecko\//i.test(userAgent)) {
				result.name = result.name || 'Gecko';
				result.gecko = t;
				result.version = result.version || this.getFirstMatch(/gecko\/(\d+(\.\d+)?)/i, userAgent);
			}

			// set OS flags for platforms that have multiple browsers
			if (android || result.silk) {
				result.android = t;
			} else if (iosdevice) {
				result[iosdevice] = t;
				result.ios = t;
			}

			// OS version extraction
			let osVersion = '';

			if (iosdevice) {
				osVersion = this.getFirstMatch(/os (\d+([_\s]\d+)*) like mac os x/i, userAgent);
				osVersion = osVersion.replace(/[_\s]/g, '.');
			} else if (android) {
				osVersion = this.getFirstMatch(/android[ /-](\d+(\.\d+)*)/i, userAgent);
			} else if (result.windowsphone) {
				osVersion = this.getFirstMatch(/windows phone (?:os)?\s?(\d+(\.\d+)*)/i, userAgent);
			} else if (result.webos) {
				osVersion = this.getFirstMatch(/(?:web|hpw)os\/(\d+(\.\d+)*)/i, userAgent);
			} else if (result.blackberry) {
				osVersion = this.getFirstMatch(/rim\stablet\sos\s(\d+(\.\d+)*)/i, userAgent);
			} else if (result.bada) {
				osVersion = this.getFirstMatch(/bada\/(\d+(\.\d+)*)/i, userAgent);
			} else if (result.tizen) {
				osVersion = this.getFirstMatch(/tizen[/\s](\d+(\.\d+)*)/i, userAgent);
			}
			if (osVersion) {
				result.osversion = osVersion;
			}

			// device type extraction
			const osMajorVersion = osVersion.split('.')[0];
			if (tablet || iosdevice === 'ipad' ||
				(android && (osMajorVersion === 3 || (osMajorVersion === 4 && !mobile))) || result.silk
			) {
				result.tablet = t;
			} else if (mobile || iosdevice === 'iphone' || iosdevice === 'ipod' || android ||
				result.blackberry || result.webos || result.bada
			) {
				result.mobile = t;
			}

			// Graded Browser Support
			// http://developer.yahoo.com/yui/articles/gbs
			if (
				(result.msie && result.version >= 10) ||
				(result.chrome && result.version >= 20) ||
				(result.firefox && result.version >= 20.0) ||
				(result.safari && result.version >= 6) ||
				(result.opera && result.version >= 10.0) ||
				(result.ios && result.osversion && result.osversion.split('.')[0] >= 6)
			) {
				result.a = t;
			} else if (
				(result.msie && result.version < 10) ||
				(result.chrome && result.version < 20) ||
				(result.firefox && result.version < 20.0) ||
				(result.safari && result.version < 6) ||
				(result.opera && result.version < 10.0) ||
				(result.ios && result.osversion && result.osversion.split('.')[0] < 6)
			) {
				result.c = t;
			} else {
				result.x = t;
			}

			return result;
		},
		getBrowser(userAgent) {
			const browser = this.detect(userAgent);
			/*
			 * Set our detect method to the main browser object so we can
			 * reuse it to test other user agents.
			 * This is needed to implement future tests.
			 */
			browser._detect = this.detect;
			return browser;
		},
	},
};
</script>
