<template>
	<div class="breadcrumbs" v-if="!main || !$route.meta.hideBreadcrumb">
		<template v-for="(breadcrumb, index) in currentBreadcrumbs">
			<span v-if="index === currentBreadcrumbs.length - 1" :key="`link-${index}`" :data-cy="`breadcrumb__${index}`">
				{{ breadcrumb.translated }}
			</span>
			<a
				v-else
				:key="`link-${index}`"
				:data-cy="`breadcrumb__${index}`"
				@click="onClick($event, breadcrumb)"
				:class="{ disabled: isBreadcrumbDisabled(breadcrumb) }"
			>
				{{ breadcrumb.translated }}
			</a>
			<span :key="index" v-if="index < currentBreadcrumbs.length - 1">></span>
		</template>
	</div>
</template>

<style>
.breadcrumbs a {
	cursor: pointer;
}
.breadcrumbs a.disabled {
	color: grey;
	cursor: default;
}
</style>

<script>
export default {
	props: {
		main: Boolean,
	},
	data() {
		return {
			tabs: null,
		};
	},
	computed: {
		currentBreadcrumbs() {
			if (this.tabs && this.tabs.activeTab) {
				return this.tabs.activeTab.breadcrumbs;
			}
			return [];
		},
	},
	watch: {
		tabs() {
			this.$forceUpdate();
		},
	},
	created() {
		this.tabs = this.$root.$tabs;
		this.$root.$listen('breadcrumb-update', this.updateBreadcrumb, this);
		this.$root.$listen('route-meta-update', this.updateBreadcrumb, this);
		this.$root.$listen('tabs.ready', () => { this.tabs = this.$root.$tabs; }, this);
	},
	methods: {
		updateBreadcrumb() {
			this.$forceUpdate();
		},
		onClick(event, breadcrumb) {
			event.preventDefault();

			if (!breadcrumb.disabled && breadcrumb.initDone !== false) {
				const params = breadcrumb.params || {};
				if (!('openNewTab' in params)) {
					params.openNewTab = event.ctrlKey;
				}
				this.$routerWrap.push({ name: breadcrumb.name, params });
			}
		},
		isBreadcrumbDisabled(breadcrumb) {
			return breadcrumb.disabled === true || breadcrumb.initDone === false;
		},
	},
};
</script>
