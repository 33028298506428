export default {
	data() {
		return {
			recordDeleted: false,
			recordDeletedEmited: false,
			gridNames: null,
		};
	},
	activated() {
		if (this.recordDeleted && !this.recordDeletedEmited) {
			this.$emit('view-record-deleted');
			this.recordDeletedEmited = true;
		}
	},
	methods: {
		onRecordDeleted(uid) {
			if (_.isEmpty(this.$vnode.key)) {
				const missingKeyErrorMessage = '[core-mixin.OnRecordDeleted] missing key parameter, ' +
					'component must be directly under <router-view> and contain key parameter, current key:';
				return console.error(missingKeyErrorMessage, this.$vnode.key);
			}
			if (_.isEmpty(uid)) {
				return console.error('[core-mixin.OnRecordDeleted] missing uid:', uid);
			}
			if (_.isEmpty(this.gridNames)) {
				return console.error('[core-mixin.OnRecordDeleted] gridNames are not set:', this.gridNames);
			}
			return (info) => this.checkRecordDeleted(info, uid);
		},
		checkRecordDeleted(info, uid) {
			if (
				!this.recordDeleted &&
				this.gridNames.includes(info.gridName) && (
					(!_.isEmpty(info.uid) && info.uid === uid) ||
					(!_.isEmpty(info.uids) && info.uids.includes(uid))
				)
			) {
				this.recordDeleted = true;

				if (!this._inactive) {
					this.$emit('view-record-deleted');
					this.recordDeletedEmited = true;
				}
			}
		},
	},
};
