// need to debug this? here ya go
/*
console.log(
	'anyChecked:', this.anyChecked(),
	'checkedAll:', this.checkedAll,
	'filterCheckedAll:', this.filterCheckedAll,
	'\nselectedRow:', this.selectedRow,
	'selectedRowIndexFrom:', this.selectedRowIndexFrom,
	'selectedRowIndexTo:', this.selectedRowIndexTo,
	'lastSelectedRowIndexFrom:', this.lastSelectedRowIndexFrom,
	'\nchecked:', JSON.stringify(this.checked, null, 2),
	'\nUNchecked:', JSON.stringify(this.unChecked, null, 2),
	'\nshiftChecked:', JSON.stringify(this.shiftChecked, null, 2)
);
*/

export default {
	data() {
		return {
			checked: {},
			unChecked: {},
			shiftChecked: {},
			checkedAll: false,
		};
	},
	created() {
		if (this.multiEnabled) {
			this.setColumnsProperties({
				id: 'checkbox',
				width: 40,
				minWidth: 40,
				initPosition: 0,
				visible: true,
				resizable: false,
				sortable: false,
				searchable: false,
			});
		}

		this.$root.$listen('content.removed', this.clearChecked, this);
	},
	methods: {
		shouldBeChecked(row) {
			return (
				!row._checkbox_disabled && (
					(
						(this.checkedAll || this.filterCheckedAll) &&
						!this.isUnChecked(row)
					) ||
					this.isChecked(row)
				)
			);
		},
		checkViewport() {
			this.clearChecked();

			for (let i = 0; i <= this.viewport.length; i += 1) {
				const row = this.viewport[i];
				if (row) {
					const rowID = this.getRowId(row);
					if (rowID && !row._checkbox_disabled) {
						this.$set(this.checked, rowID, true);
					}
				}
			}
			this.$emit('toggle-row-checkbox', this.anyChecked());
		},
		checkFiltered() {
			this.clearChecked();
			this.filterCheckedAll = true;
			this.$emit('toggle-row-checkbox', this.anyChecked());
		},
		checkedCount() {
			if (this.checkedAll || this.filterCheckedAll) {
				return this.getTotalRowsCount() - Object.keys(this.unChecked).length;
			}
			return Object.keys(this.checked).length;
		},
		clearChecked() {
			this.checked = {};
			this.unChecked = {};
			this.shiftChecked = {};
			this.checkedAll = false;
			this.filterCheckedAll = false;
			// this.selectedRow = null;
			this.lastSelectedRowIndexFrom = null;
			this.selectedRowIndexFrom = null;
			this.selectedRowIndexTo = null;
			this.$emit('toggle-row-checkbox', false);
		},
		getCheckedQueryOptions(options) {
			const newOptions = _.cloneDeep(options);
			delete newOptions._limit;
			delete newOptions._offset;
			delete newOptions._order;
			delete newOptions._order_dir;
			delete newOptions._scroll;
			return newOptions;
		},
		getChecked() {
			const gridFilters = _.cloneDeep(this.filterShow);
			gridFilters.forEach((filter) => {
				filter.conditionLabel = this.$t(filter.conditionLabel);
			});

			if (this.checkedAll) {
				return {
					checkedAll: this.checkedAll,
					unchecked: this.unChecked,
					...this.getCheckedQueryOptions(this.collection.originalQueryOptions),
					gridFilters,
				};
			}
			if (this.filterCheckedAll) {
				return {
					filterCheckedAll: this.filterCheckedAll,
					unchecked: this.unChecked,
					...this.getCheckedQueryOptions(this.collection.lastOptions),
					gridFilters,
				};
			}
			if (!_.isEmpty(this.checked)) {
				return { checked: this.checked, gridFilters };
			}
			return { gridFilters };
		},
		async toggleColumnCheckbox(event, row, index) {
			// only when called as @click event
			if (event && event.target && event.target.parentNode) {
				// all labels are with static class - remove it to enable animation
				event.target.parentNode.classList.remove('static');
			}

			// current state of checkbox input
			let checked;
			const rowID = this.getRowId(row);

			// clear that nasty selection
			if (this.shiftDown) {
				const selection = window.getSelection();
				selection.empty();
			}

			if (this.shiftDown && this.selectedRowIndexFrom >= 0) {
				this.shiftSelect(row, index);
			} else {
				if (this.checkedAll || this.filterCheckedAll) {
					checked = this.unChecked[rowID];
				} else {
					checked = !this.checked[rowID];
				}

				// everything is checked
				if (this.checkedAll || this.filterCheckedAll) {
					// row was unchecked and is checked again
					if (checked) {
						this.$delete(this.unChecked, rowID);
					// row is unchecked
					} else {
						this.$set(this.unChecked, rowID, true);
					}
				// row is checked
				} else if (checked) {
					this.$set(this.checked, rowID, true);
				// row is unchecked
				} else {
					this.$delete(this.checked, rowID);
				}

				this.selectedRowIndexFrom = index;
				this.selectedRowIndexTo = null;
				this.lastSelectedRowIndexFrom = null;
			}

			if (event && event.target) {
				// in case when you shift select and value of clicked checkbox should not change
				event.target.checked = this.shouldBeChecked(row);
			}
			this.$emit('toggle-row-checkbox', this.anyChecked());

			if (event) {
				setTimeout(() => {
					event.target.parentNode.classList.add('static');
				}, 500);
			}
			// this.checkShouldCloseDetail();
		},
		anyChecked() {
			return (
				(this.checkedAll || this.filterCheckedAll) &&
				Object.keys(this.unChecked).length < this.getTotalRowsCount()
			) || Object.keys(this.checked).length > 0;
		},
		isChecked(row) {
			return this.checked[this.getRowId(row)] || false;
		},
		isUnChecked(row) {
			return this.unChecked[this.getRowId(row)] || false;
		},
		checkAll() {
			this.clearChecked();
			this.checkedAll = true;
			this.$emit('toggle-row-checkbox', this.anyChecked());
			// this.checkShouldCloseDetail();
		},
		async shiftSelect(row, index) {
			let from = this.selectedRowIndexFrom;
			let to = index;
			let state = true;

			// if out of cached range
			if (from < 0 || from >= this.getTotalRowsCount() || to < 0 || to >= this.getTotalRowsCount()) {
				this.selectedRowIndexTo = null;
				return;
			}

			// if same from - remove selection (usefull when from > to or to < lastTo)
			if (this.lastSelectedRowIndexFrom === this.selectedRowIndexFrom) {
				const keys = Object.keys(this.shiftChecked);

				for (let i = 0; i < keys.length; i += 1) {
					if (this.checkedAll) {
						this.$delete(this.unChecked, keys[i]);
					} else {
						this.$delete(this.checked, keys[i]);
					}
				}
			}

			// unselect when shift + click same selection
			if (
				this.lastSelectedRowIndexFrom === this.selectedRowIndexFrom &&
				this.selectedRowIndexTo === to
			) {
				state = false;
				this.selectedRowIndexFrom = null;
				this.selectedRowIndexTo = null;
			} else {
				this.lastSelectedRowIndexFrom = this.selectedRowIndexFrom;
				this.selectedRowIndexTo = to;
			}

			if (to < from) {
				const tempTo = to;
				to = from;
				from = tempTo;
			}

			let checkRows = [];
			let loadCheckRows = null;
			const checkedAll = this.checkedAll || this.filterCheckedAll;

			if (from !== to) {
				// if ((to + 1) - from > this.collection.cacheLimit) {
				// 	this.$notify.warn(`${this.$t('tooManyRowsSelected')}. ${this.$t('limitIs')}: ${this.collection.cacheLimit}.`);
				// 	return;
				// }

				this.shiftChecked = {};
				const fromInRange = _.inRange(from, this.loadedIndexFirst, this.loadedIndexLast + 1);
				const toInRange = _.inRange(to, this.loadedIndexFirst, this.loadedIndexLast + 1);

				if (fromInRange && toInRange) {
					const viewportFrom = from - this.loadedIndexFirst;
					const viewportTo = (to + 1) - this.loadedIndexFirst;
					checkRows = this.viewport.slice(viewportFrom, viewportTo);
				} else if (fromInRange) {
					const viewportFrom = from - this.loadedIndexFirst;
					checkRows = this.viewport.slice(viewportFrom);
					console.debug('[LbaGrid](shiftSelect) fromInRange');
					loadCheckRows = this.collection.getAll(this.loadedIndexLast + 1, to - this.loadedIndexLast, { _scroll: true });
					this.refreshPages();
				} else if (toInRange) {
					const viewportTo = (to + 1) - this.loadedIndexFirst;
					checkRows = this.viewport.slice(0, viewportTo);
					console.debug('[LbaGrid](shiftSelect) toInRange');
					loadCheckRows = this.collection.getAll(from, this.loadedIndexFirst - from, { _scroll: true });
					this.refreshPages();
				}

				this.checkRows(checkRows, checkedAll, state);
			}

			if (loadCheckRows) {
				checkRows = await loadCheckRows;
				this.$emit('data-loaded');
				this.checkRows(checkRows, checkedAll, state);
				console.debug('[LbaGrid](shiftSelect) loaded');
			}
		},
		checkRows(rows, checkedAll, state) {
			rows.forEach((row) => {
				if (row._checkbox_disabled) return;
				const rowId = this.getRowId(row);

				if (checkedAll && state) {
					this.$set(this.shiftChecked, rowId, state);
					this.$set(this.unChecked, rowId, state);
				} else if (checkedAll && !state) {
					this.$delete(this.unChecked, rowId);
				} else if (state) {
					this.$set(this.shiftChecked, rowId, state);
					this.$set(this.checked, rowId, state);
				} else {
					this.$delete(this.checked, rowId);
				}
			});
		},
	},
};
