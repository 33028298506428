export default class {
	constructor($http) {
		this.$http = $http;
	}

	get() {
		return this.$http.get('lbadmin/pages-settings');
	}

	save(params) {
		return this.$http.post('lbadmin/pages-settings', { params });
	}
}
