export default class {
	constructor() {
		this.vm = null;
		this.versions = null;
		this.isActual = true;
	}

	check(versions) {
		this.isActual = true;
		if (!this.versions) {
			this.versions = this.parseVersions(versions);
		} else {
			const newVersions = this.parseVersions(versions);
			_.forEach(this.versions, (version, module) => {
				if (newVersions[module] !== version) this.isActual = false;
			});
		}
	}

	parseVersions(versions) {
		const ver = { lbadmin: versions.version };
		_.forEach(versions.modules, (el) => {
			ver[el.module] = el.version;
		});
		return ver;
	}

};
