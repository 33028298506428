import _ from 'lodash';
import PageSettingsModel from '../models/PageSettings';

class PageSettings {
	constructor() {
		this.vm = null;
		this.saveDebouncer = null;
		this.pageSettingsModel = null;
		this.pageSettings = {};
		this.routesToSave = new Set();
		this.isSaving = false;

		this.init = this.init.bind(this);
		this.load = this.load.bind(this);
		this.get = this.get.bind(this);
		this.savePageSettings = this.savePageSettings.bind(this);
		this.onPageSettingsChange = this.onPageSettingsChange.bind(this);
	}

	init(vm) {
		this.vm = vm;
		this.saveDebouncer = new vm.$Debouncer(this.vm, this.savePageSettings, null, 1000);
		this.pageSettingsModel = new PageSettingsModel(this.vm.$http);
		this.vm.$listen('page-settings.change', this.onPageSettingsChange, this.vm);
	}

	async load() {
		let result = null;
		try {
			result = await this.pageSettingsModel.get();
		} catch (error) {
			console.error('[plugin.PageSettings](init)', error);
			return;
		}

		if (!('data' in result)) {
			console.error('[plugin.PageSettings](init) missing data in result');
			return;
		}

		this.pageSettings = result.data;
	}

	get(componentName, name, createIfNotExists = true) {
		if (_.isEmpty(componentName)) {
			console.error('[plugin.PageSettings](get) missing componentName parameter');
			return;
		}
		if (_.isEmpty(name)) {
			console.error('[plugin.PageSettings](get) missing name parameter');
			return;
		}
		const routeName = _.get(this.vm.$route, 'matched[0].name');
		if (_.isEmpty(routeName)) {
			console.error('[plugin.PageSettings](get) can not get route name');
			return null;
		}
		let settings = _.get(this.pageSettings, `["${routeName}"].settings["${componentName}"]["${name}"]`, null);
		if (settings == null && createIfNotExists) {
			_.set(this.pageSettings, `["${routeName}"].settings["${componentName}"]["${name}"]`, {});
			settings = this.pageSettings[routeName].settings[componentName][name];
		}
		return settings;
	}

	async onPageSettingsChange(info) {
		if (_.isEmpty(info)) {
			console.error('[plugin.PageSettings](onPageSettingsChange) missing info:', info);
			return;
		}
		if (_.isEmpty(info.componentName)) {
			console.error('[plugin.PageSettings](onPageSettingsChange) missing componentName, info:', info);
			return;
		}
		if (_.isEmpty(info.name)) {
			console.error('[plugin.PageSettings](onPageSettingsChange) missing name, info:', info);
			return;
		}
		const routeName = _.get(this.vm.$route, 'matched[0].name', info.routeName);
		if (_.isEmpty(routeName)) {
			console.error('[plugin.PageSettings](get) can not get route name');
			return null;
		}

		if (this.savingPromise) {
			await this.savingPromise;
		}

		if ('data' in info) {
			_.set(this.pageSettings, `["${routeName}"].settings["${info.componentName}"]["${info.name}"]`, info.data);
		}
		this.routesToSave.add(routeName);
		this.saveDebouncer.emit();
	}

	async savePageSettings() {
		if (this.savingPromise) {
			await this.savingPromise;
		}

		let resolveSavingPromise = null;
		this.savingPromise = new Promise((resolve) => {
			resolveSavingPromise = resolve;
		});

		const pageSettings = {};
		this.routesToSave.forEach((routeToSave) => {
			pageSettings[routeToSave] = _.cloneDeep(this.pageSettings[routeToSave]);
		});
		this.routesToSave.clear();

		try {
			await this.pageSettingsModel.save(pageSettings);
		} catch (error) {
			console.error('[plugin.PageSettings](savePageSettings)', error);
		}

		this.savingPromise = null;
		resolveSavingPromise();
	}
}

export default {
	install(Vue) {
		const pageSettings = new PageSettings();
		Vue.prototype.$pageSettings = pageSettings;
		window.$pageSettings = pageSettings;
	},
};
