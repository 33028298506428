export default class {
	constructor($http) {
		this.$http = $http;
	}

	getOptions() {
		return this.$http.get(`lbadmin/options`);
	}

	setOptions(params) {
		return this.$http.post(`lbadmin/options`, { params });
	}

	getAuth() {
		return this.$http.get(`lbadmin/options/auth-methods`);
	}

}
