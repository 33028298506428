<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<div class="color_picker">
			<div class="color_picker_color" :style="{ backgroundColor: data[key] }"></div>
		</div>
	</component>
	<s
		v-else
		:data-cy="currentComponentId"
		:class="classes"
		:style="style"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<compact
			v-if="options && options.compact"
			:data-cy="`${currentComponentId}__sketch`"
			v-model="color"
			@input="onColor('input', $event)"
			@change="onColor('change', $event)"
			:style="[this.colorPicker ? { display: 'block' }: { display: 'none' }]"
		/>
		<sketch
			v-else
			:data-cy="`${currentComponentId}__sketch`"
			v-model="color"
			@input="onColor('input', $event)"
			@change="onColor('change', $event)"
			:style="[this.colorPicker ? { display: 'block' }: { display: 'none' }]"
		/>
		<div :data-cy="`${currentComponentId}__colorPicker`" class="color_picker" @click.stop="toggleColorPicker()">
			<div :data-cy="`${currentComponentId}__color`" class="color_picker_color" :style="{ backgroundColor: data[key] }"></div>
		</div>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';
import { Sketch, Compact } from 'vue-color';

export default {
	name: 'InputColor',
	mixins: [CustomInputMixin],
	components: {
		Sketch,
		Compact,
	},
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			colorPicker: false,
			color: {
				hex: null,
			},
		};
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
		this.prepareColorPicker();
	},
	watch: {
		colorPicker(newValue) {
			if (newValue === true) {
				document.body.addEventListener('click', this.clickCallback, true);
			} else {
				document.body.removeEventListener('click', this.clickCallback, true);
			}
		},
	},
	methods: {
		clickCallback(event) {
			if (this.parentNodeClass(event.target, 'vc-sketch')) {
				return;
			}
			if (this.parentNodeClass(event.target, 'vc-compact')) {
				return;
			}
			this.colorPicker = false;
			document.body.removeEventListener('click', this.clickCallback, true);
		},
		parentNodeClass(el, class_) {
			if (!el) {
				return false;
			}
			if (el.classList && el.classList.contains(class_)) {
				return true;
			}
			return this.parentNodeClass(el.parentNode, class_);
		},
		onColor(event, value) {
			this.data[this.key] = value && value.hex;
			this.onEvent(event);
		},
		prepareColorPicker() {
			if (this.options && this.options.ui_type === 'color') {
				this.color.hex = this.data[this.key];
			}
		},
		toggleColorPicker() {
			if (this.readOnly) {
				return;
			}
			this.colorPicker = !this.colorPicker;
		},
	},
};
</script>
