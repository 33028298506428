<template>
	<div id="menu" :class="{ menuSmall: state && !tempBigState, menuOverlay: overlay, menuBig: !state }">
		<!-- shows when menu is hidden and has lot of items -->
		<div
			v-show="classUp"
			data-cy="menu__goDown"
			@click="down"
			style="
				color: white;
				background-color: #3c3c3c;
				position: fixed;
				top: 40;
				left: 50px;
				width: 25px;
				height: 25px;
				border: 1px #333 solid;
				text-align: center;
			"
		>↓</div>
		<!-- TODO
		<div id="leftMenuPlugins" v-if="app.$plugins.leftMenu.length > 0">
			<span v-for="templateUrl in app.$plugins.leftMenu track by templateUrl"
				ng-include="templateUrl"
			></span>
		</div>-->
		<portal-target name="menu-top"></portal-target>
		<template v-for="(pluginID, index) in sortedPlugins">
			<div
				class="menuModule"
				:class="{ menuItemLineAfter: index < sortedPlugins.length - 1 }"
				:key="pluginID"
				:data-cy="`menu__plugin${index}`"
				v-html="$root.leftMenuPlugins[pluginID]"
			></div>
		</template>
		<template v-if="!isLoading">
			<div class="menu-top">
				<lba-menu-tree
					v-show="administrationItem == null || administrationCollapsed"
					v-for="(item) in menuItems"
					parentComponentId="menu"
					:componentId="`${item.module}__${item.name}`"
					:item="item"
					:key="item.name"
					:smallMenu="state"
					:tempBigMenu="tempBigState"
					@toggle-temp-big-menu="toggleTempBigMenu"
					@toggle-overlay="$emit('toggle-overlay')"
				></lba-menu-tree>
			</div>
			<lba-menu-tree
				v-if="administrationItem != null"
				parentComponentId="menu"
				componentId="administration"
				:item="administrationItem"
				:smallMenu="state"
				:tempBigMenu="tempBigState"
				@toggle-overlay="$emit('toggle-overlay')"
				@toggle-temp-big-menu="toggleTempBigMenu"
				@expanded="onAdministrationExpandToggle(true)"
				@collapsed="onAdministrationExpandToggle(false)"
			></lba-menu-tree>
		</template>
		<!-- menu is not small - option to hide it -->
		<span
			v-if="!state"
			id="menuSwitchHide"
			class="menuItem menuItemModule menuSwitch"
			data-cy="menu__hide"
			@click="toggle(true)"
		>
			<i class="icon-menu-hide"></i>
			<span>{{ $t('menu.hide') }}</span>
		</span>
		<!-- menu is small & expanded - option to hide it -->
		<span
			v-if="state && tempBigState"
			id="menuSwitchHide"
			class="menuItem menuItemModule menuSwitch"
			data-cy="menu__hide"
			@click="tempBigState = false"
		>
			<i class="icon-menu-hide"></i>
			<span>{{ $t('menu.hide') }}</span>
		</span>
		<!-- menu is small & not expanded - option to show it -->
		<span
			v-if="state && !tempBigState"
			id="menuSwitchShow"
			class="menuItem menuItemModule menuSwitch"
			data-cy="menu__show"
			@click="toggle(false)"
		>
			<i class="icon-menu-show"></i>
			<span>{{ $t('menu.show') }}</span>
		</span>
		<!-- shows when menu is hidden and has lot of items -->
		<div
			v-show="classDown"
			data-cy="menu__goUp"
			@click="up"
			style="
				color: white;
				background-color: #3c3c3c;
				position: fixed;
				bottom: 0;
				left: 50px;
				width: 25px;
				height: 25px;
				border: 1px #333 solid;
				text-align: center;
			"
		>↑</div>
	</div>
</template>

<script>
/* eslint-disable no-useless-escape */
const MENU_APPLICATION_ORDER = 3000;

export default {
	model: {
		prop: 'menuModules',
	},
	props: {
		overlay: Boolean,
		menuModules: Array,
	},
	data() {
		return {
			classUp: false,
			classDown: false,
			state: false,
			tempBigState: false,
			singleApp: false,
			modules: [],
			isLoading: true,
			isPreparingMenu: false,
			defaultAction: null,
			dialogOverlay: false,
			sortedPlugins: [],
			perms: null,
			administrationCollapsed: true,
		};
	},
	computed: {
		menuItems() {
			return this.modules.filter((item) => item.name !== 'administration');
		},
		administrationItem() {
			const item = this.modules[this.modules.length - 1];
			if (item && item.name === 'administration') return item;
			return null;
		},
	},
	async created() {
		await this.init();
		this.$root.$listen('menu-plugin.add', this.addMenuPlugin, this);
		this.$root.$listen('menu-plugin.remove', this.removeMenuPlugin, this);
		this.$root.$listen('menu-plugin.change', this.changeMenuPlugin, this);
		this.$root.$listen('user-changed', this.init, this);
		this.$root.$listen('user.updated', this.init, this);
		this.$root.$listen('menu.dynamic-menu-changed', this.init, this);
		this.$root.$listen('permissions-reload', this.userPermsChanged, this);
		this.$root.$listen('dialog-open', (options) => this.toggleDialogOverlay(options, true), this);
		this.$root.$listen('dialog-close', (options) => this.toggleDialogOverlay(options, false), this);
		window.addEventListener('resize', this.handleResize);
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize);
	},
	mounted() {
		this.sortPlugins();
	},
	methods: {
		toggleTempBigMenu(state) {
			this.tempBigState = state && this.state;
		},
		onAdministrationExpandToggle(state) {
			this.administrationCollapsed = !state;
		},
		sortPlugins() {
			const keys = Object.keys(this.$root.leftMenuPlugins);
			keys.sort((a, b) => {
				if (this.$root.leftMenuPlugins[a].order < this.$root.leftMenuPlugins[b].order) { return -1; }
				if (this.$root.leftMenuPlugins[a].order > this.$root.leftMenuPlugins[b].order) { return 1; }
				return 0;
			});
			this.sortedPlugins = keys;
		},
		addMenuPlugin(params) {
			this.$root.leftMenuPlugins[params.id] = params.plugin;
			this.sortPlugins();
		},
		changeMenuPlugin(params) {
			this.removeMenuPlugin(params.id);
			this.addMenuPlugin(params);
		},
		removeMenuPlugin(id) {
			delete this.$root.leftMenuPlugins[id];
		},
		toggleDialogOverlay(options, state) {
			if (state && options.name === 'closeTabDialog') {
				this.dialogOverlay = state;
			} else if (!state && options === 'closeTabDialog') {
				this.dialogOverlay = state;
			}
		},
		/**
		 * compare old and new menu permissions, if changed, refresh menu
		 */
		async userPermsChanged() {
			const oldPerms = _.cloneDeep(this.perms);

			this.perms = (await this.$http.get('lbadmin/menu-perms')).data;

			for (let i = 0; i < this.$user.loadedModules.length; i += 1) {
				const moduleName = this.$user.loadedModules[i];
				const module = window[moduleName];

				if (module.prepareMenuPerms != null) {
					await module.prepareMenuPerms(this.$root, this.perms);
				}
			}

			const oldKeys = Object.keys(oldPerms).sort();
			const newKeys = Object.keys(this.perms).sort();

			if (oldKeys.length !== newKeys.length || !_.isEqual(oldKeys, newKeys)) {
				this.init({}, true);
			}

			const anyChange = oldKeys.some((oldKey) => !_.isEqual(oldPerms[oldKey].sort(), this.perms[oldKey].sort()));

			if (anyChange) {
				this.init({}, true);
			}
		},
		async init(params = {}, permsChecked = false) {
			if (this.isPreparingMenu) return;
			this.isPreparingMenu = true;
			this.isLoading = true;

			const response = await this.$http.get('lbadmin/menu', { params });
			this.$user.menuModuleOrder = [];
			this.$user.wholeMenu = [];
			this.modules = [];
			let modules = _.cloneDeep(this.$root.menu);

			if (!permsChecked) {
				// get menu perms
				this.perms = (await this.$http.get('lbadmin/menu-perms')).data;

				for (let i = 0; i < this.$user.loadedModules.length; i += 1) {
					const moduleName = this.$user.loadedModules[i];
					const module = window[moduleName];

					if (module.prepareMenuPerms != null) {
						await module.prepareMenuPerms(this.$root, this.perms);
					}
				}
			}

			// module contains either array or object -> join to one array
			response.data.forEach((module) => {
				if (module.constructor === Object) {
					modules.push(module);

					if (module.name !== 'administration') {
						this.$user.menuModuleOrder.push({ name: module.name, order: module.order || 99000 });
					}
				} else if (module.constructor === Array) {
					modules = _.concat(modules, module);
					module.forEach((menuModule) => {
						if (menuModule.name !== 'administration') {
							this.$user.menuModuleOrder.push({ name: menuModule.name, order: menuModule.order || 99000 });
						}
					});
				} else {
					console.error('menu error: recieved unknown type of menu module', module);
				}
			});

			this.$user.menuModuleOrder.sort(this.sortMenu);

			// also join dynamic menus to one array
			this.$user.dynamicMenus.forEach((module) => {
				if (module.constructor === Object) {
					modules.push(module);
				} else {
					modules = _.concat(modules, module);
				}
			});

			// merge multiple menus
			for (let i = 0; i < modules.length; i += 1) {
				const module = modules[i];

				if (module.multiple) {
					if (module.multipleHandled) {
						continue;
					}
					for (let j = i + 1; j < modules.length; j += 1) {
						const searchModule = modules[j];
						if (!searchModule.multiple) {
							continue;
						}
						if (searchModule.name === module.name) {
							searchModule.multipleHandled = true;
							module.items = _.concat(module.items, searchModule.items);

							if (!module.translatedName && searchModule.translatedName) {
								module.translatedName = searchModule.translatedName;
							}
						}
					}
				}
				this.modules.push(module);
			}

			this.translate(this.modules);

			this.modules.sort(this.sortMenu);

			// get menu applications
			const menuApplications = [];
			let firstMenuApplication = null;
			let lastMenuApplication = null;

			for (let i = 0; i < this.modules.length; i += 1) {
				const module = this.modules[i];

				if (module.order === MENU_APPLICATION_ORDER) {
					if (firstMenuApplication == null) {
						firstMenuApplication = i;
					}

					menuApplications.push(module);
				} else if (firstMenuApplication != null && lastMenuApplication == null) {
					lastMenuApplication = i;
				}
			}

			// sort menu applications
			menuApplications.sort(this.sortByGroup);
			this.modules.splice(firstMenuApplication, menuApplications.length, ...menuApplications);

			for (let i = 0; i < this.modules.length; i += 1) {
				const module = this.modules[i];
				module.rootItem = true;
				module.openOnLoad = this.isActive(module);
			}

			// sort administration
			const lastMenuItem = this.modules[this.modules.length - 1];

			if (lastMenuItem.name === 'administration') {
				lastMenuItem.items.sort(this.sortMenu);
			}

			if (
				this.singleApp &&
				this.$route.matched.length > 0 &&
				this.$route.matched[0].name === 'home'
			) {
				this.$routerWrap.push(this.defaultAction);
			}

			this.$user.wholeMenu = this.modules;
			await this.filterMenuByPemissions();
			this.$emit('input', this.modules);

			this.isPreparingMenu = false;
			this.isLoading = false;
		},
		sortMenu(menuItemA, menuItemB) {
			return (menuItemA.order || 99000) - (menuItemB.order || 99000);
		},
		sortByGroup(menuItemA, menuItemB) {
			const rootA = this.modules.find((menuItem) => menuItem.name === menuItemA.group);
			const rootB = this.modules.find((menuItem) => menuItem.name === menuItemB.group);
			const orderA = (rootA && rootA.order) || 99000;
			const orderB = (rootB && rootB.order) || 99000;
			return orderA - orderB;
		},
		translate(items, rootItem) {
			for (let i = 0; i < items.length; i += 1) {
				const item = items[i];
				const rootItemName = rootItem ? rootItem.name : item.name;

				/* (rootItem && rootItem.multiple) && */
				if (!item.translatedName) {
					if (item.translatePath) {
						item.translatedName = this.$t(item.translatePath);
					} else if (item.group) {
						item.translatedName = this.$t(`${item.group}.menu.${item.name}`);
					} else {
						item.translatedName = this.$t(`${rootItemName}.menu.${item.name}`) || item.name;
					}
				}
				/* else if (item.multiple) {
					item.translatedName = this.$t(`${item.name}`);
				} */

				if (item.items) {
					if (!rootItem) {
						this.translate(item.items, item);
					} else {
						this.translate(item.items, rootItem);
					}
				}
			}
		},
		// item page loaded/shown
		isActive(item) {
			let found = false;

			if (item.action) {
				const escaped = item.action.replace(/[\.\*\+\?\^\$\{\}\(\)\|\[\]\\\/]/g, '\\$&');
				const re = new RegExp(`^\\/${escaped}($|\\?|\\/)`);
				found = re.test(this.$route.path);
			} else if (item.actionName) {
				found = item.actionName === this.$route.name;
			}

			if (!found && item.items) {
				found = item.items.find((childItem) => {
					if (this.isActive(childItem)) {
						return true;
					}

					return false;
				}) != null;
			}

			if (found) {
				item.openOnLoad = true;
			}

			return found;
		},
		up(event) {
			const nodes = event.srcElement.parentElement.parentElement.getElementsByClassName('menuModule');
			nodes[0].scrollIntoView();
			this.classup = true;
			this.classdown = false;
		},
		down(event) {
			const nodes = event.srcElement.parentElement.parentElement.getElementsByClassName('menuModule');
			nodes[nodes.length - 1].scrollIntoView();
			this.classup = false;
			this.classdown = true;
		},
		toggle(state) {
			this.state = state;

			if (this.state) {
				const nodes = document.getElementsByClassName('menuModule');
				const menuHeight = nodes[0].clientHeight * nodes.length;

				if (document.documentElement.clientHeight < menuHeight) {
					this.classdown = true;
					this.classup = false;
				}
			}
		},
		filterMenuByPemissions() {
			const modules = _.cloneDeep(this.modules);
			const menuModulesSorted = [];
			const newMenu = modules.filter((el) => {
				if ((el.module || el.group) &&
					(menuModulesSorted.indexOf(el.module || el.group) === -1)) {

					menuModulesSorted.push(el.module || el.group);
				}
				if (el.name === 'administration') {
					const newAdmin = el.items.filter((item) => {
						if (!item.items || item.items.length === 0) {
							// admin item dont have subitems, check if I have perm or it is dynamic
							if (
								(item.dynamic && !window[item.group].prepareMenuPerms) ||
								(this.perms[item.group] && this.perms[item.group].includes(`menu.${item.name}`))
							) {
								return item;
							}
						} else {
							// admin item has items, so list throught them and add only those, I have perms to or it is dynamic
							const newItems = item.items.filter((subItem) => {
								if (
									(subItem.dynamic && !window[item.group].prepareMenuPerms) ||
									(this.perms[item.group] && this.perms[item.group].includes(`menu.${subItem.name}`))
								) {
									return subItem;
								}
							});
							// add menu item only if it has some available subitems
							if (newItems.length) {
								item.items = newItems;
								return item;
							}
						}

					});
					if (newAdmin.length) {
						el.items = newAdmin;
						return el;
					}

				} else {
					if (!el.items || el.items.length === 0) {
						// module dont have items, check if I have perm or it is dynamic
						if (
							(el.dynamic && !window[el.module].prepareMenuPerms) ||
							(this.perms[el.module] && this.perms[el.module].includes(`menu.${el.name}`))
						) {
							return el;
						}
					} else {
						// module has items, so list throught them and add only those, I have perms to or it is dynamic
						const newItems = el.items.filter((item) => {
							if (
								(item.dynamic && !window[el.module].prepareMenuPerms) ||
								(this.perms[el.module] && this.perms[el.module].includes(`menu.${item.name}`))
							) {
								return item;
							}
						});
						// add menu item only if it has some available subitems
						if (newItems.length) {
							el.items = newItems;
							return el;
						}
					}
				}
			});
			this.$user.menuModulesSorted = menuModulesSorted;
			this.modules = newMenu;
		},
		handleResize(event) {
			if (window.innerWidth > 768 && window.innerWidth <= 1145) {
				this.state = true;
			} else {
				this.state = false;
			}
		},
	},
};
</script>
