var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"page-header"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("settings.gr"))+" "),_c('span',{staticClass:"gray"},[_vm._v(_vm._s(_vm.meta.name))])]),_c('div',{staticClass:"page-controls"},[(!_vm.isNew && !_vm.readOnly)?_c('button',{staticClass:"buttonTransparent",attrs:{"name":"openRemoveDialog","data-cy":"group__openDialogRemove"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteGroup($event)}}},[_vm._v(" "+_vm._s(_vm.$t('remove'))+" ")]):_vm._e(),(!_vm.readOnly)?_c('button',{staticClass:"button",attrs:{"name":"save","disabled":!_vm.dirty,"data-cy":"group__save"},on:{"click":function($event){$event.stopPropagation();return _vm.submit($event)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e(),(!_vm.isNew)?_c('span',{attrs:{"data-cy":"group__reload"},on:{"click":_vm.tabReload}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('refreshData')),expression:"$t('refreshData')"}],staticClass:"icon-reset"})]):_vm._e()])]),_c('div',{staticClass:"page-content"},[_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"name":"form","lba-size-class":"{'contentWide': 700}"}},[_c('lba-section',{attrs:{"parentComponentId":"group","title":_vm.$t('settings.groupSettings'),"slotType":"form","expanded":""}},[_c('ValidationProvider',{staticStyle:{"display":"inline-block"},attrs:{"name":_vm.$t('settings.groupName'),"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('s',[_c('small',[_vm._v(" "+_vm._s(_vm.$t('settings.groupName'))+" "),_c('span',{staticClass:"required-sign"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group.role_name),expression:"group.role_name"}],class:{ 'lba-invalid': invalid && _vm.dirty },attrs:{"data-cy":"group__groupName__inputText","type":"text","name":"role_name","disabled":_vm.readOnly},domProps:{"value":(_vm.group.role_name)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.group, "role_name", $event.target.value)},function($event){_vm.dirty = true}]}}),(_vm.dirty)?_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages",attrs:{"data-cy":("group__groupName__error" + index)}},[_vm._v(_vm._s(err))])}):_vm._e()],2)]}}],null,false,839108309)}),_c('lba-dnd-two-lists',{ref:"dnd",staticClass:"popupScrollable",attrs:{"parentComponentId":"group","componentId":"members","name":"settings-group-members-dialog","dialogTitle":_vm.$t('settings.groupMembersDialog'),"list1":_vm.tmpMembers,"list2":_vm.tmpSelectedMembers,"listTitle1":_vm.$t('settings.availableRoles'),"listTitle2":_vm.$t('settings.appendedRoles'),"staticListItems1":false,"staticListItems2":false,"staticListOrder1":true,"staticListOrder2":true,"sort":_vm.sortMembers},on:{"update:list1":function($event){_vm.tmpMembers=$event},"update:list2":function($event){_vm.tmpSelectedMembers=$event},"save":_vm.saveMembers},scopedSlots:_vm._u([{key:"list1",fn:function(props){return _vm._l((props.list),function(role,index){return _c('div',{key:index,staticClass:"flex-row inner border"},[_c('label',{staticClass:"checkbox label",staticStyle:{"width":"100%"},attrs:{"data-cy":("group__list1__member" + index + "__checkboxLabel")}},[_c('input',{attrs:{"type":"checkbox","lba-dnd-list-checkbox":"","data-cy":("group__list1__member" + index + "__inputCheckbox")}}),_c('i',{staticClass:"icon-ok"}),_c('span',{staticClass:"label"},[_c('i',{class:{
												'icon-user': role.role_type === 'USER',
												'icon-users': role.role_type === 'GROUP',
											},staticStyle:{"padding":"5px","border-radius":"50%","background-color":"#00b8ff","color":"white"},attrs:{"title":("" + (role.role_type === 'GROUP' ? _vm.$t('settings.group') : _vm.$t('settings.user.user')))}}),_vm._v(" "+_vm._s(role.role_name)+" ")])])])})}},{key:"list2",fn:function(props){return _vm._l((props.list),function(role,index){return _c('div',{key:index,staticClass:"flex-row inner border"},[_c('label',{staticClass:"checkbox label",staticStyle:{"width":"100%"},attrs:{"data-cy":("group__list2__member" + index + "__checkboxLabel")}},[_c('input',{attrs:{"type":"checkbox","lba-dnd-list-checkbox":"","data-cy":("group__list2__member" + index + "__inputCheckbox")}}),_c('i',{staticClass:"icon-ok"}),_c('span',{staticClass:"label"},[_c('i',{class:{
												'icon-user': role.role_type === 'USER',
												'icon-users': role.role_type === 'GROUP',
											},staticStyle:{"padding":"5px","border-radius":"50%","background-color":"#00b8ff","color":"white"},attrs:{"title":("" + (role.role_type === 'GROUP' ? _vm.$t('settings.group') : _vm.$t('settings.user.user')))}}),_vm._v(" "+_vm._s(role.role_name)+" ")])])])})}}],null,false,4078591882)}),_c('s',[_c('small',[_vm._v(" "+_vm._s(_vm.$t('settings.groupMembers'))+" ")]),_c('button',{staticClass:"buttonIcon",attrs:{"data-cy":"group__openDialogMembers","type":"button","disabled":_vm.readOnly},on:{"click":_vm.openMembersDialog}},[_c('i',{staticClass:"icon-edit"}),_vm._v(_vm._s(_vm.$t('settings.groupMembersEdit'))+" ")])]),_c('br'),(_vm.group.members.length)?_c('s',{staticClass:"size-3 mt-5"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('settings.groupMembers'))+" ")]),_c('table',{key:_vm.tableId,staticClass:"content-table wide mt-1"},[_c('tr',[_c('th',{staticStyle:{"width":"50%"}},[_vm._v(_vm._s(_vm.$t('settings.member')))]),_c('th',{staticStyle:{"width":"50%"}},[_vm._v(_vm._s(_vm.$t('settings.profile')))])]),_vm._l((_vm.group.members),function(member,memberIndex){return _c('tr',{key:memberIndex},[_c('td',[_c('a',{key:("i-" + memberIndex),attrs:{"data-cy":("group__members__member" + memberIndex + "__openMember")},on:{"click":function($event){return _vm.openDetail(member.role_type, member.role_uid)}}},[_c('i',{class:{
												'icon-user': member.role_type === 'USER',
												'icon-users': member.role_type === 'GROUP',
											},attrs:{"title":("" + (member.role_type === 'GROUP' ? _vm.$t('settings.group') : _vm.$t('settings.user.user')))}}),_vm._v(" "+_vm._s(member.role_name)+" ")])]),_c('td',{staticStyle:{"display":"flex","align-items":"center","flex-direction":"column","min-height":"1.5em"}},[_c('lba-select',{key:("member-" + memberIndex + "-" + _vm.tableId),staticStyle:{"width":"100%"},attrs:{"opts":_vm.labeledProfiles,"multiple":true,"renderHtmlLabels":true,"rootTag":'div',"noNotSetOption":"","disableUnknown":"","combobox":true,"readOnly":_vm.readOnly,"topPlaceholder":member.user_profiles && member.user_profiles.length ?
											_vm.$t('settings.inherited', { profiles: member.user_profiles.join(', ') }) :
											_vm.$t('settings.inheritedFromTeam', { team: member.role_name })},on:{"change":function($event){_vm.dirty = true}},model:{value:(member.profiles),callback:function ($$v) {_vm.$set(member, "profiles", $$v)},expression:"member.profiles"}})],1)])})],2)]):_vm._e()],1),_c('lba-section',{attrs:{"parentComponentId":"group","title":_vm.$t('settings.memberOf'),"slotType":"form","expanded":""}},[(_vm.group.member_of && _vm.group.member_of.length)?_c('s',{staticClass:"size-3"},[_c('div',[_vm._l((_vm.group.member_of),function(memberOf,memberOfIndex){return [_c('i',{key:("iii-" + memberOfIndex),staticClass:"icon-users",staticStyle:{"color":"#999"},attrs:{"title":_vm.$t('settings.group')}}),_c('a',{key:("i-" + memberOfIndex),attrs:{"data-cy":("group__member_of__member" + memberOfIndex + "__openMember")},on:{"click":function($event){return _vm.openDetail('GROUP', memberOf.role_uid)}}},[_vm._v(" "+_vm._s(memberOf.role_name)+" ")]),(memberOfIndex <= _vm.group.member_of.length - 2)?_c('span',{key:("ii-" + memberOfIndex)},[_vm._v(", ")]):_vm._e()]})],2)]):_c('span',[_vm._v(_vm._s(_vm.$t('notSet')))])]),_c('lba-confirm-remove-dialog',{attrs:{"parentComponentId":"group","componentId":"confirmDelete","name":"group-confirm-delete","title":_vm.$t('settings.deleteGroup'),"message":((_vm.group.role_name) + " ?"),"on-confirm":_vm.deleteGroupConfirm}})],1)])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }