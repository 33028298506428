<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<!-- {{ valueLabel }} -->
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<span>
			<lba-dnd-list2
				:parentComponentId="currentComponentId"
				:list.sync="data[key]"
			>
				<template v-slot:list>
					<div
						v-for="(option, index) in data[key]"
						:key="'option' + index"
						class="formGroup wide"
					>
						<div class="formGroupHeader">
							<span class="formGroupLabel" style="display: initial">
								<span class="formGroupOrder" style="margin-right: 10px;">{{ index + 1 }}</span>
								<small>{{ $t('crm.value') }}: </small>
									<input
										:data-cy="`${currentComponentId}__optionValueInput${index}`"
										style="width: 150px; margin-right: 10px;"
										v-model="option.value"
										@input="onEvent('input')"
										@change="onEvent('change')"
									/>&nbsp;
								<small>{{ $t('crm.label') }}: </small>
									<input
										:data-cy="`${currentComponentId}__optionLabelInput${index}`"
										style="width: 180px"
										v-model="option.label"
										@input="onEvent('input')"
										@change="onEvent('change')"
									/>
							</span>
							<span class="formGroupControls">
								<a :data-cy="`${currentComponentId}__removeOption${index}`"
									v-tooltip="$t('remove')" v-on:click="delOption(index)"
								>
									<i class="icon-delete"></i>
								</a>
								<a :data-cy="`${currentComponentId}__addOption${index}`"
									v-tooltip="$t('add')" v-on:click="addOption()"
								>
									<i class="icon-plus-circle"></i>
								</a>
							</span>
						</div>
					</div>
				</template>
			</lba-dnd-list2>
		</span>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputOptions',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
		if (!this.data[this.key]) {
			this.data[this.key] = [{
				value: null,
				label: null,
			}];
		}
	},
	methods: {
		addOption() {
			if (!this.data[this.key]) {
				this.data[this.key] = [];
			}
			this.data[this.key].push({
				value: null,
				label: null,
			});
		},
		delOption(index) {
			this.data[this.key].splice(index, 1);
			if (this.data[this.key].length === 0) {
				this.data[this.key].push({
					value: null,
					label: null,
				});
			}
		},
	},
};
</script>
