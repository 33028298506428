var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"inputPass userSelectNone input",class:{
		passStrength: _vm.showPasswdStrength,
		passWeak: _vm.weakPasswd,
		passFair: _vm.mediumPasswd,
		passStrong: _vm.strongPasswd,
		passVisible: !_vm.showPasswd,
		passReadable: _vm.passwdReadable,
		passEditable: _vm.passwdEditable,
		passDisabled: _vm.passwdDisabled
	},staticStyle:{"max-width":"250px"}},[_c('button',{attrs:{"type":"button","title":_vm.title,"data-cy":(_vm.currentComponentId + "__toggleShowHide")},on:{"click":_vm.toggle}},[_c('i',{staticClass:"icon-pass-show"}),_c('i',{staticClass:"icon-pass-hide"})]),_c('button',{staticClass:"inputPassGenerate",attrs:{"data-cy":(_vm.currentComponentId + "__generatePassword"),"type":"button","title":_vm.$t('generatePass')},on:{"click":_vm.generatePassword,"~click":function($event){return _vm.onTouch($event)}}},[_c('i',{staticClass:"icon-pass-generate"})]),(!_vm.isPin)?_c('span',{staticClass:"inputPassStrength"},[_c('i',{staticClass:"icon-pass-weak",attrs:{"title":"Weak password"}}),_c('i',{staticClass:"icon-pass-fair",attrs:{"title":"Fair password"}}),_c('i',{staticClass:"icon-pass-strong",attrs:{"title":"Strong password"}})]):_vm._e(),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],ref:"input",class:{
				'lba-invalid': _vm.invalid
			},attrs:{"data-cy":(_vm.currentComponentId + "__inputPassword"),"disabled":_vm.passwdInputDisabled,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.input)?_vm._i(_vm.input,null)>-1:(_vm.input)},on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur,"~blur":function($event){return _vm.onTouch($event)},"input":_vm.checkPasswdStrength,"~input":function($event){return _vm.onTouch($event)},"change":function($event){var $$a=_vm.input,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.input=$$a.concat([$$v]))}else{$$i>-1&&(_vm.input=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.input=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],ref:"input",class:{
				'lba-invalid': _vm.invalid
			},attrs:{"data-cy":(_vm.currentComponentId + "__inputPassword"),"disabled":_vm.passwdInputDisabled,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.input,null)},on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur,"~blur":function($event){return _vm.onTouch($event)},"input":_vm.checkPasswdStrength,"~input":function($event){return _vm.onTouch($event)},"change":function($event){_vm.input=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input),expression:"input"}],ref:"input",class:{
				'lba-invalid': _vm.invalid
			},attrs:{"data-cy":(_vm.currentComponentId + "__inputPassword"),"disabled":_vm.passwdInputDisabled,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.input)},on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur,"~blur":function($event){return _vm.onTouch($event)},"input":[function($event){if($event.target.composing){ return; }_vm.input=$event.target.value},_vm.checkPasswdStrength],"~input":function($event){return _vm.onTouch($event)}}}),(_vm.hasPassword)?_c('span',{staticClass:"passOverlay passOverlayRead",on:{"click":_vm.read}},[_c('i',{staticClass:"con-pass-show"}),_vm._v(" "+_vm._s(_vm.$t('showPassword'))+" ")]):_vm._e(),(_vm.hasPassword)?_c('span',{staticClass:"passOverlay passOverlayEdit",on:{"click":_vm.edit}},[_c('i',{staticClass:"icon-edit"}),_vm._v(_vm._s(_vm.$t('changePassword'))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }