<template>
	<div>
		<lba-dialog-modal
			:name="dialogName"
			:title="dialogTitle"
			modal
			ref="dialog"
			class="popup-modal fullwidth"
		>
			<div
				class="popup-body py-0"
			>
					<s class="editorInDialog">
						<span>
							<lba-ckeditor
								data-cy="LbaCkEditorDialog__note__textarea"
								v-model="attribute[dataKey]"
								@change="dirty = true; inputDirty = true"
								@input="inputDirty = true"
								:disabled="readOnly"
							/>
						</span>
					</s>
			</div>
			<div class="popup-footer">
				<button
					@click.stop="edit()"
					data-cy="LbaCkEditorDialog__ok__button"
					class="button"
				>
					{{ $t('ok') }}
				</button>
			</div>
		</lba-dialog-modal>
	</div>
</template>

<script>
export default {
	name: 'CkEditorDialog',
	props: {
		dialogName: {
			type: String,
			required: true,
		},
		dialogTitle: {
			type: String,
			required: true,
		},
		attribute: {
			type: Object,
			required: true,
		},
		dataKey: {
			type: String,
			required: true,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		this.prepareComponent(this.attribute);
	},
	methods: {
		edit() {
			this.$root.$emit('dialog-close', this.dialogName);
		},
		prepareComponent(attr) {
			if (!attr[this.dataKey]) {
				this.$set(this.attribute, `${this.dataKey}`, '');
			}
		},
	},
};
</script>
