<template>
	<div class="player">
		<div class="player-controls">
			<div id="play">
				<a v-on:click.prevent.stop="play()" v-tooltip="(playing) ? $t('pause'): $t('play')" href="#"
					:data-cy="`${currentComponentId}__togglePlayPause`"
				>
					<i v-if="!playing" class="icon-play"></i>
					<i v-else class="icon-pause"></i>
				</a>
			</div>
			<div id="stop">
				<a v-on:click.prevent.stop="stop" href="#" v-tooltip="$t('stop')" :data-cy="`${currentComponentId}__stop`">
					<i class="icon-stop"></i>
				</a>
			</div>
			<div id="stop" v-if="downloadable">
				<a v-on:click.stop="download()" href="#" v-tooltip="$t('download')">
					<i class="icon-download"></i>
				</a>
			</div>
			<div id="seek">
				<div class="player-timeline">
					<div :style="progressStyle" class="player-progress" :data-cy="`${currentComponentId}__progress`"></div>
					<div v-on:click.stop="seek" class="player-seeker" title="Seek" :data-cy="`${currentComponentId}__seek`"></div>
				</div>
				<div class="player-time">
					<div class="player-time-current">{{ this.currentSeconds | convertTimeHHMMSS }}</div>
					<div class="player-time-total">{{ this.durationSeconds | convertTimeHHMMSS }}</div>
				</div>
			</div>
			<div id="volume">
				<a v-on:click.prevent.stop="" :title="volumeTitle" href="#" style="display: flex;">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
						<path
							fill="currentColor"
							d="M19,13.805C19,14.462,18.462,15,17.805,15H1.533c-0.88,0-0.982-0.371-0.229-0.822l16.323-9.055C18.382,
							4.67,19,5.019,19,5.9V13.805z"/>
					</svg>
					<vue-slider
						:data-cy="`${currentComponentId}__vue-slider`"
						v-model.lazy.number="volume"
						direction="ltr"
						:width="60"
						:tooltip="'none'"
						style="margin:0 15px 0 15px;"
					/>
				</a>
			</div>
		</div>
		<audio
			:data-cy="`${currentComponentId}__audio`"
			:loop="loop"
			ref="audio"
			:src="file"
			v-on:timeupdate="update"
			v-on:loadeddata="load"
			v-on:pause="playing = false"
			v-on:play="playing = true"
			:preload="preload"
			style="display: none;"
		></audio>
	</div>
</template>

<script>
import { basename } from 'path';
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	name: 'LbaAudio',
	mixins: [ComponentIdentifier],
	props: {
		autoPlay: {
			type: Boolean,
			default: true,
		},
		file: {
			type: String,
			default: null,
		},
		loop: {
			type: Boolean,
			default: false,
		},
		preload: {
			type: String,
			default: 'none',
		},
		volume: {
			type: Number,
			default: 100,
		},
		duration: {
			default: 0,
		},
		downloadable: {
			type: Boolean,
			default: false,
		},
		filename: {
			type: String,
			default: null,
		},
		listenToSeek: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: null,
		},
	},
	data: () => ({
		currentSeconds: 0,
		durationSeconds: 0,
		loaded: false,
		playing: false,
		player_uid: 0,
	}),
	computed: {
		percentComplete() {
			return parseInt(this.currentSeconds / this.durationSeconds * 100);
		},
		progressStyle() {
			return { width: `${this.percentComplete}%` };
		},
		volumeTitle() {
			return `Volume (${this.volume}%)`;
		},
		fileName() {
			if (this.filename) return this.filename;
			const name = basename(this.file);
			return name.replace(/\./, '_');
		},
	},
	filters: {
		convertTimeHHMMSS(val) {
			const hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

			return hhmmss.indexOf('00:') === 0 ? hhmmss.substr(3) : hhmmss;
		},
	},
	watch: {
		playing(value) {
			if (value) {
				this.$refs.audio.play();
				this.$emit('play');
			} else {
				this.$refs.audio.pause();
				this.$emit('pause');
			}
		},
		volume(value) {
			this.$refs.audio.volume = this.volume / 100;
		},
		currentSeconds() {
			this.$emit('currentSeconds', this.currentSeconds);
		},
	},
	created() {
		if (this.id) {
			this.player_uid = this.id;
		} else {
			this.player_uid = this.$generateUID();
		}

		const dur = typeof this.duration;
		if (dur === 'number') {
			this.durationSeconds = _.cloneDeep(this.duration);
		} else if (dur === 'object') {
			this.duration.hours = this.duration.hours ? this.duration.hours : 0;
			this.duration.minutes = this.duration.minutes ? this.duration.minutes : 0;
			this.duration.seconds = this.duration.seconds ? this.duration.seconds : 0;
			this.durationSeconds = (this.duration.hours * 60 * 60) + (this.duration.minutes * 60) + this.duration.seconds;
		}

		if (this.listenToSeek) {
			this.$root.$listen('lbaudio-seek', (opts) => {
				if (opts && opts.id === this.player_uid) {
					opts.seek = opts.seek <= 1 ? 0 : opts.seek - 1;
					this.$refs.audio.currentTime = opts.seek;
					this.currentSeconds = opts.seek;
					if (opts.play) {
						this.playing = true;
						this.$root.$emit('lbaudio-playing', this.player_uid);
					}
				}
			}, this, true);
		}
	},
	methods: {
		load() {
			if (this.$refs.audio?.readyState >= 2) {
				this.loaded = true;
				this.durationSeconds = parseInt(this.$refs.audio.duration);

				if (!this.playing) {
					this.playing = this.autoPlay;
				}
				return;
			}

			console.warn('Failed to load sound file.');
		},
		seek(e) {
			if (!this.loaded) return;

			const bounds = e.target.getBoundingClientRect();
			const seekPos = (e.clientX - bounds.left) / bounds.width;

			this.$refs.audio.currentTime = parseInt(this.$refs.audio.duration * seekPos);
		},
		stop() {
			this.playing = false;
			this.$refs.audio.currentTime = 0;
		},
		update(e) {
			if (!this.$refs.audio || !this.$refs.audio.currentTime) return;
			this.currentSeconds = parseInt(this.$refs.audio.currentTime);
		},
		play() {
			this.playing = !this.playing;
			if (this.playing) {
				this.$root.$emit('lbaudio-playing', this.player_uid);
				this.$root.$listen('lbaudio-playing', (uid) => {
					if (uid !== this.player_uid && this.playing) this.play();
				}, this, true);
			}
		},
		download() {
			this.$downloadFile(this, this.file.replace('/api/lbadmin', ''), this.fileName);
		},
	},
};
</script>
