<template>
	<div id="list-content" data-cy="list-content">
		<div id="list">
			<slot name="list" parentComponentId="list-content__slotList"></slot>
		</div>
		<div id="options" class="optionsWide hideControls active" v-lba-right-panel-resizer data-cy="list-content__options">
			<div class="optionsContent">
				<router-view
					v-show="!getAnyError()"
					ref="view"
					:key="routeKey"
					@view-not-found="onError($event, 'notFound')"
					@view-permission-denied="onError($event, 'permissionDenied')"
					@view-server-error="onError($event, 'serverError')"
					@view-record-deleted="onError($event, 'recordDeleted')"
					@view-missing-parameter="onError($event, 'missingParameter')"
					@view-wrong-parameter="onError($event, 'wrongParameter')"
				></router-view>
				<div v-if="getAnyError()" class="error-wrap" data-cy="list-content__options__anyError">
					<h1 v-if="getErrorType() === 'notFound'" data-cy="list-content__options__viewNotFound">
						{{ $t('viewNotFound') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'permissionDenied'" data-cy="list-content__options__viewPermissionDenied">
						{{ $t('viewPermissionDenied') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'serverError'" data-cy="list-content__options__viewServerError">
						{{ $t('viewServerError') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'recordDeleted'" data-cy="list-content__options__viewRecordDeleted">
						{{ getError() || $t('viewRecordDeleted') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'missingParameter'" data-cy="list-content__options__viewMissingParameter">
						{{ $t('viewMissingParameter', { params: getError() }) }}
					</h1>
					<h1 v-else-if="getErrorType() === 'wrongParameter'" data-cy="list-content__options__viewWrongParameter">
						{{ $t('viewWrongParameter', { params: getError() }) }}
					</h1>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RouteKey from '../mixins/RouteKey';
import TabErrors from '../mixins/TabErrors';

export default {
	mixins: [RouteKey, TabErrors],
};
</script>
