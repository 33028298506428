export default class {
	constructor(vm) {
		this.vm = vm;

		this.pad = (value) => {
			if (value === null) {
				return '';
			}
			return value.toString().length < 2 ? `0${value}` : value.toString();
		};
		this.isNaN = (value) => Number.isNaN(Number(value));
		// this.updateTime();
	}

	padTime() {
		if (this.vm.hoursStr && this.vm.hoursStr !== '') {
			this.vm.hoursStr = this.pad(this.vm.hoursStr);
		} else if (this.vm.value) {
			this.vm.hoursStr = this.vm.time.getHours();
		}
		if (this.vm.minutesStr && this.vm.minutesStr !== '') {
			this.vm.minutesStr = this.pad(this.vm.minutesStr);
		} else if (this.vm.value) {
			this.vm.minutesStr = this.vm.time.getMinutes();
		}
	}

	setHours(hours) {
		this.vm.time.setHours(hours);
	}

	setMinutes(minutes) {
		this.vm.time.setMinutes(minutes);
	}

	setTime(hours, minutes) {
		this.setHours(hours);
		this.setMinutes(minutes);
		this.padTime();
	}

	updateTime() {
		this.setTime(this.vm.time.getHours(), this.vm.time.getMinutes());
	}

	hourIsValid() {
		const hours = parseInt(this.vm.hoursStr, 10);

		if (hours < 0 || hours > 23 || hours == null || this.isNaN(hours)) {
			return false;
		}

		return true;
	}

	minuteIsValid() {
		const minutes = parseInt(this.vm.minutesStr, 10);

		if (minutes < 0 || minutes > 59 || minutes == null || this.isNaN(minutes)) {
			return false;
		}

		return true;
	}

	isValid() {
		return this.hourIsValid() && this.minuteIsValid();
	}

	makeValid() {
		if (this.vm.invalidHours || this.vm.invalidMinutes) {
			let lastValid = this.vm.lastValid;
			if (lastValid && lastValid.getTime) {
				lastValid = new Date(lastValid.getTime());
			} else if (lastValid && !lastValid.getTime) {
				lastValid = new Date(lastValid);
			} else {
				lastValid = new Date();
			}
			this.vm.date = lastValid;
			this.vm.time = lastValid;
			this.vm.invalidHours = false;
			this.vm.invalidMinutes = false;
			this.updateTime();
		}
	}

	updateHours() {
		const hours = parseInt(this.vm.hoursStr, 10);

		if (this.hourIsValid()) {
			this.vm.invalidHours = false;
			this.setHours(hours);
			this.padTime();
		} else {
			this.vm.invalidHours = true;
		}
	}

	updateMinutes() {
		const minutes = parseInt(this.vm.minutesStr, 10);

		if (this.minuteIsValid()) {
			this.vm.invalidMinutes = false;
			this.setMinutes(minutes);
			this.padTime();
		} else {
			this.vm.invalidMinutes = true;
		}
	}

	noIncrement(incremented) {
		return incremented > this.vm.max ||
			(incremented < this.vm.time && incremented < this.vm.min);
	}

	noDecrement(decremented) {
		return decremented < this.vm.min ||
			(decremented > this.vm.time && decremented > this.vm.max);
	}

	noIncrementHours() {
		const incremented = this.addSecondsCheck(this.vm.hourStep * 3600);
		return this.noIncrement(incremented);
	}

	noDecrementHours() {
		const decremented = this.addSecondsCheck(-this.vm.hourStep * 3600);
		return this.noDecrement(decremented);
	}

	noIncrementMinutes() {
		const incremented = this.addSecondsCheck(this.vm.minuteStep * 60);
		return this.noIncrement(incremented);
	}

	noDecrementMinutes() {
		const decremented = this.addSecondsCheck(-this.vm.minuteStep * 60);
		return this.noDecrement(decremented);
	}

	incrementHours() {
		if (!this.noIncrementHours()) {
			this.makeValid();
			this.addSeconds(this.vm.hourStep * 60 * 60);
		}
	}

	decrementHours() {
		if (!this.noDecrementHours()) {
			this.makeValid();
			this.addSeconds(-this.vm.hourStep * 60 * 60);
		}
	}

	incrementMinutes() {
		if (!this.noIncrementMinutes()) {
			this.makeValid();
			this.addSeconds(this.vm.minuteStep * 60);
		}
	}

	decrementMinutes() {
		if (!this.noDecrementMinutes()) {
			this.makeValid();
			this.addSeconds(-this.vm.minuteStep * 60);
		}
	}

	addSecondsCheck(seconds) {
		if (this.vm.time) {
			return new Date(this.vm.time.getTime() + seconds * 1000);
		}

		return null;
	}

	addSeconds(seconds) {
		if (!this.vm.time) {
			this.vm.time = new Date();
		}

		const dt = new Date(this.vm.time.getTime() + seconds * 1000);
		this.vm.time.setHours(dt.getHours(), dt.getMinutes(), dt.getSeconds());
		this.setTime(dt.getHours(), dt.getMinutes());

		this.vm.hoursStr = this.vm.time.getHours();
		this.vm.minutesStr = this.vm.time.getMinutes();
		this.padTime();
	}

	inputHourHandle() {
		if (this.hourIsValid(this.vm.hoursStr) && this.minuteIsValid(this.vm.minutesStr)) {
			if (!this.vm.time) {
				this.vm.time = new Date();
			}

			this.vm.time.setHours(parseInt(this.vm.hoursStr, 10));
			this.vm.invalidHours = false;
			this.vm.time.setMinutes(parseInt(this.vm.minutesStr, 10));
			this.vm.invalidMinutes = false;

			if (!this.vm.lastValid) this.vm.lastValid = this.vm.time;

			this.vm.date = this.vm.lastValid;

		} else {
			this.vm.invalidHours = true;
			this.vm.date = null;
		}
	}

	inputMinuteHandle() {
		if (this.minuteIsValid(this.vm.minutesStr) && this.hourIsValid(this.vm.hoursStr)) {
			if (!this.vm.time) {
				this.vm.time = new Date();
			}
			this.vm.time.setMinutes(parseInt(this.vm.minutesStr, 10));
			this.vm.invalidMinutes = false;
			this.vm.time.setHours(parseInt(this.vm.hoursStr, 10));
			this.vm.invalidHours = false;

			if (!this.vm.lastValid) this.vm.lastValid = this.vm.time;

			this.vm.date = this.vm.lastValid;

		} else {
			this.vm.invalidMinutes = true;
			this.vm.date = null;
		}
	}
}
