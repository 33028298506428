export default {
	data() {
		return {
			dirty: false,
			inputDirty: false,
			inputDirtyForSave: false,
		};
	},
	methods: {
		setDirty(value) {
			this.dirty = value;
			this.setInputDirty(value);
		},
		setInputDirty(value) {
			// console.debug('[mixin.SaveEntry](setInputDirty)', value);
			this.inputDirty = value;
			this.inputDirtyForSave = value;
		},
		listenContentOnSave() {
			this.$root.$listen('keydown-ctrl-s', (event) => {
				let canSave = false;
				if (this.validateCanSave) {
					canSave = this.validateCanSave;
				} else {
					canSave = !this.readOnly;
				}
				if (canSave && this.inputDirty && !event.solved) {
					const optionsElement = document.getElementById('options');
					// focus on side detail
					if (
						document.activeElement &&
						optionsElement &&
						optionsElement.contains(document.activeElement)
					) {
						return;
					}
					// fake event
					if (document.activeElement) {
						document.activeElement.blur();
					}
					if (_.get(this.$refs, 'saveButton.onClick')) {
						this.$refs.saveButton.onClick({ stopPropagation: () => {} });
					} else if (_.get(this.$refs, 'saveButton.click')) {
						this.$refs.saveButton.click();
					} else {
						console.error('[mixins.SaveEntry](listenContentOnSave) missing saveButton ref');
					}
					event.solved = true;
				}
			}, this);
		},
		listenOptionsOnSave() {
			this.$root.$listen('keydown-ctrl-s', (event) => {
				let canSave = false;
				if (this.validateCanSave) {
					canSave = this.validateCanSave;
				} else {
					canSave = !this.readOnly;
				}
				if (canSave && this.inputDirty && !event.solved) {
					const optionsElement = document.getElementById('options');
					// focus not on side detail
					if (
						!document.activeElement ||
						!optionsElement ||
						!optionsElement.contains(document.activeElement)
					) {
						return;
					}
					// fake event
					document.activeElement.blur();
					if (_.get(this.$refs, 'saveButton.onClick')) {
						this.$refs.saveButton.onClick({ stopPropagation: () => {} });
					} else if (_.get(this.$refs, 'saveButton.click')) {
						this.$refs.saveButton.click();
					} else if (this.save) {
						this.save();
					} else if (this.saveEntry) {
						this.saveEntry();
					} else {
						console.error('[mixins.SaveEntry](listenOptionsOnSave) missing saveButton ref');
					}
					event.solved = true;
				}
			}, this);
		},
	},
};
