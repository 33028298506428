export default {
	activated() {
		this.refreshTitle();
	},
	methods: {
		refreshTitle() {
			this.$route.meta.setName = this.meta.name;
			this.$route.meta.tooltipPrefix = this.meta.tooltipPrefix;
			this.$route.meta.tooltipText = this.meta.tooltipText;

			this.$root.$emit('breadcrumb-set', {
				name: this.meta.path,
				translated: this.$route.meta.setName,
			});

			this.$root.$emit('route-meta-update');
		},
	},
};
