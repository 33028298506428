export default class {
	constructor($http) {
		this.$http = $http;
	}

	query(params) {
		return this.$http.get('lbadmin/profiles', { params });
	}

	get(id) {
		return this.$http.get(`lbadmin/profiles/${id}`);
	}

	getProfilesToSet() {
		return this.$http.get(`lbadmin/profiles-to-set`);
	}

	getTypes() {
		return this.$http.get(`lbadmin/all-permissions`);
	}

	save(params) {
		return this.$http.post('lbadmin/profiles', { params });
	}

	delete(id) {
		return this.$http.delete(`lbadmin/profiles/${id}`);
	}

	bulkDelete(params) {
		return this.$http.delete(`lbadmin/profiles-bulk-delete`, { data: params });
	}

}
