<script>
export default {
	inserted(el, bindings) {
		const breakpoints = Object.entries(bindings.value).map(
			(item) => [item[0], parseInt(item[1], 10)]
		);
		breakpoints.sort((a, b) => b[1] - a[1]);

		const resizer = () => {
			let done = false;
			const currentWidth = el.offsetWidth;

			for (let i = 0, len = breakpoints.length; i < len; i += 1) {
				const breakpoint = breakpoints[i];
				const className = breakpoint[0];
				const width = breakpoint[1];

				if (!done && currentWidth >= width) {
					el.addClass(className);
					done = true;
				} else {
					el.removeClass(className);
				}
			}
		};

		document.addEventListener('onresize', resizer);
	},
};
</script>
