export default {
	methods: {
		getError() {
			const pathInfo = this.$root.$tabs.getActiveTabViewError(this.currentPath || this.$route.path);
			return pathInfo && pathInfo.viewError;
		},
		getErrorType() {
			const pathInfo = this.$root.$tabs.getActiveTabViewError(this.currentPath || this.$route.path);
			return pathInfo && pathInfo.viewErrorType;
		},
		setError(data, errorType) {
			if (data && data.constructor === Object) {
				this.$root.$tabs.setTabViewError(data.key, data.error, errorType);
			} else {
				this.$root.$tabs.setActiveTabViewError(data, errorType);
			}
			this.$forceUpdate();
		},
		getAnyError() {
			return !_.isEmpty(this.getError()) || !_.isEmpty(this.getErrorType());
		},

		onError(data, errorType) {
			this.setError(data, errorType);

			if (errorType === 'notFound') {
				console.error(`[${this.$options.name}] ERROR: Not found:`, data);

			} else if (errorType === 'permissionDenied') {
				console.error(`[${this.$options.name}] ERROR: Permission denied:`, data);

			} else if (errorType === 'serverError') {
				console.error(`[${this.$options.name}] ERROR: Server error:`, data);

			} else if (errorType === 'recordDeleted') {
				console.error(`[${this.$options.name}] ERROR: Record deleted:`, data);

			} else if (errorType === 'missingParameter') {
				console.error(`[${this.$options.name}] ERROR: Missing parameter:`, data);

			} else {
				console.error(`[${this.$options.name}] ERROR: Type:`, errorType, data);

			}
		},
	},
};
