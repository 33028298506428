export default class {
	constructor($http) {
		this.$http = $http;
	}

	getFileDataInfo(importFileUid, params) {
		return this.$http.get(`lbadmin/table-import/file-data-info/${importFileUid}`, { params });
	}

	uploadXLSX(formData) {
		const config = { headers: { 'Content-Type': 'multipart/form-data' } };
		return this.$http.post(`lbadmin/table-import/upload-file`, formData, config);
	}

	validateCSVData(body, encoding) {
		const config = { headers: { 'Content-Type': 'multipart/form-data' } };
		return this.$http.post(`lbadmin/table-import/validate-csv?encoding=${encoding}`, body, config);
	}

	validateFileData(importFileUid, params) {
		return this.$http.post(`lbadmin/table-import/validate/${importFileUid}`, { params });
	}

	getImportExportDefinition(tableName) {
		return this.$http.get(`lbadmin/table-import/definition/${tableName}`);
	}

	deleteFile(params) {
		return this.$http.post(`lbadmin/table-import/delete-file`, { params });
	}
}
