<template>
	<prism-editor
		v-model="internalValue"
		:data-cy="`${currentComponentId}__prism-editor`"
		:highlight="highlighter"
		:line-numbers="lineNumbers"
		:readonly="readOnly"
		@input="onInput"
		@keydown="onKeyDown"
		@blur="onBlur"
		:style="styles"
	/>
</template>

<style>
.height-200 {
	height: 200px
}

.code-editor {
	/* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
	background: #f5f5f5;
	color: #2d2d2d;

	/* you must provide font-family font-size line-height. Example:*/
	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
	outline: none;
}

.prism-editor-wrapper textarea {
	max-height: none !important;
	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace !important;
}
</style>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
export default {
	mixins: [ComponentIdentifier],
	props: {
		value: {
			type: String,
		},
		language: {
			type: String,
			validator: (value) => {
				return ['js', 'javascript', 'json', 'html', 'sql', 'postgresql', 'py', 'python', 'yml', 'yaml', 'css'].includes(value);
			},
		},
		readOnly: Boolean,
		lineNumbers: Boolean,
		styles: {
			type: [Object, String],
			require: false,
		},
	},
	data() {
		return {
			dirty: false,
		};
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		onInput(event) {
			this.dirty = true;
			this.$emit('input', event);
		},
		onKeyDown(event) {
			this.$emit('keydown', event);
		},
		onBlur(event) {
			if (this.dirty) {
				this.dirty = false;
				this.$emit('change', this.internalValue);
			}
			this.$emit('blur', event);
		},
		highlighter(code) {
			if (this.language) {
				if (['js', 'javascript'].includes(this.language)) {
					return window.highlight(code, window.languages.js);

				} else if (['html'].includes(this.language)) {
					return window.highlight(code, window.languages.markup);

				} else if (['sql', 'postgresql'].includes(this.language)) {
					return window.highlight(code, window.languages.sql);

				} else if (['py', 'python'].includes(this.language)) {
					return window.highlight(code, window.languages.python);

				} else if (['json'].includes(this.language)) {
					return window.highlight(code, window.languages.json);

				} else if (['yml', 'yaml'].includes(this.language)) {
					return window.highlight(code, window.languages.yaml);

				} else if (['css'].includes(this.language)) {
					return window.highlight(code, window.languages.css);

				}
			}
			return code;
		},
	},
};
</script>
