export default {
	data() {
		return {
			scrollElement: null,
			scrollPosition: 0,
		};
	},
	activated() {
		this.scrollTo();
	},
	methods: {
		setScroll(e) {
			if (e && e.target && e.target.scrollTop) {
				this.scrollElement = e.target;
				this.scrollPosition = e.target.scrollTop;
			}
		},
		scrollTo() {
			if (this.scrollElement) {
				this.scrollElement.scrollTo(0, this.scrollPosition);
			}
		},
	},
};
