export default class {
	constructor() {
		this.container = null;
	}

	setContainer() {
		this.container = document.getElementById('alertContainer');
	}

	create(text, alertClass) {
		let timeout = null;
		const parent = document.createElement('div');
		const element = document.createElement('span');
		parent.appendChild(element);
		element.classList.add('alert');

		if (alertClass) {
			element.classList.add(alertClass);
		}

		element.textContent = text;

		if (this.container.childNodes && this.container.childNodes[0]) {
			// console.log('insertBefore');
			this.container.insertBefore(parent, this.container.childNodes[0]);
		} else {
			// console.log('appendChild');
			this.container.appendChild(parent);
		}

		/* setTimeout(() => {
			element.classList.add('start-animate');
		}, 0); */

		// close on click
		parent.addEventListener('click', () => {
			element.classList.remove('permanent');
			this.delete(parent, true);
		});

		// disable autohide when mouse points to alert
		parent.addEventListener('mouseover', () => {
			clearTimeout(timeout);

			// cancel hide, when element fading out
			if (element.classList.contains('close-animate')) {
				// element.unbind('mouseover mouseout');
				element.classList.add('permanent');
				element.classList.remove('close-animate');
			}
		});

		// reenable autohide after mouse moves out
		parent.addEventListener('mouseout', () => {
			timeout = this.removeAfterDelay(parent);
		});

		timeout = this.removeAfterDelay(parent);
	}

	removeAfterDelay(element) {
		return setTimeout(() => {
			if (element.classList.contains('permanent')) {
				return;
			}

			this.remove(element);
		}, 5000);
	}

	remove(element) {
		element.children[0].classList.add('close-animate');
		// animate hiding
		setTimeout(() => this.delete(element), 3000);
	}

	delete(element, immediete = false) {
		// cancel hide, when element have class permanent
		const child = element.children[0];

		if (child.classList.contains('permanent')) {
			child.classList.remove('permanent');
			child.classList.remove('close-animate');
			return;
		}

		if (immediete) {
			this.container.removeChild(element);
			return;
		}

		let height = element.children[0].clientHeight;
		element.removeChild(child);
		let animationSpeed = 100;

		const hide = () => {
			if (height > 0) {
				element.setAttribute('style', `overflow: hidden; margin: .58em 0 0 0; height: ${height}px;`);
				height -= 1;

				if (animationSpeed > 5) {
					animationSpeed *= 0.95;
				}

				setTimeout(hide, animationSpeed);
			} else {
				if (element.parentNode === this.container) {
					this.container.removeChild(element);
				}
			}
		};

		hide();
	}

	success(text) {
		return this.create(text, 'success');
	}

	notify(text) {
		return this.create(text);
	}

	warn(text) {
		return this.create(text, 'warning');
	}
}
