<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<i v-if="data[key]" :data-cy="`${currentComponentId}__ok`" class="icon-ok"></i>
		<i v-else :data-cy="`${currentComponentId}__cancel`" class="icon-cancel"></i>
	</component>
	<s
		v-else
		:data-cy="currentComponentId"
		:class="classes"
		:style="style"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<template>
			<label :data-cy="`${currentComponentId}__labelYes`" class="checkbox radio">
				<input type="radio"
					:data-cy="`${currentComponentId}__yes`"
					v-model="data[key]"
					v-bind="bindOptions()"
					:value="true"
					:disabled="readOnly"
					@input="onEvent('input')"
					@change="onEvent('change')"
				>
				<span class="checkmark"></span>
				<span class="label">{{ $t('yes') }}</span>
			</label>
			<label :data-cy="`${currentComponentId}__labelNo`" class="checkbox radio">
				<input type="radio"
					:data-cy="`${currentComponentId}__no`"
					v-model="data[key]"
					:value="false"
					:disabled="readOnly"
					@input="onEvent('input')"
					@change="onEvent('change')"
				>
				<span class="checkmark"></span>
				<span class="label">{{ $t('no') }}</span>
			</label>
		</template>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputBoolean',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
};
</script>
