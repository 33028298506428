/* eslint-disable no-underscore-dangle */
export default class {
	constructor() {
		this.permissions = {};
		this.vm = null;
	}

	setListener() {
		this.vm.$listen('user-permissions-changed', (data) => {
			this.permissions = data;
			this.vm.$emit('permissions-reload');
		}, this.vm);
	}

	/**
	 * Load permissions from server
	 */
	async setPermissions() {
		const response = await this.vm.$http.get('/lbadmin/user-perms');

		this.permissions = response && response.data && response.data.permissions;
	}

	getModulePermissions(module_name) {
		let permissions_ = [];
		if (this.permissions.global) {
			permissions_ = _.filter(this.permissions.global, (perm) => {
				return perm.match(`^${module_name}.module.`);
			});
		}

		return permissions_;
	};

	/**
	 * Check if user have wanted permission
	 * @param {String} acl_type_name - required, permission name (lbadmin.menu.options.enable)
	 * @param {String} team_uid - optional, check permission in specific team, if not set, check global perms
	 * @returns {Boolean} - does user have checked permission (in team)?
	 */
	checkPermission(acl_type_name, team_uid) {
		if (!team_uid) {
			return this.permissions.global && this.permissions.global.includes(acl_type_name);
		}

		return this.permissions[team_uid] && this.permissions[team_uid].includes(acl_type_name);
	};

	/**
	 * Check if user have wanted permissions
	 * @param {Array} acl_type_name - required, array of strings, permissions names (lbadmin.menu.options.enable)
	 * @param {String} team_uid - optional, check permissions in specific team, if not set, check global perms
	 * @returns {Boolean} - does user have checked permission (in team)?
	 */
	checkPermissions(acl_type_names, team_uid) {
		let perm = true;
		if (!team_uid) {
			_.forEach(acl_type_names, (acl_type_name) => {
				if (!(this.permissions.global && this.permissions.global.includes(acl_type_name))) {
					perm = false;
				}
			});
		} else {
			_.forEach(acl_type_names, (acl_type_name) => {
				if (!(this.permissions[team_uid] && this.permissions[team_uid].includes(acl_type_name))) {
					perm = false;
				}
			});
		}

		return perm;
	};
};
