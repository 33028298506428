export default {
	data() {
		return {
			serverSettings: _.first(this.$root.servers),
			testingPassword: '',
			passwordErrorsId: $generateUID(),
			passwordErrors: [],
		};
	},
	watch: {
		testingPassword(newValue) {
			if (!newValue) {
				this.testingPassword = '';
			}
			this.generatePasswordErrors();
		},
	},
	computed: {
		passwordRegex() {
			let regex = '^';

			if (this.passwordRules) {
				if (this.passwordRules.lowercase) {
					regex += '(?=(.*[a-z]){1,})';
				}
				if (this.passwordRules.uppercase) {
					regex += '(?=(.*[A-Z]){1,})';
				}
				if (this.passwordRules.number) {
					regex += '(?=(.*[0-9]){1,})';
				}
				if (this.passwordRules.special) {
					regex += '(?=(.*[!@#$%^&*()\\-_+.]){1,})';
				}
				if (this.passwordRules.length) {
					regex += `.{${this.passwordRules.length},}`;
				}
			} else {
				regex += '.*';
			}
			regex += '$';

			return regex;
		},
		passwordRules() {
			if (this.serverSettings && this.serverSettings.login_password_strength) {
				return this.serverSettings.login_password_strength;
			}
			return null;
		},
	},
	methods: {
		refreshPasswordServerSettings() {
			this.serverSettings = _.first(this.$root.servers);
		},
		generatePasswordErrors() {
			this.passwordErrorsId = this.$generateUID();
			const errors = [];
			if (this.passwordRules) {
				if (this.passwordRules.lowercase && !/(?=(.*[a-z]){1,})/.test(this.testingPassword)) {
					errors.push(this.$t('settings.passwordError.noLowercase'));
				}
				if (this.passwordRules.uppercase && !/(?=(.*[A-Z]){1,})/.test(this.testingPassword)) {
					errors.push(this.$t('settings.passwordError.noUppercase'));
				}
				if (this.passwordRules.number && !/(?=(.*[0-9]){1,})/.test(this.testingPassword)) {
					errors.push(this.$t('settings.passwordError.noNumber'));
				}
				if (this.passwordRules.special && !/(?=(.*[!@#$%^&*()\\\-_+.]){1,})/.test(this.testingPassword)) {
					errors.push(this.$t('settings.passwordError.noSpecial'));
				}
				if (this.passwordRules.length && this.testingPassword.length < this.passwordRules.length) {
					errors.push(this.$t('settings.passwordError.noLength', { length: this.passwordRules.length }));
				}
				if (this.passwordRules.easy_to_guess) {
					_.find(this.avoidedItemsWhole, (el) => {
						if (el.value && this.testingPassword.toLowerCase().includes(el.value.toLowerCase())) {
							errors.push(this.$t('settings.passwordError.contains', { field: el.label }));
						}
					});
				}
			}
			this.passwordErrors = errors;
		},
	},
};
