export default {
	data() {
		return {
			isActive: true,
		};
	},
	computed: {
		rootClass() {
			if (_.isEmpty(this.$el.classList)) {
				return null;
			}
			const classes = {};
			this.$el.classList.forEach((classItem) => {
				if (classItem !== 'v-portal') {
					classes[classItem] = true;
				}
			});
			return classes;
		},
	},
	activated() {
		this.isActive = true;
	},
	deactivated() {
		this.isActive = false;
	},
};
