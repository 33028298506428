export default class {
	constructor(vm, callback, toggleStateCallback, timeout = 10) {
		this.vm = vm;
		this.callback = callback;
		this.callbackArguments = [];

		this.toggleStateCallback = toggleStateCallback;
		this.timeout = timeout;
		this.timeoutID = null;
		this.inTimeout = false;
		this.counter = 0;
		this.emitCounter = 0;

		this.cancel = this.cancel.bind(this);
		this.emit = this.emit.bind(this);
		this.timeoutHandler = this.timeoutHandler.bind(this);
	}

	cancel() {
		if (this.timeoutID != null) {
			clearTimeout(this.timeoutID);
			this.timeoutID = null;
			this.inTimeout = false;
		}
	}

	emit(...args) {
		this.inTimeout = true;

		if (this.toggleStateCallback) {
			this.toggleStateCallback(true);
		}

		this.callbackArguments = args;

		if (this.timeoutID == null) {
			this.emitCounter = 1;
			this.timeoutID = setTimeout(this.timeoutHandler, this.timeout);
		} else {
			this.emitCounter += 1;
			this.counter += 1;
		}
	}

	timeoutHandler() {
		if (this.counter !== 0) {
			clearTimeout(this.timeoutID);
			this.timeoutID = setTimeout(this.timeoutHandler, this.timeout);
		} else {
			clearTimeout(this.timeoutID);
			this.timeoutID = null;
			this.inTimeout = false;

			if (this.toggleStateCallback) {
				this.toggleStateCallback(false);
			}

			this.callback.apply(this.vm, this.callbackArguments);
		}

		this.counter = 0;
	}
}
