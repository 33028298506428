<template>
	<div
		v-if="canShow()"
		class="window"
		:class="{ 'window-resize': resizable === true }"
		:data-cy="`${currentComponentId}__movable`"
		v-lba-movable="{ movable, position, id, savePosition }"
		ref="window"
		:id="`window-${id}`"
	>
		<div class="window-header">
			<i v-if="iconClass" :class="iconClass"></i>
			<div class="window-info">
				<slot v-if="$scopedSlots.title" name="title" :parentComponentId="`${currentComponentId}__slotTitle`"></slot>
				<template v-else-if="title">{{ title }}</template>
			</div>
			<div class="window-controls">
				<!--<button v-if="btnMinimalize" @click="toggleMinimalize">-</button>-->
				<button v-if="btnClose" @click="close(id)" type="button" class="button-cancel" :data-cy="`${currentComponentId}__close`">
				</button>
			</div>
		</div>
		<div class="window-content" ref="content">
			<slot :parentComponentId="`${currentComponentId}__slotDefault`"></slot>
		</div>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		id: {
			type: [String, Number],
			required: true,
		},
		title: {
			type: String,
			default: '',
		},
		btnClose: {
			type: Boolean,
			default: true,
		},
		/* btnMinimalize: {
		type: Boolean,
		default: true,
		}, */
		movable: {
			type: Boolean,
			default: true,
		},
		savePosition: {
			type: Boolean,
			default: true,
		},
		opened: {
			type: Boolean,
			default: false,
		},
		iconClass: {
			type: String,
		},
		resetPositionOnShow: {
			type: Boolean,
			default: false,
		},
		resizable: {
			type: Boolean,
			default: false,
		},
		minWidth: {
			type: Number,
			default: 1,
		},
		minHeight: {
			type: Number,
			default: 1,
		},
		openNextTo: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			visible: false,
			position: {
				x: null,
				y: null,
			},
			lastWindowWidth: window.innerWidth,
			lastWindowHeight: window.innerHeight,
			element: null,
			resizeTimeout: null,
		};
	},
	watch: {
		async opened() {
			if (this.opened) {
				// wait for window to open so we can access it from $refs
				if (!this.$refs.window) {
					await this.$nextTick();
				}
				this.open(this.id);
			} else {
				this.close(this.id);
			}
		},
	},
	created() {
		if (this.savePosition) {
			this.position.x = localStorage.getItem(`${this.id}-positionX`) || 0;
			this.position.y = localStorage.getItem(`${this.id}-positionY`) || 0;
			if (this.resizable) {
				this.position.width = localStorage.getItem(`${this.id}-width`) || 280;
				if (this.position.width < this.minWidth) this.position.width = this.minWidth;

				this.position.height = localStorage.getItem(`${this.id}-height`) || 500;
				if (this.position.height < this.minHeight) this.position.height = this.minHeight;
			}
		}
		this.$root.$listen('window-open', this.open, this, true);
		this.$root.$listen('window-close', this.close, this, true);
		window.addEventListener('resize', this.resize);
	},
	methods: {
		resize() {
			if (this.position.x != null && this.position.y != null && this.canShow()) {
				const lastRatioX = this.position.x / this.lastWindowWidth;
				const lastRatioY = this.position.y / this.lastWindowHeight;
				this.position.x = lastRatioX * window.innerWidth;
				this.position.y = lastRatioY * window.innerHeight;
				this.lastWindowWidth = window.innerWidth;
				this.lastWindowHeight = window.innerHeight;

				let rect = {
					x: this.position.x,
					y: this.position.y,
					width: this.$refs.window.offsetWidth,
					height: this.$refs.window.offsetHeight,
				};

				const collision = this.$detectRectWindowCollision(rect);
				rect = this.$handleRectWindowCollision(rect, collision);

				this.position.x = rect.x;
				this.position.y = rect.y;

				if (this.savePosition) {
					localStorage.setItem(`${this.id}-positionX`, this.position.x);
					localStorage.setItem(`${this.id}-positionY`, this.position.y);
					if (this.resizable) {
						if (this.position.width < this.minWidth) this.position.width = this.minWidth;
						localStorage.setItem(`${this.id}-width`, this.position.width);

						if (this.position.height < this.minHeight) this.position.height = this.minHeight;
						localStorage.setItem(`${this.id}-height`, this.position.height);
					}
				}

				let style = `
					left: ${this.position.x}px;
					top: ${this.position.y}px;
				`;
				if (this.resizable) {
					style = style + `
						width: ${this.position.width}px;
						height: ${this.position.height}px;
					`;
				}
				this.$refs.window.setAttribute('style', style.trim());
			}
		},
		canShow() {
			return this.opened || this.visible;
		},
		async open(id) {
			if (this.id === id) {
				if (this.resetPositionOnShow) {
					this.position = { x: 0, y: 0 };
				}

				if (this.openNextTo) {
					const masterX = parseInt(localStorage.getItem(`${this.openNextTo}-positionX`)) || 0;
					const masterY = parseInt(localStorage.getItem(`${this.openNextTo}-positionY`)) || 0;

					if ((masterX - 290) < 0) {
						this.position.x = masterX + 290;
						this.position.y = masterY;
					} else {
						this.position.x = masterX - 290;
						this.position.y = masterY;
					}
				}

				this.visible = true;

				// wait for window to open so we can access it from $refs
				if (!this.$refs.window) {
					await this.$nextTick();
				}

				// set resize listener on window open if it is resizable
				if (this.resizable && !this.element) {
					this.element = document.getElementById(`window-${this.id}`);
					new ResizeObserver(() => {
						if (this.savePosition && this.canShow()) {
							const maxWidth = window.innerWidth - this.position.x - 2;
							if ((this.element.offsetWidth) > maxWidth) {
								let style = this.element.getAttribute('style');
								style = style + `max-width: ${maxWidth}px;`;
								this.element.setAttribute('style', style.trim());
							}

							const maxHeight = window.innerHeight - this.position.y - 2;
							if ((this.element.offsetHeight) > maxHeight) {
								let style = this.element.getAttribute('style');
								style = style + `max-height: ${maxHeight}px;`;
								this.element.setAttribute('style', style.trim());
							}

							clearTimeout(this.resizeTimeout);
							this.resizeTimeout = setTimeout(() => {
								this.position.width = this.element.offsetWidth - 2;
								this.position.height = this.element.offsetHeight - 2;
								localStorage.setItem(`${this.id}-width`, this.position.width);
								localStorage.setItem(`${this.id}-height`, this.position.height);
							}, 500);
						}
					}).observe(this.element);
				}
				this.resize();
				this.$emit('opened');
			}
		},
		close(id) {
			if (this.id === id) {
				this.visible = false;
				this.$emit('closed');
				this.element = null;
			}
		},
	},
};
</script>
