<template>
  <div :id="id" ref="root">
    <slot name="before"></slot>
    <slot></slot>
    <slot name="after"></slot>
  </div>
</template>

<script>
const cssDragging = 'dnd-dragging';
const cssDropArea = 'dnd-drop-area';

class Parent {
	constructor(buttonUp, buttonDown, buttonDel, handle, element, item, Vnode) {
		this.buttonUp = buttonUp;
		this.buttonDown = buttonDown;
		this.buttonDel = buttonDel;
		this.handle = handle;
		this.element = element;
		this.item = item;
		this.Vnode = Vnode;
		this.positionStart = null;
		this.insideElementCounter = 0;

		this.moveDraggedItem = (from, to) => {
			// remove item on index 'from' and put this item on index 'to'
			this.Vnode.listCache.splice(from, 1);
			this.Vnode.listCache.splice(to, 0, this.Vnode.draggedItem);
			this.Vnode.internalValue = this.Vnode.listCache.slice();
		};

		if (this.handle) {
			this.handle.setAttribute('draggable', 'true');
			this.handle.addEventListener('mousedown', (event) => {
				this.Vnode.clickTarget = this.Vnode.getParent(event.target);
			}, true);
		}

		this.element.addEventListener('dragstart', (event) => {
			// when handle exist and clicked on something else than handle
			if (this.Vnode.clickTarget && !this.Vnode.clickTarget.contains(this.handle)) {
				event.preventDefault();
			}

			event.dataTransfer.setData('text', '');
			this.Vnode.dropped = false;
			this.positionStart = this.Vnode.getPosition(this.item);
			this.Vnode.draggedItem = this.item;
			setTimeout(() => {
				this.element.classList.add(cssDragging);
			}, 0);
			this.Vnode.notifyDragstart(this);
		});

		this.element.addEventListener('dragend', () => {
			this.element.classList.remove(cssDragging);
			this.element.classList.remove(cssDropArea);

			if (!this.Vnode.dropped) {
				if (this.Vnode.dropArea) {
					this.Vnode.dropArea.classList.remove(cssDragging);
					this.Vnode.dropArea.classList.remove(cssDropArea);
					this.Vnode.dropArea = null;
				}
				this.insideElementCounter = 0;
				const from = this.Vnode.getPosition(this.Vnode.draggedItem);

				if (from !== this.positionStart) {
					this.moveDraggedItem(from, this.positionStart);
					this.Vnode.updateItems();
				}
			}

			this.Vnode.draggedItem = null;
			this.Vnode.clickTarget = null;
			this.Vnode.notifyDragend(this);
		});
		this.element.addEventListener('dragover', (event) => {
			if (this.Vnode.draggedItem) {
				event.preventDefault();
			}
		});
		this.element.addEventListener('dragenter', (event) => {
			this.insideElementCounter += 1;

			// if this element is not drop area
			if (this.Vnode.dropArea !== this.element) {
				// and if drop area exists
				if (this.Vnode.dropArea) {
					this.Vnode.dropArea.classList.remove(cssDragging);
					this.Vnode.dropArea.classList.remove(cssDropArea);
				}

				this.Vnode.dropArea = this.element;
				this.Vnode.dropAreaElement = this.element;
				const positionBefore = this.Vnode.getPosition(this.Vnode.draggedItem);
				const positionAfter = this.Vnode.getPosition(this.item);
				event.preventDefault();

				// when position of dragged item is not same as this item
				if (positionBefore !== positionAfter) {
					// move dragged item to position of this item
					this.moveDraggedItem(positionBefore, positionAfter);
					this.Vnode.updateItems();
				}
			}

			this.element.classList.add(cssDragging);
			this.element.classList.add(cssDropArea);
		}, false);
		this.element.addEventListener('dragleave', (event) => {
			this.insideElementCounter -= 1;

			if (this.insideElementCounter <= 0) {
				this.insideElementCounter = 0;
				this.element.classList.remove(cssDropArea);
			}

			event.preventDefault();
		});
		this.element.addEventListener('drop', (event) => {
			if (this.Vnode.dropArea) {
				this.Vnode.dropArea.classList.remove(cssDragging);
				this.Vnode.dropArea.classList.remove(cssDropArea);
				this.Vnode.dropArea = null;
			}

			this.element.classList.remove(cssDragging);
			this.element.classList.remove(cssDropArea);
			this.insideElementCounter = 0;
			this.Vnode.dropped = true;
			event.preventDefault();
			this.Vnode.notifyDrop(this);
		});
	}
}

export default {
	props: {
		value: {
			type: Array,
			required: false,
			default: () => [],
		},
		isTab: {
			type: Boolean,
			required: false,
			default: false,
		},
		notifyDragstart: {
			type: Function,
			required: false,
			default: () => {},
		},
		notifyDragend: {
			type: Function,
			required: false,
			default: () => {},
		},
		notifyDrop: {
			type: Function,
			required: false,
			default: () => {},
		},
	},
	data() {
		return {
			id: '',
			listCache: [],
			parentList: [],
			dropped: true,
			clickTarget: null,
			dropArea: null,
			dropAreaElement: null,
			draggedItem: null,
			dropAreaChanged: false,
		};
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		beforeLength() {
			let beforeSlotsLength = 0;

			if (this.$scopedSlots.before) {
				const beforeSlots = this.$scopedSlots.before();

				if (beforeSlots && beforeSlots.length > 0) {
					beforeSlotsLength = beforeSlots.length;
				}
			}

			return beforeSlotsLength;
		},
	},
	created() {
		console.warn('[LbaDragAndDropList] this component is deprecated, please use LbaDragAndDropList2 instead');
		if (this.isTab) {
			this.id = 'tabs-wrap';
		}
	},
	mounted() {
		this.listCache = this.internalValue.slice();
		this.setParentList(0);
	},
	updated() {
		const listCacheLength = this.listCache.length;
		const isNewParent = this.internalValue.length > listCacheLength;
		this.listCache = this.internalValue.slice();

		if (isNewParent) {
			this.setParentList(listCacheLength);
		}

		this.updateItems();
	},
	methods: {
		setParentList(startIndex) {
			const defaultSlots = this.$scopedSlots.default();

			if (!defaultSlots) {
				return;
			}

			for (
				let index = startIndex + this.beforeLength;
				index < defaultSlots.length + this.beforeLength;
				index += 1
			) {
				const buttonUp = this.$refs.root.children[index].querySelector('[lba-dnd-list-up]');
				const buttonDown = this.$refs.root.children[index].querySelector('[lba-dnd-list-down]');
				const buttonDel = this.$refs.root.children[index].querySelector('[lba-dnd-list-del]');

				if (buttonUp) buttonUp.addEventListener('click', this.moveUp);
				if (buttonDown) buttonDown.addEventListener('click', this.moveDown);
				if (buttonDel) buttonDel.addEventListener('click', this.remove);

				const handle = this.$refs.root.children[index].querySelector('[lba-dnd-handle]');
				const parent = this.$refs.root.children[index];
				const item = this.listCache[index - this.beforeLength];

				this.parentList.push(
					new Parent(buttonUp, buttonDown, buttonDel, handle, parent, item, this)
				);
			}
		},
		updateItems() {
			const parentListLength = this.parentList.length;
			const listCacheLength = this.listCache.length;

			for (let index = 0; index < parentListLength; index += 1) {
				if (index >= listCacheLength) {
					this.parentList.splice(-1, 1);
				} else {
					this.parentList[index].item = this.listCache[index];
				}
			}
		},
		getPosition(item) {
			return this.listCache.indexOf(item);
		},
		getParent(child) {
			let parent = child;

			while (parent.parentNode !== this.$refs.root) {
				parent = parent.parentNode;
			}

			return parent;
		},
		moveUp(event) {
			const index = Array.prototype.indexOf.call(
				this.$refs.root.children,
				this.getParent(event.target)
			) - this.beforeLength;

			if (index >= 1) {
				const item = this.listCache.splice(index, 1)[0];
				this.listCache.splice(index - 1, 0, item);
				this.internalValue = this.listCache.slice();
				this.updateItems();
			}
		},
		moveDown(event) {
			const index = Array.prototype.indexOf.call(
				this.$refs.root.children,
				this.getParent(event.target)
			) - this.beforeLength;

			if (index < this.listCache.length - 1) {
				const item = this.listCache.splice(index, 1)[0];
				this.listCache.splice(index + 1, 0, item);
				this.internalValue = this.listCache.slice();
				this.updateItems();
			}
		},
		remove(event) {
			const index = Array.prototype.indexOf.call(
				this.$refs.root.children,
				this.getParent(event.target)
			) - this.beforeLength;

			if (index >= 0) {
				this.parentList.splice(index, 1);
				this.listCache.splice(index, 1);
				this.internalValue = this.listCache.slice();
				this.updateItems();
			}
		},
	},
};
</script>
