<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<template v-for="(entry, index) in internalData">
			<div :key="index" :data-cy="`${currentComponentId}__entry${index}`">{{ entry }}<br></div>
		</template>
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<span>
			<lba-dnd-list2
				:data-cy="currentComponentId"
				:list.sync="internalData"
				:key="`list-${listKey}`"
				:readOnly="readOnly"
			>
				<template v-slot:list>
					<div
						v-for="(entry, index) in internalData"
						:key="index"
						class="formGroup draggable wide"
					>
						<div class="formGroupHeader">
							<span class="formGroupDraggable" v-tooltip="$t('move')"><i class="icon-draggable"></i></span>
							<span class="formGroupLabel">
								<input style="width: 100%"
									:data-cy="`${currentComponentId}__optionInput${index}`"
									v-model="entry.value"
									@input="onEvent('input')"
									@change="onEvent('change')"
									@blur="$emit('blur')"
									:disabled="readOnly"
								/>
							</span>
							<span class="formGroupControls" v-if="!readOnly">
								<a :data-cy="`${currentComponentId}__removeOption${index}`"
									v-tooltip="$t('remove')" v-on:click="delOption(index)">
									<i class="icon-delete"></i>
								</a>
								<a :data-cy="`${currentComponentId}__addOption${index}`"
									v-tooltip="$t('add')" v-on:click="addOption()">
									<i class="icon-plus-circle"></i>
								</a>
							</span>
						</div>
					</div>
				</template>
			</lba-dnd-list2>
		</span>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputTextArray',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			listKey: 0,
			internalData: [],
		};
	},
	watch: {
		internalData: {
			immediate: true,
			deep: true,
			handler(newValue) {
				if (this.data && this.key) {
					const output = _.map(newValue, (entry) => entry.value);
					this.data[this.key] = output;
					this.$emit('input', this.data[this.key]);
				}
			},
		},
	},
	created() {
		this.prepareComponent();
		if (!this.data[this.key]) {
			this.data[this.key] = [];
		}
		_.forEach(this.data[this.key], (entry) => {
			this.internalData.push({ value: entry });
		});
		if (this.internalData.length === 0) {
			this.internalData.push({ value: '' });
		}
	},
	methods: {
		addOption() {
			if (!this.internalData) {
				this.internalData = [];
			}
			this.internalData.push({ value: '' });
			this.listKey += 1;
		},
		delOption(index) {
			this.internalData.splice(index, 1);
			if (this.internalData.length === 0) {
				this.internalData.push({ value: '' });
			}
			this.listKey += 1;
		},
	},
};
</script>
