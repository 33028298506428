export default {
	data() {
		return {
			pageRecordsFrom: 0,
			pageRecordsTo: 0,
			pageCount: 0,
			pages: [],
		};
	},
	computed: {
	},
	watch: {
		currentPage(newValue) {
			this.loadData();
		},
	},
	methods: {
		async loadData() {
			this.refreshPages();
			await this.$nextTick();
			this.fetchCollection(this.pageRecordsFrom - 1, true, true, false);
		},
		setPrewPage() {
			if (this.currentPage === 1 || this.showIsLoading) return;
			console.debug('[LbaGrid] (setPrewPage)');
			this.currentPage--;
		},
		setNextPage() {
			if (this.currentPage === this.pageCount || this.showIsLoading) return;
			console.debug('[LbaGrid] (setNextPage)');
			this.currentPage++;
		},
		setCurrentPage(page) {
			if (this.showIsLoading || this.currentPage === page || isNaN(page)) return;
			console.debug('[LbaGrid] (setCurrentPage) - ', page);
			this.currentPage = page;
		},
		async setCurrentPerPage(perPage) {
			if (this.showIsLoading || this.currentPerPage === perPage) return;
			console.debug('[LbaGrid] (setCurrentPerPage) - ', perPage);
			this.currentPerPage = perPage;
			this.currentPage = 1;
			await this.$nextTick();
			this.loadData();
			// this.setViewportHeight();
			this.saveColumnsSettings();
		},
		async refreshPages() {
			await this.$nextTick();
			const max = this.collection.length;

			const from = ((this.currentPerPage * this.currentPage) - this.currentPerPage + 1);
			// console.debug(`[LbaGrid] (pageRecordsFrom) - from: ${from}, max: ${max}`);
			this.pageRecordsFrom = from > max ? max : from;

			const to = this.currentPerPage * this.currentPage;
			// console.debug(`[LbaGrid] (pageRecordsTo) - to: ${to}, max: ${max}`);
			this.pageRecordsTo = to > max ? max : to;

			this.pageCount = Math.ceil(this.collection.length / this.currentPerPage);
			// console.debug(`[LbaGrid] (pageCount) - `, this.pageCount);

			if (this.pageCount < 7) {
				this.pages = Array.from({ length: this.pageCount }, (_, i) => i + 1);
			} else {
				this.pages = [1];
				if (this.currentPage - 2 > 1) this.pages.push('...');
				for (let i = (this.currentPage - 2); i <= (this.currentPage + 2); i++) {
					if (i > 1 && i < this.pageCount) {
						this.pages.push(i);
					}
				}

				if (this.currentPage + 2 < this.pageCount - 2) this.pages.push('...');
				if (this.pageCount > 1) this.pages.push(this.pageCount);
			}
		},
	},
};
