<template>
	<lba-dialog-edit
		:parentComponentId="currentComponentId"
		:name="dialogName"
		:title="dialogTitle"
		class="lba-dnd-two-lists"
		modal
		@submit="onDialogSubmit"
		@close="onDialogClose"
	>
		<s v-if="!_.isEmpty(groupBy) && !_.isEmpty(groups)" class="size-6">
			<small>{{ $t('group') }}</small>
			<label
				v-for="(group, groupIndex) in groups"
				:key="groupIndex"
				class="checkbox radio"
				:data-cy="`${currentComponentId}__group${groupIndex}__radioLabel`"
			>
				<input
					:data-cy="`${currentComponentId}__group${groupIndex}__inputRadio`"
					type="radio"
					:value="group.values.join('$|$')"
					v-model="editedGroup"
					:name="`${dialogName}.group`"
				>
				<span class="checkmark"></span>
				<span class="label">{{ group.label }}</span>
			</label>
		</s>
		<s v-if="distinguishOddEvenOrder && activeGroup" class="size-6">
			<small>{{ $t('orderOfGroups') }}</small>
			<label class="checkbox radio" :data-cy="`${currentComponentId}__mix__radioLabel`">
				<input
					:data-cy="`${currentComponentId}__mix__inputRadio`"
					type="radio"
					value="mix"
					v-model="activeGroup.groupOrder"
					:name="`${dialogName}.group-order`"
				>
				<span class="checkmark"></span>
				<span class="label">{{ $t('orderInTurns') }}</span>
			</label>
			<label class="checkbox radio" :data-cy="`${currentComponentId}__leftFirstRightLast__radioLabel`">
				<input
					:data-cy="`${currentComponentId}__leftFirstRightLast__inputRadio`"
					type="radio"
					value="leftFirstRightLast"
					v-model="activeGroup.groupOrder"
					:name="`${dialogName}.group-order`"
				>
				<span class="checkmark"></span>
				<span class="label">{{ $t('leftSideFirstRightSideLast') }}</span>
			</label>
			<label class="checkbox radio" :data-cy="`${currentComponentId}__leftLastRightFirst__radioLabel`">
				<input
					:data-cy="`${currentComponentId}__leftLastRightFirst__inputRadio`"
					type="radio"
					value="leftLastRightFirst"
					v-model="activeGroup.groupOrder"
					:name="`${dialogName}.group-order`"
				>
				<span class="checkmark"></span>
				<span class="label">{{ $t('leftSideLastRightSideFirst') }}</span>
			</label>
		</s>
		<div class="row" :style="`max-height: calc(100% - 140px); ${minWidth}`">
			<template v-for="(group, groupIndex) in groups">
				<div v-show="group.values.join('$|$') === editedGroup" :key="groupIndex" class="row col-12">
					<template v-for="(valueGroup, valueGroupIndex) in group.valuesGroups">
						<div :key="`valueGroup-${valueGroupIndex}`" :class="['column', columnWidthClass]">
							<div class="title-wrapper">
								<label>
									<span class="label">
										{{ (valueGroupIndex + 1) % 2 === 0 ? evenLabel : oddLabel }}
									</span>
								</label>
							</div>
							<div class="dnd-list-wrapper">
								<lba-dnd-list2
									:parentComponentId="`${currentComponentId}__group${groupIndex}__valueGroup${valueGroupIndex}`"
									class="lba-wrapper"
									ref="list1"
									:list.sync="valueGroup.values"
									:group="`${dialogName}-dnd-group`"
									:staticListItems="false"
									:staticListOrder="false"
									minHeight="200px"
								>
									<template v-slot:list>
										<div
											v-for="(value, valueIndex) in valueGroup.values"
											:key="valueIndex"
											class="flex-row inner border label dnd-list-item"
											:data-cy="
												`${currentComponentId}__group${groupIndex}__valueGroup${valueGroupIndex}` +
												`__value${valueIndex}`
											"
										>
											<i class="icon-draggable"></i>
											<label class="label">{{ value.label }}</label>
										</div>
									</template>
								</lba-dnd-list2>
							</div>
						</div>
						<div
							v-if="valueGroupIndex < group.valuesGroups.length - 1"
							:key="`valueGroup-space-${valueGroupIndex}`"
							style="min-width: 16.66%;"
							class="col-2"
						></div>
					</template>
				</div>
			</template>
		</div>
	</lba-dialog-edit>
</template>

<style scoped>
.lba-dnd-two-lists .lba-wrapper {
	max-width: none !important;
}
.lba-dnd-two-lists .lba-wrapper .flex-row {
	padding: 4px 10px !important;
}
</style>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	model: {
		prop: 'values',
	},
	props: {
		values: {
			type: Array,
			required: true,
		},
		dialogTitle: {
			type: String,
			required: true,
		},
		dialogName: {
			type: String,
			required: true,
		},
		pathToOrder: {
			type: String,
			default: 'order',
			required: true,
		},
		pathToUid: {
			type: String,
			default: 'uid',
			required: true,
		},
		pathToLabel: {
			type: String,
			default: 'label',
			required: true,
		},
		orderFrom: {
			type: Number,
			default: 0,
			required: true,
		},
		distinguishOddEvenOrder: {
			type: Boolean,
			default: false,
		},
		oddLabel: {
			type: String,
		},
		evenLabel: {
			type: String,
		},
		groupBy: {
			type: Array,
			validator(value) {
				return (
					_.isEmpty(value) ||
					_.every(value, (groupInfo) => (
						!_.isEmpty(groupInfo) && (
							groupInfo.constructor === String || (
								!_.isEmpty(groupInfo.pathToGroup) &&
								!_.isEmpty(groupInfo.pathToLabel)
							)
						)
					))
				);
			},
		},
		sortOnChange: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			groups: [],
			existingGroupsFromGroupBy: [],
			editedGroup: null,
		};
	},
	computed: {
		preparedValues() {
			return _.sortBy(
				this.values.map((value) => {
					const preparedValue = {
						uid: _.get(value, this.pathToUid),
						label: _.get(value, this.pathToLabel),
						order: _.get(value, this.pathToOrder),
					};
					if (!_.isEmpty(this.groupBy)) {
						preparedValue.groups = [];
						this.groupBy.forEach((groupInfo) => {
							preparedValue.groups.push({
								group: _.get(value, groupInfo.pathToGroup || groupInfo),
								label: _.get(value, groupInfo.pathToLabel || groupInfo),
							});
						});
					}
					return preparedValue;
				}),
				['order', 'label', 'uid']
			);
		},
		activeGroup() {
			if (this.editedGroup) {
				return this.groups.find((group) => group.values.join('$|$') === this.editedGroup);
			}
			return null;
		},
		minWidth() {
			if (this.distinguishOddEvenOrder) {
				return 'min-width: 800px;';
			}
			return 'min-width: 300px;';
		},
		columnWidthClass() {
			if (this.distinguishOddEvenOrder) {
				return 'col-5';
			}
			return 'col-12';
		},
	},
	watch: {
		values() {
			this.prepareGroups();
		},
	},
	created() {
		if (_.uniqBy(this.preparedValues, 'uid').length !== this.preparedValues.length) {
			throw new Error(`[LbaValueOrder] values must have unique uid: ${this.dialogName}`);
		}
		if (this.distinguishOddEvenOrder && (_.isEmpty(this.oddLabel))) {
			throw new Error(`[LbaValueOrder] distinguishOddEvenOrder is set but missing oddLabel prop: ${this.dialogName}`);
		}
		if (this.distinguishOddEvenOrder && (_.isEmpty(this.evenLabel))) {
			throw new Error(`[LbaValueOrder] distinguishOddEvenOrder is set but missing evenLabel prop: ${this.dialogName}`);
		}

		this.prepareGroups();
	},
	methods: {
		prepareGroups() {
			this.groups = [];

			if (_.isEmpty(this.groupBy)) {
				this.groups.push({
					values: ['default'],
					label: 'default',
					valuesGroups: [],
					groupOrder: null,
					preparedValues: this.preparedValues,
				});

			} else {
				// prepare groups
				this.preparedValues.forEach((preparedValue) => {
					// find this group combination
					const group = this.groups.find(
						(item1) => _.isEqual(
							item1.values,
							preparedValue.groups.map((item2) => item2.group)
						)
					);

					// if group not found, create one
					if (_.isEmpty(group)) {
						/* console.log(
							'create group:',
							preparedValue.groups.map((item) => item.group),
							preparedValue.groups.map((item) => item.label).join(', ')
						); */
						this.groups.push({
							values: preparedValue.groups.map((item) => item.group),
							label: preparedValue.groups.map((item) => item.label).join(', '),
							valuesGroups: [],
							groupOrder: null,
							preparedValues: [preparedValue],
						});
					// group found, add preparedValue
					} else {
						group.preparedValues.push(preparedValue);

					}
				});

			}

			this.groups.sort((a, b) => {
				const compareResult = a.label.localeCompare(b.label, undefined, { ignorePunctuation: true });
				if (compareResult < 0) return -1;
				if (compareResult > 0) return 1;
				return 0;
			});

			this.editedGroup = this.groups[0].values.join('$|$');

			this.groups.forEach((group) => {
				if (this.distinguishOddEvenOrder) {
					const odd = { values: [] };
					const even = { values: [] };
					group.preparedValues.forEach((value) => {
						if (value.order % 2 === 0) {
							even.values.push(value);
						} else {
							odd.values.push(value);
						}
					});
					odd.values = _.sortBy(odd.values, ['order', 'label', 'uid']);
					even.values = _.sortBy(even.values, ['order', 'label', 'uid']);

					const firstOddValue = _.get(odd.values, '[0].order');
					const lastOddValue = _.get(odd.values, `[${odd.values.length - 1}].order`);
					const firstEvenValue = _.get(even.values, '[0].order');
					const lastEvenValue = _.get(even.values, `[${even.values.length - 1}].order`);

					if (lastOddValue < firstEvenValue) {
						group.groupOrder = 'leftFirstRightLast';
					} else if (lastEvenValue < firstOddValue) {
						group.groupOrder = 'leftLastRightFirst';
					} else {
						group.groupOrder = 'mix';
					}

					group.valuesGroups.push(odd, even);

				} else {
					group.valuesGroups.push({ values: group.preparedValues });

				}
			});
		},
		onDialogSubmit() {
			this.groups.forEach((group) => {
				if (this.distinguishOddEvenOrder) {
					let oddOrderOffset = this.orderFrom;
					let evenOrderOffset = this.orderFrom;

					if (group.groupOrder === 'leftFirstRightLast') {
						evenOrderOffset += (group.valuesGroups[0].values.length - 1) * 2;
					} else if (group.groupOrder === 'leftLastRightFirst') {
						oddOrderOffset += group.valuesGroups[1].values.length * 2;
					}

					const isOddOrderFromEven = oddOrderOffset % 2 === 0;
					const isEvenOrderFromEven = evenOrderOffset % 2 === 0;
					const oddOrderFrom = (isOddOrderFromEven ? oddOrderOffset + 1 : oddOrderOffset);
					const evenOrderFrom = (isEvenOrderFromEven ? evenOrderOffset : evenOrderOffset + 1);

					group.valuesGroups[0].values.forEach((updatedValue, index) => {
						const value = this.values.find((item) => _.get(item, this.pathToUid) === updatedValue.uid);
						const newIndex = index * 2 + oddOrderFrom;
						// console.log(group.label, 'set odd order:', _.get(value, this.pathToLabel), newIndex);
						_.set(value, this.pathToOrder, newIndex);
					});
					group.valuesGroups[1].values.forEach((updatedValue, index) => {
						const value = this.values.find((item) => _.get(item, this.pathToUid) === updatedValue.uid);
						const newIndex = index * 2 + evenOrderFrom;
						// console.log(group.label, 'set even order:', _.get(value, this.pathToLabel), newIndex);
						_.set(value, this.pathToOrder, newIndex);
					});

				} else {
					group.valuesGroups[0].values.forEach((updatedValue, index) => {
						const value = this.values.find((item) => _.get(item, this.pathToUid) === updatedValue.uid);
						// console.log(group.label, 'set order:', _.get(value, this.pathToLabel), index + this.orderFrom);
						_.set(value, this.pathToOrder, index + this.orderFrom);
					});

				}

				if (this.sortOnChange) {
					this.values.sort((a, b) => {
						if (_.get(a, this.pathToOrder) < _.get(b, this.pathToOrder)) return -1;
						if (_.get(a, this.pathToOrder) > _.get(b, this.pathToOrder)) return 1;
						return 0;
					});
				}
			});

			this.$emit('input', this.values);
			this.$emit('change', this.values);
			this.$emit('submit');
		},
		onDialogClose() {
			this.$emit('close');
		},
	},
};
</script>
