<template>
	<portal to="root">
		<div
			v-if="visible"
			v-show="isActive"
			class="popup-modal"
			:class="{
				...rootClass,
				animate: animate,
				popupModal: modal,
				popupMax: fullscreen,
			}"
			:data-cy="currentComponentId"
			@click="onNodeClick"
		>
			<div class="popup-dialog" @click="onContentNodeClick" :class="classList" :style="popupDialogStyle"
				:data-cy="`${currentComponentId}__popup-dialog`"
			>
				<div class="popup-content" :class="{ selectList: listDialog }">
					<i class="popupClose icon-cancel" @click="close" :data-cy="`${currentComponentId}__iconClose`"></i>
					<div class="popup-header">
						<h2>{{ title }}</h2>
					</div>
					<div class="popup-body" :style="popupBodyStyle">
						<slot :parentComponentId="`${currentComponentId}__slotDefault`"></slot>
					</div>
					<div class="popup-footer">
						<button
							name="confirm"
							type="button"
							class="button"
							@click="submit"
							:data-cy="`${currentComponentId}__submit`"
							v-if="!readOnly"
						>
						{{ $t('ok') }}
						</button>
						<button
							name="cancel"
							type="button"
							class="buttonInverse"
							:data-cy="`${currentComponentId}__close`"
							@click="close"
						>
						{{ $t('cancel') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
import Dialog from '../mixins/Dialog';

export default {
	mixins: [ComponentIdentifier, Dialog],
	props: {
		name: {
			type: String,
			required: true,
		},
		modal: {
			type: Boolean,
			required: false,
			default: false,
		},
		fullscreen: {
			type: Boolean,
			required: false,
			default: false,
		},
		onOpen: {
			type: Function,
			required: false,
			default: () => {},
		},
		onClose: {
			type: Function,
			required: false,
			default: () => {},
		},
		onSubmit: {
			type: Function,
			required: false,
			default: () => {},
		},
		title: {
			type: String,
			required: false,
			default: '',
		},
		classList: {
			type: Array,
		},
		popupDialogStyle: String,
		popupBodyStyle: String,
		readOnly: {
			type: Boolean,
			default: false,
		},
		closeOnEscape: {
			type: Boolean,
			default: true,
		},
		submitOnEnter: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			visible: false,
			animate: false,
			active: false,
			listDialog: false,
			node: null,
			contentNode: null,
			ignoreClick: false,
			showSelected: false,
		};
	},
	created() {
		if (this.submitOnEnter) {
			this.$root.$listen('keydown-enter', this.onEnter, this);
		}
	},
	mounted() {
		this.$root.$listen('dialog-open', this.onDialogOpen, this);
		this.$root.$listen('dialog-close', this.onDialogClose, this);

		if (this.listDialog) {
			this.showSelected = '=';
		}

		if (this.closeOnEscape) {
			document.addEventListener('keydown', this.onKeyDown);
		}

		this.$emit('mounted');
	},
	beforeDestroy() {
		if (this.closeOnEscape) {
			document.removeEventListener('keydown', this.onKeyDown);
		}
	},
	methods: {
		onEnter() {
			if (this.visible) {
				this.submit();
			}
		},
		onKeyDown(event) {
			if (event.key === 'Escape' && this.visible) {
				this.close();
			}
		},
		onNodeClick() {
			if (!this.modal && !this.ignoreClick) {
				this.close();
			}
		},
		onContentNodeClick() {
			this.ignoreClick = true;
		},
		toggleView() {
			this.showSelected = !this.showSelected;
		},
		onDialogOpen(arg) {
			if (arg.name === this.name) {
				if (arg.modifiers && arg.modifiers.submitDialog) {
					this.listDialog = false;
					this.open();
				} else {
					this.listDialog = true;
					this.open();
				}
			}
		},
		onDialogClose(name) {
			if (name === this.name) {
				this.close();
			}
		},
		open() {
			if (this.visible) return;
			this.animate = false;
			this.visible = true;
			setTimeout(() => {
				this.animate = true;
			}, 0);
			this.onOpen();
			this.$emit('open', this.name);
		},
		close(event, quiet) {
			if (!this.visible) return;
			const closeTimeout = (quiet) ? 0 : 200;
			this.animate = false;
			setTimeout(() => {
				this.visible = false;
			}, closeTimeout);
			this.onClose();
			this.$emit('close', this.name);
		},
		async submit() {
			const close = await this.onSubmit();

			if (close == null || close === true) {
				this.$emit('submit');
				this.close();
			}
		},
	},
};
</script>
