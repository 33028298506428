export default {
	data() {
		return {
			colorThemesList: [],
		};
	},
	methods: {
		initColorThemes() {
			this.colorThemesList = [
				{
					label: this.$t('colorThemes.basic'),
					value: 'theme-basic',
					isDark: false,
				},
				{
					label: this.$t('colorThemes.solarized'),
					value: 'theme-solarized',
					isDark: false,
				},
				{
					label: this.$t('colorThemes.sharp'),
					value: 'theme-sharp',
					isDark: false,
				},
				{
					label: this.$t('colorThemes.rt'),
					value: 'theme-rt',
					isDark: false,
				},
				{
					label: this.$t('colorThemes.light'),
					value: 'theme-light',
					isDark: false,
				},
				{
					label: this.$t('colorThemes.dark'),
					value: 'theme-dark',
					isDark: true,
				},
			];
			this.themes = this.colorThemesList;
		},
		isDarkTheme(themeName) {
			const theme = _.find(this.colorThemesList, (th) => th.value === themeName);
			return (theme && theme.isDark);
		},
	},
};
