function diffArrays(array1, array2, diffArray) {
	for (let i = 0; i < array1.length; i += 1) {
		const value1 = array1[i];
		let found = false;

		for (let j = 0; j < array2.length; j += 1) {
			const value2 = array2[j];

			if (value1 === value2) {
				found = true;
				break;
			}
		}

		if (!found) {
			diffArray.push(value1);
		}
	}
}

function getArraysDiff(oldArray, newArray) {
	const diff = { added: [], removed: [] };
	diffArrays(oldArray, newArray, diff.removed);
	diffArrays(newArray, oldArray, diff.added);
	return diff;
}

export default class {
	constructor(vm, targetNode, classesToWatch, callback) {
		this.vm = vm;
		this.targetNode = targetNode;
		this.classesToWatch = classesToWatch;
		this.callback = callback;
		this.observer = null;
		this.lastClasses = Array.prototype.slice.call(targetNode.classList, 0);

		this.init = this.init.bind(this);
		this.observe = this.observe.bind(this);
		this.disconnect = this.disconnect.bind(this);
		this.mutationCallback = this.mutationCallback.bind(this);

		this.init();
	}

	init() {
		this.observer = new MutationObserver(this.mutationCallback);
		this.observe();
		this.vm.$once('hook:beforeDestroy', this.disconnect);
	}

	observe() {
		this.observer.observe(this.targetNode, { attributes: true });
	}

	disconnect() {
		this.observer.disconnect();
	}

	mutationCallback(mutationsList) {
		for (let index = 0; index < mutationsList.length; index += 1) {
			const mutation = mutationsList[index];

			if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
				const { target } = mutation;
				const currentClasses = Array.prototype.slice.call(target.classList, 0);

				const diff = getArraysDiff(this.lastClasses, currentClasses);
				const isDifferent = diff.added.length > 0 || diff.removed.length > 0;

				if (isDifferent) {
					const watchedDiff = { added: [], removed: [] };
					diff.added.forEach((cssClass) => {
						if (this.classesToWatch.includes(cssClass)) {
							watchedDiff.added.push(cssClass);
						}
					});
					diff.removed.forEach((cssClass) => {
						if (this.classesToWatch.includes(cssClass)) {
							watchedDiff.removed.push(cssClass);
						}
					});

					if (diff.added.length > 0 || diff.removed.length > 0) {
						this.callback(watchedDiff);
					}
				}

				this.lastClasses = currentClasses;
			}
		}
	}
}
