<template>
	<div class="form-fieldset">
		<s class="size-6">
			<small>{{ $t('translationType') }}</small>
			<label class="checkbox radio" :data-cy="`${currentComponentId}__forAllLangs__radioLabel`">
				<input
					:data-cy="`${currentComponentId}__forAllLangs__inputRadio`"
					type="radio"
					value="forAllLangs"
					:checked="translationType === 'forAllLangs'"
					@change="$emit('change', 'forAllLangs')"
				>
				<span class="checkmark"></span>
				<span class="label">{{ $t('forAllLangs') }}</span>
			</label>
			<label class="checkbox radio" :data-cy="`${currentComponentId}__pathToLocale__radioLabel`">
				<input
					:data-cy="`${currentComponentId}__pathToLocale__inputRadio`"
					type="radio"
					value="pathToLocale"
					:checked="translationType === 'pathToLocale'"
					@change="$emit('change', 'pathToLocale')"
				>
				<span class="checkmark"></span>
				<span class="label">{{ $t('pathToLocale') }}</span>
			</label>
		</s>
		<br>

		<template v-if="internalValue.constructor === Object">
			<template v-if="translationType === 'forAllLangs'">
				<template v-for="(locale, index) in $i18n.availableLocales">
					<s class="size-6" :key="index">
						<small>{{ $getLocale(localeTitle) || $t('translation') }} [{{ locale.toUpperCase() }}]</small>
						<ValidationProvider
							:ref="`locale${locale.toUpperCase()}`"
							:name="`${$getLocale(localeTitle) || $t('translation')} [ ${locale.toUpperCase()} ]`"
							:rules="{ required: _.isEmpty(internalValue.translated) }"
							:customMessages="{
								required: $t(
									'validation.requiredIfOtherEmpty',
									null,
									{
										attribute: `${$getLocale(localeTitle) || $t('translation')} [${locale.toUpperCase()}]`,
										attribute2: $t('defaultTranslation'),
									}
								)
							}"
							v-slot="{ errors, invalid }"
						>
							<input
								:data-cy="`${currentComponentId}__forAllLangs${index}__inputText`"
								type="text"
								v-model="internalValue[locale]"
								:class="{ 'lba-invalid': invalid && dirty }"
								@input="$emit('value-input')"
								@change="$emit('value-change')"
							>
							<template v-if="dirty">
								<span
									v-for="(err, errorIndex) in errors"
									:key="errorIndex"
									:data-cy="`${currentComponentId}__forAllLangs${index}__error${errorIndex}`"
									class="lba-messages"
								>{{ err }}</span>
							</template>
						</ValidationProvider>
					</s>
					<br :key="`br-${index}`">
				</template>
			</template>
			<template v-else>
				<ValidationProvider
					ref="pathToLocale"
					:name="$t('pathToLocale')"
					:rules="{
						required: _.isEmpty(internalValue.translated),
						pathToLocale: true,
					}"
					:customMessages="{
						required: $t(
							'validation.requiredIfOtherEmpty',
							null,
							{ attribute: $t('pathToLocale'), attribute2: $t('defaultTranslation') }
						)
					}"
					v-slot="{ errors, invalid }"
				>
					<s class="size-3">
						<small>
							{{ $t('pathToLocale') }}
							<span class="required-sign"></span>
						</small>
						<input
							:data-cy="`${currentComponentId}__pathToLocale__inputText`"
							type="text"
							v-model="internalValue.translate"
							:class="{ 'lba-invalid': invalid && dirty }"
							@input="$emit('value-input')"
							@change="$emit('value-change')"
						>
						<template v-if="dirty">
							<span
								v-for="(err, index) in errors"
								:key="index"
								:data-cy="`${currentComponentId}__pathToLocale__error${index}`"
								class="lba-messages"
							>{{ err }}</span>
						</template>
					</s>
					<s class="size-3">
						<small>{{ $t('translated') }}</small>
						<span v-if="!invalid" :data-cy="`${currentComponentId}__pathToLocaleTranslated`">
							{{ $t(internalValue.translate) }}
						</span>
					</s>
				</ValidationProvider>
			</template>
			<s class="size-6">
				<small>{{ $t('defaultTranslation') }}</small>
				<input
					:data-cy="`${currentComponentId}__defaultTranslation__inputText`"
					type="text"
					v-model="internalValue.translated"
					@input="$emit('value-input')"
					@change="$emit('value-change')"
				>
			</s>
		</template>
		<template v-else>
			<lba-locale-edit></lba-locale-edit>
		</template>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	model: {
		prop: 'translationType',
		event: 'change',
	},
	props: {
		internalValue: {
			type: Object,
			required: true,
		},
		translationType: {
			type: String,
			required: true,
		},
		dirty: {
			type: Boolean,
			required: true,
		},
		localeTitle: {
			type: String,
		},
	},
};
</script>
