<template>
	<div id="content" class="text" v-if="!loading">
		<lba-grid
			ref="grid"
			multiSelectAll
			hideableColumns
			gridName="lbadmin.def_import_export_settings"
			:collection="collection"
			:rowId="['def_import_export_setting_uid']"
			:title="$t(`${type}Settings`)"
			:filterAttributes="filterAttributes"
			:dynamicHeight="false"
		>
			<template v-slot:toolbar="props">
				<button @click="openDetail('+')" :data-cy="`${props.parentComponentId}__new`">
					{{ $t('new') }}
				</button>
			</template>
			<template v-slot:selected="props">
				<button class="buttonInverse" @click="openRemoveSelected" :data-cy="`${props.parentComponentId}__remove`">
					{{ $t('remove') }}
				</button>
			</template>
			<template v-slot:columns="props">
				<lba-grid-column
					id="name"
					:data-cy="`${props.parentComponentId}__name`"
					:label="$t('name')"
					searchable
					width="200"
				>
					<a @click="openDetail(props.row.def_import_export_setting_uid)"
						:data-cy="`${props.parentComponentId}__name__openDetail`"
					>
						{{ props.row.label }}
					</a>
				</lba-grid-column>
				<lba-grid-column
					id="system_name"
					:data-cy="`${props.parentComponentId}__systemName`"
					:label="$t('forTable')"
					searchable
					width="250"
				>
					{{ props.row.name }}
				</lba-grid-column>
				<lba-grid-column
					id="created"
					:data-cy="`${props.parentComponentId}__created`"
					:label="$t('created')"
					width="150"
				>
					{{ props.row.created ? $d(new Date(props.row.created), 'fullDigitsMedium') : '' }}
				</lba-grid-column>
				<lba-grid-column
					id="created_by"
					:data-cy="`${props.parentComponentId}__createdBy`"
					:label="$t('createdByUser')"
					width="150"
				>
					{{ getName(props.row) }}
				</lba-grid-column>
				<lba-grid-column
					id="file_type"
					:data-cy="`${props.parentComponentId}__fileZype`"
					:label="$t('fileType')"
					width="150"
				>
					{{ props.row.file_type }}
				</lba-grid-column>
				<lba-grid-column
					id="encoding"
					:data-cy="`${props.parentComponentId}__encoding`"
					:label="$t('encoding')"
					width="150"
				>
					{{ props.row.encoding }}
				</lba-grid-column>
				<lba-grid-column
					id="contains_header"
					:data-cy="`${props.parentComponentId}__containsHeader`"
					:label="$t('containsHeader')"
					width="150"
				>
					<i class="icon-ok green" v-if="props.row.contains_header"></i>
					<!--<i class="icon-cancel silver" v-if="!props.row.contains_header"></i>-->
				</lba-grid-column>
				<lba-grid-column
					:id="'actions'"
					:data-cy="`${props.parentComponentId}__actions`"
					:label="$t('actions')"
					width="60"
					class="actions"
					style="justify-content: right"
				>
					<a
						src="#"
						style="flex: 1"
						:data-cy="`${props.parentComponentId}__actions__showInCard`"
						@click.stop="openDetail(props.row.def_import_export_setting_uid)"
					>
						<i class="icon-new-tab"
							v-tooltip="$t('showInCard')">
						</i>
					</a>
					<a
						src="#"
						:data-cy="`${props.parentComponentId}__actions__openDialogRemoveEntry`"
						@click.stop="openRemoveDialog(props.row)"
					>
						<i class="icon-delete"
							v-tooltip="$t('remove')"
						>
						</i>
					</a>
				</lba-grid-column>
			</template>
		</lba-grid>
		<lba-dialog-modal
			parentComponentId="importExportSettings"
			componentId="removeEntry"
			name="removeEntry"
			:title="$t('removeImportExportSetting')"
			@close="removeRow = null"
		>
			<template #default="props">
				<div class="popup-footer">
					<button class="buttonRed" type="button" @click="confirmRemove"
						:data-cy="`${props.parentComponentId}__remove`"
					>
						{{ $t('remove') }}
					</button>
					<button class="buttonInverse" type="button" v-lba-dialog-close="'removeEntry'"
						:data-cy="`${props.parentComponentId}__closeDialog`"
					>
						{{ $t('cancel') }}
					</button>
				</div>
			</template>
		</lba-dialog-modal>
		<lba-dialog-modal
			parentComponentId="importExportSettings"
			componentId="removeSelectedEntries"
			name="removeSelectedEntries"
			:title="messageSelectedRows()"
			@close="removeRow = null"
		>
			<template #default="props">
				<div class="popup-footer">
					<button class="buttonRed" type="button" @click="confirmRemoveSelected"
						:data-cy="`${props.parentComponentId}__remove`"
					>
						{{ $t('remove') }}
					</button>
					<button class="buttonInverse" type="button" v-lba-dialog-close="'removeSelectedEntries'"
						:data-cy="`${props.parentComponentId}__closeDialog`"
					>
						{{ $t('cancel') }}
					</button>
				</div>
			</template>
		</lba-dialog-modal>
	</div>
</template>

<script>
import ImportExportSettingsModel from '../models/ImportExportSettings';

export default {
	data() {
		return {
			loading: true,
			importExportSettingsModel: null,
			collection: null,
			filterAttributes: {},
			removeRow: null,
			removeSelected: null,
			type: null,
		};
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.importExportSettingsModel = new ImportExportSettingsModel(this.$http);

		const params = { _type: null };
		if (this.$route.name === 'import-settings') {
			params._type = 'IMPORT';
			this.type = 'import';
		} else if (this.$route.name === 'export-settings') {
			params._type = 'EXPORT';
			this.type = 'export';
		}

		this.collection = new this.$Collection(this.importExportSettingsModel, { params });
		this.collection.attach(this, 'collection');
		this.loading = false;
	},
	methods: {
		getName(row) {
			if (row.firstname && row.lastname) {
				return `${row.firstname} ${row.lastname}`;
			}
			if (row.firstname) {
				return row.firstname;
			}
			if (row.lastname) {
				return row.lastname;
			}
			return row.user_name;
		},
		openDetail(id) {
			this.$routerWrap.push({
				name: `${this.type}-setting`,
				params: { id, openNewTab: true },
			});
		},
		openRemoveDialog(row) {
			this.removeRow = row;
			this.$root.$emit('dialog-open', { name: 'removeEntry' });
		},
		confirmRemove() {
			if (this.removeRow) {
				this.importExportSettingsModel.remove(this.removeRow.def_import_export_setting_uid);
			}
			this.removeRow = null;
			this.$root.$emit('dialog-close', 'removeEntry');
		},
		openRemoveSelected() {
			this.removeSelected = this.$refs.grid.getChecked();
			this.$root.$emit('dialog-open', { name: 'removeSelectedEntries' });
		},
		confirmRemoveSelected() {
			if (this.removeSelected) {
				this.importExportSettingsModel.removeSelected(this.removeSelected)
					.then((response) => {
						if (response && response.data === 'ok') {
							this.$notify.success(this.$t('entriesRemoved'));
							this.$root.$emit('dialog-close', 'removeSelectedEntries');
							this.$root.$emit('grid.reload', this.$refs.grid.id);
						}
					})
					.catch(() => {
						this.$notify.warn(this.$t('entriesCantRemove'));
					});
			}
		},
		messageSelectedRows() {
			const selection = this.removeSelected;
			const action = this.$t('remove').toLowerCase();

			if (!selection) return '';
			if (selection.checkedAll) {
				if (selection.unchecked && Object.keys(selection.unchecked).length > 0) {
					return this.$t(
						'confirmRemoveDialog.messageCheckedAllUnchecked', {
							action,
							uncheckedCount: Object.keys(selection.unchecked).length,
						});
				} else {
					return this.$t('confirmRemoveDialog.messageCheckedAll', { action });
				}
			} else if (selection.filterCheckedAll) {
				return this.$t('confirmRemoveDialog.messageFilteredAll', { action });

			} else if (selection.checked && Object.keys(selection.checked).length > 0) {
				return this.$t('confirmRemoveDialog.messageChecked', {
					action,
					checkedCount: Object.keys(selection.checked).length,
				});
			}
			return '';
		},
	},
};
</script>
