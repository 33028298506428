<template>
	<lba-dialog
		:parentComponentId="currentComponentId"
		:name="`${name}.export-dialog`"
		:title="title"
		:modal="modal"
		:classList="['fixed-size-dialog import-dialog']"
		@open="init"
		@close="resetSteps(true)"
	>
		<lba-content-tabs
			:parentComponentId="currentComponentId"
			v-if="initialized"
			ref="contentTabs"
			nextPrevious
			:tabs="tabs"
			:defaultTab="defaultTab"
			:beforeTabSelected="beforeTabSelected"
			@active-tab-changed="setActiveTab"
		>
			<!--STEP 0: FORMAT AND SELECT-->
			<div>
				<!--CSV & XSLX-->
				<h3>{{ $t('settings.settings') }}</h3>
				<s>
					<small>{{ $t('importExportSettingTemplates') }}</small>
					<select
						:data-cy="`${currentComponentId}__templates__select`"
						v-model="usedImportExportSettingUid"
						@change="prepareImportExportSettings"
					>
						<option :value="null" :data-cy="`${currentComponentId}__templates__select__optionNull`">
							-- {{ $t('blank') }} --
						</option>
						<option
							v-for="(setting, index) in importExportSettings"
							:key="index"
							:value="setting.def_import_export_setting_uid"
							:data-cy="`${currentComponentId}__templates__select__option${index}`"
						>{{ setting.label }}</option>
					</select>
				</s>
				<!--<s v-if="isFilterUsed()" class="half">
					<small class="orange">{{ $t('useFilter') }}</small>
					<lba-switch
						v-model="useFilter"
						class="mb-2 mt-2"
						:name="`${name}.table-export.switch-filter`"
						:label1="$t('no')"
						:label2="$t('yes')"
						@input="setFilter(); setTabChanged(1);"
					/>
				</s>-->
				<s style="width: 160px;">
					<small>{{ $t('fileType') }}</small>
					<label class="checkbox radio" :data-cy="`${currentComponentId}__fileTypeCsv__label`">
						<input
							:data-cy="`${currentComponentId}__fileTypeCsv__input`"
							type="radio"
							value="csv"
							v-model="fileType"
							:name="`${name}.file-type`"
							@change="setTabChanged(1);"
						>
						<span class="checkmark"></span>
						<span class="label">CSV</span>
					</label>
					<label class="checkbox radio" :data-cy="`${currentComponentId}__fileTypeXlsx__label`">
						<input
							:data-cy="`${currentComponentId}__fileTypeXlsx__input`"
							type="radio"
							value="xlsx"
							v-model="fileType"
							:name="`${name}.file-type`"
							@change="setTabChanged(1);"
						>
						<span class="checkmark"></span>
						<span class="label">XLSX</span>
					</label>
				</s>
				<s class="half">
					<small>{{ $t('exportHeader') }}</small>
					<lba-switch
						:parentComponentId="currentComponentId"
						componentId="exportHeader"
						v-model="exportHeader"
						class="mb-2 mt-2"
						:name="`${name}.table-export.switch-header`"
						:label1="$t('no')"
						:label2="$t('yes')"
						@input="exportHeaderSetByUser = true; setTabChanged(1);"
					/>
				</s>
				<s v-if="exportHeader">
					<small>{{ $t('columnNames') }}</small>
					<label class="checkbox radio" :data-cy="`${currentComponentId}__columnNamesInternal__label`">
						<input
							:data-cy="`${currentComponentId}__columnNamesInternal__input`"
							type="radio"
							value="INTERNAL"
							v-model="columnNames"
							:name="`${name}.column-names`"
							@change="setColumnNames(); setTabChanged(1);"
						>
						<span class="checkmark"></span>
						<span class="label">{{ $t('internalName') }}</span>
					</label>
					<label class="checkbox radio" :data-cy="`${currentComponentId}__columnNamesTranslate__label`">
						<input
							:data-cy="`${currentComponentId}__columnNamesTranslate__input`"
							type="radio"
							value="TRANSLATE"
							v-model="columnNames"
							:name="`${name}.column-names`"
							@change="setColumnNames(); setTabChanged(1);"
						>
						<span class="checkmark"></span>
						<span class="label">{{ $t('translation') }}</span>
					</label>
				</s>
				<!--ONLY CSV-->
				<template v-if="fileType === 'csv'">
					<s class="half">
						<small>{{ $t('columnDelimiter') }}</small>
						<select
							:data-cy="`${currentComponentId}__columnDelimiter__select`"
							v-model="columnDelimiter"
							@change="setTabChanged(1);"
						>
							<option v-for="(delimiter, index) in columnDelimiters" :key="index" :value="delimiter.value"
								:data-cy="`${currentComponentId}__columnDelimiter__select__option${index}`"
							>
								{{ delimiter.label }}
							</option>
						</select>
					</s>
					<s class="half">
						<small>{{ $t('quoteCharacter') }}</small>
						<select
							:data-cy="`${currentComponentId}__quoteCharacter__select`"
							v-model="quoteCharacter"
							@change="setTabChanged(1);"
						>
							<option v-for="(character, index) in quoteCharacters" :key="index" :value="character.value"
								:data-cy="`${currentComponentId}__quoteCharacter__select__option${index}`"
							>
								{{ character.label }}
							</option>
						</select>
					</s>
					<s class="half">
						<small>{{ $t('encoding') }}</small>
						<select
							:data-cy="`${currentComponentId}__encoding__select`"
							v-model="encoding"
							@change="setTabChanged(1);"
						>
							<option v-for="(encode, index) in encodings" :key="index" :value="encode"
								:data-cy="`${currentComponentId}__encoding__select__option${index}`"
							>
								{{ encode }}
							</option>
						</select>
					</s>
				</template>
				<!--ONLY XLSX-->
				<template v-else>
					<s style="width: 160px;">
						<small>{{ $t('autoFilter') }}</small>
						<lba-switch
							:parentComponentId="currentComponentId"
							componentId="autoFilter"
							v-model="autoFilter"
							class="mb-2 mt-2"
							:name="`${name}.table-export.switch-autoFilter`"
							:label1="$t('no')"
							:label2="$t('yes')"
							@input="setTabChanged(1);"
						/>
					</s>
					<s class="half">
						<small>{{ $t('freezeFirstRow') }}</small>
						<lba-switch
							:parentComponentId="currentComponentId"
							componentId="freezeFirstRow"
							v-model="freezeFirstRow"
							class="mb-2 mt-2"
							:name="`${name}.table-export.switch-freezeFirstRow`"
							:label1="$t('no')"
							:label2="$t('yes')"
							@input="setTabChanged(1);"
						/>
					</s>
				</template>

				<!--VÝBĚR SLOUPCŮ-->
				<h3>{{ $t('columnSelecting') }}</h3>
				<lba-dnd-two-lists
					:parentComponentId="currentComponentId"
					componentId="columnSelecting"
					ref="dnd"
					:name="`${name}.columnSelection`"
					:listTitle1="$t('notExportedColumns')"
					:listTitle2="$t('exportedColumns')"
					:list1.sync="notExportedColumns"
					:list2.sync="headerMap"
					:staticListItems1="false"
					:staticListItems2="false"
					:staticListOrder1="false"
					:staticListOrder2="false"
					listMaxHeight="300px"
					noDialog
					class="fullwidth"
					style="width: 100%;"
					@update:list1="setColumnNames"
					@update:list2="setColumnNames"
				>
					<template v-slot:list1="props">
						<div
							class="flex-row inner border"
							v-for="(item, index) in props.list"
							:key="index"
						>
							<label class="checkbox" :data-cy="`${currentComponentId}__list1__${index}__label`">
								<input
									type="checkbox"
									lba-dnd-list-checkbox
									:data-cy="`${currentComponentId}__list1__${index}__input`"
								/>
								<i class="icon-ok"></i>
								<strong class="label">{{ item.title }}</strong>
							</label>
						</div>
					</template>

					<template v-slot:list2="props">
						<div
							class="flex-row inner border"
							v-for="(item, index) in props.list"
							:key="index"
						>
							<label class="checkbox" :data-cy="`${currentComponentId}__list2__${index}__label`">
								<input
									type="checkbox"
									lba-dnd-list-checkbox
									:data-cy="`${currentComponentId}__list2__${index}__input`"
								/>
								<i class="icon-ok"></i>
								<strong class="label">{{ item.title }}</strong>
							</label>
						</div>
					</template>
				</lba-dnd-two-lists>
			</div>

			<!--STEP 1: PŘEHLED EXPORTOVANÝCH DAT-->
			<div>
				<div v-if="isLoading" class="row">
					<span class="note">{{ $t('loadingExportPreview') }} {{ formattedLoadingTime }}</span>
				</div>

				<template v-else>
					<div class="content-table-wrapper" style="max-height: 500px;">
						<table class="content-table mt-1">
							<tr>
								<th>{{ $t('rowNumber') }}</th>
								<th v-for="(mapped, index) in previewHeaderMap" :key="index">
									<template v-if="exportHeader">{{ mapped.header || mapped.title }}</template>
									<template v-else>{{ mapped.title }} ({{ index + 1 }})</template>
								</th>
							</tr>

							<tr v-for="(row, index) in tableContent" :key="index"
								:data-cy="`${currentComponentId}__tableContentRow${index}`"
							>
								<td>{{ index + 1 }}</td>
								<td
									v-for="(mapped, mappedColumnIndex) in previewHeaderMap"
									:key="`${mapped.column}-${mappedColumnIndex}`"
									:data-cy="`${currentComponentId}__tableContentRow${index}__${mapped.column}${mappedColumnIndex}`"
								>
									<span class="input">
										{{ getRowValue(row, mapped, mappedColumnIndex) }}
									</span>
								</td>
							</tr>
						</table>
					</div>
					<span class="note displayBlock mt-1">
						{{ $t('shownNumberOfRecordsOutOfTotal', null, { number: tableContent.length, total: internalCollection.length }) }}
					</span>
					<br>
				</template>
			</div>

			<!--STEP 2: VÝSLEDEK ZE SERVERU-->
			<div class="active-flex-column">
				<div class="row mb-3">
					<div class="col-3">
						<h3 class="mt-0">{{ $t('saveSettings') }}</h3>
						<s>
							<small>{{ $t('name') }}</small>
							<input
								type="text"
								v-model="importExportSettingName"
								:data-cy="`${currentComponentId}__importExportSettingName__input`"
							>
						</s>
						<br>
						<button
							v-if="usedImportExportSettingUid != null && hasImportExportSettingChanged"
							type="button"
							class="mb-3 mr-2 displayInlineBLock"
							:data-cy="`${currentComponentId}__saveImportExportSetting`"
							@click="saveImportExportSetting"
						>
							{{ $t('save') }}
						</button>
						<button
							:data-cy="`${currentComponentId}__createImportExportSetting`"
							type="button"
							class="mb-3"
							@click="createImportExportSetting"
							:disabled="createNewTemplateDisabled"
						>
							{{ $t('createNew') }}
						</button>
					</div>
					<div class="col-9">
						<div class="well">
							<h3 class="mt-0">{{ $t('formatSettings') }}</h3>
							<template v-if="fileType === 'csv'">
								<s class="half">
									<small>{{ $t('columnDelimiter') }}</small>
									<span :data-cy="`${currentComponentId}__columnDelimiterLabel`">{{ getColumnDelimiter().label }}</span>
								</s>
								<s class="half">
									<small>{{ $t('quoteCharacter') }}</small>
									<span :data-cy="`${currentComponentId}__quoteCharacterLabel`">{{ getQuoteCharacter().label }}</span>
								</s>
								<s class="half">
									<small>{{ $t('encoding') }}</small>
									<span :data-cy="`${currentComponentId}__encodingLabel`">{{ encoding }}</span>
								</s>
							</template>
							<s>
								<small>{{ $t('exportHeader') }}</small>
								<span :data-cy="`${currentComponentId}__exportHeaderLabel`">
									<i v-if="exportHeader" class="icon-ok green"></i>
									<i v-else class="icon-cancel gray" style="font-size:12px;top: 5px;"></i>
								</span>
								<!--<input type="checkbox" v-model="exportHeader" disabled>-->
								<!--<lba-checkbox
										v-model="containsHeader"
										disabled
										:name="`${name}.contains-header`"
										:label="$t('exportHeader')"
								/>-->
							</s>

							<h3>{{ $t('exportedColumns') }}</h3>
							<div class="export-column-list">
								<span v-for="(mapped, index) in headerMap" :key="index"
									:data-cy="`${currentComponentId}__exportedColumns__${index}`"
								>
									<em>{{ mapped.title }}:</em> {{ mapped.header }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="displayFlex center" style="flex: 1">
					<button @click="exportFile" class="buttonBig" :data-cy="`${currentComponentId}__downloadFile`">
						<i class="icon-download"></i>
						{{ $t('downloadFile') }}
					</button>
				</div>
			</div>
			<template v-if="activeTabId === tabs[2].id" v-slot:arrows>
				<button v-lba-dialog-close="`${name}.export-dialog`" :data-cy="`${currentComponentId}__closeDialogExport`">
					{{ $t('close') }}
				</button>
			</template>
		</lba-content-tabs>
	</lba-dialog>
</template>

<style scoped>
.active.active-flex-column {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}
</style>

<script>
import TableExportModel from './models/TableExport';
import ImportExportSettingsModel from '../../models/ImportExportSettings';
import ComponentIdentifier from '../../mixins/ComponentIdentifier';

export default {
	name: 'LbaTableExport',
	mixins: [ComponentIdentifier],
	props: {
		// dialog props
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default() { return this.$t('doExportRecords'); },
		},
		modal: {
			type: Boolean,
			default: true,
		},
		collection: {
			type: Object,
		},
		model: {
			type: Object,
		},
		modelOptions: {
			type: Object,
			default: () => ({}),
		},
		originalModelOptions: {
			type: Object,
			default: () => ({}),
		},
		preselectedColumns: {
			type: Array,
		},
		exportData: {
			type: String,
			validator: (value) => (value === 'all' || value === 'checked' || value === 'filtered'),
		},
		checkedInfo: {
			type: Object,
			default: null,
		},

		description: String,
		mappingDescription: String,
		dataCheckDescription: String,
		resources: {
			type: Object,
			validator(value) {
				return (
					!_.isEmpty(value) &&
					'exportSettings' in value &&
					'importExportDefinition' in value
				);
			},
		},
	},
	data() {
		return {
			importExportSettingsModel: null,
			tableImportModel: null,
			internalCollection: null,
			tabs: [
				{
					id: 0,
					label: `1 ${this.$t('formatSettings')} ${this.$t('and')} ${this.$t('columnSelecting').toLowerCase()}`,
					changed: false,
					tooltipNext: null,
				},
				{
					id: 1,
					label: `2 ${this.$t('dataPreview')}`,
					disabled: true,
					disabledArrowNext: false,
					changed: false,
					tooltipNext: null,
					next: this.$t('doExport'),
				},
				{
					id: 2,
					label: `3 ${this.$t('export')}`,
					disabled: true,
					disabledTab: true,
					changed: false,
					tooltipNext: null,
				},
			],
			defaultTab: 0,
			columns: [],
			importExportDefinition: null,
			usedImportExportSettingUid: null,
			importExportSettingName: null,
			importExportSettings: [],

			// STEP 1: Format settings
			fileType: 'csv',
			columnDelimiter: ';',
			columnDelimiters: [
				{ label: this.$t('tab'), value: `\t` },
				{ label: `,`, value: `,` },
				{ label: `;`, value: `;` },
			],
			quoteCharacter: '"',
			quoteCharacters: [
				{ label: `"`, value: `"` },
				{ label: `'`, value: `'` },
				{ label: `-- ${this.$t('blank')} --`, value: null },
			],
			encoding: 'UTF-8',
			encodings: [
				'UTF-8',
				'IBM866',
				'ISO-8859-2',
				'ISO-8859-3',
				'ISO-8859-4',
				'ISO-8859-5',
				'ISO-8859-6',
				'ISO-8859-7',
				'ISO-8859-8',
				'ISO-8859-8-I',
				'ISO-8859-10',
				'ISO-8859-13',
				'ISO-8859-14',
				'ISO-8859-15',
				'ISO-8859-16',
				// 'KOI8-R',
				// 'KOI8-U',
				'macintosh',
				'windows-874',
				'windows-1250',
				'windows-1251',
				'windows-1252',
				'windows-1253',
				'windows-1254',
				'windows-1255',
				'windows-1256',
				'windows-1257',
				'windows-1258',
				'x-mac-cyrillic',
				// 'GBK',
				// 'gb18030',
				// 'Big5',
				// 'EUC-JP',
				// 'ISO-2022-JP',
				// 'Shift_JIS',
				// 'EUC-KR',
				'replacement',
				'UTF-16BE',
				'UTF-16LE',
				'x-user-defined',
			],
			exportHeader: false,
			exportHeaderSetByUser: false,
			headerMap: [],
			previewHeaderMap: [],
			requiredColumns: [],
			tableContent: [],
			// tablePreview: [],
			validate: null,
			loadingTimeStart: 0,
			formattedLoadingTime: '',
			isLoading: false,
			headerMapValid: false,
			notExportedColumns: [],
			columnNames: 'TRANSLATE',
			autoFilter: false,
			freezeFirstRow: false,
			queryOptions: {},
			initialized: false,
			activeTabId: null,
			hasImportExportSettingChanged: false,
			createNewTemplateDisabled: false,
		};
	},
	computed: {
		customColumn() {
			return this.columns.find((column) => column.custom);
		},
		currentImportExportSetting() {
			return {
				name: this.name,
				quote_character: this.quoteCharacter,
				column_delimiter: this.columnDelimiter,
				encoding: this.encoding || null,
				contains_header: this.exportHeader === true,
				header_map: this.headerMap || null,
				label: this.importExportSettingName,
				file_type: this.fileType || null,
				type: 'EXPORT',
				column_names: this.columnNames,
			};
		},
	},
	watch: {
		headerMap() {
			this.validateHeaderMap();
		},
	},
	created() {
		this.importExportSettingsModel = new ImportExportSettingsModel(this.$http);
		this.tableImportModel = new TableExportModel(this.$http);
	},
	methods: {
		setActiveTab(id) {
			this.activeTabId = id;

			if (this.activeTabId === 2) {
				this.hasImportExportSettingChanged = this.checkImportExportSetting();
			}
		},
		getColumnDelimiter() {
			return this.columnDelimiters.find((item) => item.value === this.columnDelimiter);
		},
		getQuoteCharacter() {
			return this.quoteCharacters.find((item) => item.value === this.quoteCharacter);
		},
		async init() {
			// console.debug('[LbaTableExport] open');
			if (this.initialized) return this.prepareColumns();

			if (this.collection) {
				const options = { method: this.collection.modelExportMethod, dataChunkLength: 100, vm: this };
				this.internalCollection = new this.$Collection(this.collection.model, options);
			} else if (this.model) {
				const options = { ...this.modelOptions, dataChunkLength: 100, vm: this };
				this.internalCollection = new this.$Collection(this.model, options);
			} else {
				throw new Error('[LbaTableExport] collection or model must be specified');
			}

			if (!_.isEmpty(this.resources) && this.resources.exportSettings != null && this.resources.importExportDefinition != null) {
				this.importExportSettings = this.resources.exportSettings;
				this.importExportDefinition = this.resources.importExportDefinition;
			} else {
				this.importExportSettings = (await this.importExportSettingsModel.query({ tableName: this.name, _type: 'EXPORT' })).data;
				const result = await this.tableImportModel.getImportExportDefinition(this.name);
				this.importExportDefinition = result.data;
			}
			this.prepareColumns();
			this.setFilter();
			this.initialized = true;
		},
		prepareColumns() {
			// console.debug('[LbaTableExport] preselectedColumns:', JSON.stringify(this.preselectedColumns));
			let changed = false;
			this.columns = [];
			if (!_.isEmpty(this.preselectedColumns)) {
				this.preselectedColumns.forEach((preselectedColumn) => {
					const column = this.importExportDefinition.columns.columns.find((item) => item.name === preselectedColumn);

					if (!column) {
						console.warn(
							`[LbaTableExport] ${this.name} | preselected column: ${preselectedColumn} not found in import/export definition`
						);
						return;
					}

					changed = true;
					this.columns.push(column);
				});

				if (!this.columns.length) {
					console.warn(
						`[LbaTableExport] ${this.name} | preselected columns are defined but none was found in import/export definition`
					);
				}
			}

			// fallback if preselectedColumns are invalid or not defined
			if (!this.columns.length) {
				changed = true;
				this.columns = this.importExportDefinition.columns.columns;
			}

			if (changed || !this.notExportedColumns.length) {
				this.prepareNotExportedColumns();
			}
			// console.debug('[LbaTableExport] columns:', JSON.stringify(this.columns.map((item) => item.name)));
		},
		prepareNotExportedColumns() {
			this.notExportedColumns = [];
			this.columns.forEach((column, index) => {
				this.notExportedColumns.push({
					column: column.name,
					doImport: true,
					multiple: column.multiple,
					custom: column.custom,
					title: $getLocale(column.title),
					label: $getLocale(column.title),
					header: $getLocale(column.title),
					type: column.type,
					date_format: column.date_format,
					index,
				});
			});
		},
		isFilterUsed() {
			let originalQueryOptions = null;
			let updatedOptions = null;

			if (this.collection) {
				originalQueryOptions = _.cloneDeep(this.collection.originalQueryOptions);
				updatedOptions = _.cloneDeep(this.collection.lastOptions);
			} else {
				originalQueryOptions = _.cloneDeep(this.originalModelOptions.params);
				updatedOptions = _.cloneDeep(this.modelOptions.params);
			}
			delete originalQueryOptions._limit;
			delete originalQueryOptions._offset;
			delete updatedOptions._limit;
			delete updatedOptions._offset;
			const isEqual = _.isEqual(originalQueryOptions, updatedOptions);
			// console.debug('equal:', isEqual, originalQueryOptions, updatedOptions);
			return !isEqual;
		},
		async setFilter() {
			if (this.collection) {
				if (this.exportData === 'filtered') {
					this.queryOptions = _.cloneDeep(this.collection.lastOptions);
				} else {
					this.queryOptions = _.cloneDeep(this.collection.originalQueryOptions);
				}
			} else {
				if (this.exportData === 'filtered') {
					this.queryOptions = _.cloneDeep(this.modelOptions.params);
				} else {
					this.queryOptions = _.cloneDeep(this.originalModelOptions.params);
				}
			}
			delete this.queryOptions._limit;
			delete this.queryOptions._offset;
			await this.preparePreview();
			// await this.prepareTablePreview();
		},
		setColumnNames() {
			this.headerMap.forEach((mappedColumn) => {
				if (this.columnNames === 'INTERNAL') {
					mappedColumn.header = mappedColumn.column;
				} else {
					mappedColumn.header = mappedColumn.title;
				}
			});
		},
		/* async prepareTablePreview() {
			const response = await this.internalCollection.getAll(0, 5, { queryOptions: this.queryOptions });
			this.tablePreview = response;
			this.headerMap = [];
			this.validateHeaderMap();
		}, */
		getColumnError(mapped) {
			if (!this.isMappedColumnValid(mapped)) {
				const tooltip = {
					content: this.$t('columnDuplicate', null, { attribute: $getLocale(mapped.title) }),
					classes: ['lba-messages'],
					html: true,
				};
				return tooltip;
			}
		},
		getRowErrors(row, columnName) {
			if (
				!row || !(
					row.$errors &&
					row.$errors[columnName] &&
					row.$errors[columnName].length > 0
				)
			) {
				return;
			}

			const tooltip = { content: '', classes: ['lba-messages'], html: true };
			row.$errors[columnName].forEach((error) => {
				const message = this.$t(error.message, null, error.attributes);
				tooltip.content += `${message}<br>`;
			});
			return tooltip;
		},
		setTabChanged(step, changed = true) {
			this.tabs[step].changed = changed;

			if (changed) {
				this.resetSteps();
			}
		},
		getRowValue(row, mapped, mappedIndex) {
			const columnName = mapped.column;

			if (
				_.isEmpty(row) ||
				row[columnName] == null ||
				(
					row[columnName].constructor !== Number &&
					row[columnName].constructor !== Boolean &&
					_.isEmpty(row[columnName])
				)
			) {
				return '-';
			}
			const column = row[columnName];

			if (this.customColumn && this.customColumn.name === columnName) {
				const keys = Object.keys(column);
				const result = [];
				keys.forEach((key) => {
					if (this.customColumn.excludeExportKeys && this.customColumn.excludeExportKeys.includes(key)) return;
					result.push(`${key}: "${column[key] || ''}"`);
				});
				return result.join('; ');
			}
			if (mapped.multiple || column.constructor === Array) {
				if (mapped.spreadIntoMultipleColumns) {
					let count = 0;
					for (let i = 0; i < mappedIndex; i += 1) {
						count += (this.previewHeaderMap[i].column === columnName);
					}

					const value = column[count];
					if (value != null && value !== '') {
						return value;
					}
					return '-';
				}
				return column.join(',');
			}
			if (mapped.type === 'date') {
				if (!_.isEmpty(mapped.date_format)) {
					return moment(new Date(column)).format(mapped.date_format);
				}
				return moment(new Date(column)).format('DD.MM.YYYY HH:mm:ss');
			}
			return column;
		},

		resetStepsFrom(step) {
			// format settings and column mapping
			if (step <= 1) {
				this.fileType = 'csv';
				this.columnDelimiter = ';';
				this.quoteCharacter = '"';
				this.encoding = 'UTF-8';
				this.exportHeader = null;
				this.autoFilter = false;
				this.freezeFirstRow = false;
				this.columnNames = 'TRANSLATE';
				this.exportHeaderSetByUser = false;
				this.usedImportExportSettingUid = null;
				this.importExportSettingName = null;
				this.headerMap = [];
				this.previewHeaderMap = [];
				this.activeTabId = 0;
				this.queryOptions = {};
				this.setFilter();
				this.initialized = false;
				this.createNewTemplateDisabled = false;
			}
			// data control
			if (step <= 2) {
				this.isLoading = true;
				this.tableContent = [];
			}

			this.tabs.forEach((tab) => {
				tab.changed = false;
			});
		},
		resetSteps(force = false) {
			const needsReload = this.importTotalCount > 0;
			const changedTabIndex = this.tabs.findIndex((tab) => tab.changed);
			if (changedTabIndex < 1 && !force) return;

			const resetFrom = force ? 0 : changedTabIndex + 1;
			this.resetStepsFrom(resetFrom);

			if (force) {
				this.$emit('close', { needsReload });
			}
		},

		beforeTabSelected(fromStep, toStep) {
			if (toStep < 1) return true;
			if (toStep === 1 && this.headerMapValid) {
				this.preparePreview();
				return true;
			}
			if (toStep === 2 && this.headerMapValid) {
				return true;
			}

			return false;
		},

		// STEP 0
		async prepareImportExportSettings() {
			if (this.usedImportExportSettingUid == null) return;

			const importExportSetting = this.importExportSettings.find(
				(setting) => setting.def_import_export_setting_uid === this.usedImportExportSettingUid
			);
			this.quoteCharacter = importExportSetting.quote_character || '"';
			this.columnDelimiter = importExportSetting.column_delimiter || ';';
			this.encoding = importExportSetting.encoding || 'UTF-8';
			this.exportHeader = importExportSetting.contains_header;
			this.exportHeaderSetByUser = false;
			this.importExportSettingName = importExportSetting.label;
			this.headerMap = _.cloneDeep(importExportSetting.header_map || []);
			this.headerMap.filter((mapped) => {
				const columnDefinition = this.importExportDefinition.columns.columns.find((column) => column.name === mapped.column);
				if (!_.isEmpty(columnDefinition)) {
					mapped.date_format = columnDefinition.date_format;
					return true;
				}
				return false;
			});
			this.previewHeaderMap = [];
			this.columnNames = importExportSetting.column_names || 'TRANSLATE';
			this.prepareNotExportedColumns();
			this.notExportedColumns = this.notExportedColumns.filter((column) =>
				!this.headerMap.find((item) => item.column === column.column));
			if (importExportSetting.file_type) {
				this.fileType = importExportSetting.file_type;
			}
			this.mapWithImportExportSetting(importExportSetting);
		},
		mapWithImportExportSetting(setting) {
			if (_.isEmpty(this.headerMap)) return;
			let headerMap = _.cloneDeep(setting.header_map);
			headerMap = headerMap.filter((mapped, index) => headerMap.findIndex((item) => item.column === mapped.column) === index);

			headerMap.forEach((mapped) => {
				const m = this.headerMap.find((item) => item.column === mapped.column);
				m.column = mapped.column;
				this.columnMapChange(m);
			});
		},

		isMappedColumnValid(mappedColumn) {
			if (mappedColumn.column == null) return true;
			return this.headerMap.find((mapped) => mapped.column === mappedColumn.column && mapped !== mappedColumn) == null;
		},
		validateHeaderMap() {
			if (this.headerMap.length === 0) {
				this.headerMapValid = false;
				this.tabs[1].disabled = true;
				this.tabs[2].disabled = true;
				this.tabs[0].tooltipNext = this.$t('validation.min', { attribute: this.$t('exportedColumns'), min: 1 });
				this.tabs[2].disabledTab = true;
			} else {
				this.headerMapValid = true;
				this.tabs[1].disabled = false;
				this.tabs[2].disabled = false;
				this.tabs[0].tooltipNext = null;
				this.tabs[2].disabledTab = false;
			}
		},
		columnMapChange(mapped) {
			this.setTabChanged(1);

			if (mapped.column == null) {
				mapped.custom = false;
				mapped.title = null;
				mapped.index = null;
				mapped.doImport = false;
			} else {
				const columnIndex = this.columns.findIndex((col) => col.name === mapped.column);
				if (columnIndex > -1) {
					const column = this.columns[columnIndex];
					mapped.title = $getLocale(column.title);
					mapped.doImport = true;
					mapped.custom = column.custom === true;
					mapped.index = columnIndex;

					if (_.isEmpty(mapped.header)) {
						mapped.header = $getLocale(column.title);
					}
				} else {
					console.warn(
						`[LbaTableExport] columnMapChange: mapped column '${mapped.column}' is not found in columns list`
					);
				}
			}

			this.validateHeaderMap();
		},

		// STEP 1
		formatTime(from) {
			const now = (new Date()).getTime();
			const diff = Math.round((now - from) / 1000);

			const minutes = (Math.round(diff / 60));
			const seconds = diff % 60;
			return `${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
		},
		setLoadingTime() {
			this.formattedLoadingTime = this.formatTime(this.loadingTimeStart);

			if (this.isLoading) {
				setTimeout(this.setLoadingTime, 1000);
			}
		},
		async preparePreview() {
			this.isLoading = true;
			this.loadingTimeStart = (new Date()).getTime();
			this.setLoadingTime();
			let response = [];

			if (this.exportData === 'checked') {
				const info = _.cloneDeep(this.checkedInfo);

				if (!info.checkedAll && !info.filterCheckedAll && _.isEmpty(info.checked)) {
					console.warn('[LbaTableExport] no data available');
					this.tableContent = [];
					this.isLoading = false;
					return;
				}

				this.queryOptions._checked = Object.keys(info.checked || {});
				this.queryOptions._unchecked = Object.keys(info.unchecked || {});
				this.queryOptions._checkedAll = info.checkedAll;
				this.queryOptions._filterCheckedAll = info.filterCheckedAll;
				delete info.checked;
				delete info.unchecked;
				delete info.checkedAll;
				delete info.searchValue;
				delete info.filterCheckedAll;
				this.queryOptions = { ...this.queryOptions, ...info };

				const result = await this.internalCollection
					.model[this.collection.modelExportMethod]({ _limit: 100, ...this.queryOptions });
				response = result.data;
				const count = parseInt(result.headers['x-lbadmin-count'], 10);

				if (!Number.isNaN(count)) {
					this.internalCollection.length = count;
				}
			} else {
				response = await this.internalCollection.getAll(0, 100, { queryOptions: this.queryOptions });
			}

			this.prepareHeader(response);
			this.tableContent = response;
			this.isLoading = false;
		},
		// STEP 3
		exportFile() {
			if (this.fileType === 'csv') {
				this.internalCollection.model.exportCSV({
					headerMap: JSON.stringify(this.headerMap),
					exportHeader: this.exportHeader,
					columnNames: this.columnNames,
					columnDelimiter: this.columnDelimiter,
					quoteCharacter: this.quoteCharacter,
					encoding: this.encoding,
					queryOptions: JSON.stringify(this.queryOptions),
				});
			} else {
				this.internalCollection.model.exportXLSX({
					headerMap: JSON.stringify(this.headerMap),
					exportHeader: this.exportHeader,
					columnNames: this.columnNames,
					autoFilter: this.autoFilter,
					freezeFirstRow: this.freezeFirstRow,
					queryOptions: JSON.stringify(this.queryOptions),
				});
			}
		},
		checkImportExportSetting() {
			if (this.usedImportExportSettingUid) {
				const keys = [
					'quote_character',
					'column_delimiter',
					'encoding',
					'contains_header',
					'file_type',
					'name',
					'header_map',
					'column_names',
				];
				const oldSetting = _.find(this.importExportSettings, { def_import_export_setting_uid: this.usedImportExportSettingUid });
				const newHeaderMap = this.currentImportExportSetting.header_map;

				// find key which is not same
				return keys.find((key) => {
					if (key === 'header_map') {
						const oldHeaderMap = oldSetting[key];
						if (oldHeaderMap.length !== newHeaderMap.length) return true;
						if (this.currentImportExportSetting.contains_header) {
							return oldHeaderMap.find((oldMapped, index) => oldMapped.column !== newHeaderMap[index].column);
						}
						return JSON.stringify(oldSetting[key]) !== JSON.stringify(newHeaderMap);
					}
					return oldSetting[key] !== this.currentImportExportSetting[key];
				}) != null;
			}
			return true;
		},
		async createImportExportSetting() {
			if (!_.isEmpty(this.currentImportExportSetting.header_map)) {
				this.currentImportExportSetting.header_map.forEach((item) => { delete item.index; });
			}
			try {
				const result = await this.importExportSettingsModel.create(this.currentImportExportSetting);
				this.importExportSettings.push(result.data);
				this.createNewTemplateDisabled = true;
				this.$notify.success(this.$t('form.saved'));
			} catch (error) {
				console.error(error);
				this.$notify.warn(this.$t('form.save_failed'));
			}
		},
		async saveImportExportSetting() {
			if (!_.isEmpty(this.currentImportExportSetting.header_map)) {
				this.currentImportExportSetting.header_map.forEach((item) => { delete item.index; });
			}
			try {
				const result = await this.importExportSettingsModel.save(this.usedImportExportSettingUid, this.currentImportExportSetting);
				const index = this.importExportSettings.findIndex(
					(setting) => setting.def_import_export_setting_uid === this.usedImportExportSettingUid
				);
				this.importExportSettings.splice(index, 1, result.data);
				this.$notify.success(this.$t('form.saved'));
			} catch (error) {
				console.error(error);
				this.$notify.warn(this.$t('form.save_failed'));
			}
		},
		prepareHeader(data) {
			if (_.isEmpty(data)) return;

			this.previewHeaderMap = _.cloneDeep(this.headerMap);

			// find columns with multiple and column spread
			const multipleColumns = this.importExportDefinition.columns.columns
				.filter((column) => column.multiple && column.spreadIntoMultipleColumns);
			let columnsCount = {};

			// find count of new spread columns
			multipleColumns.forEach((column) => {
				columnsCount[column.name] = 0;
			});
			data.forEach((row) => {
				multipleColumns.forEach((column) => {
					if (!_.isEmpty(row[column.name]) && row[column.name].length > columnsCount[column.name]) {
						columnsCount[column.name] = row[column.name].length;
					}
				});
			});

			for (let i = this.previewHeaderMap.length - 1; i >= 0; i -= 1) {
				const header = this.previewHeaderMap[i];
				if (_.find(multipleColumns, { name: header.column })) {
					let max = columnsCount[header.column];

					if (!_.isEmpty(this.importExportDefinition.columns.groups)) {
						// find group and max length item of it
						const group = this.importExportDefinition.columns.groups.find((item) => item.includes(header.column));
						group.forEach((column) => {
							if (columnsCount[column] > max) {
								max = columnsCount[column];
							}
						});
					}

					// add number to column only when more than 1 spread columns
					if (max > 1) {
						header.originalHeader = header.header;
						header.header = `${header.originalHeader} 1`;
						header.spreadIntoMultipleColumns = true;
						// add new spreaded columns
						for (let j = 1; j < max; j += 1) {
							const newHeader = _.cloneDeep(header);
							// number starting from 1
							newHeader.header = `${newHeader.originalHeader} ${j + 1}`;
							newHeader.spreadIntoMultipleColumns = true;
							this.previewHeaderMap.splice(i + j, 0, newHeader);
						}
					}
				}
			}

			const flatHeaderMap = this.previewHeaderMap.map((header) => header.column);
			columnsCount = _.countBy(flatHeaderMap);
			const uniqueFlatHeaderMap = _.uniq(flatHeaderMap);

			this.importExportDefinition.columns.groups.forEach((group) => {
				let firstIndex = null;
				let lastIndex = null;
				let distinctColumnsCount = 0;
				let lastHeader = null;
				const totalGroupColumns = uniqueFlatHeaderMap.filter((item) => group.includes(item));
				if (_.isEmpty(totalGroupColumns)) return this.previewHeaderMap;

				// find some item from group
				// once found, check all exported items from group are next to each other
				for (let i = 0; i < this.previewHeaderMap.length; i += 1) {
					const header = this.previewHeaderMap[i];

					if (firstIndex == null && group.includes(header.column)) {
						firstIndex = i;
						lastIndex = i;
						lastHeader = header.column;
						distinctColumnsCount += 1;
					} else if (firstIndex !== null) {
						if (!group.includes(header.column)) {
							break;
						}
						if (lastHeader !== header.column) {
							lastHeader = header.column;
							distinctColumnsCount += 1;
						}
						lastIndex = i;
					}
				}

				// they are all in next to each other
				if (distinctColumnsCount === totalGroupColumns.length) {
					// group columns together
					// find column length
					let maxLength = 0;
					group.find((column) => {
						if (columnsCount[column] > maxLength) {
							maxLength = columnsCount[column];
							return true;
						}
					});

					const originalMap = _.cloneDeep(this.previewHeaderMap);
					this.previewHeaderMap.splice(firstIndex, lastIndex + 1 - firstIndex);
					lastIndex = firstIndex;

					for (let i = 0; i < maxLength; i += 1) {
						totalGroupColumns.forEach((groupColumn, index) => {
							const originalColumn = _.cloneDeep(originalMap.find((item) => item.column === groupColumn));
							// add number only when there is more than 1 value
							if (maxLength !== 1) {
								originalColumn.header = `${originalColumn.originalHeader || originalColumn.header} ${i + 1}`;
							}
							this.previewHeaderMap.splice(lastIndex, 0, originalColumn);
							lastIndex += 1;
						});
					}

				}
			});
		},
	},
};
</script>
