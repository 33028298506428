<template>
	<component :is="rootTag">
		<template v-if="!dontShowEdit">
			<span>
				<span
					class="input"
					:class="{ 'lba-invalid': invalid && dirty }"
					v-tooltip="invalid && dirty && {
						content: errors.join('\n'),
						classes: ['lba-messages'],
					}"
				>
					{{ $getLocale(value) || `-- ${$t('blank')} --` }}
				</span>
				<span v-lba-dialog-open="dialogName" class="edit ml-2" v-tooltip="$t('edit')"
					:data-cy="`${currentComponentId}__openDialogEditLocaleWrap`"
				>
					<i class="icon-edit"></i>
				</span>
			</span>
		</template>

		<lba-dialog
			:parentComponentId="currentComponentId"
			componentId="editLocaleWrap"
			v-if="!noDialog"
			modal
			:name="dialogName"
			:title="dialogTitle"
			popupBodyStyle="width: 600px;"
		>
			<lba-locale-edit
				:parentComponentId="currentComponentId"
				componentId="editLocale"
				v-model="translationType"
				:internalValue="internalValue"
				:localeTitle="localeTitle"
				:dirty="dirty"
				@value-input="onInput"
				@value-change="onChange"
			></lba-locale-edit>
		</lba-dialog>

		<lba-locale-edit
			v-else
			:parentComponentId="currentComponentId"
			componentId="editLocale"
			v-model="translationType"
			:internalValue="internalValue"
			:localeTitle="localeTitle"
			:dirty="dirty"
			@value-input="onInput"
			@value-change="onChange"
		></lba-locale-edit>
	</component>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

function makeLocale(locale) {
	if (locale == null) return {};
	if (locale.constructor === String) return { translated: locale };
	if (locale.constructor === Object) return locale;
	if (locale.constructor === Array) {
		return locale.map((item) => makeLocale(item));
	}
	return null;
}

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: {
			type: [String, Object],
			required: true,
		},
		rootTag: {
			type: [String, Object],
			default: 'div',
		},
		title: [String, Object, Array],
		localeTitle: [String, Object, Array],
		noDialog: Boolean,
		name: String,
		dirty: Boolean,
		invalid: Boolean,
		errors: Array,
		dontShowEdit: Boolean,
	},
	data() {
		return {
			dialogName: this.name || `LbaLocale-${this.$generateUID()}`,
			dialogTitle: null,
			internalValue: null,
			translationType: 'forAllLangs',
		};
	},
	watch: {
		value() {
			this.init();
		},
	},
	created() {
		if (_.isEmpty(this.title)) {
			if (!_.isEmpty(this.localeTitle)) {
				this.dialogTitle = `${this.$t('translations')}: ${$getLocale(this.localeTitle)}`;
			} else {
				this.dialogTitle = this.$t('translations');
			}
		} else {
			this.dialogTitle = $getLocale(this.title);
		}

		this.init();
	},
	methods: {
		init() {
			this.internalValue = makeLocale(this.value);

			if (this.internalValue === null) {
				throw new Error(`[LbaLocale] unknown type error: ${this.value}`);
			}
		},
		onInput() {
			const value = this.prepareInternalValue();
			this.$emit('input', value);
		},
		onChange() {
			const value = this.prepareInternalValue();
			this.$emit('change', value);
		},
		prepareInternalValue() {
			const value = _.cloneDeep(this.internalValue);

			if (this.translationType === 'forAllLangs') {
				delete value.translate;
				this.$i18n.availableLocales.forEach((locale) => {
					if (_.isEmpty(value[locale]) || value[locale].trim() === '') {
						delete value[locale];
					}
				});
			} else {
				this.$i18n.availableLocales.forEach((locale) => {
					delete value[locale];
				});
				if (_.isEmpty(value.translate) || value.translate === '') {
					delete value.translate;
				}
			}
			if (_.isEmpty(value.translated) || value.translated === '') {
				delete value.translated;
			}
			return value;
		},
	},
};
</script>
