var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.noInput && _vm._.isEmpty(_vm.options.format))?_c(_vm.rootTag,{tag:"component",class:_vm.classes,attrs:{"data-cy":_vm.currentComponentId}},[_vm._v(" "+_vm._s(_vm.$d(new Date(_vm.data[_vm.key]), 'long'))+" ")]):(_vm.noInput && _vm.options.format === 'time_since')?_c(_vm.rootTag,{tag:"component",class:_vm.classes,attrs:{"data-cy":_vm.currentComponentId}},[_vm._v(" "+_vm._s(_vm.timeSince(_vm.data[_vm.key], false))+" ")]):(_vm.noInput && _vm.options.format === 'from_now')?_c(_vm.rootTag,{tag:"component",class:_vm.classes,attrs:{"data-cy":_vm.currentComponentId}},[_vm._v(" "+_vm._s(_vm.moment(new Date(_vm.data[_vm.key])).locale(_vm.$i18n.locale).fromNow())+" ")]):_c('span',{class:_vm.classes,style:(_vm.style),attrs:{"data-cy":_vm.currentComponentId}},[_c('ValidationProvider',{attrs:{"name":_vm.$getLocale(_vm.label),"vid":((_vm.$getLocale(_vm.label)) + "-" + (_vm.$generateUID())),"rules":_vm.rules,"immediate":"","data-required-recommended":_vm.requiredRecommended},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var errors = ref.errors;
return [_c('lba-period-picker',{key:(_vm.currentComponentId + "-dateRange"),class:{
				'lba-invalid': (invalid && _vm.dirty),
				'showWarning': (_vm.showRecommendation),
				'fullscreenHidex': true,
			},style:(_vm.options.style != null ? _vm.options.style : 'display: inline'),attrs:{"sClass":_vm.options.sClass != null ? _vm.options.sClass : 'size-0',"sPeriodStyle":_vm.options.sPeriodStyle != null ? _vm.options.sPeriodStyle : 'width: 100px',"sFromStyle":_vm.options.sFromStyle != null ? _vm.options.sFromStyle : 'width: 160px',"sToStyle":_vm.options.sToStyle != null ? _vm.options.sToStyle : 'width: 160px',"period":_vm.data[_vm.key].period,"from":_vm.data[_vm.key].from,"to":_vm.data[_vm.key].to,"inline":_vm.options.inline != null ? _vm.options.inline : true,"parentComponentId":_vm.currentComponentId},on:{"update:period":function($event){return _vm.$set(_vm.data[_vm.key], "period", $event)},"update:from":function($event){return _vm.$set(_vm.data[_vm.key], "from", $event)},"update:to":function($event){return _vm.$set(_vm.data[_vm.key], "to", $event)},"input":function($event){return _vm.onEvent('input')},"change":function($event){return _vm.onEvent('change')}}}),(_vm.dirty)?[_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages",attrs:{"data-cy":(_vm.currentComponentId + "__error" + index)}},[_vm._v(" "+_vm._s(err)+" ")])}),_vm._l((_vm.customMessages),function(msg,index){return _c('span',{key:index,staticClass:"lba-messages warning",attrs:{"data-cy":(_vm.currentComponentId + "__error" + index)}},[_vm._v(" "+_vm._s(msg)+" ")])})]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }