<template>
	<content-options>
		<template v-slot:content>
			<div class="page-header">
				<h1>{{ $t('settings.menu.loggedIn') }}</h1>
			</div>
			<div class="page-content">
				<lba-grid
					ref="grid"
					id="loggedIn-grid"
					:gridName="'loggedIn-grid'"
					:collection="loggedInUsers"
					:rowId="['user_name']"
					hideableColumns
					:filterAttributes="filterAttributes"
					:dynamicHeight="false"
				>
					<template v-slot:columns="props">
						<lba-grid-column
							id="user_name"
							:data-cy="`${props.parentComponentId}__user_name`"
							:label="$t('settings.user.login')"
							searchable
							sortable
							width="250"
						>
							{{ props.row.user_name }}
						</lba-grid-column>
						<lba-grid-column
							id="name"
							:data-cy="`${props.parentComponentId}__name`"
							:label="$t('settings.activity.name')"
							searchable
							sortable
							width="180"
						>
							{{ props.row.name }}
						</lba-grid-column>
						<lba-grid-column
							id="last_activity"
							:data-cy="`${props.parentComponentId}__last_activity`"
							:label="$t('settings.activity.lastActivity')"
							sortable
							width="180"
						>
							{{ props.row.last_activity ? $d(new Date(props.row.last_activity), 'fullDigitsLong') : null }}
						</lba-grid-column>
						<lba-grid-column
							id="logout"
							:data-cy="`${props.parentComponentId}__logout`"
							:label="$t('settings.activity.logout')"
							width="180"
							:removed="!allowLogout"
						>
							<a
								v-if="props.row.user_name !== $user.user_name"
								:data-cy="`${props.parentComponentId}__logout__kickout`"
								v-on:click="logout(props.row.user_name)"
								class="settings-activity-logout"
							>
								<i class="icon-logout"></i>
								<span>{{ $t('settings.activity.kickout') }}</span>
							</a>
							<span v-if="props.row.user_name === $user.user_name">
								{{ $t('settings.activity.current_user') }}
							</span>
						</lba-grid-column>
					</template>
				</lba-grid>
			</div> <!-- /.page-content -->
		</template>
	</content-options>
</template>

<script>
import LoggedInUserModel from '../models/LoggedIn';

export default {
	name: 'LoggedIn',
	data() {
		return {
			loggedInUsers: [],
			allowLogout: true,
			filterAttributes: {},
		};
	},
	created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.filterAttributes = {
			module: 'settings.lbadmin-logged-in',
			columns: [
				{
					name: 'user_name',
					label: this.$t('settings.user.login'),
					type: 'text',
				},
				{
					name: 'name',
					label: this.$t('settings.activity.name'),
					type: 'text',
				},
				{
					name: 'last_activity',
					label: this.$t('settings.activity.lastActivity'),
					type: 'datetime',
				},
			],
		};

		const gridOptions = {
			params: {
				_order: 'user_name',
				_order_dir: 'asc',
			},
		};

		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.loggedInUserModel = new LoggedInUserModel(this.$http);
		this.loggedInUsers = new this.$Collection(this.loggedInUserModel, gridOptions);
		this.loggedInUsers.attach(this, 'loggedInUsers');
		this.permissionsReload();
	},
	methods: {
		permissionsReload() {
			this.allowLogout = this.$permissions.checkPermission('lbadmin.force-logout.enable');
		},
		logout(user_name) {
			this.$http.delete(`lbadmin/logged-in/${user_name}`)
				.then(() => {
					this.$root.$emit('grid.delete-row', {
						gridName: 'loggedIn-grid',
						rowId: user_name,
					});
				});
		},
	},
};
</script>
