<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ data[key] }}
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
			<slot name="actions" :customValue="this" :parentComponentId="currentComponentId"></slot>
		</small>
		<ValidationProvider
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${$generateUID()}`"
			:rules="rules"
			:ref="inputRef"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<lba-resizable-textarea ref="resize" :parentComponentId="currentComponentId">
				<textarea
					:data-cy="`${currentComponentId}__textarea`"
					v-model="data[key]"
					v-bind="bindOptions()"
					:disabled="readOnly"
					:class="{
						'lba-invalid': (invalid && dirty),
						'showWarning': (showRecommendation)
					}"
					@input="onEvent('input', parseString)"
					@change="onEvent('change', parseString)"
					@keydown="onEvent('change', parseString)"
					@blur="$emit('blur')"
				>
				</textarea>
			</lba-resizable-textarea>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</ValidationProvider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputTextarea',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
	methods: {
		parseString() {
			if (!this.data[this.key]) {
				this.data[this.key] = null;
			}
		},
	},
};
</script>
