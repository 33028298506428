<template>
	<div class="input">
		<div class="inputNumber">
			<input
				:data-cy="`${currentComponentId}__inputNumber`"
				type="number"
				v-model="input"
				v-bind:name="name"
				v-bind:min="min"
				v-bind:max="max"
				v-bind:step="step"
				v-bind:disabled="disabled"
				v-bind:required="required"
				v-bind:minlength="minLength"
				v-bind:maxlength="maxLength"
				v-bind:pattern="pattern"
				v-on:change="onChange"
				v-on:input="onInput"
				v-on:input.once="onTouch"
						@blur="$emit('blur')"
				v-on:blur.once="onTouch"
				v-bind:class="{
				'ng-invalid': !valid && dirty
				}"
			>
			<div v-if="!disabled">
				<button
					:data-cy="`${currentComponentId}__increment`"
					v-if="!onUpperRange"
					v-on:click="increase"
					v-on:mousedown.once="onTouch"
					v-on:mousedown="holdButton(increase)"
					v-on:mouseup="releaseButton"
					v-on:mouseleave="releaseButton"
					class="buttonIncrease"
					type="button"
				></button>
				<button
					:data-cy="`${currentComponentId}__decrement`"
					v-if="!onLowerRange"
					v-on:click="decrease"
					v-on:mousedown.once="onTouch"
					v-on:mousedown="holdButton(decrease)"
					v-on:mouseup="releaseButton"
					v-on:mouseleave="releaseButton"
					class="buttonDecrease"
					type="button"
				></button>
			</div>
		</div>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: {
			required: false,
		},
		name: {
			type: String,
			required: false,
			default: '',
		},
		step: {
			type: Number,
			required: false,
			default: 1,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		required: {
			type: Boolean,
			required: false,
			default: false,
		},
		pattern: {
			type: String,
			required: false,
			default: '',
		},
		min: {
			type: Number,
			required: false,
			default: Number.MIN_VALUE,
		},
		max: {
			type: Number,
			required: false,
			default: Number.MAX_VALUE,
		},
		minLength: {
			type: Number,
		},
		maxLength: {
			type: Number,
		},
		validator: {
			type: Object,
			required: false,
			default: null,
		},
		isDirty: Boolean,
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set(value) {
				let newValue = this.roundNumber(value);

				if (Number.isNaN(newValue)) {
					newValue = undefined;
				}

				this.$emit('input', newValue);
			},
		},
	},
	data() {
		return {
			input: this.value,
			toFixed: 0,
			holdDownTick: 0,
			holdDownRepeatFunc: null,
			intervalID: null,
			onLowerRange: null,
			onUpperRange: null,
			inRange: null,
			tooBig: false,
			tooSmall: false,
			valid: false,
			dirty: false,
		};
	},
	watch: {
		value() {
			this.input = this.value;
			this.watcherUpdate();

			if (this.onLowerRange || this.onUpperRange) {
				this.releaseButton();
			}
		},
		step() {
			this.toFixed = this.getDecimals(this.step);
		},
		min() {
			this.watcherUpdate();
		},
		max() {
			this.watcherUpdate();
		},
		required() {
			this.watcherUpdate();
		},
		disabled() {
			this.watcherUpdate();
		},
		isDirty(value) {
			if (value) {
				this.dirty = true;
			}
		},
	},
	mounted() {
		if (this.validator) {
			this.toFixed = this.getDecimals(this.step);
			this.inRange = this.resolveRange();
			this.setCornerStates(this.input);
		}

		this.valid = this.isValid();
	},
	methods: {
		isNull(value) {
			return (value == null || Number.isNaN(value) || value === '');
		},
		onTouch() {
			this.dirty = true;

			if (this.validator) {
				this.validator.$touch();
			}
		},
		onChange() {
			let value = this.roundNumber(this.input);

			if (Number.isNaN(value)) {
				value = undefined;
			}

			this.$emit('change', value);
		},
		onInput() {
			this.internalValue = this.input;
			this.valid = this.isValid();
		},
		isValid() {
			if (this.validator) {
				return !this.validator.$invalid;
			}

			if (this.required && this.isNull(this.input)) {
				return false;
			}

			return true;

			/* this.inRange = this.resolveRange();
	this.tooSmall = this.lessThanMinimum(this.input);
	this.tooBig = this.moreThanMaximum(this.input);

	if (!this.inRange || (this.required && this.isNull(this.input))) {
		return false;
	}

	return true; */
		},
		holdButton(func) {
			this.intervalID = setInterval(() => {
				this.holdDownTick += 1;

				if (this.holdDownTick > 5) {
					func();
				}
			}, 50);
		},
		releaseButton() {
			clearInterval(this.intervalID);
			this.intervalID = null;
			this.holdDownTick = 0;
		},
		addToModel() {
			if (this.input == null) {
				this.input = 0;
			}

			return this.input + this.step;
		},
		substractFromModel() {
			if (this.input == null) {
				this.input = 0;
			}

			return this.input - this.step;
		},
		decrease() {
			const newValue = this.substractFromModel();

			if (this.isNull(this.input) || this.moreThanMaximum(newValue)) {
				this.input = this.max;
				this.internalValue = this.input;
			} else if (newValue >= this.min) {
				this.input = this.roundNumber(newValue);
				this.internalValue = newValue;
			}

			this.valid = true;
		},
		increase() {
			const newValue = this.addToModel();

			if (this.isNull(this.input) || this.lessThanMinimum(newValue)) {
				this.input = this.min;
				this.internalValue = this.input;
			} else if (newValue <= this.max) {
				this.input = this.roundNumber(newValue);
				this.internalValue = newValue;
			}

			this.valid = true;
		},
		roundNumber(value) {
			if (!this.toFixed) {
				return parseInt(value, 10);
			}

			return parseFloat(value.toFixed(this.toFixed));
		},
		setCornerStates(currentValue) {
			const length = currentValue ? currentValue.toString() : 0;
			this.onLowerRange = currentValue <= this.min && length <= this.minLength;
			this.onUpperRange = currentValue >= this.max && length >= this.maxLength;
		},
		moreThanMaximum(value) {
			return value > this.max;
		},
		lessThanMinimum(value) {
			return value < this.min;
		},
		resolveRange() {
			return (this.input <= this.max) && (this.input >= this.min);
		},
		watcherUpdate() {
			this.setCornerStates(this.input);
			this.isValid();
		},
		getDecimals(input) {
			if (input == null) {
				return 0;
			}

			const inputString = input.toString();
			const position = inputString.lastIndexOf('.');
			const stringLength = inputString.length;

			if (position > -1) {
				return stringLength - (position + 1);
			}

			return 0;
		},
	},
};
</script>
