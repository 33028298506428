/* eslint-disable no-underscore-dangle, no-loop-func */

export default class {
	constructor() {
		this.vm = null;
		this.loadedModules = [];
		this.changedModules = {};
		this.loadedModulesCount = 0;
		this.loadingModules = false;
		this.loaded = false;
		this.extraSettings = [];

		this.channels = [];
		this.dynamicMenus = [];
		this.menuModuleOrder = [];

		this.isLogingOut = false;

		this.actions = {
			groups: { method: 'GET', url: 'lbadmin/groups', isArray: true },
			timezoneList: { method: 'GET', url: 'lbadmin/timezone-list', isArray: true },
		};

		this.allowedPerUserChanges = {};

		this.permSocket = null;
		this.userSocket = null;
	}

	getName(user = this) {
		if (!user) return null;
		if (user.firstname && user.lastname) {
			return `${user.firstname} ${user.lastname}`;
		}
		if (user.firstname) {
			return user.firstname;
		}
		if (user.lastname) {
			return user.lastname;
		}
		return user.user_name;
	}

	getModuleOrder(module) {
		const menuModule = this.menuModuleOrder.find((item) => item.name === module);

		if (menuModule) return menuModule.order;
		return 99000;
	}

	async loadModule(name, obj, theme) {
		if (!theme) {
			this.loadedModules.push(name);

			// if (this.loadedModules.length === this.loadedModulesCount) {
			// 	modulesLoaded = true;
			// }
		}

		_.forEach(obj.routes, (route) => {
			this.vm.$router.addRoute(route);
		});

		Object.entries(obj.locales).forEach(([key, value]) => {
			const locale = {};
			locale[name] = value;
			this.vm.preparePluralLocales(locale);
			this.vm.$i18n.mergeLocaleMessage(key, locale);
		});

		if (obj.headerPlugins) {
			obj.headerPlugins.forEach((headerPlugin) => {
				headerPlugin.id = this.vm.$generateUID();
				this.vm.$headerPlugins.push(headerPlugin);
			});
		}

		if (obj.widgets) {
			this.vm.$widgets.push(...obj.widgets);
		}

		if (obj.init && obj.init.constructor === Function) {
			await obj.init(this.vm, this);
		}

		if (obj.websocketChannels) {
			obj.websocketChannels.forEach((websocketChannel) => {
				console.log('register channel', websocketChannel.address, websocketChannel.name);
				const channel = this.vm.$socket.on(
					websocketChannel.address,
					{ userName: this.user_name },
					(data) => {
						console.log('msg callback');
						this.vm.$root.$emit(websocketChannel.name, data);
					}
				);
				this.channels.push(channel);
			});
		}

		// if (userLoaded) {
		// 	this.onUserLoaded();
		// }
	}

	onUserLoaded() {
		this.vm.$headerPlugins.sort((a, b) => {
			if (a.order < b.order) return 1;
			if (a.order > b.order) return -1;
			return 0;
		});
		this.loaded = true;
		this.vm.$root.$emit('user-loaded');
	}

	loadTheme(name, obj) {
		this.loadModule(name, obj, true);
	}

	async loadModules(onChange = false) {
		if (this.loadingModules) return;
		this.loadingModules = true;
		let requestUrl = '/lbadmin/modules';

		// first load - axios does not have set base url
		if (this.vm.$http.defaults.baseURL !== '/api/lbadmin/') {
			requestUrl = '/api/lbadmin/lbadmin/modules';
		}

		const promises = [];
		const oldModules = _.cloneDeep(this.loadedModules);
		this.loadedModules = [];
		this.changedModules = {};

		try {
			const response = await this.vm.$http.get(requestUrl);

			// allowed modules can be specified in URL params
			const allowedModules = new URLSearchParams(window.location.search).get('allowedModules');
			if (allowedModules && response.data.length) {
				console.debug(`[LBADMIN] (User) - allowed modules specified in URL: `, allowedModules);
				const mods = allowedModules.split(',');
				const finalModules = [];
				response.data.forEach((el) => {
					if (mods.includes(el.name)) finalModules.push(el);
				});
				response.data = finalModules;
			}

			this.loadedModulesCount = response.data.length;

			for (let i = 0; i < response.data.length; i += 1) {
				const item = response.data[i];

				if (oldModules.includes(item.name)) this.loadedModules.push(item.name);

				if (!this.loadedModules.includes(item.name)) {
					if (process.env.NODE_ENV === 'production') {
						const promise = new Promise((resolve) => {
							this.vm.scripts.push({
								src: `/modules/${item.name}/${item.name}.umd.min.js`,
								callback: async () => {
									await this.loadModule(item.name, window[item.name]);
									resolve();
								},
							});
						});
						promises.push(promise);
					} else {
						/* DEVELOPMENT ONLY - BEGIN
						const promise = new Promise((resolve, reject) => {
							try {
								import(`../../../modules/${item.name}/client/index.js`)
									.then(async (module) => {
										window[item.name] = module;
										await this.loadModule(item.name, module);
										resolve();
									});
							} catch (err) {
								reject(err);
								console.error('cannot load module', item.name, err);
							}
						});
						promises.push(promise);
						/* DEVELOPMENT ONLY - END */
					}
				}
			}

			if (this.loadedModulesCount === 0) this.loadedModules = [];

			await Promise.all(promises);

			const newModules = _.cloneDeep(this.loadedModules);
			const diff1 = _.difference(oldModules, newModules);
			const diff2 = _.difference(newModules, oldModules);
			if ((diff1.length || diff2.length) && onChange) {
				_.forEach(diff1, (e) => { this.changedModules[e] = true; });
				_.forEach(diff2, (e) => { this.changedModules[e] = true; });
				this.vm.$root.$emit('permissions.modules-changed');
			}

			this.loadingModules = false;
			return true;
		} catch (error) {
			this.loadingModules = false;
			return false;
		}
	}

	async load(emitChange = true) {
		this.loaded = false;
		let requestUrl = '/lbadmin/user';
		let perUserRequestUrl = '/lbadmin/per-user-change';

		// first load - axios does not have set base url
		if (this.vm.$http.defaults.baseURL !== '/api/lbadmin/') {
			requestUrl = '/api/lbadmin/lbadmin/user';
			perUserRequestUrl = '/api/lbadmin/per-user-change';
		}

		try {
			let response = await this.vm.$http.get(requestUrl);
			Object.keys(response.data).forEach((key) => {
				this[key] = response.data[key];
			});
			this.vm.$i18n.locale = this.lang || this.vm.lang;
			this.vm.localeChanged();

			response = await this.vm.$http.get(perUserRequestUrl);
			this.allowedPerUserChanges = response.data;

			if (this.user_name !== null) {
				await this.vm.$permissions.setPermissions();
				this.vm.$permissions.setListener();
			}

			if (emitChange) {
				// menu reloads itself after this
				this.vm.$emit('user-changed');
			}
			this.vm.$socket.socketConnect();
			const modulesLoaded = await this.loadModules();
			this.loadExtraSettings();
			await this.vm.$pageSettings.load();
			if (modulesLoaded) {
				this.onUserLoaded();
			}

			if (this.permSocket) {
				this.permSocket.unsubscribe();
				this.permSocket = null;
			}

			this.permSocket = this.vm.$socket.on(
				'/lbadmin/permissions',
				{ roleUid: this.role_uid },
				async (data) => {
					console.debug(`[User] (onPermissionWS) - `, data);
					if (data && !data.status) {
						this.permissions = data;
						const loaded = await this.loadModules(true);
						if (loaded) {
							this.onUserLoaded();
						}
						this.vm.$root.$emit('user-permissions-changed', data);
					}
				}
			);

			if (this.userSocket) {
				this.userSocket.unsubscribe();
				this.userSocket = null;
			}

			this.userSocket = this.vm.$socket.on(
				'lbadmin/user-settings',
				{ roleUid: this.role_uid },
				async (data) => {
					if (data.authorUid && this.role_uid === data.userUid) {
						this.load();
						if (data.authorUid === data.userUid) {
							this.vm.$root.$emit('user.settings-updated');
						}
					}
				}
			);

			return true;
		} catch (error) {
			return false;
		}
	}

	async loadPermissions() {
		try {
			const response = await this.vm.$http.get('/api/lbadmin/lbadmin/user-perms');
			this.permissions = response;
		} catch (error) {
			return false;
		}
	}

	async login(user) {
		try {
			let requestUrl = '/lbadmin/login';

			// first load - axios does not have set base url
			if (this.vm.$http.defaults.baseURL !== '/api/lbadmin/') {
				requestUrl = '/api/lbadmin/lbadmin/login';
			}

			localStorage.setItem('tabs', '');

			await this.vm.$http.post(requestUrl, {
				username: user.user_name,
				password: user.password,
				token: user.token,
				exten: user.exten,
			});

			await this.load();
			return null;
		} catch (error) {
			return error.response;
		}
	}

	async logout() {
		if (this.user_name && !this.isLogingOut) {
			this.isLogingOut = true;
			let requestUrl = '/lbadmin/logout';

			if (this.vm.$http.defaults.baseURL !== '/api/lbadmin/') {
				requestUrl = '/api/lbadmin/lbadmin/logout';
			}

			this.user_name = undefined;
			this.loaded = false;
			localStorage.setItem('tabs', '');

			const response = await this.vm.$http.post(requestUrl, {
				username: this.user_name,
				password: this.password,
				token: this.token,
			});
			this.vm.$socket.socketDisconnect();
			this.vm.$emit('lbadmin.logged-out');
			this.isLogingOut = false;
			return response;
		}
	}

	async loadExtraSettings() {
		let requestUrl = '/lbadmin/extra-settings';

		if (this.vm.$http.defaults.baseURL !== '/api/lbadmin/') {
			requestUrl = '/api/lbadmin/lbadmin/extra-settings';
		}

		try {
			const response = await this.vm.$http.get(requestUrl);
			this.extraSettings = response.data;
		} catch (error) {
			console.error('User load extra settings error:', error);
		}
	}

	async updateUser() {
		const updatedUser = {
			user_name: this.vm.$user.user_name,
			id: this.vm.$user.id,
			firstname: this.vm.$user.firstname,
			lastname: this.vm.$user.lastname,
			lang: this.vm.$user.lang,
			timezone: this.vm.$user.timezone,
			email: this.vm.$user.email,
			extraSettings: this.vm.$user.extraSettings,
			password: this.vm.$user.password,
			color_theme: this.vm.$user.color_theme,
			current_password: this.vm.$user.current_password,
			tfa: this.vm.$user.tfa,
		};

		if (this.vm.$user.tfa_secret) {
			updatedUser.tfa_secret = this.vm.$user.tfa_secret;
			delete this.vm.$user.tfa_secret;
		}

		this.extraSettings.forEach((setting) => {
			updatedUser[setting.name] = this[setting.name];
		});

		if (!Array.isArray(updatedUser.groups) && updatedUser.groups != null) {
			updatedUser.groups = [updatedUser.groups];
		} else if (!Array.isArray(updatedUser.groups)) {
			updatedUser.groups = [];
		}

		let requestUrl = 'lbadmin/user/';

		if (this.vm.$http.defaults.baseURL !== '/api/lbadmin/') {
			requestUrl = '/api/lbadmin/lbadmin/user/';
		}

		const result = await this.vm.$http.put(requestUrl, updatedUser);
		this.vm.$emit('user.updated');
		return result;
	}

	async queryAction(action) {
		const query = this.actions[action];

		// first load - axios does not have set base url
		if (this.vm.$http.defaults.baseURL !== '/api/lbadmin/') {
			query.url = `/api/lbadmin/${query.url}`;
		}

		const response = await this.vm.$http(this.actions[action]);
		return response.data;
	}
}
