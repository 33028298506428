export default class {
	constructor() {
		this.vm = null;
	}

	push(location, onComplete, onAbort) {
		this.vm.$router.push(location, onComplete, onAbort).catch(() => {});
	}

	replace(location, onComplete, onAbort) {
		this.vm.$router.replace(location, onComplete, onAbort).catch(() => {});
	}

	hasRoute(routeName) {
		return this.vm.$router.resolve({ name: routeName }).resolved.matched.length > 0;
	}
}
