export default {
	props: {
		searchInput: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data() {
		return {
			searchValue: '',
			isSearching: false,
			searchDebouncer: null,
		};
	},
	created() {
		this.searchDebouncer = new this.$Debouncer(
			this,
			this.fetchCollection,
			this.toggleIsSearching,
			500
		);
	},
	methods: {
		searchValueEmpty() {
			return this.searchValue == null || this.searchValue === '';
		},
		toggleIsSearching(state) {
			this.isSearching = state;

			if (this.isSearching) {
				this.viewport = [];
				this.offsetTop = 0;
			}
		},
		onSearchRowInput(columnId) {
			if (this.collection.search[columnId] === null || this.collection.search[columnId] === '') {
				delete this.collection.search[columnId];
			}
			this.searching();
		},
		searching() {
			this.searchDebouncer.emit(0, true, true);
		},
		async clearSearch() {
			this.searchValue = null;
			this.collection.search = {};
			this.collection.filter = {};
			this.filterShow = [];
			this.cleanFilter();
			this.usedFilterName = null;
			this.usedFilterIsGlobal = false;
			if (this.hiddenSearch) {
				await this.removeHiddenSearch({ type: 'clearAll' });
			} else {
				await this.fetchCollection(0, true, true);
			}
			await this.$nextTick();
			this.resize();
			// this.setViewportHeight();
		},
		async removeHiddenSearch(search) {
			const keys = Object.keys(this.collection.search);
			let keyword;
			if (search && search.type && search.type !== 'clearAll') {
				const keywords = search.type.split('_');
				keyword = keywords[1];
			}

			keys.map((key) => {
				if ((keyword && key.includes(keyword)) || search.type === 'clearAll') {
					delete this.collection.search[key];
				}
			});

			if (search.type === 'clearAll') {
				this.hiddenSearch = [];
			} else {
				this.hiddenSearch = this.hiddenSearch.filter(
					(s) => !s.type.includes(search.type)
				);
			}

			if (_.isEmpty(this.hiddenSearch) && this.collection.search._join_subject) {
				delete this.collection.search._join_subject;
			}
			await this.fetchCollection(0, true, true);
		},
	},
};
