<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ $t('crm.used') }}:&nbsp;
		<span v-for="(item, index) in twoLists2" :key="index" :data-cy="`${currentComponentId}__option${index}`">{{ item }}&nbsp;</span>
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ $getLocale(label) }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<lba-dnd-two-lists
			:parentComponentId="currentComponentId"
			:name="key"
			:dialogTitle="$getLocale(options.label)"
			:list1.sync="twoList1"
			:list2.sync="twoList2"
			:listTitle1="$t('crm.available')"
			:listTitle2="$t('crm.used')"
			:staticListItems1="false"
			:staticListItems2="false"
			:staticListOrder1="false"
			:staticListOrder2="false"
			:disabled="readOnly"
			:listMaxHeight="listMaxHeight"
			:noDialog="options.noDialog"
			@save="saveTwoLists"
		>
			<template v-slot:list1="props1">
				<div v-for="(opt1, okey1) in props1.list"
					:key="okey1"
					class="flex-row inner border"
				>
					<label :data-cy="`${currentComponentId}__list1__optionLabel${okey1}`" class="checkbox">
						<input type="checkbox" lba-dnd-list-checkbox :data-cy="`${currentComponentId}__list1__optionInput${okey1}`" />
						<i class="icon-ok"></i>
						<span class="label">{{ $getLocale(opt1.label) }}</span>
					</label>
				</div>
			</template>
			<template v-slot:list2="props2">
				<div v-for="(opt2, okey2) in props2.list"
					:key="okey2"
					class="flex-row inner border"
				>
					<label :data-cy="`${currentComponentId}__list2__optionLabel${okey2}`" class="checkbox">
						<input type="checkbox" lba-dnd-list-checkbox :data-cy="`${currentComponentId}__list2__optionInput${okey2}`" />
						<i class="icon-ok"></i>
						<span class="label">{{ $getLocale(opt2.label) }}</span>
					</label>
				</div>
			</template>
		</lba-dnd-two-lists>
		<span v-if="!options.noDialog" class="displayInlineBlock">
			{{ twoListLabels.join(', ') }}
		</span>
		<span v-if="!hideSettings && !readOnly && !options.noDialog" :data-cy="`${currentComponentId}__openDialogChange`"
			v-lba-dialog-open="key" class="ml-2 displayInlineBlock"
		>
			<i class="icon-settings hover-pointer" v-tooltip="$t('crm.change')"></i>
		</span>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputTwoLists',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			twoList1: [],
			twoList2: [],
			twoListLabels: [],
			listMaxHeight: '100%',
		};
	},
	created() {
		this.prepareComponent();
		this.prepareTwoList();
	},
	watch: {
		'attribute.options': function () {
			this.prepareComponent();
			this.prepareTwoList();
		},
	},
	methods: {
		prepareTwoList() {
			if (this.options) {
				this.listMaxHeight = this.options.listMaxHeight || '100%';
				this.twoList1 = [];
				this.twoList2 = [];
				this.twoListLabels = [];
				_.each(this.options.options, (available) => {
					available.used = false;
				});
				_.each(this.data[this.key], (selected) => {
					_.each(this.options.options, (available) => {
						if (available.value === selected) {
							this.twoList2.push(available);
							this.twoListLabels.push(this.$getLocale(available.label));
							available.used = true;
						}
					});
				});
				this.twoList1 = _.filter(this.options.options, (opt) => !opt.used);
			}
		},
		saveTwoLists() {
			this.data[this.key] = _.map(this.twoList2, (opt) => opt.value);
			this.twoListLabels = _.map(this.twoList2, (opt) => this.$getLocale(opt.label));
			this.$root.$emit('dialog-close', this.key);
			this.$emit('change', this.data[this.key]);
		},
	},
};
</script>
