/* eslint no-bitwise: 0 */
import './assets/styles/style.scss';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import VueCompositionAPI from '@vue/composition-api';
import VueVuelidateJsonSchema from 'vue-vuelidate-jsonschema';
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { setupCache } from 'axios-cache-adapter';
import ECharts from 'vue-echarts';
import Chat from 'vue-beautiful-chat';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import {
	use
} from 'echarts/core';
import {
	CanvasRenderer
} from 'echarts/renderers';
import {
	BarChart,
	LineChart,
	PieChart,
	GaugeChart,
	HeatmapChart
} from 'echarts/charts';
import {
	GridComponent,
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	DatasetComponent,
	CalendarComponent,
	VisualMapComponent
} from 'echarts/components';
import './themes/echarts-dark';
// import 'echarts/lib/chart/bar';
// import 'echarts/lib/chart/pie';
// import 'echarts/lib/chart/gauge';
// import 'echarts/lib/chart/heatmap';
// import 'echarts/lib/chart/line';
// import 'echarts/lib/component/tooltip';
// import 'echarts/lib/component/legend';
import VueMeta from 'vue-meta';
import VueI18n from 'vue-i18n';
import VuePapaParse from 'vue-papa-parse';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/cs';
import moment from 'moment';
import VCalendar from 'v-calendar';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import IdleJs from 'idle-js';
import VTooltip from 'v-tooltip';
import VueGridLayout from 'vue-grid-layout';
import VueMasonry from 'vue-masonry-css';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js';
import PortalVue from 'portal-vue';
import * as VeeValidate from 'vee-validate';
import * as validationRules from 'vee-validate/dist/rules';
import { messages as validationMessagesCs } from 'vee-validate/dist/locale/cs.json';
import { messages as validationMessagesEn } from 'vee-validate/dist/locale/en.json';
import vuedraggable from 'vuedraggable';
import DOMPurify from 'dompurify';
import ImportRecords from './views/ImportRecords.vue';
import ImportRecord from './views/ImportRecord.vue';
import ImportExportConfigurations from './views/ImportExportConfigurations.vue';
import ImportExportConfiguration from './views/ImportExportConfiguration.vue';
import ImportExportSettings from './views/ImportExportSettings.vue';
import ImportExportSetting from './views/ImportExportSetting.vue';
import Home from './views/Home.vue';
import Users from './views/Users.vue';
import User from './views/User.vue';
import Groups from './views/Groups.vue';
import Group from './views/Group.vue';
import Profiles from './views/Profiles.vue';
import Profile from './views/Profile.vue';
import Activity from './views/Activity.vue';
import LoggedIn from './views/LoggedIn.vue';
import Options from './views/Options.vue';
import App from './App.vue';
import NotFound from './components/NotFound.vue';
import ListContent from './components/ListContent.vue';
import ListOptions from './components/ListOptions.vue';
import ContentOptions from './components/ContentOptions.vue';
import Controls from './components/Controls.vue';
import LbaGrid from './components/LbaGrid/LbaGrid.vue';
import LbaGridColumn from './components/LbaGridColumn.vue';
import LbaGridDate from './components/LbaGridDate.vue';
import LbaInputPassword from './components/LbaInputPassword.vue';
import LbaDialog from './components/LbaDialog.vue';
import LbaDialogEdit from './components/LbaDialogEdit.vue';
import LbaDialogPopup from './components/LbaDialogPopup.vue';
import LbaDialogModal from './components/LbaDialogModal.vue';
import LbaDialogRecommendEmpty from './components/LbaDialogRecommendEmpty.vue';
import LbaCkEditorDialog from './components/LbaCkEditorDialog.vue';
import LbaSelect from './components/LbaSelect.vue';
import LbaCombobox from './components/LbaCombobox.vue';
import LbaDialogOpen from './directives/LbaDialogOpen.vue';
import LbaDialogClose from './directives/LbaDialogClose.vue';
import LbaSection from './components/LbaSection.vue';
import LbaExpander from './directives/LbaExpander.vue';
import LbaSwitch from './components/LbaSwitch.vue';
import LbaDatepicker from './components/LbaDatepicker.vue';
import LbaBreadcrumb from './components/LbaBreadcrumb.vue';
import LbaWindow from './components/LbaWindow.vue';
import LbaImageSlider from './components/LbaImageSlider/LbaImageSlider.vue';
import LbaInputRange from './components/LbaInputRange.vue';
import LbaCheckbox from './components/LbaCheckbox.vue';
import LbaTableImport from './components/LbaTableImport/LbaTableImport.vue';
import LbaTableExport from './components/LbaTableExport/LbaTableExport.vue';
import LbaDialogConfirm from './components/LbaDialogConfirm.vue';
import LbaButtonSave from './components/LbaButtonSave.vue';
import LBTray from './mixins/LBTray';
import Collection from './mixins/Collection';
import Permissions from './mixins/Permissions';
import UserMixin from './mixins/User';
import Socket from './mixins/Socket';
import Versions from './mixins/Versions';
import Timepicker from './mixins/Timepicker';
import Notification from './mixins/Notification';
import DesktopNotification from './mixins/DesktopNotification';
import Formatter from './mixins/Formatter';
import Content from './mixins/Content';
import MenuUpdate from './mixins/MenuUpdate';
import RouteKey from './mixins/RouteKey';
import SaveEntry from './mixins/SaveEntry';
import RouteParams from './plugins/RouteParams';
import RecommendedProvider from './plugins/RecommendedProvider';
import LbaLoading from './directives/LbaLoading.vue';
import LbaRightPanelResizer from './directives/LbaRightPanelResizer.vue';
import Autofocus from './directives/Autofocus.vue';
import LbaScrollTo from './directives/LbaScrollTo.vue';
import LbaSizeClass from './directives/LbaSizeClass.vue';
import LbaMovable from './directives/LbaMovable.vue';
import Popper from './directives/Popper.vue';
import LbaDragAndDropList from './components/LbaDragAndDropList.vue';
import LbaMenu from './components/LbaMenu.vue';
import LbaMenuTree from './components/LbaMenuTree.vue';
import LbaNumber from './components/LbaNumber.vue';
import LbaToolbarButton from './components/LbaToolbarButton.vue';
import LbaExtraSetting from './components/LbaExtraSetting.vue';
import LbaUserForm from './components/LbaUserForm.vue';
import LbaKeepAlive from './components/LbaKeepAlive.vue';
import LbaTabs from './components/LbaTabs/LbaTabs.vue';
import LbaInputFile from './components/LbaInputFile.vue';
import LbaHistoryBar from './components/LbaHistoryBar.vue';
import LbaDragAndDropList2 from './components/LbaDragAndDropList2.vue';
import LbaDragAndDropTwoLists from './components/LbaDragAndDropTwoLists.vue';
import LbaContentTabs from './components/LbaContentTabs.vue';
import LbaConfirmRemoveDialog from './components/LbaConfirmRemoveDialog.vue';
import LbaCKEditor from './components/LbaCKEditor.vue';
import LbaCustomMasonry from './components/LbaCustomMasonry/LbaCustomMasonry';
import LbaValueOrder from './components/LbaValueOrder';
import LbaCode from './components/LbaCode';
import LbaIconPicker from './components/LbaIconPicker.vue';
import LbaResizableTextarea from './components/LbaResizableTextarea.vue';
import LbaCustomValue from './components/LbaCustomValue.vue';
import ModuleNotActive from './components/ModuleNotActive.vue';
import LbaPeriodPicker from './components/LbaPeriodPicker.vue';
import InputTimeRange from './components/InputTimeRange.vue';
import CustomValue from './components/CustomValues/CustomValue.vue';
import InputAction from './components/CustomValues/InputAction.vue';
import InputBreak from './components/CustomValues/InputBreak.vue';
import InputBoolean from './components/CustomValues/InputBoolean.vue';
import InputColor from './components/CustomValues/InputColor.vue';
import InputCode from './components/CustomValues/InputCode.vue';
import InputDatetime from './components/CustomValues/InputDatetime.vue';
import InputDate from './components/CustomValues/InputDate.vue';
import InputPeriodPicker from './components/CustomValues/InputPeriodPicker.vue';
import InputCheckbox from './components/CustomValues/InputCheckbox.vue';
import InputIcon from './components/CustomValues/InputIcon.vue';
import InputInterval from './components/CustomValues/InputInterval.vue';
import InputLinkFormat from './components/CustomValues/InputLinkFormat.vue';
import InputLink from './components/CustomValues/InputLink.vue';
import InputMultiCheckbox from './components/CustomValues/InputMultiCheckbox.vue';
import InputNumber from './components/CustomValues/InputNumber.vue';
import InputOptions from './components/CustomValues/InputOptions.vue';
import InputPhoneNumber from './components/CustomValues/InputPhoneNumber.vue';
import InputSelect from './components/CustomValues/InputSelect.vue';
import InputSwitch from './components/CustomValues/InputSwitch.vue';
import InputTexArray from './components/CustomValues/InputTextArray.vue';
import InputTextarea from './components/CustomValues/InputTextarea.vue';
import InputText from './components/CustomValues/InputText.vue';
import InputCKEditor from './components/CustomValues/InputCKEditor.vue';
import InputTime from './components/CustomValues/InputTime.vue';
import InputTwoLists from './components/CustomValues/InputTwoLists.vue';
import InputMultiSelect from './components/CustomValues/InputMultiSelect.vue';
import InputLbaSelect from './components/CustomValues/InputLbaSelect.vue';
import InputValueWithUnit from './components/CustomValues/InputValueWithUnit.vue';
import LbaLocale from './components/LbaLocale.vue';
import LbaLocaleEdit from './components/LbaLocaleEdit.vue';
import LbaAudio from './components/LbaAudio.vue';
import LbaObjectPermissions from './components/LbaObjectPermissions.vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import ComponentIdentifier from './mixins/ComponentIdentifier';
import OnRecordDeleted from './mixins/OnRecordDeleted';
import OnScroll from './mixins/OnScroll';
import RootListener from './mixins/RootListener';
import RouterWrap from './mixins/RouterWrap';
import GetWrappedItems from './mixins/GetWrappedItems';
import GenerateUID from './mixins/GeneratorUID';
import Debouncer from './mixins/Debouncer';
import ClassWatcher from './mixins/ClassWatcher';
import PhoneNumber from './mixins/PhoneNumber';
import Collision from './plugins/Collision';
import DownloadFile from './plugins/DownloadFile';
import FetchCached from './plugins/FetchCached';
import CheckDestroyed from './plugins/CheckDestroyed';
import Sleep from './plugins/Sleep';
import PageSettings from './plugins/PageSettings';
import LbaSaveExitButton from './components/LbaSaveExitButton';
import exceljs from 'exceljs/dist/es5/exceljs.browser';
import jsonMenu from '../config/menu.json';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism.css'; // import syntax highlighting styles

const _ = require('lodash');
const mime = require('mime');

use([
	CanvasRenderer,
	BarChart,
	LineChart,
	PieChart,
	GaugeChart,
	HeatmapChart,
	GridComponent,
	TooltipComponent,
	LegendComponent,
	TitleComponent,
	DatasetComponent,
	CalendarComponent,
	VisualMapComponent,
]);

const fileTypeMap = {
	'ms-word/word': ['doc', 'docx'],
	'ms-excel/excel': ['xls', 'xlsx'],
	'ms-powerpoint/powerpoint': ['ppt', 'pptx'],
};
mime.define(fileTypeMap, true);

const ajv = new Ajv({ allowUnionTypes: true, allErrors: true });
addFormats(ajv, { formats: ['email', 'date', 'time'] });
const ajvSchemaMap = new Map();
const ajvApi = {
	ajvInstance: ajv,
	prepareAjvSchema(name, schema) {
		if (_.isEmpty(name)) throw new Error('invalid name:', name);
		if (ajvSchemaMap.has(name)) {
			const ajvSchema = ajvSchemaMap.get(name);
			if (!_.isEqual(schema, ajvSchema.schema)) {
				ajvSchema.validate = ajv.compile(schema);
			}
			return ajvSchema;
		}
		const validate = ajv.compile(schema);
		const ajvSchema = { schema, validate };
		ajvSchemaMap.set(name, ajvSchema);
		return ajvSchema;
	},
	compile(name, schema) {
		const ajvSchema = this.prepareAjvSchema(name, schema);
		return ajvSchema.validate;
	},
	validate(name, schema, data) {
		const ajvSchema = this.prepareAjvSchema(name, schema);
		return ajvSchema.validate(data);
	},
};

// globals
window.mime = mime;
window.highlight = highlight;
window.languages = languages;
window.mixins = {
	ComponentIdentifier,
	OnRecordDeleted,
	OnScroll,
	SaveEntry,
};
// lodash in code
window._ = _;
window.moment = moment;
// lodash in template
Object.defineProperties(Vue.prototype, { _: { get() { return _; } } });
Object.defineProperties(Vue.prototype, { moment: { get() { return moment; } } });

moment.updateLocale('cs', {
	relativeTime: {
		future: 'za %s',
		past: 'před %s',
		s: 'několika sekundami',
		ss: '%d sekundami',
		m: 'minutou',
		mm: '%d minutami',
		h: 'hodinou',
		hh: '%d hodinami',
		d: 'dnem',
		dd: '%d dny',
		w: 'týdnem',
		ww: '%d týdny',
		M: 'měsícem',
		MM: '%d měsíci',
		y: 'rokem',
		yy: '%d roky',
	},
});

// default: without cache
const cache = setupCache({
	maxAge: 0,
});

window.Vue = Vue;

Vue.use(VueCompositionAPI);
Vue.use(VueAxios, axios);
Vue.axios.defaults.adapter = cache.adapter;
Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(Chat);
Vue.use(VuePapaParse);
Vue.use(VueI18n);
Vue.use(VCalendar, { componentPrefix: 'vue', masks: { weekdays: 'WW' } });
Vue.use(CKEditor);
Vue.use(VueMasonry);
Vue.use(VueVuelidateJsonSchema);
Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
	template: templates.singleErrorExtractor.foundation6,
	i18n: 'validation',
});
Vue.use(VTooltip, { defaultClass: 'tooltip', defaultDelay: { show: 700, hide: 0 } });
Vue.use(PortalVue);
Vue.use(RouteParams);
Vue.use(Collision);
Vue.use(VeeValidate, { locale: 'cs' });
Vue.use(Sleep);
Vue.use(FetchCached);
Vue.use(CheckDestroyed);
Vue.use(DownloadFile);
Vue.use(RecommendedProvider);
Vue.use(PageSettings);
VeeValidate.setInteractionMode('lazy');

Vue.component('lba-button-save', LbaButtonSave);
Vue.component('list-content', ListContent);
Vue.component('list-options', ListOptions);
Vue.component('content-options', ContentOptions);
Vue.component('controls', Controls);
Vue.component('lba-grid', LbaGrid);
Vue.component('lba-grid-column', LbaGridColumn);
Vue.component('lba-grid-date', LbaGridDate);
Vue.component('lba-code', LbaCode);
Vue.component('lba-custom-masonry', LbaCustomMasonry);
Vue.component('lba-input-password', LbaInputPassword);
Vue.component('lba-input-file', LbaInputFile);
Vue.component('lba-dialog', LbaDialog);
Vue.component('lba-dialog-edit', LbaDialogEdit);
Vue.component('lba-dialog-popup', LbaDialogPopup);
Vue.component('lba-dialog-modal', LbaDialogModal);
Vue.component('lba-dialog-recommend-empty', LbaDialogRecommendEmpty);
Vue.component('lba-ckeditor-dialog', LbaCkEditorDialog);
Vue.component('lba-select', LbaSelect);
Vue.component('lba-combobox', LbaCombobox);
Vue.component('lba-section', LbaSection);
Vue.component('lba-switch', LbaSwitch);
Vue.component('lba-datepicker', LbaDatepicker);
Vue.component('lba-dnd-list', LbaDragAndDropList);
Vue.component('lba-menu', LbaMenu);
Vue.component('lba-menu-tree', LbaMenuTree);
Vue.component('lba-number', LbaNumber);
Vue.component('lba-toolbar-button', LbaToolbarButton);
Vue.component('lba-extra-setting', LbaExtraSetting);
Vue.component('lba-user-form', LbaUserForm);
Vue.component('v-chart', ECharts);
Vue.component('lba-breadcrumb', LbaBreadcrumb);
Vue.component('lba-tabs', LbaTabs);
Vue.component('lba-keep-alive', LbaKeepAlive);
Vue.component('grid-layout', VueGridLayout.GridLayout);
Vue.component('grid-item', VueGridLayout.GridItem);
Vue.component('ValidationProvider', VeeValidate.ValidationProvider);
Vue.component('ValidationObserver', VeeValidate.ValidationObserver);
Vue.component('lba-history-bar', LbaHistoryBar);
Vue.component('vue-draggable', vuedraggable);
Vue.component('lba-dnd-list2', LbaDragAndDropList2);
Vue.component('lba-dnd-two-lists', LbaDragAndDropTwoLists);
Vue.component('lba-content-tabs', LbaContentTabs);
Vue.component('lba-confirm-remove-dialog', LbaConfirmRemoveDialog);
Vue.component('lba-ckeditor', LbaCKEditor);
Vue.component('lba-value-order', LbaValueOrder);
Vue.component('lba-window', LbaWindow);
Vue.component('multiselect', Multiselect);
Vue.component('lba-input-range', LbaInputRange);
Vue.component('lba-image-slider', LbaImageSlider);
Vue.component('module-not-active', ModuleNotActive);
Vue.component('lba-checkbox', LbaCheckbox);
Vue.component('lba-table-import', LbaTableImport);
Vue.component('lba-table-export', LbaTableExport);
Vue.component('VueSlider', VueSlider);
Vue.component('lba-dialog-confirm', LbaDialogConfirm);
Vue.component('lba-icon-picker', LbaIconPicker);
Vue.component('input-interval', InputInterval);
Vue.component('lba-resizable-textarea', LbaResizableTextarea);
Vue.component('lba-period-picker', LbaPeriodPicker);
Vue.component('lba-input-time-range', InputTimeRange);
Vue.component('lba-custom-value', LbaCustomValue);
Vue.component('custom-value', CustomValue);
Vue.component('input-action', InputAction);
Vue.component('input-break', InputBreak);
Vue.component('input-boolean', InputBoolean);
Vue.component('input-color', InputColor);
Vue.component('input-code', InputCode);
Vue.component('input-datetime', InputDatetime);
Vue.component('input-date', InputDate);
Vue.component('input-period-picker', InputPeriodPicker);
Vue.component('input-checkbox', InputCheckbox);
Vue.component('input-icon', InputIcon);
Vue.component('input-link-format', InputLinkFormat);
Vue.component('input-link', InputLink);
Vue.component('input-multi-checkbox', InputMultiCheckbox);
Vue.component('input-number', InputNumber);
Vue.component('input-options', InputOptions);
Vue.component('input-phone', InputPhoneNumber);
Vue.component('input-select', InputSelect);
Vue.component('input-switch', InputSwitch);
Vue.component('input-textarray', InputTexArray);
Vue.component('input-textarea', InputTextarea);
Vue.component('input-text', InputText);
Vue.component('input-ckeditor', InputCKEditor);
Vue.component('input-time', InputTime);
Vue.component('input-two-lists', InputTwoLists);
Vue.component('input-multi-select', InputMultiSelect);
Vue.component('input-lba-select', InputLbaSelect);
Vue.component('input-value-with-unit', InputValueWithUnit);
Vue.component('lba-locale', LbaLocale);
Vue.component('lba-locale-edit', LbaLocaleEdit);
Vue.component('lba-audio', LbaAudio);
Vue.component('lba-object-permissions', LbaObjectPermissions);
Vue.component('lba-save-exit-button', LbaSaveExitButton);
Vue.component('PrismEditor', PrismEditor);

Vue.directive('autofocus', Autofocus);
Vue.directive('lba-loading', LbaLoading);
Vue.directive('lba-dialog-open', LbaDialogOpen);
Vue.directive('lba-dialog-close', LbaDialogClose);
Vue.directive('lba-right-panel-resizer', LbaRightPanelResizer);
Vue.directive('lba-expander', LbaExpander);
Vue.directive('lba-size-class', LbaSizeClass);
Vue.directive('lba-scroll-to', LbaScrollTo);
Vue.directive('lba-movable', LbaMovable);
Vue.directive('popper', Popper);

Vue.config.productionTip = false;
Vue.prototype.$hasValue = (value, allowSpace = false) => {
	if (value == null) return false;
	if (value.constructor === Boolean) return true;
	if (value.constructor === Date) return true;
	if (value.constructor === String) return allowSpace ? !_.isEmpty(value) : !_.isEmpty(_.trim(value));
	if (value.constructor === Number) return !isNaN(value);
	return !_.isEmpty(value);
};
Vue.prototype.$LBTray = LBTray;
Vue.prototype.$Collection = Collection;
Vue.prototype.$moment = moment;
Vue.prototype.$DOMPurify = DOMPurify;
Vue.prototype.$ClassicEditor = ClassicEditor;
Vue.prototype.$socket = new Socket();
Vue.prototype.$versions = new Versions();
Vue.prototype.$Timepicker = Timepicker;
Vue.prototype.$permissions = new Permissions();
Vue.prototype.$user = new UserMixin();
Vue.prototype.$notify = new Notification();
Vue.prototype.$desktopNotify = new DesktopNotification();
Vue.prototype.$content = new Content();
Vue.prototype.$RouteKey = RouteKey;
Vue.prototype.$SaveEntry = SaveEntry;
Vue.prototype.$routerWrap = new RouterWrap();
Vue.prototype.$getWrappedItems = GetWrappedItems;
Vue.prototype.$headerPlugins = [];
Vue.prototype.$widgets = [];
Vue.prototype.$phoneNumber = new PhoneNumber();
Vue.prototype.$generateUID = GenerateUID;
window.$generateUID = GenerateUID;
Vue.prototype.$Debouncer = Debouncer;
Vue.prototype.$ClassWatcher = ClassWatcher;
Vue.prototype.$ajv = ajvApi;
Vue.prototype.$exceljs = exceljs;
Vue.prototype.$formatter = new Formatter();
Vue.prototype.$axiosCache = cache;
Vue.prototype.$axiosFetch = async (vm, request) => {

	try {
		const response = await request;
		return response;

	} catch (error) {
		const customError = {
			url: error.response.config.url,
			status: error.response.status,
			error: null,
			key: vm.$vnode.key,
		};

		if (error.response.status === 404) {
			vm.$emit('view-not-found', customError);

		} else if (error.response.status === 401 || error.response.status === 403) {
			vm.$emit('view-permission-denied', customError);

		} else if (error.response.status === 500) {
			customError.error = error.response.data.error;
			vm.$emit('view-server-error', customError);

		}
		throw new Error(error);

	}
};
Vue.prototype.$normalizeString = (value) => (value || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
Vue.prototype.$nullIfEmptyString = (value) => value === '' ? null : value;

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			name: () => 'Home',
			breadcrumb: () => [{ name: 'home', text: 'Home' }],
		},
	},
	{
		path: '/import-records',
		name: 'import-records',
		component: ImportRecords,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importRecords'),
			breadcrumb: () => [{ name: 'import-records', text: 'importRecords' }],
		},
	},
	{
		path: '/import-record/:id',
		name: 'import-record',
		component: ImportRecord,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importRecord'),
			breadcrumb: () => [
				{ name: 'import-records', text: 'importRecords' },
				{ name: 'import-record', text: 'importRecord' },
			],
		},
	},
	{
		path: '/import-export/configurations',
		name: 'import-export-configurations',
		component: ImportExportConfigurations,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importExportConfigurations'),
			breadcrumb: () => [{ name: 'import-export-configurations', text: 'importExportConfigurations' }],
		},
	},
	{
		path: '/import-export/configuration/:id',
		name: 'import-export-configuration',
		component: ImportExportConfiguration,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importExportConfiguration'),
			breadcrumb: () => [
				{ name: 'import-export-configurations', text: 'importExportConfigurations' },
				{ name: 'import-export-configuration', text: 'importExportConfiguration' },
			],
		},
	},
	{
		path: '/import/settings',
		name: 'import-settings',
		component: ImportExportSettings,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importSettings'),
			breadcrumb: () => [{ name: 'import-settings', text: 'importSettings' }],
		},
	},
	{
		path: '/import/setting/:id',
		name: 'import-setting',
		component: ImportExportSetting,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('importSetting'),
			breadcrumb: () => [
				{ name: 'import-settings', text: 'importSettings' },
				{ name: 'import-setting', text: 'importSetting' },
			],
		},
	},
	{
		path: '/export/settings',
		name: 'export-settings',
		component: ImportExportSettings,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('exportSettings'),
			breadcrumb: () => [{ name: 'export-settings', text: 'exportSettings' }],
		},
	},
	{
		path: '/export/setting/:id',
		name: 'export-setting',
		component: ImportExportSetting,
		meta: {
			icon: 'icon-exchange',
			name: (vm) => vm.$t('exportSetting'),
			breadcrumb: () => [
				{ name: 'export-settings', text: 'exportSettings' },
				{ name: 'export-setting', text: 'exportSetting' },
			],
		},
	},
	{
		path: '/users',
		name: 'settings-users',
		component: Users,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$route.meta.setName || vm.$t('settings.menu.users'),
			breadcrumb: () => [
				{ name: 'settings-users', text: ['settings.menu.users', 'settings.menu.users'] },
			],
		},
	},
	{
		path: '/users/:id',
		name: 'settings-user',
		component: User,
		meta: {
			icon: 'icon-users',
			name(vm) { return this.setName || vm.$t('settings.newUser'); },
			tooltip() { return ((this.tooltipPrefix ? `${this.tooltipPrefix} - ` : '') + (this.tooltipText || this.setName)); },
			breadcrumb: () => [
				{ name: 'settings-users', text: ['settings.menu.users', 'settings.menu.users'] },
				{ name: 'settings-user', text: ['settings.user.user'] },
			],
		},
	},
	{
		path: '/groups',
		name: 'settings-groups',
		component: Groups,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$t('settings.menu.groups'),
			breadcrumb: () => [
				{ name: 'settings-groups', text: ['settings.menu.users', 'settings.menu.groups'] },
			],
		},
	},
	{
		path: '/groups/:id',
		name: 'settings-group',
		component: Group,
		meta: {
			icon: 'icon-users',
			name(vm) { return this.setName || vm.$t('settings.group'); },
			tooltip() { return ((this.tooltipPrefix ? `${this.tooltipPrefix} - ` : '') + (this.tooltipText || this.setName)); },
			breadcrumb: () => [
				{ name: 'settings-groups', text: ['settings.menu.users', 'settings.menu.groups'] },
				{ name: 'settings-group', text: 'settings.group' },
			],
		},
	},
	{
		path: '/profiles',
		name: 'settings-profiles',
		component: Profiles,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$t('settings.menu.profiles'),
			breadcrumb: () => [
				{ name: 'settings-profiles', text: ['settings.menu.users', 'settings.menu.profiles'] },
			],
		},
	},
	{
		path: '/profiles/:id',
		name: 'settings-profile',
		component: Profile,
		meta: {
			icon: 'icon-users',
			name(vm) { return this.setName || vm.$t('settings.profile'); },
			tooltip() { return ((this.tooltipPrefix ? `${this.tooltipPrefix} - ` : '') + (this.tooltipText || this.setName)); },
			breadcrumb: () => [
				{ name: 'settings-profiles', text: ['settings.menu.users', 'settings.menu.profiles'] },
				{ name: 'settings-profile', text: 'settings.profile' },
			],
		},
	},
	{
		path: '/activity',
		name: 'settings-activity',
		component: Activity,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$route.meta.setName || vm.$t('settings.menu.activity'),
			breadcrumb: () => [
				{ name: 'settings-activity', text: ['settings.menu.users', 'settings.menu.activity'] },
			],
		},
	},
	{
		path: '/logged-in',
		name: 'settings-logged-in',
		component: LoggedIn,
		meta: {
			icon: 'icon-users',
			name: (vm) => vm.$route.meta.setName || vm.$t('settings.menu.loggedIn'),
			breadcrumb: () => [
				{ name: 'settings-logged-in', text: ['settings.menu.users', 'settings.menu.loggedIn'] },
			],
		},
	},
	{
		path: '/options',
		name: 'options',
		component: Options,
		meta: {
			icon: 'icon-settings',
			name: (vm) => vm.$route.meta.setName || vm.$t('settings.settings'),
			breadcrumb: () => [
				{ name: 'options', text: ['settings.settings'] },
			],
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

const i18n = new VueI18n({
	locale: 'en',
	messages: {
		cs: { veeValidate: validationMessagesCs },
		en: { veeValidate: validationMessagesEn },
	},
	pluralizationRules: {
		cs: (choice) => {
			if (choice === 0) {
				return 0;
			}
			if (choice === 1) {
				return 1;
			}
			if (choice === (choice | 0) && choice >= 2 && choice <= 4) {
				return 2;
			}
			return 3;
		},
	},
	dateTimeFormats: {
		cs: {
			timeDiff: { minute: 'numeric', second: 'numeric' },
			timeShort: { hour: 'numeric', minute: 'numeric' },
			timeLong: { hour: 'numeric', minute: 'numeric', second: 'numeric' },
			short: {
				year: 'numeric', month: 'numeric', day: 'numeric',
			},
			medium: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			},
			long: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
			},
			fullDigitsShort: {
				year: 'numeric', month: '2-digit', day: '2-digit',
			},
			fullDigitsMedium: {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
			},
			fullDigitsLong: {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			},
		},
		en: {
			timeDiff: { minute: 'numeric', second: 'numeric' },
			timeShort: { hour: 'numeric', minute: 'numeric' },
			timeLong: { hour: 'numeric', minute: 'numeric', second: 'numeric' },
			short: {
				year: 'numeric', month: 'numeric', day: 'numeric',
			},
			medium: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			},
			long: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
				second: 'numeric',
			},
			fullDigitsShort: {
				year: 'numeric', month: '2-digit', day: '2-digit',
			},
			fullDigitsMedium: {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
			},
			fullDigitsLong: {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			},
		},
	},
});

new Vue({
	mixins: [MenuUpdate, RootListener],
	data() {
		return {
			scripts: [],
			theme: null,
			lang: null,
			anonymousAccess: false,
			loadingLogin: true,
			servers: [],
			leftMenuPlugins: [],
			menu: [],
			firstURL: null,
			isFullscreen: false,
			uid: this.$generateUID(),
			browserName: null,
			gridsChannel: null,
			preparingGridsChannel: true,
			blocked: null,
		};
	},
	metaInfo() {
		return {
			script: this.scripts,
		};
	},
	computed: {
		hasAnonymousAccess() {
			return (
				this.$user.user_name !== null && (
					!this.$user.is_anonymous_user ||
					(this.$user.is_anonymous_user && this.anonymousAccess)
				)
			);
		},
	},
	async created() {
		this.$listen('grid.update-row', this.gridUpdateRow, this, true);
		this.$once('socket-opened', this.connectGridsChannel);

		if (navigator.userAgent.match(/opr\//i)) {
			this.browserName = 'opera';
		} else if (navigator.userAgent.match(/firefox|fxios/i)) {
			this.browserName = 'firefox';
		} else if (navigator.userAgent.match(/edg/i)) {
			this.browserName = 'edge';
		} else if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
			this.browserName = 'chrome';
		// not very reliable, chrome contains safari too
		} else if (navigator.userAgent.match(/safari/i)) {
			this.browserName = 'safari';
		}

		if (this.browserName != null) {
			document.body.classList.add(this.browserName);
		}

		Object.keys(validationRules).forEach((rule) => {
			VeeValidate.extend(rule, {
				...validationRules[rule],
				message: (value, params) => {
					return this.$t(`veeValidate.${rule}`, params);
				},
			});
		});
		VeeValidate.extend('after', {
			validate: this.validateAfter,
			message: (fieldName, placeholders) => {
				return this.$t('validation.after', null, {
					attribute: fieldName,
					after: this.$d(new Date(placeholders.after), placeholders.dateType || 'long'),
				});
			},
			params: ['after', 'dateType'],
		});
		VeeValidate.extend('before', {
			validate: this.validateBefore,
			message: (fieldName, placeholders) => {
				return this.$t('validation.before', null, {
					attribute: fieldName,
					before: this.$d(new Date(placeholders.before), placeholders.dateType || 'long'),
				});
			},
			params: ['before', 'dateType'],
		});
		VeeValidate.extend('pathToLocale', {
			validate: this.validatePathToLocale,
			message: (fieldName) => {
				return this.$t('validation.pathToLocale', null, { attribute: fieldName });
			},
			params: ['allowObject'],
		});
		VeeValidate.extend('locale', {
			validate: this.validateLocale,
			message: (fieldName) => {
				return this.$t('validation.locale', null, { attribute: fieldName });
			},
			params: ['allowObject'],
		});
		VeeValidate.extend('localeRequired', {
			validate: this.validateLocaleRequired,
			message: (fieldName) => {
				return this.$t('validation.required', null, { attribute: fieldName });
			},
			params: ['allowObject'],
		});
		VeeValidate.extend('notOneOf', {
			validate: this.validateNotOneOf,
			message: (fieldName) => {
				return this.$t('validation.unique', null, { attribute: fieldName });
			},
			params: ['values', 'pathToKey'],
		});
		VeeValidate.extend('templateCardItem', {
			validate: this.validateTemplateCardItem,
			message: (fieldName) => {
				return this.$t('validation.templateCardItem', null, { attribute: fieldName });
			},
			params: ['values'],
		});
		VeeValidate.extend('unique', {
			validate: this.validateUnique,
			message: (fieldName, placeholders) => {
				return this.$t('validation.uniques', null, { attribute1: fieldName, attribute2: placeholders.fieldName2 });
			},
			params: ['values', 'pathToKey', 'fieldName2', 'filter'],
		});
		VeeValidate.extend('requiredGroup', {
			validate: this.validateRequiredGroup,
			message: (fieldName, placeholders) => {
				return this.$t('validation.requiredGroup', null, {
					attributes: [fieldName, ...placeholders.info.labels].join(', '),
				});
			},
			params: ['info'],
			computesRequired: true,
		});
		VeeValidate.extend('emailWithName', {
			validate: this.validateEmailWithName,
			message: (fieldName) => {
				return this.$t('validation.format', null, {
					attribute: fieldName,
				});
			},
		});
		VeeValidate.extend('emailsWithName', {
			validate: this.validateEmailsWithName,
			message: (fieldName) => {
				return this.$t('validation.formats', null, {
					attribute: fieldName,
				});
			},
			params: ['filter'],
		});
		VeeValidate.extend('requiredInterval', {
			validate: this.validateRequiredInterval,
			message: (fieldName) => {
				return this.$t('validation.formats', null, {
					attribute: fieldName,
				});
			},
			params: ['time'],
		});
		VeeValidate.extend('requiredMulticheckbox', {
			validate: this.validateRequiredMultiCheckbox,
			message: (fieldName) => {
				return this.$t('validation.formats', null, {
					attribute: fieldName,
				});
			},
			params: ['checkboxes'],
		});
		VeeValidate.extend('requiredCheckbox', {
			validate: this.validateRequiredCheckbox,
			message: (fieldName) => {
				return this.$t('validation.required', null, {
					attribute: fieldName,
				});
			},
			params: ['checkbox'],
		});
		VeeValidate.extend('notContains', {
			validate: this.validateNotContains,
			message: (fieldName) => {
				return this.$t('validation.containBanned');
			},
			params: ['values'],
		});
		VeeValidate.extend('phoneNumber', {
			validate: this.validatePhoneNumber,
			message: (fieldName) => {
				return this.$t('validation.format', null, {
					attribute: fieldName,
				});
			},
			params: ['phone'],
		});

		const validateJson = (value, allowObject = false) => {
			let status = false;
			try {
				JSON.parse(value);
				status = true;
			} catch (e) {
				const jsonError = JSON.stringify(e.message);
				if (jsonError.indexOf('position') > -1) {
					console.log(e.message);
					// highlight error position
					const positionStr = jsonError.lastIndexOf('position') + 8;
					const position = parseInt(jsonError.substring(positionStr));
					const rows = value.split('\n');
					const rowsToError = value.substring(0, position).split('\n');
					const rowPosition = rowsToError.length;
					const rowErrorPosition = rowsToError[rowPosition - 1].length;
					const errorRow = rows[rowPosition - 1];
					if (position >= 0) {
						status = this.$t('validation.jsonAt', { rowPosition, rowErrorPosition, errorRow, jsonError });
					}
				} else {
					status = e.message;
				}
			}
			return status;
		};
		VeeValidate.extend('json', {
			validate: validateJson,
			message: (fieldName) => {
				return this.$t('validation.json', null, { attribute: fieldName });
			},
			params: ['allowObject'],
		});

		const getLocale = (locale, allowObject = false, joinWith = ' ') => {
			if (!this.$i18n.locale) { this.$i18n.locale = this.getBrowserLanguage(); }
			if (_.isEmpty(locale)) return '';
			if (locale.constructor === String) return locale;
			if (locale.constructor === Array) return locale.map((value) => getLocale(value)).join(joinWith);
			if (locale[this.$i18n.locale]) return locale[this.$i18n.locale];
			if (locale.translate && this.validatePathToLocale(locale.translate, allowObject)) {
				return this.$t(locale.translate, locale.attributes);
			}
			if (locale.translate) {
				console.warn(`[$getLocale] locale translate is invalid:`, locale, 'allowObject:', allowObject);
			}
			if (locale.translated) return locale.translated;
			console.warn(`[$getLocale] locale: ${this.$i18n.locale} not found:`, locale);
			return '';
		};
		window.$getLocale = getLocale;
		Vue.prototype.$getLocale = getLocale;

		Vue.prototype.$containsLocale = (a, b) => !!a.split('')
			.filter((v, i) => a.slice(i, b.length).localeCompare(b, this.$i18n.locale, { sensitivity: 'base' }) === 0).length;

		Vue.prototype.$localeIncludes = (string, searchString, { position = 0, locales, ...options } = {}) => {
			if (string === undefined ||
				string === null ||
				searchString === undefined ||
				searchString === null
			) {
				console.error('localeIncludes requires at least 2 parameters');
				return false;
			}

			const stringLength = string.length;
			const searchStringLength = searchString.length;
			const lengthDiff = stringLength - searchStringLength;

			for (let i = position; i <= lengthDiff; i++) {
				if (string.substring(i, i + searchStringLength).localeCompare(searchString, locales, options) === 0) {
					return true;
				}
			}

			return false;
		};

		DOMPurify.addHook('afterSanitizeAttributes', (node, data, config) => {
			if (node.nodeName === 'IMG' && node.src && node.src.indexOf('cid:') !== 0) {
				if (config.banExternalImage) {
					node.alt = this.$t('externalImage', { attribute: node.src });
					node.src = '';
				} else {
					const newSrc = `/api/lbadmin/lbadmin/external-image?url=${encodeURIComponent(node.src)}`;
					node.src = newSrc;
				}
				return node;
			}
		});

		const overwrittenShortCuts = [
			'ctrl-s',
		];
		const allowedKeys = ['enter', 'backspace', 'escape', 'delete'];
		window.addEventListener('keydown', (event) => {
			if (_.isEmpty(event.key)) {
				return;
			}
			const altKey = event.altKey;
			const ctrlKey = event.ctrlKey;
			const shiftKey = event.shiftKey;
			const key = event.key.toLowerCase();

			if (!altKey && !ctrlKey && !allowedKeys.includes(key)) {
				return;
			}
			if (key === 'control' || key === 'alt') {
				return;
			}

			const nameParts = [];
			if (ctrlKey) {
				nameParts.push('ctrl');
			}
			if (altKey) {
				nameParts.push('alt');
			}
			if (shiftKey) {
				nameParts.push('shift');
			}
			nameParts.push(key);
			const name = nameParts.join('-');

			if (overwrittenShortCuts.includes(name)) {
				event.preventDefault();
			}
			console.debug(`keydown-${name}`);
			this.$emit(`keydown-${name}`, {});
		});

		this.$http.defaults.baseURL = '/api/lbadmin/';
		this.$content.init(this);
		this.$socket.vm = this;
		this.$versions.vm = this;
		this.$user.vm = this;
		this.$permissions.vm = this;
		this.$routerWrap.vm = this;
		this.$desktopNotify.vm = this;
		this.$pageSettings.init(this);

		if (!this.$route.fullPath.startsWith('/login')) {
			this.firstURL = this.$route.fullPath;
		}

		if ('Android' in window) {
			document.body.classList.add('androidApp');
		}

		// load locales
		const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
		locales.keys().forEach((key) => {
			const matched = key.match(/([A-Za-z0-9-_]+)\./i);
			if (matched && matched.length > 1) {
				const locale = matched[1];
				// i18n.setLocaleMessage(locale, locales(key));
				const preparedLocales = locales(key);
				this.preparePluralLocales(preparedLocales);
				this.$i18n.mergeLocaleMessage(locale, preparedLocales);
				moment.updateLocale(locale, preparedLocales);
			}
		});

		this.menu = [jsonMenu];

		if (!this.$route.fullPath.startsWith('/login')) {
			await this.$user.load();
			// add last route with 404 page
			router.addRoute({
				name: 'error-404',
				path: '*',
				component: NotFound,
				meta: { name: () => '404' },
			});
		}

		try {
			const info = await this.$http.get('lbadmin/servers');
			this.servers = info.data;
			this.theme = this.servers[0].theme;
			this.lang = this.servers[0].lang;

			for (let i = 0; i < this.servers.length; i += 1) {
				this.servers[i].timeDiff = Math.abs(new Date() - new Date(info.headers.date));
			}

			this.$root.$emit('servers-loaded');

			this.anonymousAccess = this.servers[0].anonymous_access;

			this.idleLogoutEnabled = this.servers[0].idle_logout_enabled;
			this.idleLogoutTime = this.servers[0].idle_logout_time;
			if (this.idleLogoutEnabled) {
				const idle = new IdleJs({
					idle: this.idleLogoutTime,
					events: ['mousemove', 'keydown', 'mousedown', 'touchstart'],
					keepTracking: true,
					startAtIdle: true,
					onIdle: () => {
						this.$root.$emit('user-idle');
					},
					onActive: () => {
						this.$root.$emit('user-active');
					},
				});
				idle.start();
			}

		} catch (error) {
			console.error('Theme load error:', error);
			if (error.response.data.blocked) {
				this.blocked = error.response.data.blocked;
			}
			this.theme = 'linuxbox';
			this.lang = 'cs';
		}

		if (process.env.NODE_ENV === 'production') {
			this.scripts.push({
				src: `/themes/${this.theme}.umd.min.js`,
				callback: () => {
					this.$user.loadTheme(this.theme, window[this.theme]);

					// user is anonymous but server does not have anonymous access -> login page
					// or user has anonymous access but does not have some special link
					if (
						(!this.hasAnonymousAccess && !this.$route.fullPath.startsWith('/login')) ||
						(
							this.$user.is_anonymous_user && (
								this.$route.fullPath === '/' || this.$route.name === 'error-404'
							)
						)
					) {
						this.$routerWrap.push({ name: 'login' });
					} else {
						this.loadingLogin = false;
						document.body.classList.remove('ng-cloak');
					}

					this.setupRouterHooks();
				},
			});
		} else {
			/* DEVELOPMENT ONLY - BEGIN
			import(`./themes/${this.theme}/index.js`)
				.then((module) => {
					window[this.theme] = module;
					this.$user.loadTheme(this.theme, module);

					// user is anonymous but server does not have anonymous access -> login page
					// or user has anonymous access but does not have some special link
					if (
						(!this.hasAnonymousAccess && !this.$route.fullPath.startsWith('/login')) ||
						(
							this.$user.is_anonymous_user && (
								this.$route.fullPath === '/' || this.$route.name === 'error-404'
							)
						)
					) {
						this.$routerWrap.push({ name: 'login' });
					} else {
						this.loadingLogin = false;
						document.body.classList.remove('ng-cloak');
					}

					this.setupRouterHooks();
				});
			/* DEVELOPMENT ONLY - END */
		}

		this.$http.interceptors.response.use(
			(response) => response,
			(error) => {
				let message;
				let quiet = false;

				if (error.response && error.response.data && error.response.data.error) {
					if (typeof error.response.data.error === 'string') {
						if (error.response.data.error.substr(0, 6) === 'quiet:') {
							quiet = true;
							message = error.response.data.error.substr(6);
						} else {
							message = error.response.data.error;
						}
					}
				}

				if (
					error.response &&
					(
						(!error.response.data && error.response.status < 0) ||
						(error.response.status === 502 && error.response.statusText === 'Proxy Error')
					)
				) {
					message = 'requestTimeout';
				}

				if (message && !quiet) {
					this.$notify.warn(this.$t(message));
				}

				throw error;
			}
		);
	},
	beforeDestroy() {
		if (this.gridsChannel) {
			this.gridsChannel.unsubscribe();
			this.gridsChannel = null;
		}
	},
	methods: {
		preparePluralLocales(locale) {
			const keys = Object.keys(locale);
			for (let i = 0; i < keys.length; i += 1) {
				const key = keys[i];

				if (!_.isEmpty(locale[key])) {
					const pluralTag = '_plural';
					if (key.endsWith(pluralTag) && locale[key].constructor === Array) {
						const newKey = key.slice(0, key.length - pluralTag.length);
						locale[newKey] = locale[key].join(' | ');
						delete locale[key];
					} else if (locale[key].constructor === Object) {
						this.preparePluralLocales(locale[key]);
					}
				}
			}
		},
		connectGridsChannel() {
			if (_.isEmpty(this.gridsChannel)) {
				// console.log('register grid channel');
				this.gridsChannel = this.$socket.on(
					'/lbadmin/grids',
					{
						vueUid: this.uid,
						browser: this.browserName,
						roleUid: this.$user.role_uid,
					},
					this.gridOnSocketMessage
				);
			}
		},
		gridOnSocketMessage(message) {
			if (message.type === 'registered') {
				if (this.preparingGridsChannel) {
					// console.log('socket ready');
					this.preparingGridsChannel = false;
					this.$emit('grid.channel-ready');
				}

			} else if (message.type === 'reload-grid') {
				console.warn('[main](gridOnSocketMessage) socket message type reload-grid is not yet implemented');

			} else if (message.type === 'reload-grid-row') {
				if (_.isEmpty(message.payload.gridName)) {
					console.error('[main](gridOnSocketMessage) socket message missing payload.gridName type:', message.type);
					return;
				}
				if (_.isEmpty(message.payload.rowId)) {
					console.error('[main](gridOnSocketMessage) socket message missing payload.rowId type:', message.type);
					return;
				}
				// console.log('update row', message.payload.gridName);
				this.$emit('grid.update-row', {
					gridName: message.payload.gridName,
					rowId: message.payload.rowId,
					reload: true,
					internalOnly: true,
				});

			} else {
				console.error('[main](gridOnSocketMessage) unknown message type:', message.type);

			}
		},
		gridUpdateRow(info) {
			if (
				info.internalOnly ||
				_.isEmpty(this.gridsChannel) ||
				this.preparingGridsChannel ||
				(_.isEmpty(info.gridName) && _.isEmpty(info.gridNames)) ||
				_.isEmpty(info.rowId)
			) {
				// console.log('update row but only internal', info);
				return;
			}

			const gridNames = info.gridNames || [info.gridName];
			gridNames.forEach((gridName) => {
				// console.log('update row for grid:', gridName);
				this.gridsChannel.send({
					type: 'reload-grid-row',
					payload: { gridName, rowId: info.rowId },
				});
			});
		},
		/* DEVELOPMENT ONLY - BEGIN
		testDataCy() {
			const elements = document.querySelectorAll('[data-cy]');
			const ids = _.map(elements, (element) => element.getAttribute('data-cy'));
			const getDuplicates = (array) => array.filter((item, index) => array.indexOf(item) !== index);
			const duplicates = getDuplicates(ids);
			if (duplicates.length > 0) {
				console.error('data-cy duplicates found:', duplicates);
			} else {
				console.log('data-cy duplicates not found -> ok');
			}
			const uniqueIds = Array.from(new Set(ids));
			const nullOrUndefined = uniqueIds.filter((id) => id.includes('null') || id.includes('undefined'));
			if (nullOrUndefined.length > 0) {
				console.error('data-cy null or undefined found:', nullOrUndefined);
			} else {
				console.log('data-cy null or undefined not found -> ok');
			}
		},
		/* DEVELOPMENT ONLY - END */
		localeChanged() {
			VeeValidate.localeChanged();
			moment.locale(this.$i18n.locale);
		},
		validateAfter(value, { after }) {
			if (value) {
				return new Date(value) > new Date(after);
			}
			return true;
		},
		validateBefore(value, { before }) {
			if (value) {
				return new Date(value) < new Date(before);
			}
			return true;
		},
		validatePathToLocale(value, allowObject = false) {
			return this.$te(value) && (allowObject || this.$t(value).constructor === String);
		},
		validateLocale(value, allowObject = false) {
			if (_.isEmpty(value)) return true;
			if (value.constructor !== Object) return false;
			if (Object.keys(value).length === 1 && !_.isEmpty(value.translated)) return true;
			if (!_.isEmpty(value.translate)) return this.validatePathToLocale(value.translate, allowObject);
			if (!_.isEmpty(value.translated)) return true;
			return this.$i18n.availableLocales.find((locale) => _.isEmpty(value[locale])) == null;
		},
		validateLocaleRequired(value, allowObject = false) {
			return !_.isEmpty(value) && this.validateLocale(value, allowObject);
		},
		validateNotOneOf(value, { values = [], pathToKey = null }) {
			if (!_.isEmpty(pathToKey)) {
				return !values.some((item) => _.get(item, pathToKey) === value);
			}
			return !values.includes(value);
		},
		validateTemplateCardItem(value, { values = [] }) {
			if (_.isEmpty(value)) return true;

			const regex = /\{(?<cardItem>[\w.\-_0-9]+)\}/gmi;
			let match = regex.exec(value);

			while (match != null && !_.isEmpty(match.groups) && !_.isEmpty(match.groups.cardItem)) {
				if (_.isEmpty(values) || !values.includes(match.groups.cardItem)) return false;
				match = regex.exec(value);
			}
			return true;
		},
		validateUnique(value, { values = [], pathToKey = null, filter = null }) {
			if (_.isEmpty(value)) return true;

			const set = new Set();
			let values1 = value;
			let values2 = values;

			if (filter) {
				values1 = values1.filter(filter);
				values2 = values2.filter(filter);
			}

			if (pathToKey != null) {
				values1.forEach((item) => set.add(_.get(item, pathToKey)));
				values2.forEach((item) => set.add(_.get(item, pathToKey)));
			} else {
				values1.forEach((item) => set.add(item));
				values2.forEach((item) => set.add(item));
			}
			return (set.size === values1.length + values2.length);
		},
		validateRequiredGroup(value, { info = {} }) {
			if (
				this.$hasValue(value) || (
					!_.isEmpty(info) &&
					!_.isEmpty(info.data) &&
					!_.isEmpty(info.paths) &&
					_.some(info.paths, (path) => this.$hasValue(_.get(info.data, path)))
				)
			) {
				return { valid: true, required: false };
			}
			return { valid: false, required: true };
		},
		validateEmailWithName(value) {
			value = _.trim(value);
			if (!this.$hasValue(value)) return true;

			const splitted = value.split(' ');
			if (splitted.length > 1) {
				let email = splitted.pop();
				let name = splitted.join(' ');

				// email with name must be inside <>
				const emailMatches = email.match(/^<.*>$/gi);
				if (_.isEmpty(emailMatches) || _.isEmpty(emailMatches[0])) return false;

				// remove <> for vee-validation and validate
				email = email.slice(1, email.length - 1);
				if (!validationRules.email.validate(email)) return false;

				// email is now valid, validate name
				const nameMatches = name.match(/^".*"$/gi);
				// name is not wrapped in double quotes
				if (_.isEmpty(nameMatches) || _.isEmpty(nameMatches[0])) {
					// check if name contains only allowed characters
					return !_.isEmpty(name.match(/^[a-ž0-9!#$%&'*+\-/=?^_`{|}~ ]+$/gi));
				}

				// name is wrapped in double quotes
				name = name.slice(1, name.length - 1);
				// check if double quotes are escaped
				return (name.match(/"/gi) || []).length === (name.match(/\\"/gi) || []).length;
			} else {
				return validationRules.email.validate(value);
			}
		},
		validateEmailsWithName(value, { filter = null }) {
			if (_.isEmpty(value)) return true;
			// validate emails
			if (value.constructor === String) return this.validateEmailWithName(value);
			if (filter) {
				value = value.filter(filter);
			}
			return _.every(value, (email) => {
				if (_.isEmpty(email)) return true;
				if (email.constructor === Object) {
					return this.validateEmailWithName(email.value);
				}
				return this.validateEmailWithName(email);
			});
		},
		validateRequiredInterval(value, { time = null }) {
			if (time.hours !== '00' || time.minutes !== '00' || time.seconds !== '00') {
				return true;
			}
			return false;
		},
		validateRequiredMultiCheckbox(value, { checkboxes = [] }) {
			for (let i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked === true) {
					return true;
				}
			}
			return false;
		},
		validateRequiredCheckbox(value, { checkbox = false }) {
			return !!checkbox;
		},
		validateNotContains(value, { values = [] }) {
			value = value.toLowerCase();
			if (Array.isArray(values)) {
				for (let i = 0; i < values.length; i++) {
					const test = values[i].toLowerCase();
					if (value.includes(test)) {
						return false;
					}
				}
			} else if (value.includes(values)) {
				return false;
			}
			return true;
		},
		validatePhoneNumber(value, { phone = {} }) {
			if (!value) return false;
			const min = phone.min || 7;
			const max = phone.max || 15;
			const phoneVal = this.$phoneNumber.validate(value, min, max);
			return phoneVal || false;
		},
		setIsFullscreen() {
			this.isFullscreen = (
				document.webkitIsFullScreen ||
				document.mozFullScreen ||
				document.msFullscreenElement != null ||
				document.fullscreenElement ||
				document.mozFullScreenElement ||
				document.webkitFullscreenElement
			);
		},
		setupRouterHooks() {
			// check if logged in if not, redirect to login page
			router.beforeEach(async (to, from, next) => {
				document.body.classList.add('ng-cloak');

				if (to.name === 'login' || to.path === '/login') {
					next();
				} else if (router.app.$user.user_name == null) {
					const userLoaded = await router.app.$user.load();

					// anonymous with access or not anonymous
					if (
						userLoaded &&
						(
							(router.app.$user.is_anonymous_user && router.app.anonymousAccess) ||
							!router.app.$user.is_anonymous_user
						)
					) {
						next();
					} else {
						next({ name: 'login' });
					}
				} else if (
					(router.app.$user.is_anonymous_user && router.app.anonymousAccess) ||
					!router.app.$user.is_anonymous_user
				) {
					next();
				} else {
					next({ name: 'login' });
				}
			});

			router.afterEach(() => {
				document.body.classList.remove('ng-cloak');
			});

			router.onError(() => {
				document.body.classList.remove('ng-cloak');
			});
		},
		getBrowserLanguage() {
			const browserLang = navigator.language;
			if (browserLang.includes('cs')) { return 'cs'; }
			return 'en';
		},
	},
	router,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
