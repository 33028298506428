/* eslint-disable no-param-reassign */
import Breadcrumbs from './mixins/Breadcrumbs';

export default {
	mixins: [Breadcrumbs],
	props: {
		keepAlive: Object,
		menuModules: Array,
	},
	data() {
		return {
			tabs: [],
			removeCache: null,
			removeTab: false,
			tabToClose: null,
			redirectFrom: null,
			redirect: null,
			redirectTab: null,
			channel: null,
			timeout: false,
			openRoutesWatchers: {},
			unregisterBeforeEach: () => {},
			unregisterAfterEach: () => {},
			lastMouseOverTimeout: null,
			mouseOverTabs: false,
			tabSearchValue: null,
			tabSearchDebouncer: () => {},
			closeTabDialogOpened: false,
		};
	},
	computed: {
		showOpenNewHomeTab() {
			for (let i = 0; i < this.tabs.length; i += 1) {
				if (this.tabs[i].path === '/') {
					return false;
				}
			}

			return true;
		},
		activeTabIndex() {
			for (let i = 0; i < this.tabs.length; i += 1) {
				if (this.tabs[i].path === this.$route.path) {
					return i;
				}
			}

			return -1;
		},
		activeTab() {
			if (this.activeTabIndex < 0) {
				return undefined;
			}

			return this.tabs[this.activeTabIndex];
		},
	},
	created() {
		this.$root.$tabs = this;
		this.$root.$listen('get-tabs', (ref) => { ref.tabs = this; }, this);
		this.$root.$listen('keydown-ctrl-q', this.closeCurrentTab, this);
		this.$root.$listen('keydown-alt-pageup', () => {
			if (this.activeTabIndex > 0) {
				this.$routerWrap.push(this.tabs[this.activeTabIndex - 1].fullPath);
			} else if (this.tabs.length > 0) {
				this.$routerWrap.push(this.tabs[this.tabs.length - 1].fullPath);
			}
		}, this);
		this.$root.$listen('keydown-alt-pagedown', () => {
			if (this.activeTabIndex < this.tabs.length - 1) {
				this.$routerWrap.push(this.tabs[this.activeTabIndex + 1].fullPath);
			} else if (this.tabs.length > 0) {
				this.$routerWrap.push(this.tabs[0].fullPath);
			}
		}, this);
		this.$root.$listen('keydown-ctrl-home', this.openHomeTab, this);

		this.setPath(this.$route, { ts: new Date().toString() });

		const storedTabs = localStorage.getItem('tabs');
		let tabs = null;

		if (!window._.isEmpty(storedTabs)) {
			tabs = JSON.parse(storedTabs);
		}

		if (!window._.isEmpty(tabs)) {
			let currentTabExists = false;
			tabs.forEach((tab) => {
				const newTab = {
					...tab,
					_key: Math.random(),
					showClose: true,
					editted: false,
					disabledClosing: false,
					breadcrumbs: [],
					remainingBreadcrumbs: [],
					hover: false,
					channelReady: false,
					showTooltip: false,
					showInList: true,
				};

				const newTabRoute = this.$router.resolve(tab.fullPath).resolved;

				if (window._.isEmpty(newTabRoute.name) || newTabRoute.name === 'error-404' || newTabRoute.name === 'login') {
					return;
				}

				if (!currentTabExists) {
					const isSame = tab.path === this.$route.path;
					let isRelated = false;

					if (!isSame) {
						const currentResolvedRoutes = this.$route.matched.map((match) => {
							const { resolved } = this.$router.resolve({ name: match.name, params: this.$route.params });
							return resolved;
						});
						const newTabResolvedRoutes = newTabRoute.matched.map((match) => {
							const { resolved } = this.$router.resolve({ name: match.name, params: newTabRoute.params });
							return resolved;
						});

						isRelated = currentResolvedRoutes[0].path === newTabResolvedRoutes[0].path;
						if (isRelated) {
							newTab.path = this.$route.path;
							newTab.icon = this.$route.meta.icon;
							newTab.name = this.$route.meta.name ? this.$route.meta.name(this) : '';
							newTab.tooltip = this.$route.meta.tooltip ? this.$route.meta.tooltip(this) : null;
						}
					}

					if (isSame || isRelated) {
						currentTabExists = true;
						newTab.fullPath = this.$route.fullPath;
						newTab.route = this.$route;
						newTab.key = this.getKey(this.$route);
					}
				}
				this.tabs.push(newTab);
			});

			if (!currentTabExists) {
				this.tabs.splice(0, 0, {
					_key: Math.random(),
					position: 0,
					showClose: true,
					path: this.$route.path,
					fullPath: this.$route.fullPath,
					key: this.getKey(),
					name: this.$route.meta.name ? this.$route.meta.name(this) : '',
					tooltip: this.$route.meta.tooltip ? this.$route.meta.tooltip(this) : null,
					route: this.$route,
					editted: false,
					disabledClosing: false,
					breadcrumbs: [],
					remainingBreadcrumbs: [],
					hover: false,
					icon: this.$route.meta.icon,
					channelReady: false,
					showTooltip: false,
					showInList: true,
				});
			}

			this.tabs.forEach((tab, index) => {
				tab.position = index;
			});

			this.saveTabs();

		} else {
			this.tabs.push({
				_key: Math.random(),
				position: 0,
				showClose: true,
				path: this.$route.path,
				fullPath: this.$route.fullPath,
				key: this.getKey(),
				name: this.$route.meta.name ? this.$route.meta.name(this) : '',
				tooltip: this.$route.meta.tooltip ? this.$route.meta.tooltip(this) : null,
				route: this.$route,
				editted: false,
				disabledClosing: false,
				breadcrumbs: [],
				remainingBreadcrumbs: [],
				hover: false,
				icon: this.$route.meta.icon,
				channelReady: false,
				showInList: true,
			});
		}

		this.initBreadcrumbs();

		this.channel = this.$socket.on(
			'/lbadmin/tabs',
			{
				vueUid: this.$root.uid,
				browser: this.$root.browserName,
				roleUid: this.$user.role_uid,
				userName: this.$user.getName(),
				tabs: this.getTabsForWebsocket(),
			},
			this.onSocketMessage
		);

		this.tabSearchDebouncer = new this.$Debouncer(
			this,
			this.searching,
			null,
			300
		);

		this.$root.$emit('tabs-ready');
		this.$root.$emit('tabs.ready');
	},
	mounted() {
		this.$root.$listen('open-new-tab', this.openNewTab, this);
		this.$root.$listen('close-current-tab', this.closeCurrentTab, this);
		this.$root.$listen('route-meta-update', this.updateRouteMeta, this);
		this.$root.$listen('tab-editted', this.tabEditted, this);

		this.$root.$listen('tabs.open-new-tab', this.openNewTab, this);
		this.$root.$listen('tabs.close-current-tab', this.closeCurrentTab, this);
		this.$root.$listen('tabs.route-meta-update', this.updateRouteMeta, this);
		this.$root.$listen('tabs.tab-editted', this.tabEditted, this);
		this.$root.$listen('tabs.tab-edited', this.tabEditted, this);
		this.$root.$listen('tabs.disable-closing', this.tabDisableClosing, this);
		this.$root.$listen('tabs.reload', this.tabReload, this);
		this.$root.$listen('tabs.close-show', this.tabCloseShow, this);
		this.$root.$listen('tabs.close-hide', this.tabCloseHide, this);
		this.$root.$listen('tabs.register-open-path-watcher', this.registerOpenPathWatcher, this);
		this.$root.$listen('tabs.unregister-open-path-watcher', this.unregisterOpenPathWatcher, this);
		this.$root.$listen('socket.state-changed', this.onSocketStateChanged, this);

		this.unregisterBeforeEach = this.$router.beforeEach(this.beforeEach);
		this.unregisterAfterEach = this.$router.afterEach(this.afterEach);
		window.addEventListener('blur', this.onWindowBlur);
		window.addEventListener('resize', this.wrapTabs);
		window.addEventListener('beforeunload', this.beforeUnload);

		if (!_.isEmpty(this.activeTab)) {
			this.scrollToTab(this.activeTab);
		}
	},
	beforeDestroy() {
		window.removeEventListener('blur', this.onWindowBlur);
		window.removeEventListener('resize', this.wrapTabs);
		this.unregisterBeforeEach();
		this.unregisterAfterEach();
		if (this.channel) {
			this.channel.unsubscribe();
			this.channel = null;
		}
	},
	methods: {
		saveTabs() {
			if (this.$route.path === '/login') return;

			const tabs = this.tabs.map((tab) => ({
				path: tab.path,
				fullPath: tab.fullPath,
				key: tab.key,
				name: tab.name,
				tooltip: tab.tooltip,
				icon: tab.icon,
			}));
			localStorage.setItem('tabs', JSON.stringify(tabs));
		},
		onSocketStateChanged(state) {
			if (!state) {
				this.channelReady = false;
			}
		},
		registerOpenPathWatcher(data) {
			if (this.openRoutesWatchers[data.path] != null) {
				this.openRoutesWatchers[data.path] += 1;
			} else {
				this.openRoutesWatchers[data.path] = 1;
				if (this.channelReady) {
					this.channel.send({
						type: 'register-open-path-watcher',
						payload: { path: data.path },
					});
				}
			}
			if (data.vm && !data.vm._isDestroyed) {
				data.vm.$once('hook:beforeDestroy', () => this.unregisterOpenPathWatcher(data.path));
			}
		},
		unregisterOpenPathWatcher(path) {
			if (this.openRoutesWatchers[path]) {
				this.openRoutesWatchers[path] -= 1;
			}
			if (this.openRoutesWatchers[path] === 0 && this.channelReady) {
				this.channel.send({
					type: 'unregister-open-path-watcher',
					payload: { path },
				});
				delete this.openRoutesWatchers[path];
			}
		},
		onSocketMessage(message) {
			if (window._.isEmpty(message) || window._.isEmpty(message.type)) {
				console.error('[LbaTabs] socket on message missing type and payload', message);
			}
			const { type, payload } = message;

			if (type === 'registered') {
				// console.log('[LbaTabs] channel registered');
				window._.forEach(this.openRoutesWatchers, (value, key) => {
					if (value > 0) {
						// console.log('[LbaTabs] re-register path:', value, key);
						this.channel.send({
							type: 'register-open-path-watcher',
							payload: { path: key },
						});
					} else {
						// console.log('[LbaTabs] un-register path:', value, key);
						this.channel.send({
							type: 'unregister-open-path-watcher',
							payload: { path: key },
						});
						delete this.openRoutesWatchers[key];
					}
				});
				this.channelReady = true;

			} else if (type === 'open-path-watcher') {
				this.$root.$emit('tabs.open-path-watcher', payload);

			} else {
				console.error('[LbaTabs] unknown message type:', type);

			}
		},
		sendUpdatedUserTabs() {
			if (!this.timeout && this.channel) {
				this.timeout = true;
				setTimeout(() => {
					if (this.channel) {
						this.channel.send({
							type: 'update-user-tabs',
							payload: {
								vueUid: this.$root.uid,
								browser: this.$root.browserName,
								roleUid: this.$user.role_uid,
								userName: this.$user.getName(),
								tabs: this.getTabsForWebsocket(),
							},
						});
					}
					this.timeout = false;
				}, 5000);
			}
		},
		getTabsForWebsocket() {
			return this.tabs
				.filter((tab) => !window._.isEmpty(tab.route))
				.map((tab) => {
					const routes = tab.route.matched.map((matched) => {
						const { resolved } = this.$router.resolve({ name: matched.name, params: tab.route.params });
						return { path: resolved.path, pathsInfo: resolved.meta.pathsInfo };
					});
					const tabForWebsocket = {
						fullPath: tab.fullPath,
						routes,
						active: tab === this.activeTab,
					};
					return tabForWebsocket;
				});
		},
		/* print(info, route) {
			console.log(info, route.name);
			if (route && route.matched) {
				route.matched.forEach((match) => {
					const { resolved } = this.$router.resolve({ name: match.name, params: route.params });
					console.log(resolved.fullPath, JSON.stringify(resolved.meta.pathsInfo, null, 2));
				});
			} else {
				console.log('---empty---');
			}
		}, */
		tabCloseShow(fullPath = null) {
			if (!window._.isEmpty(fullPath)) {
				const tab = this.tabs.find((item) => item.fullPath === fullPath);
				tab.showClose = true;
			} else if (!window._.isEmpty(this.activeTab)) {
				this.activeTab.showClose = true;
			}
		},
		tabCloseHide(fullPath = null) {
			if (!window._.isEmpty(fullPath)) {
				const tab = this.tabs.find((item) => item.fullPath === fullPath);
				tab.showClose = false;
			} else if (!window._.isEmpty(this.activeTab)) {
				this.activeTab.showClose = false;
			}
		},
		setMatchedPath(params, additionalInfo = null) {
			return (match) => {
				const { resolved } = this.$router.resolve({ name: match.name, params });
				if (this.getPath(resolved, resolved.path)) return;
				const matchPathInfo = {
					path: resolved.path,
					edited: false,
					disabledClosing: false,
					viewError: null,
					viewErrorType: null,
					...additionalInfo,
				};

				if (window._.isEmpty(resolved.meta.pathsInfo)) {
					resolved.meta.pathsInfo = [matchPathInfo];
				} else {
					resolved.meta.pathsInfo.push(matchPathInfo);
				}
			};
		},
		setPath(route, additionalInfo = null) {
			// this.print('setPath before:', route);
			const routePathInfo = {
				path: route.path,
				edited: false,
				disabledClosing: false,
				viewError: null,
				viewErrorType: null,
				...additionalInfo,
			};

			if (window._.isEmpty(route.meta.pathsInfo)) {
				route.meta.pathsInfo = [routePathInfo];
				route.matched.forEach(this.setMatchedPath(route.params, additionalInfo));
			} else {
				route.matched.forEach(this.setMatchedPath(route.params, additionalInfo));
				if (this.getPath(route, route.path)) return;
				route.meta.pathsInfo.push(routePathInfo);
			}
			// this.print('setPath after:', route);
		},
		getPath(route, path, deep = false) {
			if (window._.isEmpty(route) || window._.isEmpty(path)) return null;

			if (!deep) {
				if (window._.isEmpty(window._.get(route, 'meta.pathsInfo'))) return;
				return route.meta.pathsInfo.find((item) => item.path === path);
			}

			let pathInfo = null;
			route.matched.find((match) => {
				const { resolved } = this.$router.resolve({ name: match.name, params: route.params });
				if (window._.isEmpty(window._.get(resolved, 'meta.pathsInfo'))) return;

				pathInfo = resolved.meta.pathsInfo.find((item) => item.path === path);
				return pathInfo;
			});
			return pathInfo;
		},
		setPathAndChildrenEdited(route, state = false, parentRoute = null) {
			if (window._.isEmpty(window._.get(route, 'meta.pathsInfo'))) return false;
			let path = route.path;

			if (route.fullPath == null) {
				if (parentRoute == null) return false;
				const newRoute = this.$router.resolve({ name: route.name, params: parentRoute.params });
				if (window._.isEmpty(window._.get(newRoute, 'resolved.path'))) return false;
				path = newRoute.resolved.path;
			}

			let found = false;
			route.meta.pathsInfo.forEach((item) => {
				if (!found && item.path === path) {
					found = true;
				}
				if (found) {
					item.edited = state;
				}
			});
		},
		setPathEdited(route, state = true, parentRoute = null) {
			// this.print('setPathEdited before:', route);
			let path = route.path;

			if (route.fullPath == null) {
				if (parentRoute == null) return;
				const newRoute = this.$router.resolve({ name: route.name, params: parentRoute.params });
				if (window._.isEmpty(window._.get(newRoute, 'resolved.path'))) return;
				path = newRoute.resolved.path;
			}

			const pathInfo = route.meta.pathsInfo.find((item) => item.path === path);
			pathInfo.edited = state;
			// this.print('setPathEdited after:', route);
		},
		setPathDisabledClosing(route, disableClosing = true, parentRoute = null) {
			// this.print('setPathDisabledClosing before:', route);
			let path = route.path;

			if (route.fullPath == null) {
				if (parentRoute == null) return;
				const newRoute = this.$router.resolve({ name: route.name, params: parentRoute.params });
				if (window._.isEmpty(window._.get(newRoute, 'resolved.path'))) return;
				path = newRoute.resolved.path;
			}

			const pathInfo = route.meta.pathsInfo.find((item) => item.path === path);
			pathInfo.disabledClosing = disableClosing;
			// this.print('setPathDisabledClosing after:', route);
		},
		setActiveTabViewError(error, errorType, path = null) {
			let pathInfo = null;

			if (!window._.isEmpty(path)) {
				pathInfo = this.getPath(this.activeTab.route, path);
			} else {
				pathInfo = this.getPath(this.activeTab.route, this.activeTab.path);
			}

			if (pathInfo) {
				pathInfo.viewError = error;
				pathInfo.viewErrorType = errorType;
				return pathInfo;
			}
			return null;
		},
		setTabViewError(path, error, errorType) {
			// check if path in active tab
			let pathInfo = this.getPath(this.activeTab.route, path, true);

			if (!pathInfo) {
				const tab = this.tabs.find((item) => item.path === path);

				if (tab) {
					pathInfo = this.getPath(tab.route, tab.path, true);
				}
			}

			if (pathInfo) {
				pathInfo.viewError = error;
				pathInfo.viewErrorType = errorType;
				return pathInfo;
			}
			return null;
		},
		cleanTabViewError(tab) {
			// this.print('cleanTabViewError before:', tab.route);
			if (window._.get(tab.route, 'meta.pathsInfo')) {
				// console.log('cleanTabViewError1:', tab.route.name, '\n', JSON.stringify(tab.route.meta.pathsInfo, null, 2));
				tab.route.meta.pathsInfo.forEach((item) => {
					item.viewError = null;
					item.viewErrorType = null;
				});
			}
			tab.route.matched.forEach((match) => {
				const { resolved } = this.$router.resolve({ name: match.name, params: tab.route.params });
				if (window._.isEmpty(window._.get(resolved, 'meta.pathsInfo'))) return;
				// console.log('cleanTabViewError2:', match.name, '\n', JSON.stringify(resolved.meta.pathsInfo, null, 2));
				resolved.meta.pathsInfo.forEach((item) => {
					item.viewError = null;
					item.viewErrorType = null;
				});
			});
			// this.print('cleanTabViewError after:', tab.route);
		},
		anyTabViewError(tab, viewErrorType = null) {
			const compareFunction = (item) => {
				if (viewErrorType) {
					return item.viewErrorType === viewErrorType;
				}
				return item.viewError || item.viewErrorType;
			};
			if (window._.get(tab.route, 'meta.pathsInfo')) {
				const errorItem = tab.route.meta.pathsInfo.find(compareFunction);
				if (errorItem) return true;
			}
			const errorItem = tab.route.matched.find((match) => {
				const { resolved } = this.$router.resolve({ name: match.name, params: tab.route.params });
				if (window._.isEmpty(window._.get(resolved, 'meta.pathsInfo'))) return;

				return resolved.meta.pathsInfo.find(compareFunction) != null;
			});
			return errorItem != null;
		},
		getActiveTabViewError(path) {
			const pathInfo = this.getPath(this.activeTab.route, path, true);
			if (pathInfo) return { viewError: pathInfo.viewError, viewErrorType: pathInfo.viewErrorType };
			return null;
		},
		getPathOrChildrenEdited(route, parentRoute = null) {
			if (window._.isEmpty(window._.get(route, 'meta.pathsInfo'))) return false;
			let path = route.path;

			if (route.fullPath == null) {
				if (parentRoute == null) return false;
				const newRoute = this.$router.resolve({ name: route.name, params: parentRoute.params });
				if (window._.isEmpty(window._.get(newRoute, 'resolved.path'))) return false;
				path = newRoute.resolved.path;
			}

			let found = false;
			return route.meta.pathsInfo.some((item) => {
				if (!found && item.path === path) {
					found = true;
				}
				if (found) {
					return item.edited;
				}
			});
		},
		getPathEdited(route, parentRoute = null) {
			if (window._.isEmpty(window._.get(route, 'meta.pathsInfo'))) return false;
			let path = route.path;

			if (route.fullPath == null) {
				if (parentRoute == null) return false;
				const newRoute = this.$router.resolve({ name: route.name, params: parentRoute.params });
				if (window._.isEmpty(window._.get(newRoute, 'resolved.path'))) return false;
				path = newRoute.resolved.path;
			}

			const pathInfo = route.meta.pathsInfo.find((item) => item.path === path);
			return !window._.isEmpty(pathInfo) && pathInfo.edited;
		},
		getPathOrChildrenDisabledClosing(route, parentRoute = null) {
			if (window._.isEmpty(window._.get(route, 'meta.pathsInfo'))) return false;
			let path = route.path;

			if (route.fullPath == null) {
				if (parentRoute == null) return false;
				const newRoute = this.$router.resolve({ name: route.name, params: parentRoute.params });
				if (window._.isEmpty(window._.get(newRoute, 'resolved.path'))) return false;
				path = newRoute.resolved.path;
			}

			let found = false;
			return route.meta.pathsInfo.some((item) => {
				if (!found && item.path === path) {
					found = true;
				}
				if (found) {
					return item.disabledClosing;
				}
			});
		},
		getPathDisabledClosing(route, parentRoute = null) {
			if (window._.isEmpty(window._.get(route, 'meta.pathsInfo'))) return false;
			let path = route.path;

			if (route.fullPath == null) {
				if (parentRoute == null) return false;
				const newRoute = this.$router.resolve({ name: route.name, params: parentRoute.params });
				if (window._.isEmpty(window._.get(newRoute, 'resolved.path'))) return false;
				path = newRoute.resolved.path;
			}

			const pathInfo = route.meta.pathsInfo.find((item) => item.path === path);
			return !window._.isEmpty(pathInfo) && pathInfo.disabledClosing;
		},
		removePath(route) {
			// this.print('removePath before:', route);
			if (!window._.isEmpty(route) && !window._.isEmpty(route.meta.pathsInfo)) {
				const pathInfoIndex = route.meta.pathsInfo.findIndex((item) => item.path === route.path);
				if (pathInfoIndex >= 0) {
					route.meta.pathsInfo.splice(pathInfoIndex, 1);
				}
			}
			// this.print('removePath after:', route);
		},
		showCloseButton(tab) {
			if (tab) {
				return tab.path !== '/' || (tab.path === '/' && this.tabs.length > 1);
			}
			return false;
		},
		onTabClick(fullPath) {
			if (window._.isEmpty(this.activeTab) || this.activeTab.fullPath !== fullPath) {
				this.$root.$routerWrap.push(fullPath);
			}
		},
		async scrollToTab(tab) {
			await this.$nextTick();
			const tabIndex = this.tabs.indexOf(tab);
			if (tabIndex != null && tabIndex >= 0) {
				const tabElement = document.querySelector(`[data-id="tab-${tabIndex}"]`);
				if (tabElement) {
					tabElement.scrollIntoView();
				}
			}
		},
		getPathName(path) {
			return this.$router.resolve(path).resolved.name;
		},
		isPathChild(parentPath, childPath) {
			// parent path with leading slash
			const newParentPath = parentPath[0] !== '/' ? `/${parentPath}` : parentPath;
			const parentPathName = this.getPathName(newParentPath);
			const childPathResolved = this.$router.resolve(childPath);
			const found = childPathResolved.route.matched.find(
				(match) => match.name === parentPathName
			);
			return found != null;
		},
		isNameChild(parentName, childName) {
			const childPathResolved = this.$router.resolve({ name: childName });
			const found = childPathResolved.route.matched.find(
				(match) => match.name === parentName
			);
			return found != null;
		},
		getIconIterator(items, path, name) {
			for (let index = 0; index < items.length; index += 1) {
				const item = items[index];

				if (item.action && this.isPathChild(item.action, path)) {
					return item.icon;
				}
				if (item.actionName && this.isNameChild(item.actionName, name)) {
					return item.icon;
				}

				if (item.items) {
					return this.getIconIterator(item.items, path, name);
				}
			}

			return null;
		},
		getIcon(tab) {
			const path = tab.fullPath;
			const name = _.get(tab, '$route.name');
			if (this.getPathName(path) === 'home') {
				return 'icon-home';
			}

			if (tab.icon) {
				return tab.icon;
			}

			let icon = null;

			// path starts with slash
			const root = path.split('/')[1];

			if (this.menuModules) {
				for (let i = 0; i < this.menuModules.length; i += 1) {
					const menuModule = this.menuModules[i];

					if (menuModule.items) {
						icon = this.getIconIterator(menuModule.items, path, name);
					}

					if (
						!icon && (
							root === menuModule.name ||
							(root === 'dashboard' && menuModule.name.startsWith('dashboard-'))
						)
					) {
						icon = menuModule.icon;
					}

					if (icon) break;
				}
			}

			return icon;
		},
		isPathFromMenu(path) {
			if (!_.isEmpty(path)) {
				// path without leading slash
				const adjustedPath = path[0] === '/' ? path.substring(1) : path;
				return this.isPathFromItems(this.menuModules, adjustedPath);
			}
			return false;
		},
		isNameFromMenu(name) {
			if (!_.isEmpty(name)) {
				return this.isNameFromItems(this.menuModules, name);
			}
			return false;
		},
		isPathFromItems(items, path) {
			for (let index = 0; index < items.length; index += 1) {
				const item = items[index];

				if (item.action === path) {
					return true;
				}

				if (item.items) {
					if (this.isPathFromItems(item.items, path)) {
						return true;
					}
				}
			}
			return false;
		},
		isNameFromItems(items, name) {
			for (let index = 0; index < items.length; index += 1) {
				const item = items[index];

				if (item.actionName === name) {
					return true;
				}

				if (item.items) {
					if (this.isNameFromItems(item.items, name)) {
						return true;
					}
				}
			}
			return false;
		},
		anyTabEdited() {
			return this.tabs.find((tab) => this.isTabEdited(tab)) != null;
		},
		wrapTabs() {
			const wrappedItems = this.$getWrappedItems('header-tab', 'new-header-tab');
			const headerTabs = document.getElementById('headerTabs');

			if (wrappedItems.length > 0) {
				headerTabs.classList.add('wrapped');
			} else {
				headerTabs.classList.remove('wrapped');
			}
		},
		beforeUnload(event) {
			if (process.env.NODE_ENV === 'production' && this.anyTabEdited()) {
				// that content wont be shown, but must be set to != null
				event.returnValue = '';
			}
		},
		submitTabClose() {
			this.$root.$emit('allow-closing', this.activeTab.path);
			// redirect from edited tab to different route
			if (this.redirect && this.redirectFrom && !this.redirectTab) {
				this.activeTab.editted = false;
				this.$route.matched.forEach((matched) => {
					// console.log('reset edited:', matched.name, this.redirectFrom.name, matched.name === this.redirectFrom.name);
					if (matched.name === this.redirectFrom.name) {
						this.setPathEdited(matched, false, this.$route);
					}
				});
				// this.print('after reset', this.$route);
				this.$routerWrap.push(this.redirect);
				this.redirect = null;
				this.redirectFrom = null;
			// replace current tab
			} else if (this.redirect && !this.redirectTab) {
				this.$route.matched.forEach((matched) => {
					this.setPathEdited(matched, false, this.$route);
				});
				this.activeTab.editted = false;
				this.setPathEdited(this.$route, false);
				this.$routerWrap.push(this.redirect);
				this.redirect = null;
			// redirect from route to edited tab
			} else if (this.redirect && this.redirectTab && this.redirectCleanFrom) {
				this.redirectTab.editted = false;
				this.setPathAndChildrenEdited(this.redirectCleanFrom, false);
				this.$routerWrap.push(this.redirect);
				this.redirect = null;
				this.redirectTab = null;
				this.redirectCleanFrom = null;
			// close some edited tab
			} else if (this.tabToClose) {
				this.closeTabDialogOpened = false;
				this.closeTab(this.tabToClose, false);
			// close current edited tab
			} else {
				this.activeTab.route.matched.forEach((matched) => {
					this.setPathEdited(matched, false, this.$route);
				});
				this.activeTab.editted = false;
				this.setPathEdited(this.$route, false);
				this.closeCurrentTab();
			}

			this.closeCloseTabDialog();
		},
		openCloseTabDialog() {
			this.$root.$emit('dialog-open', { name: 'closeTabDialog' });
			this.closeTabDialogOpened = true;
		},
		closeCloseTabDialog(emit = true) {
			if (emit) {
				this.$root.$emit('dialog-close', 'closeTabDialog');
				this.closeTabDialogOpened = false;
			}
		},
		tabEditted(data) {
			let state = true;
			let route = this.$route;
			let editedTab = this.activeTab;
			const resolvedRoutes = this.$route.matched.map((match) => {
				const { resolved } = this.$router.resolve({ name: match.name, params: this.$route.params });
				return resolved;
			});

			if (data != null) {
				if (data.constructor === Boolean) {
					state = data;
				} else if (data.constructor === Object) {
					const allowedKeys = ['state', 'path'];
					const invalidKeys = Object.keys(data).filter((key) => !allowedKeys.includes(key));
					if (!_.isEmpty(invalidKeys)) {
						console.error('[LbaTabs](tabEditted) invalid data keys:', invalidKeys, 'valid keys are:', allowedKeys);
					}

					if (data.state != null) {
						state = data.state;
					}
					// path is not same
					if (!window._.isEmpty(data.path) && route.path !== data.path) {
						// does path exist as some children?
						route = resolvedRoutes.find((resolvedRoute) => resolvedRoute.path === data.path);

						// path not found in current tab, try to find path in other tabs
						if (window._.isEmpty(route)) {
							editedTab = this.tabs.find((tab) => {
								// already checked as this.$route
								if (tab === this.activeTab) return;
								const tabResolvedRoutes = tab.route.matched.map((match) => {
									const { resolved } = this.$router.resolve({ name: match.name, params: tab.route.params });
									return resolved;
								});
								route = tabResolvedRoutes.find((tabResolvedRoute) => tabResolvedRoute.path === data.path);
								return !window._.isEmpty(route);
							});
							if (window._.isEmpty(editedTab) || window._.isEmpty(route)) {
								console.error('[LbaTabs](tabEditted) tab not found:', data);
								return;
							}
						}
					}
				}
			}

			if (_.isEmpty(editedTab)) {
				console.error('[LbaTabs](tabEditted) tab not found:', data);
				return;
			}

			if (editedTab.route.path === route.path) {
				editedTab.editted = state;
			}

			this.setPathEdited(route, state, null);

			if (state) {
				this.$root.$emit('prevent-closing', editedTab.path);
			} else {
				this.$root.$emit('allow-closing', editedTab.path);
			}

			this.$forceUpdate();
			this.sendUpdatedUserTabs();
		},
		tabDisableClosing(data) {
			let state = false;
			let route = this.$route;
			let disabledClosingTab = this.activeTab;
			const resolvedRoutes = this.$route.matched.map((match) => {
				const { resolved } = this.$router.resolve({ name: match.name, params: this.$route.params });
				return resolved;
			});

			if (data != null) {
				if (data.constructor === Boolean) {
					state = data;
				} else if (data.constructor === Object) {
					if (data.state != null) {
						state = data.state;
					}
					// path is not same
					if (!window._.isEmpty(data.path) && route.path !== data.path) {
						// does path exist as some children?
						route = resolvedRoutes.find((resolvedRoute) => resolvedRoute.path === data.path);

						// path not found in current tab, try to find path in other tabs
						if (window._.isEmpty(route)) {
							disabledClosingTab = this.tabs.find((tab) => {
								// already checked as this.$route
								if (tab === this.activeTab) return;
								const tabResolvedRoutes = tab.route.matched.map((match) => {
									const { resolved } = this.$router.resolve({ name: match.name, params: tab.route.params });
									return resolved;
								});
								route = tabResolvedRoutes.find((tabResolvedRoute) => tabResolvedRoute.path === data.path);
								return !window._.isEmpty(route);
							});
							if (window._.isEmpty(disabledClosingTab) || window._.isEmpty(route)) {
								console.warn('[LbaTabs](tabDisableClosing) tab not found');
							}
						}
					}
				}
			}

			if (disabledClosingTab.route.path === route.path) {
				disabledClosingTab.disabledClosing = state;
				disabledClosingTab._key = Math.random();
			}

			this.setPathDisabledClosing(route, state, null);

			if (state) {
				this.$root.$emit('prevent-closing', disabledClosingTab.path);
			} else {
				this.$root.$emit('allow-closing', disabledClosingTab.path);
			}

			this.$forceUpdate();
			this.sendUpdatedUserTabs();
		},
		openReloadTabDialog() {
			this.$root.$emit('dialog-open', { name: 'reloadTabDialog' });
		},
		closeReloadTabDialog() {
			this.$root.$emit('dialog-close', 'reloadTabDialog');
		},
		submitTabReload() {
			this.activeTab.route.matched.forEach((matched) => {
				this.setPathEdited(matched, false, this.$route);
			});
			this.activeTab.editted = false;
			this.setPathEdited(this.$route, false);
			this.closeReloadTabDialog();
			this.tabReload();
		},
		async tabReload(force = false) {
			if (this.isTabClosingDisabled(this.activeTab)) return;
			if (this.isTabEdited(this.activeTab)) {
				if (force != null && force.constructor !== Boolean) force = false;
				if (force === true) {
					this.activeTab.route.matched.forEach((matched) => {
						this.setPathEdited(matched, false, this.$route);
					});
					this.activeTab.editted = false;
					this.setPathEdited(this.$route, false);
				} else {
					return this.openReloadTabDialog();
				}
			}

			this.cleanTabViewError(this.activeTab);
			const paths = this.activeTab.route.matched
				.map((match) => {
					const route = this.$router.resolve({ name: match.name, params: this.activeTab.route.params });
					return _.get(route, 'resolved.path');
				})
				.filter((path) => !_.isEmpty(path));
			this.$root.$emit('keep-alive.remove-caches', paths);
			// console.log('[LbaTabs](tabReload) remove path:', path);
			await this.$nextTick(); // wait for remove cache to apply

			this.$emit('reloading-change', true);
			// await this.$nextTick(); // wait for change of key, so it reloads tab

			// this.$emit('reloading-change', false); // change key back to normal
		},
		updateRouteMeta() {
			if (this.activeTab) {
				this.activeTab.name = this.$route.meta.name(this);
				this.activeTab.tooltip = this.$route.meta.tooltip ? this.$route.meta.tooltip(this) : null;
				if (this.$route.meta.icon) {
					this.activeTab.icon = this.$route.meta.icon;
				}
			}
			this.$forceUpdate();
		},
		getKey(route = this.$route) {
			if (route.matched.length < 2) {
				return route.path;
			}

			const matched = route.matched[0];
			const resolved = this.$router.resolve({ name: matched.name, params: route.params });
			return resolved.route.path;
		},
		beforeEach(to, from, next) {
			const toKey = this.getKey(to);
			const toTab = this.getTab(toKey);
			const createTabEnable = (
				to.params.openNewTab || (
					(
						this.isTabEdited(this.activeTab) ||
						this.isTabClosingDisabled(this.activeTab)
					) && (
						this.isPathFromMenu(to.path) ||
						this.isNameFromMenu(to.name)
					)
				)
			);
			/* console.log(
				'create tab enabled:', createTabEnable,
				'params open new tab:', to.params.openNewTab,
				'edited/disabled and path from menu:', (
					this.isTabEdited(this.activeTab) ||
					this.isTabClosingDisabled(this.activeTab)
				) && (
					this.isPathFromMenu(to.path) ||
					this.isNameFromMenu(to.name)
				)
			); */

			const toTabExist = !window._.isEmpty(toTab) && !window._.isEmpty(toTab.route) && !window._.isEmpty(toTab.route.matched);
			let sameTab = !window._.isEmpty(toTab);
			let toIsChild = false;
			let toTabDifferentNestedParams = false;
			let toTabNested = null;
			let toTabNestedEdited = false;
			let toTabNestedDisabledClosing = false;
			let foundInFrom = false;
			let foundInTo = false;
			if (toTabExist) {
				const fromResolvedRoutes = from.matched.map((match) => {
					const { resolved } = this.$router.resolve({ name: match.name, params: from.params });
					return resolved;
				});
				const toResolvedRoutes = toTab.route.matched.map((match) => {
					const { resolved } = this.$router.resolve({ name: match.name, params: toTab.route.params });
					return resolved;
				});
				foundInFrom = fromResolvedRoutes.some((fromResolvedRoute) => fromResolvedRoute.path === to.path);
				foundInTo = toResolvedRoutes.some((toResolvedRoute) => toResolvedRoute.path === from.path);
				toTabDifferentNestedParams = toResolvedRoutes.every((toResolvedRoute) => toResolvedRoute.path !== to.path);
				if (toTabDifferentNestedParams) {
					// check if replaced route is edited/disabled
					toTabNested = toResolvedRoutes.find((item) => item.name === to.name);
					toTabNestedEdited = this.getPathOrChildrenEdited(toTabNested);
					toTabNestedDisabledClosing = this.getPathOrChildrenDisabledClosing(toTabNested);
				}
				toIsChild = !foundInFrom && foundInTo;
				sameTab = foundInFrom || foundInTo;
			}

			/* console.log(
				'exists activeTab:', !window._.isEmpty(this.activeTab),
				'\nexists toTab:', !window._.isEmpty(toTab),
				'\ntoKey:', toKey,
				'\nactiveTab === toTab:', this.activeTab === toTab,
				'\nsameTab:', sameTab,
				'\ntoTabDifferentNestedParams:', toTabDifferentNestedParams,
				'\ntoTabNestedEdited:', toTabNestedEdited,
				'\ntoTabNestedDisabledClosing:', toTabNestedDisabledClosing,
				'\ntoIsChild:', toIsChild,
				'\nto-edited:', this.getPathEdited(to),
				'\nto-disabled:', this.getPathDisabledClosing(to),
				'\nfrom-edited:', this.getPathEdited(from),
				'\nfrom-disabled:', this.getPathDisabledClosing(from),
				'\nfoundInTo:', foundInTo,
				'\nfrom:', from && from.path,
				'\nto:', to && to.path
			); */

			// redirect from edited/disabled tab to different tab (replace tab)
			if (
				!sameTab &&
				!createTabEnable &&
				window._.isEmpty(toTab) &&
				this.activeTab &&
				(this.isTabEdited(this.activeTab) || this.isTabClosingDisabled(this.activeTab))
			) {
				if (this.isTabClosingDisabled(this.activeTab)) {
					// console.log('redirect from disabled');
					next(new Error('[LbaTabs] from page disabledClosing'));
					return;
				}
				// console.log('redirect from edited');
				this.redirect = to;
				this.openCloseTabDialog();
				next(new Error('[LbaTabs] from page edited'));
			// redirect from edited/disabled child to parent (closing detail)
			} else if (
				sameTab &&
				toTabExist &&
				!toIsChild &&
				from.path !== to.path &&
				(this.getPathEdited(from) || this.getPathDisabledClosing(from))
			) {
				if (this.getPathDisabledClosing(from)) {
					// console.log('redirect from disabled child');
					next(new Error('[LbaTabs] from disabledClosing child'));
					return;
				}
				// console.log('redirect from edited child');
				this.redirect = to;
				this.redirectFrom = from;
				this.openCloseTabDialog();
				next(new Error('[LbaTabs] from edited child'));
			// redirect to existing tab with nested edited route but changing params
			} else if (
				toTabDifferentNestedParams &&
				(toTabNestedEdited || toTabNestedDisabledClosing)
			) {
				if (toTabNestedDisabledClosing) {
					// console.log('redirect to disabled child with different params');
					next(new Error('[LbaTabs] to disabledClosing child with different params'));
					return;
				}
				// console.log('redirect to edited child with different params');
				this.redirect = to;
				this.redirectTab = toTab;
				this.redirectCleanFrom = toTabNested;
				this.openCloseTabDialog();
				next(new Error('[LbaTabs] to edited child with different params'));
			// no edited tab
			} else {
				// tab does not exist
				if (!toTab) {
					// console.debug('[LbaTabs] tab does not exist');
					// create new tab
					if (createTabEnable) {
						// console.debug('[LbaTabs] create new tab');
						this.setPath(to, { ts: new Date().toString() });
						const activeTab = {
							_key: Math.random(),
							position: this.tabs.length,
							showClose: true,
							path: to.path,
							fullPath: to.fullPath,
							name: to.meta.name(this),
							tooltip: to.meta.tooltip ? to.meta.tooltip(this) : null,
							key: toKey,
							route: to,
							editted: false,
							disabledClosing: false,
							breadcrumbs: [],
							remainingBreadcrumbs: [],
							hover: false,
							icon: null,
							showTooltip: false,
							showInList: true,
						};

						if (this.isPathFromMenu(to.path) || this.isNameFromMenu(to.name)) {
							this.tabs.push(activeTab);
						} else {
							const index = this.tabs.indexOf(this.activeTab);
							activeTab.position = this.activeTab.position + 1;
							this.tabs.splice(index + 1, 0, activeTab);
							this.repositionTabs();
						}
						this.scrollToTab(activeTab);

					// replace current tab
					} else {
						// console.debug('[LbaTabs] replace current tab');
						const currentPosition = this.activeTab ? this.activeTab.position : 0;
						const index = this.activeTabIndex >= 0 ? this.activeTabIndex : 0;

						if (!to.path.startsWith('/login')) {
							this.removePath(from);
							this.setPath(to, { ts: new Date().toString() });
							const activeTab = {
								_key: Math.random(),
								position: currentPosition,
								showClose: true,
								path: to.path,
								fullPath: to.fullPath,
								name: to.meta.name(this),
								tooltip: to.meta.tooltip ? to.meta.tooltip(this) : null,
								key: toKey,
								route: to,
								editted: false,
								disabledClosing: false,
								breadcrumbs: [],
								remainingBreadcrumbs: [],
								icon: null,
								showTooltip: false,
								showInList: true,
							};
							to.meta.ts = new Date();
							this.removePath(from);
							this.tabs.splice(index, 1, activeTab);
							this.removeCache = this.getKey(from);
							this.scrollToTab(activeTab);
						}
					}
				// tab exists
				} else {
					// console.debug('[LbaTabs] tab exists');
					if (this.$refs.closeTabDialog.visible) {
						this.closeCloseTabDialog();
					}

					const currentPosition = this.activeTab ? this.activeTab.position : 0;

					// going from child to parent
					if (this.activeTab === toTab && !toIsChild && from.path !== to.path) {
						// console.debug('[LbaTabs] remove path:', from && from.path);
						this.removePath(from);
					}
					// set ts only if creating route
					if (!foundInTo) {
						this.setPath(to, { ts: new Date().toString() });
					} else {
						this.setPath(to);
					}

					/* if (window._.isEmpty(toTab.route)) {
						toTab.name = to.meta.name ? to.meta.name(this) : '';
						toTab.tooltip = to.meta.tooltip ? to.meta.tooltip(this) : null;
						toTab.icon = to.meta.icon;
					} */
					toTab.path = to.path;
					toTab.fullPath = to.fullPath;
					toTab.route = to;
					toTab.editted = this.getPathEdited(to);
					toTab.disabledClosing = this.getPathDisabledClosing(to);

					/* const index = this.getTabIndex(activeTab.key);
					this.tabs.splice(index, 1, activeTab); */

					if (this.removeTab) {
						this.removeTab = false;
						const removeTabKey = this.getKey(from);
						this.removePath(from);
						this.removeCache = removeTabKey;
						const removeTabIndex = this.getTabIndex(removeTabKey);
						this.tabs.splice(removeTabIndex, 1);
						this.repositionTabs(currentPosition);
					}

					this.scrollToTab(toTab);
				}

				next();
			}
		},
		async afterEach() {
			if (this.removeCache) {
				setTimeout(() => {
					if (this.keepAlive) {
						// console.log('[LbaTabs](afterEach) remove cache:', this.removeCache);
						this.keepAlive.$emit('removeCache', this.removeCache);
					}
					this.removeCache = null;
					this.wrapTabs();
				}, 100);
			}
			this.initBreadcrumbs();
			this.updateRouteMeta();
			this.sendUpdatedUserTabs();
			this.saveTabs();
		},
		openNewTab(args) {
			if (_.isEmpty(args)) {
				throw new Error('[LbaTabs](openNewTab) missing args');
			}
			let pushParams = args;
			if (args.constructor === String) {
				const { resolved } = this.$router.resolve(args);
				pushParams = { name: resolved.name, params: { ...resolved.params, openNewTab: true }, query: resolved.query };
			} else if (args.constructor === Object) {
				pushParams = { ...args, params: { ..._.get(args, 'params'), openNewTab: true } };
			} else {
				throw new Error('[LbaTabs](openNewTab) unknown args type: ', typeof args, 'allowed types are String and Object');
			}
			// console.debug('[LbaTabs](openNewTab) params:', pushParams);
			this.$routerWrap.push(pushParams);
		},
		openHomeTab() {
			this.$routerWrap.push({ name: 'home', params: { openNewTab: true } });
		},
		closeTab(tab, tabInView = true) {
			if (this.isTabClosingDisabled(tab)) return;

			if (this.isTabEdited(tab) && tabInView) {
				this.tabToClose = tab;
				this.$root.$emit('dialog-open', { name: 'closeTabDialog' });
				this.closeTabDialogOpened = true;

			} else if (this.closeTabDialogOpened === false) {
				// otherwise, it will stay in it and on next load it will say, it is edited
				this.tabToClose = null;
				const index = this.getTabIndex(tab.key);
				this.tabs.splice(index, 1);
				// console.log('[LbaTabs](closeTab) remove cache:', tab.key);
				this.keepAlive.$emit('removeCache', tab.key);
				this.repositionTabs(tab.position);
				this.removePath(tab.route);
				this.sendUpdatedUserTabs();
				this.saveTabs();
			}
		},
		isTabEdited(tab) {
			return (
				tab && (
					tab.editted || (
						!window._.isEmpty(tab.route) && (
							this.getPathEdited(tab.route) ||
							tab.route.matched.find((matched) => this.getPathEdited(matched, tab.route)) != null
						)
					)
				)
			);
		},
		isTabClosingDisabled(tab) {
			return (
				tab && (
					tab.disabledClosing || (
						!window._.isEmpty(tab.route) && (
							this.getPathDisabledClosing(tab.route) ||
							tab.route.matched.find((matched) => this.getPathDisabledClosing(matched, tab.route)) != null
						)
					)
				)
			);
		},
		closeCurrentTab(force = false) {
			if (this.isTabClosingDisabled(this.activeTab)) return;
			if (force != null && force.constructor !== Boolean) force = false;
			if (!force && this.isTabEdited(this.activeTab)) {
				return this.openCloseTabDialog();
			} else if (force) {
				this.activeTab.editted = false;
				this.activeTab.route.matched.forEach((matched) => {
					this.setPathEdited(matched, false, this.$route);
				});
				this.setPathEdited(this.$route, false);
			}

			let redirectToPath = '/';
			const currentPosition = this.activeTab.position;

			// more than 1 tab open
			if (this.tabs.length > 1) {
				this.removeTab = true;
				let tab = null;

				// not last tab -> get next tab
				if (currentPosition === 0) { // if (currentPosition !== this.tabs.length - 1) {
					tab = this.getTab(currentPosition + 1, 'position');
					// last tab -> get previous tab
				} else {
					tab = this.getTab(currentPosition - 1, 'position');
				}

				redirectToPath = tab.fullPath;
			} else if (this.activeTab.path === redirectToPath) {
				return;
			}

			this.removePath(this.$route);
			this.$routerWrap.push(redirectToPath);
		},
		repositionTabs(position) {
			for (let i = 0; i < this.tabs.length; i += 1) {
				if (position != null) {
					if (this.tabs[i].position > position) {
						this.tabs[i].position -= 1;
					}
				} else {
					this.tabs[i].position = i;
				}
			}
		},
		getTab(value, getBy = 'key') {
			for (let i = 0; i < this.tabs.length; i += 1) {
				if (this.tabs[i][getBy] === value) {
					return this.tabs[i];
				}
			}
			return null;
		},
		getTabIndex(value, getBy = 'key') {
			for (let i = 0; i < this.tabs.length; i += 1) {
				if (this.tabs[i][getBy] === value) {
					return i;
				}
			}
			return -1;
		},
		onWindowBlur() {
			if (this.lastMouseOverTimeout) {
				clearTimeout(this.lastMouseOverTimeout);
			}
			this.tabs.forEach((item) => {
				item.showTooltip = false;
			});
			// console.log('blur');
		},
		mouseOverTab(tab, state) {
			if (this.lastMouseOverTimeout) {
				clearTimeout(this.lastMouseOverTimeout);
			}

			if (!tab && !state) {
				this.tabs.forEach((item) => {
					item.showTooltip = false;
				});
				// console.log(null, state);
				return;
			}

			if (state) {
				// console.log(tab.fullPath, state, 1);
				this.lastMouseOverTimeout = setTimeout(() => {
					this.tabs.forEach((item) => {
						item.showTooltip = false;
					});
					// console.log(tab.fullPath, state, 2);
					tab.showTooltip = state;
				}, 700);
			} else {
				// console.log(tab.fullPath, state);
				tab.showTooltip = state;
			}
		},
		mouseOverClose(index, state) {
			if (this.tabs[index] && this.tabs[index].hover !== state) {
				this.$set(this.tabs[index], 'hover', state);
				this.tabs[index]._key = Math.random();
			}
		},
		tabTooltipClasses(tab) {
			const classes = ['vue-tooltip-theme'];
			if (this.showCircleDisabledClosing(tab)) {
				classes.push('offsetTop54');
			} else {
				classes.push('offsetTop36');
			}
			return classes;
		},
		tabTooltipContent(tab) {
			const content = tab.tooltip || tab.name;
			if (this.showCircleDisabledClosing(tab)) {
				return this.$t('closingTabDisabled', { attribute: content });
			}
			return content;
		},
		showCircleEdited(tab) {
			// console.log('show edited', tab.path, !tab.hover && this.getPathEdited(tab.route));
			return !tab.hover && this.getPathEdited(tab.route);
		},
		showCircleDisabledClosing(tab) {
			// console.log('show disabled:', tab.path, this.getPathDisabledClosing(tab.route));
			return this.getPathDisabledClosing(tab.route);
		},
		showCloseCross(tab) {
			return (tab.hover || !this.getPathEdited(tab.route)) && tab.showClose;
		},
		getTabCy(tab) {
			return `tabs__tab${tab.path.replaceAll('/', '__').replaceAll('+', '_')}`;
		},
		searching() {
			if (_.isEmpty(this.tabSearchValue)) {
				this.tabClearSearch();
				return;
			}
			const searchValue = this.tabSearchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			const filterRegexp = new RegExp(searchValue.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

			_.forEach(this.tabs, (tab) => {
				const tabName = (tab.tooltip || tab.name).normalize('NFD').replace(/[\u0300-\u036f]/g, '');
				tab.showInList = (filterRegexp.test(tabName));
			});
		},
		tabClearSearch() {
			this.tabSearchValue = '';
			_.forEach(this.tabs, (tab) => {
				tab.showInList = true;
			});
		},
	},
};
