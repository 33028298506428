<script>
export default {
	inserted(element, binding) {
		element.addEventListener('click', (event) => {
			// dont follow this link
			event.preventDefault();
			const scrollToElement = document.getElementById(binding.value);

			if (scrollToElement) {
				scrollToElement.scrollIntoView();
			}
		});
	},
};
</script>
