<template>
	<div>
		<s :class="sClass" :style="sPeriodStyle">
			<small>{{ $t('period') }}</small>
			<select
				:data-cy="`${currentComponentId}__period__select`"
				name=""
				id=""
				v-model="internalSettings.t_period"
				@change="dirty = true">
				<option :data-cy="`${currentComponentId}__period__select__optionNull`" :value="null">-- {{ $t('blank') }} --</option>
				<option :data-cy="`${currentComponentId}__period__select__optionToday`" value="today">
					{{ $t('today') }}
				</option>
				<option :data-cy="`${currentComponentId}__period__select__optionYesterday`" value="yesterday">
					{{ $t('yesterday') }}
				</option>
				<option :data-cy="`${currentComponentId}__period__select__optionLastHour`" value="lastHour">
					{{ $t('lastHour') }}
				</option>
				<option :data-cy="`${currentComponentId}__period__select__optionLastDay`" value="lastDay">
					{{ $t('lastDay') }}
				</option>
				<option :data-cy="`${currentComponentId}__period__select__optionLastWeek`" value="lastWeek">
					{{ $t('lastWeek') }}
				</option>
				<option :data-cy="`${currentComponentId}__period__select__optionLastMonth`" value="lastMonth">
					{{ $t('lastMonth') }}
				</option>
				<option :data-cy="`${currentComponentId}__period__select__optionPreviousWeek`" value="previousWeek">
					{{ $t('previousWeek') }}
				</option>
				<option :data-cy="`${currentComponentId}__period__select__optionPreviousMonth`" value="previousMonth">
					{{ $t('previousMonth') }}
				</option>
				<option :data-cy="`${currentComponentId}__period__select__optionCustom`" value="custom">
					{{ $t('custom') }}
				</option>
			</select>
		</s>

		<template v-if="internalSettings.t_period === 'custom'">
			<s :class="sClass" :style="sFromStyle">
				<small>{{ $t('periodFrom') }}</small>
				<lba-datepicker
					:parentComponentId="currentComponentId"
					componentId="periodFrom"
					v-model="internalSettings.t_from"
					v-on:input="dirty = true"
					:mode="'date'"
					:format="'YYYY-MM-DD'"
					style="width: 100%;"
				></lba-datepicker>
			</s>
			<br v-if="!inline" />
			<s :class="sClass" :style="sToStyle">
				<small>{{ $t('periodTo') }}</small>
				<lba-datepicker
					:parentComponentId="currentComponentId"
					componentId="periodTo"
					v-model="internalSettings.t_to"
					v-on:input="dirty = true"
					:mode="'date'"
					:format="'YYYY-MM-DD'"
					style="width: 100%;"
				></lba-datepicker>
			</s>
		</template>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	name: 'PeriodPicker',
	mixins: [ComponentIdentifier],
	props: {
		settings: {
			type: Object,
		},

		period: {
			type: [String, Date, Number],
		},
		from: {
			type: [String, Date, Number],
		},
		to: {
			type: [String, Date, Number],
		},

		sPeriodStyle: {
			type: String,
		},
		sFromStyle: {
			type: String,
		},
		sToStyle: {
			type: String,
		},
		sClass: [String, Object, Array],
		inline: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data() {
		return {
			dirty: false,

			areSettingsEmpty: true,
			internalSettings: {
				t_from: this.from,
				t_to: this.to,
				t_period: this.period,
			},
		};
	},
	computed: {
		t_to() {
			return this.internalSettings.t_to;
		},
		t_from() {
			return this.internalSettings.t_from;
		},
		t_period() {
			return this.internalSettings.t_period;
		},
	},
	watch: {
		// watch props
		period() {
			this.internalSettings.t_period = this.period;
			this.changeDate();
		},
		from() {
			this.internalSettings.t_from = this.from;
			this.changeDate();
		},
		to() {
			this.internalSettings.t_to = this.to;
			this.changeDate();
		},
		settings() {
			this.areSettingsEmpty = _.isEmpty(this.settings);

			if (!this.areSettingsEmpty) {
				this.internalSettings = this.settings;
				this.changeDate();
			}
		},

		t_period() {
			this.changeDate();
		},
		t_from() {
			this.changeDate();
		},
		t_to() {
			this.changeDate();
		},

		dirty() {
			this.$emit('change');
			this.dirty = false;
		},
	},
	filters: {
		date(date) {
			return moment(date).format('YYYY-MM-DD HH:mm');
		},
	},
	methods: {
		changeDate() {
			if (this.internalSettings.t_period === 'custom') {
				if (this.internalSettings.t_from) {
					this.internalSettings.t_from = this.$options.filters.date(this.internalSettings.t_from);
				} else {
					this.internalSettings.t_from = this.setTime('low');
				}

				if (this.internalSettings.t_to) {
					this.internalSettings.t_to = this.setTime('high', this.internalSettings.t_to);
				} else {
					this.internalSettings.t_to = this.setTime('high');
				}

				if (this.areSettingsEmpty) {
					this.$emit('update:from', this.internalSettings.t_from);
					this.$emit('update:to', this.internalSettings.t_to);
				}
			}

			if (this.areSettingsEmpty) {
				this.$emit('update:period', this.internalSettings.t_period);
			}
		},
		setTime(direction, time = new Date()) {
			let hours = 23;
			let minutes = 59;

			if (direction === 'low') {
				hours = 0;
				minutes = 0;
			}

			time = new Date(time);
			time.setHours(hours);
			time.setMinutes(minutes);
			return this.$options.filters.date(time);
		},
	},
};
</script>
