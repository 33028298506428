<template>
	<div id="content-options" data-cy="content-options">
		<div
			id="options"
			tabindex="0"
			class="optionsPin collapsed"
			:class="{
				active: $route.matched.length > match,
				pinned,
				smsgwOptions: true,
			}"
			data-cy="content-options__options"
			v-lba-right-panel-resizer="{
				panel_width: width,
				panel_pinned: pinned
			}"
		>
			<span id="splitter" class="userSelectNone" data-cy="content-options__options__splitter">
				<i id="splitterIcon" class="icon24 icon-splitter"></i>
			</span>
			<div class="options-panel-controls">
				<controls
					parentComponentId="content-options__options"
					btnPin
					:btnCancel="$route.matched.length > match"
					:btnPrev="(!getAnyError() && buttons.btnPrev) || null"
					:btnNext="(!getAnyError() && buttons.btnNext) || null"
					:btnShowInCard="!getAnyError() && buttons.btnShowInCard"
				>
					<template v-slot:controlsBefore>
						<slot v-if="!getAnyError()" name="controlsTitle"></slot>
					</template>
				</controls>
			</div>
			<div v-if="!getAnyError()" class="options-controls">
				<slot name="controls" parentComponentId="content-options__options__slotControls"></slot>
			</div>
			<div class="optionsContent">
				<router-view
					v-show="!getAnyError()"
					ref="view"
					:key="routeKey"
					:params="viewParams"
					@view-not-found="onError($event, 'notFound')"
					@view-permission-denied="onError($event, 'permissionDenied')"
					@view-server-error="onError($event, 'serverError')"
					@view-record-deleted="onError($event, 'recordDeleted')"
					@view-missing-parameter="onError($event, 'missingParameter')"
					@view-wrong-parameter="onError($event, 'wrongParameter')"
				></router-view>
				<div v-if="getAnyError()" class="error-wrap" data-cy="content-options__options__anyError">
					<h1 v-if="getErrorType() === 'notFound'" data-cy="content-options__options__viewNotFound">
						{{ $t('viewNotFound') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'permissionDenied'" data-cy="content-options__options__viewPermissionDenied">
						{{ $t('viewPermissionDenied') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'serverError'" data-cy="content-options__options__viewServerError">
						{{ $t('viewServerError') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'recordDeleted'" data-cy="content-options__options__viewRecordDeleted">
						{{ getError() || $t('viewRecordDeleted') }}
					</h1>
					<h1 v-else-if="getErrorType() === 'missingParameter'" data-cy="content-options__options__viewMissingParameter">
						{{ $t('viewMissingParameter', { params: getError() }) }}
					</h1>
					<h1 v-else-if="getErrorType() === 'wrongParameter'" data-cy="content-options__options__viewWrongParameter">
						{{ $t('viewWrongParameter', { params: getError() }) }}
					</h1>
				</div>
			</div>
		</div>

		<!--
			#fakeOptions - vytvari odsazeni #contentu zprava
			skutecne #options je pozicovano absolutne
		-->
		<div id="fakeOptions"></div>
		<div id="content" data-cy="content-options__content">
			<slot name="content" parentComponentId="content-options__slotContent"></slot>
		</div>
	</div>
</template>

<script>
import RouteKey from '../mixins/RouteKey';
import TabErrors from '../mixins/TabErrors';

export default {
	mixins: [RouteKey, TabErrors],
	props: {
		match: {
			type: Number,
			required: false,
			default: 1,
		},
		buttons: {
			type: Object,
			default: () => {
				return {};
			},
		},
		viewParams: Object,
	},
	data() {
		return {
			pinned: false,
			width: null,
			settings: null,
			componentName: 'OptionsPanel',
		};
	},
	computed: {
		panelWidth() {
			return this.$route.matched.length;
		},
	},
	activated() {
		this.setRightPanel();
	},
	created() {
		this.setRightPanel();
		this.$root.$listen('content.pin', this.contentPin, this);
		this.$root.$listen('content.unpin', this.contentUnpin, this);
		this.$root.$listen('content.close-detail', this.closeDetail, this);
		this.$root.$listen('right-panel-resized', this.updateSettings, this);
	},
	methods: {
		closeDetail() {
			if (this.$refs.view && !this.pinned) {
				this.$routerWrap.push(this.$route.matched[this.$route.matched.length - 2]);
			}
		},
		contentPin() {
			this.pinned = true;
			this.updateSettings();
		},
		contentUnpin() {
			this.pinned = false;
			this.updateSettings();
		},
		routeKeyChanged() {
			this.$root.$emit('content-options-route-key-change', this.routeKey);
		},
		setRightPanel() {
			this.pinned = this.$pageSettings.get(this.componentName, 'pinned');
			this.width = this.$pageSettings.get(this.componentName, 'width');

			if (this.pinned === true) {
				const optionsElement = document.getElementById('options');

				if (optionsElement) {
					const minWidth = parseInt(window
						.getComputedStyle(optionsElement)
						.getPropertyValue('min-width')
					);
					const newWidth = Math.max(this.width, minWidth);
					optionsElement.setAttribute('style', `width: ${newWidth}px;`);
					const sibling = optionsElement.nextSibling;
					(sibling) && optionsElement.nextSibling.setAttribute('style', `width: ${newWidth}px;`);
				}

			} else {
				this.pinned = false;
				this.width = null;
			}
		},
		updateSettings(width) {
			if (width) {
				this.width = width;
				this.$pageSettings.onPageSettingsChange({
					componentName: this.componentName,
					name: 'width',
					data: this.width,
				});
			} else {
				this.$pageSettings.onPageSettingsChange({
					componentName: this.componentName,
					name: 'pinned',
					data: this.pinned,
				});
			}
		},
	},
};
</script>
