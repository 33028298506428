export default class {
	constructor($http) {
		this.$http = $http;
	}

	query(params) {
		return this.$http.get('lbadmin/import-export/configurations', { params });
	}

	get(uid) {
		return this.$http.get(`lbadmin/import-export/configurations/${uid}`);
	}

	save(uid, params) {
		return this.$http.post(`lbadmin/import-export/configurations/${uid}`, { params });
	}

	create(params) {
		return this.$http.post(`lbadmin/import-export/configurations`, { params });
	}

	remove(uid, params) {
		return this.$http.delete(`lbadmin/import-export/configurations/${uid}`);
	}

	removeSelected(params) {
		return this.$http.delete(`lbadmin/import-export/configurations-selected`, { data: params });
	}
};
