<template>
	<div class="selected-actions">
		<button
			:data-cy="`${currentComponentId}__button`"
			type="button"
			:class="[buttonClass, normalColor ? '' : 'buttonTransparent']"
			:disabled="disabled"
			@click="$emit('click')"
		>
			<span>{{ $te(label) ? $t(label) : label }}</span>
			<i v-bind:class="[iconSizeClass, iconClass]"></i>
		</button>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		label: String,
		buttonClass: String,
		// if true, inverse wont be used
		normalColor: {
			type: Boolean,
			default: false,
		},
		iconClass: String,
		iconSizeClass: {
			type: String,
			default: null,
		},
		disabled: Boolean,
	},
};
</script>
