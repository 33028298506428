/* eslint no-underscore-dangle: 0 */
import Column from './mixins/Column';
import ScrollHandler from './mixins/ScrollHandler';
import PageHandler from './mixins/PageHandler';
import CheckboxHandler from './mixins/CheckboxHandler';
import Controls from './mixins/Controls';
import Settings from './mixins/Settings';
import Filters from './mixins/Filters';
import HeaderSearch from './mixins/HeaderSearch';

import TabSwitchScroll from '../../mixins/TabSwitchScroll';
import _ from 'lodash';

export default {
	mixins: [
		Column,
		ScrollHandler,
		PageHandler,
		CheckboxHandler,
		Controls,
		Settings,
		Filters,
		HeaderSearch,
		TabSwitchScroll,
	],
	props: {
		rowFormatter: Function,
		rowSelectedId: [String, Number],
		collection: {
			type: Object,
			required: true,
		},
		pOrderBy: String,
		pOrderDirection: String,
		extraColumns: Array,
		searchable: {
			type: Boolean,
			default: true,
		},
		hideableColumns: Boolean,
		multi: {
			type: Boolean,
			default: false,
			required: false,
		},
		multiSelectAll: {
			type: Boolean,
			default: false,
			required: false,
		},
		rowId: {
			type: Array,
			default: () => ['id'],
			required: false,
		},
		noControls: Boolean,
		title: String,
		exportTitle: String,
		importTitle: String,
		gridViewportHeight: Number,
		gridMaxViewportHeight: Number,
		viewportRowCount: Number,
		standalone: {
			type: Boolean,
			required: false,
			default: true,
		},
		dynamicHeight: {
			type: Boolean,
			required: false,
			default: false,
		},
		id: {
			type: [String, Number],
			default: () => {
				let result = '';
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				const charactersLength = characters.length;
				const length = 4;

				for (let i = 0; i < length; i += 1) {
					result += characters.charAt(Math.floor(Math.random() * charactersLength));
				}
				return result;
			},
			// id is used in css, so it cannot contain dots for example
			validator: (value) => {
				const re = /^[\w-]+$/gi;
				return re.test(value);
			},
		},
		// requestDataCount: Number,
		showAll: {
			type: Boolean,
			default: false,
		},
		actionsOffset: {
			type: Number,
			default: 0,
		},
		cantCloseDetail: {
			type: Boolean,
			default: false,
		},
		importEnabled: {
			type: Boolean,
			default: false,
		},
		showImportButton: {
			type: Boolean,
			required: false,
			default: true,
		},
		showExportButton: {
			type: Boolean,
			required: false,
			default: true,
		},
		importDescription: String,

		exportEnabled: {
			type: Boolean,
			default: false,
		},
		exportDescription: String,
		validationCallback: Function,
		hiddenGridSearch: Array,
		parentUid: String,
		importType: String,
		noActiveRow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			currentComponentId: null,
			rowHeight: 30,
			// set empty row for viewport, so that lba-grid-columns are initialized, it gets rewritten on collection get all
			viewport: [{}],
			viewportHeight: 0,
			lastViewportHeight: 0,
			// requestDataLength: 210,
			loadedIndexFirst: 0,
			reloadRequest: false,
			loadIndexFrom: null,
			loadFirst: null,
			orderBy: null,
			orderDirection: null,
			maxDate: new Date(),
			dragStartX: null,
			ignoreSort: false,
			styleSheet: null,
			selectedRow: null,
			selectedRowIndexFrom: null,
			selectedRowIndexTo: null,
			initDone: false,
			showSearchRow: false,
			showDropdownMenu: false,
			lastSelectedRowIndexFrom: null,
			headerWidth: 0,
			headerLeft: 0,
			sortable: [],

			loadingPrevious: false,
			loadingNext: false,

			isRowDetailShown: false,
			isRowDetailPinned: false,

			hasRowDetail: false,
			cachedSelectedRow: null,

			loadDataCallbacks: [],
			exportData: 'all',

			showIsLoading: false, // (collection.isLoading() && !initDone) || scrollingFast || scrollbarVerticalMove

			reloadingProposalState: false,
			showReloadingProposalStateIcon: false,
			mouseOverReloadingProposalState: false,
			reloadingProposalDebouncer: null,

			canChangeGlobalFilter: false,

			awaitingChannelReady: false,
			scrollElementsInfo: [],
			fetchingPromise: null,
			fetchingResolve: null,
			filterValueKey: this.$generateUID(),
			hiddenSearch: null,
		};
	},
	computed: {
		rowCount() {
			return _.ceil(this.viewportHeight / this.rowHeight, -1);
		},
		loadedIndexLast() {
			return this.loadedIndexFirst + this.viewport.length - 1;
		},
		maxTop() {
			return ((this.getTotalRowsCount() + this.showSearchRow) * this.rowHeight) - this.viewportHeight;
		},
		multiEnabled() {
			return this.multi || this.multiSelectAll;
		},
		totalRowsCount() {
			return this.collection ? this.collection.length : 0;
		},
		minRemainingRowCount() {
			// load another data when in 2/3 of scrolling (round down)
			return _.floor((this.currentPerPage / 3) * 2, -1);
		},
		canModifyFilter() {
			return !(this.filterIsGlobal && !this.canChangeGlobalFilter);
		},
	},
	watch: {
		showAll() {
			if (this.showAll) {
				this.resize();
				this.scrollDown();
			} else {
				this.resize();
			}
		},
	},
	async created() {
		this.hiddenSearch = this.hiddenGridSearch;
		if (_.isEmpty(this.gridName)) {
			console.warn('[LbaGrid] gridName is not specified, id will be used as currentComponentId', this.id, this);
			this.currentComponentId = this.id;
		} else {
			this.currentComponentId = this.gridName.replaceAll('.', '-');
		}

		this.reloadingProposalDebouncer = new this.$Debouncer(this, () => {
			this.showReloadingProposalStateIcon = false;
		}, null, 2000);
		// let limit = this.requestDataLength;

		// if (this.showAll) {
		// 	limit = null;
		// }

		this.showIsLoading = true;

		// wait for resources
		await this.internalResourcesPromise;

		// wait for columns settings / order settings
		await this.columnsSettingsInitPromise;
		const columnNames = Object.keys(this.columnsProperties);

		for (let i = 0; i < columnNames.length; i += 1) {
			const columnName = columnNames[i];
			if (
				this.columnsProperties[columnName].sortable &&
				this.columnsProperties[columnName].enabled !== false &&
				!this.columnsProperties[columnName].hidden
			) {
				this.sortable.push({ value: columnName, label: this.columnsProperties[columnName].label });
			}
		}

		if (!_.isEmpty(this.filterAttributes) && !_.isEmpty(this.filterAttributes.columns)) {
			this.filterAttributes.columns.sort(this.sortFilterColumns);
		}

		let defaultFilterFound = false;
		// apply default filter
		if (this.filterList) {
			const userDefaultFilter = _.find(this.filterList, (f) => f.is_default && f.user_name);
			if (userDefaultFilter) {
				defaultFilterFound = true;
				this.applySelectedFilter(userDefaultFilter, false);
			} else {
				const globalDefaultFilter = _.find(this.filterList, (f) => f.is_default && !f.user_name);
				if (globalDefaultFilter) {
					defaultFilterFound = true;
					this.applySelectedFilter(globalDefaultFilter, false);
				}
			}
		}
		this.resize(true);

		this.prepareGridOrder();
		if (!defaultFilterFound) {
			this.prepareGridFilter();
		}

		this.collection.lastOptions._order = this.orderBy;
		this.collection.lastOptions._order_dir = this.orderDirection;
		this.collection.lastOptions._locale = this.$user.lang;
		this.collection.lastOptions._max_date = this.maxDate;
		this.collection.originalQueryOptions._max_date = this.maxDate;
		this.collection.originalQueryOptions._locale = this.$user.lang;

		const options = { params: this.collection.lastOptions };

		console.debug('[LbaGrid] init fetch', this.gridName, this.id);
		const limit = this.showAll ? null : this.currentPerPage;
		this.collection.getAll(0, limit, options).then((data) => {
			this.viewport = data;
			this.resize(true);
			// this.setCanvasHeight();
			// this.setViewportHeight();

			if (!_.isEmpty(this.collection.search) && !this.showSearchRow) {
				this.searchRowToggle();
			}

			console.debug('[LbaGrid] init fetch done', this.gridName, this.id, this.collection);
			this.$emit('init-data-loaded');
			this.loadDataCallbacks.forEach((cb) => cb());
			this.showIsLoading = false;
			this.prepareRegistration();
			this.refreshPages();
		});
		this.$root.$listen('content-options-route-key-change', this.onDetailShowChange, this);
		this.$root.$listen('content.pin', () => { this.isRowDetailPinned = true; }, this);
		this.$root.$listen('content.unpin', this.onContentUnpin, this);
		this.$root.$listen('grid.reload', this.reload, this, true);
		this.$root.$listen('grid.hidden-reload', this.hiddenReload, this, true);
		this.$root.$listen('content.reload', () => this.reload(this.id), this, true);
		this.$root.$listen('grid.insert-row', this.insertRow, this, true);
		this.$root.$listen('grid.update-row', this.updateRow, this, true);
		this.$root.$listen('grid.delete-row', this.deleteRow, this, true);
		this.$root.$listen('grid.select-single-row', this.selectSingleRow, this, true);
		this.$root.$listen('grid.apply-filter', this.applyFilter, this, true);
		this.$root.$listen('grid.reloading-proposal', this.reloadingProposal, this, true);
		this.$root.$listen('grid.channel-ready', this.onChannelReady, this, true);
		this.$root.$listen('permissions-reload', this.permissionsReload, this, true);
		this.$root.$listen('user.updated', this.updateLocales, this, true);

		this.permissionsReload();

		if (this.$route.matched.length > 1) {
			this.hasRowDetail = true;
		}

		const viewportElement = await this.getViewportElement();
		if (viewportElement) {
			this.registerScrollElement(viewportElement);
		} else {
			console.error('[LbaGrid] cant get viewport element', this.gridName, this.gridId);
		}
	},
	mounted() {
		this.showDropdownMenu =
			!!this.$scopedSlots.menu ||
			(this.importEnabled && !!this.showImportButton) ||
			(this.exportEnabled && !!this.showExportButton);

		this.selectedRow = this.rowSelectedId;
		window.addEventListener('resize', this.resize);

		// if (this.requestDataCount != null) {
		// 	this.requestDataLength = this.requestDataCount;
		// }

		const head = document.head || document.getElementsByTagName('head')[0];
		this.styleSheet = document.createElement('style');
		this.styleSheet.id = `grid-${this.id}`;
		this.styleSheet.type = 'text/css';
		head.appendChild(this.styleSheet);

		if (this.searchable && !this.searchInput) {
			this.showSearchRow = true;
		}

		this.$root.$listen('content.cancel', this.resetSelectedRow, this);
		this.$root.$listen('grid-select', this.selectRow, this);
		this.$root.$listen('grid-select-next', this.selectNextRow, this);
		this.$root.$listen('grid-select-previous', this.selectPreviousRow, this);
		this.$root.$listen('grid-recalc-actions-offset', () => this.$forceUpdate(), this);

		this.$emit('grid-mounted', this.id);
	},
	activated() {
		this.resize();
		// this.setViewportHeight();

		if (this.reloadingProposalState && !this.mouseOverReloadingProposalState) {
			this.showReloadingProposalStateIcon = true;
			this.mouseOverReloadingProposalState = false;
			this.reloadingProposalDebouncer.emit();
		}
	},
	beforeDestroy() {
		const head = document.head || document.getElementsByTagName('head')[0];
		head.removeChild(this.styleSheet);
		this.unregisterGrid();
	},
	methods: {
		updateLocales() {
			if (!_.isEmpty(this.filterAttributes) && !_.isEmpty(this.filterAttributes.columns)) {
				this.filterAttributes.columns.forEach((filterAttribute) => {
					const column = this.columnsProperties[filterAttribute.name];
					if (_.isEmpty(column)) return;
					filterAttribute.label = column.label;
				});
			}
			if (!_.isEmpty(this.filterShow)) {
				this.filterShow.forEach((filterColumn) => {
					const column = this.columnsProperties[filterColumn.name];
					if (_.isEmpty(column)) return;
					filterColumn.label = column.label;
					const filterAttribute = this.filterAttributes.columns.find((item) => item.name === column.id);
					if (!_.isEmpty(filterAttribute)) {
						const { valueText, returnAcc } = this.getValueText(filterAttribute);
						if (!returnAcc) {
							filterColumn.valueText = valueText;
						}
					}
				});
			}
			if (!_.isEmpty(this.sortable)) {
				this.sortable.forEach((sortableColumn) => {
					const column = this.columnsProperties[sortableColumn.value];
					sortableColumn.label = column.label;
				});
			}
		},
		getCanvasHeight() {
			// return (this.getTotalRowsCount() + this.showSearchRow) * this.rowHeight;
			return (this.getLoadedRowsCount() + this.showSearchRow) * this.rowHeight;
		},
		prepareRegistration() {
			// someone is preparing channel
			if (this.$root.preparingGridsChannel) {
				// console.log('someone is preparing channel');
				this.awaitingChannelReady = true;
			}
			// channel is ready
			if (!_.isEmpty(this.$root.gridsChannel) && !this.$root.preparingGridsChannel) {
				// console.log('channel is ready');
				this.registerGrid();
			}
		},
		onChannelReady() {
			// console.log('on channel ready', this.gridName, this.awaitingChannelReady);
			if (this.awaitingChannelReady) {
				this.awaitingChannelReady = false;
				this.registerGrid();
			}
		},
		registerGrid() {
			if (this.gridName && this.$root.gridsChannel) {
				// console.log('register grid', this.gridName);
				this.$root.gridsChannel.send({
					type: 'add-user-grid',
					payload: {
						name: this.gridName,
						id: this.id,
					},
				});
			}
		},
		unregisterGrid() {
			if (this.gridName && this.$root.gridsChannel) {
				// console.log('unregister grid', this.gridName);
				this.$root.gridsChannel.send({
					type: 'remove-user-grid',
					payload: {
						name: this.gridName,
						id: this.id,
					},
				});
			}
		},
		reloadMouseOver() {
			if (this.reloadingProposalState && !this.mouseOverReloadingProposalState) {
				this.showReloadingProposalStateIcon = false;
				this.mouseOverReloadingProposalState = true;
			}
		},
		async getViewportElement(attemptCount = 3) {
			let attempt = 0;
			while (!this.$refs.viewport || attempt >= attemptCount) {
				await this.$nextTick();
				attempt += 1;
			}
			if (this.$refs.viewport) return this.$refs.viewport;
			return null;
		},
		prepareGridOrder(filterOrderBy = null, filterOrderDirection = null) {
			let orderBy = filterOrderBy || this.pOrderBy || this.collection.lastOptions._order;
			let orderDirection = filterOrderDirection || this.pOrderDirection || this.collection.lastOptions._order_dir;

			if (this.columnsOrder && this.columnsOrder.orderBy) {
				orderBy = this.columnsOrder.orderBy;
			}
			if (this.columnsOrder && this.columnsOrder.orderDirection) {
				orderDirection = this.columnsOrder.orderDirection;
			}

			if (filterOrderBy) {
				orderBy = filterOrderBy;
			}
			if (filterOrderDirection) {
				orderDirection = filterOrderDirection;
			}

			if (filterOrderBy == null && filterOrderDirection == null) {
				this.collection.originalQueryOptions._order = orderBy;
				this.collection.originalQueryOptions._order_dir = orderDirection;
			}

			this.orderBy = orderBy;
			this.orderDirection = orderDirection;
			this.collection.params._order = orderBy;
			this.collection.params._order_dir = orderDirection;
		},
		prepareGridFilter() {
			if (_.isEmpty(this.collection.filter)) {
				this.prepareEmptyFilter();
				return;
			}

			_.forEach(this.collection.filter, (item, key) => {
				this.filterAttributes.columns.find((column) => {
					if (column.name === key) {
						column.value = item.value;
						column.condition = item.condition;
						this.filterColumns.push(column);
					}
				});
			});
			this.filterColumns.sort(this.sortFilterColumns);
			this.useFilter(false);
		},
		async openExportDialog(type) {
			this.exportData = type;
			await this.$nextTick();
			this.$root.$emit('dialog-open', { name: `${this.gridName}.export-dialog` });
		},
		async insertRow(info) {
			if (
				(!_.isEmpty(info.gridName) && info.gridName !== this.gridName) ||
				(!_.isEmpty(info.gridNames) && !info.gridNames.includes(this.gridName))
			) return;
			if (this.fetchingPromise) {
				await this.fetchingPromise;
			}
			console.debug('[grid](insert-row) insert', this.gridName, this.id, JSON.parse(JSON.stringify(info)));
			this.collection.clear();
			this.fetchCollection(this.loadedIndexFirst, true, false, false);
		},
		/**
		 * update row data
		 * @param {Object} info containing { row, rowId, gridName, gridNames }
		 */
		async updateRow(info) {
			if (
				(!_.isEmpty(info.gridName) && info.gridName !== this.gridName) ||
				(!_.isEmpty(info.gridNames) && !info.gridNames.includes(this.gridName))
			) return;
			if (this.fetchingPromise) {
				await this.fetchingPromise;
			}
			info.ids = this.rowId;
			console.debug('[grid](update-row) update', this.gridName, this.id, JSON.parse(JSON.stringify(info)));
			const rowUpdated = await this.collection.updateRow(info);
			if (rowUpdated) {
				const rowId = info.rowId || this.getRowId(info.row);
				const index = this.viewport.findIndex((item) => this.getRowId(item) === rowId);

				if (index >= 0) {
					if (rowUpdated.deleted) {
						this.viewport.splice(index, 1);
						console.debug('[grid](updateRow) deleted index:', index);
						// this.setCanvasHeight();
					} else {
						const updatedRow = { ...this.viewport[index], ...info.row };
						// update some value of depth 1, so it triggers update of whole object
						updatedRow.__key__ = Math.random();
						this.viewport.splice(index, 1, updatedRow);
						console.debug('[grid](updateRow) updated:', index, updatedRow);
					}
				}
			}
		},
		/**
		 * apply filter
		 * @param {Object} info containing
		 * 		{ gridName, reload: true/false, rewrite: true/false, values: { column1: 'value1', column2: 'value2' } }
		 */
		async applyFilter(info) {
			if (
				(!_.isEmpty(info.gridName) && info.gridName !== this.gridName) ||
				(!_.isEmpty(info.gridNames) && !info.gridNames.includes(this.gridName))
			) return;
			if (this.fetchingPromise) {
				await this.fetchingPromise;
			}
			console.debug('[grid](apply-filter)', this.gridName, this.id, JSON.parse(JSON.stringify(info)));

			if (info.rewrite) {
				this.filterUid = '_';
				this.filterName = null;
				this.filterIsDefault = false;
				this.filterIsGlobal = false;
			}

			if (info.values) {
				_.forEach(this.filterAttributes.columns, (col) => {
					if (info.rewrite) {
						col.value = null;
					}
					if (info.values[col.name]) {
						col.value = info.values[col.name];
					}
				});
			}

			this.useFilter(true);
		},
		/**
		 * delete row
		 * @param {Object} info containing { row, rowId, gridName, gridNames }
		 */
		async deleteRow(info) {
			if (
				(_.isEmpty(info.gridName) && _.isEmpty(info.gridNames)) ||
				(!_.isEmpty(info.gridName) && info.gridName !== this.gridName) ||
				(!_.isEmpty(info.gridNames) && !info.gridNames.includes(this.gridName))
			) return;
			if (this.fetchingPromise) {
				await this.fetchingPromise;
			}
			// console.debug('delete-row', this.gridName, this.id, info);
			info.ids = this.rowId;
			if (this.collection.deleteRow(info)) {
				const rowId = info.rowId || this.getRowId(info.row);
				const index = this.viewport.findIndex((item) => this.getRowId(item) === rowId);

				if (index >= 0) {
					this.viewport.splice(index, 1);
					console.debug('[grid](deleteRow) deleted index:', index);
				}
				// this.setCanvasHeight();
			}
		},
		/**
		 * select grid row
		 * @param {Object} info containing { rowId, gridName | gridNames }
		 */
		async selectSingleRow(info) {
			if (
				(_.isEmpty(info.gridName) && _.isEmpty(info.gridNames)) ||
				(!_.isEmpty(info.gridName) && info.gridName !== this.gridName) ||
				(!_.isEmpty(info.gridNames) && !info.gridNames.includes(this.gridName))
			) return;
			if (this.fetchingPromise) {
				// console.debug('[LbaGrid](selectSingleRow) awaiting promise', this.gridName, this.id, info);
				await this.fetchingPromise;
			}
			console.debug('[LbaGrid](selectSingleRow)', this.gridName, this.id, info);
			const index = this.viewport.findIndex((item) => this.getRowId(item) === info.rowId);
			if (index < 0) {
				console.warn('[LbaGrid](selectSingleRow)', this.gridName, this.id, 'row not found:', JSON.parse(JSON.stringify(info)));
				return;
			}
			const row = this.viewport[index];

			this.rowSelected(null, row, index);
		},
		onContentUnpin() {
			this.isRowDetailPinned = false;
			// this.checkShouldCloseDetail();
		},
		getTotalRowsCount() {
			return this.collection.length;
		},
		getLoadedRowsCount() {
			return this.collection.loadedRows;
		},
		getErrors() {
			return this.collection.errors;
		},
		reload(id) {
			if (id === this.id || id === this.gridName) {
				console.debug('[LbaGrid](reload)', this.gridName, this.id);
				this.maxDate = new Date();
				this.clearChecked();
				this.collection.clear();
				this.fetchCollection(this.pageRecordsFrom - 1, true, true, false);
			}
		},
		hiddenReload(id) {
			if (id === this.id || id === this.gridName) {
				console.debug('[LbaGrid](hiddenReload)', this.gridName, this.id);
				this.clearChecked();
				this.collection.clear();
				this.fetchCollection(this.pageRecordsFrom - 1, true, true, false, false);
			}
		},
		onImportDialogClose(info) {
			if (info.needsReload) {
				this.reload(this.id);
			}
		},
		setCanvasHeight() {
			// if (this.$refs && this.$refs.canvas) {
			// 	this.$refs.canvas.setAttribute('style', `height: ${this.getCanvasHeight()}px;`);
			// }
		},
		setViewportHeight() {
			// let height = this.viewportHeight;
			// // row: no data found
			// if (this.getTotalRowsCount() === 0 && height < this.rowHeight + this.showSearchRow * this.rowHeight) {
			// 	height += this.rowHeight;
			// }
			// if (this.$refs && this.$refs.viewport) {
			// 	this.$refs.viewport.setAttribute('style', `height: ${height}px;`);
			// }
		},
		onDetailShowChange() {
			this.hasRowDetail = true;

			if (this.cachedSelectedRow && this.$route.matched.length > 1) {
				const rowID = this.getRowId(this.cachedSelectedRow);
				const index = this.viewport.indexOf(this.cachedSelectedRow) + this.loadedIndexFirst;
				this.cachedSelectedRow = null;

				this.checkedAll = false;
				this.filterCheckedAll = false;
				this.checked = {};
				this.checked[rowID] = true;
				this.unChecked = {};
				this.selectedRow = rowID;
				this.selectedRowIndexFrom = index >= 0 ? index : null;
			} else {
				this.cachedSelectedRow = null;
			}
		},
		getRowClasses(row, index) {
			const classes = [];
			let userFormat = null;

			if (this.rowFormatter) {
				userFormat = this.rowFormatter(row);
			}

			const classEven = (index + this.loadedIndexFirst) % 2 === 0;
			const classActive = this.isActive(row);

			if (classEven) {
				classes.push('even');
			}
			if ((classActive && !this.noActiveRow) || this.isSelected(row)) {
				if (userFormat && userFormat.active) {
					classes.push(userFormat);
				} else {
					classes.push('active');
				}
			}
			if (userFormat) {
				userFormat.classes.forEach((classCSS) => {
					classes.push(classCSS);
				});
			}

			return classes;
		},
		getActionsStyle() {
			const offset = this.actionsOffset || this.scrollbarWidth;
			const actionsLeft = this.$refs.viewport.offsetWidth -
				parseInt(this.columnsProperties.actions.width, 10) -
				this.headerLeft -
				offset;
			return `left: ${actionsLeft}px;`;
		},
		resetSelectedRow() {
			this.selectedRow = null;
			this.$forceUpdate();
		},
		isActive(row) {
			return (this.selectedRow === this.getRowId(row) && this.selectedRow != null);
		},
		isSelected(row) {
			return (this.multiEnabled && this.shouldBeChecked(row));
		},
		fetchCollection(offset = 0, saveParams = false, scrollOnTop = false, isScrollRequest = true, showIsLoading = true) {
			if (offset === 0) {
				this.currentPage = 1;
			}
			this.reloadingProposalState = false;
			const options = {
				params: {
					_order: this.orderBy,
					_order_dir: this.orderDirection,
					_locale: this.$user.lang,
					_max_date: this.maxDate,
					search: this.searchValue,
				},
				saveParams,
			};
			if (isScrollRequest) {
				options.params._scroll = true;
			}
			const queryOptions = this.collection.getQueryOptions(offset, this.currentPerPage, options);
			const changed = this.collection.optionsChanged(queryOptions);
			options.queryOptions = queryOptions;
			options.changed = changed;

			this.lastRequestTs = new Date().getTime();
			const currentTs = this.lastRequestTs;
			// console.log('vH, rH', this.viewportHeight, this.rowHeight);
			this.showIsLoading = showIsLoading;
			this.fetchingPromise = new Promise((resolve) => {
				this.fetchingResolve = resolve;
			});
			// console.debug('[LbaGrid](fetchCollection) prepared', this.gridName, this.id);
			const limit = this.showAll ? null : this.currentPerPage;
			this.collection.getAll(offset, limit, options).then((data) => {
				if (currentTs === this.lastRequestTs) {
					this.showIsLoading = false;
					if (changed) {
						this.clearChecked();
					}

					// this.loadedIndexFirst = offset;
					this.viewport = data;
					this.resize();
					// this.setViewportHeight();
					// this.setCanvasHeight();

					if (scrollOnTop && this.$refs.viewport.scrollTop !== 0) {
						this.$refs.viewport.scrollTop = 0;
					}
					if (this.fetchingResolve) {
						// console.debug('[LbaGrid](fetchCollection) done', this.gridName, this.id);
						this.fetchingResolve();
						this.fetchingPromise = null;
					}
					this.$emit('data-loaded');
					this.refreshPages();
					/* console.log(
						'recv f:', offset,
						'l:', offset + this.rowCount,
						'ts:', currentTs,
						'lf:', this.loadedIndexFirst,
						'len:', data.length,
						'elen:', this.rowCount,
						'clen:', this.getTotalRowsCount()
					); */
				}
			});
		},
		getRowId(row) {
			if (row == null) {
				return undefined;
			}

			const valuesID = [];
			this.rowId.forEach((rowID) => {
				valuesID.push(row[rowID]);
			});

			return valuesID.join('|');
		},
		getRowIdValues(rowID) {
			const rowIDvalues = {};
			const values = rowID.split('|');

			this.rowId.forEach((columnName, index) => {
				rowIDvalues[columnName] = values[index];
			});

			return rowIDvalues;
		},
		searchRowToggle() {
			if (this.showSearchRow && (Object.keys(this.collection.search)).length > 0) {
				return;
			}
			this.showSearchRow = !this.showSearchRow;
			this.resize(false, this.showSearchRow);
		},
		resize(init = false, searchRowState) {
			console.debug('[LbaGrid] (resize)');
			if (!this.$refs.viewport) return;

			this.lastViewportHeight = this.viewportHeight;
			const totalRowsCount = this.getLoadedRowsCount();

			if (this.showAll) {
				const height = (totalRowsCount + this.showSearchRow) * this.rowHeight + this.scrollbarWidth;

				if (
					init &&
					this.viewportRowCount && (
						height < (this.viewportRowCount * this.rowHeight) + this.scrollbarWidth ||
						this.standalone
					)
				) {
					this.viewportHeight = this.viewportRowCount * this.rowHeight + this.scrollbarWidth;
				} else if (init && this.gridViewportHeight && (height < this.gridViewportHeight || this.standalone)) {
					this.viewportHeight = this.gridViewportHeight;
				} else if (init || this.dynamicHeight || height > this.viewportHeight) {
					this.viewportHeight = height;
				}
			} else {
				const { height } = this.$refs.pagination.getBoundingClientRect();

				if (this.dynamicHeight) {
					let availableHeight;

					if (this.gridViewportHeight) {
						availableHeight = this.gridViewportHeight;
					} else {
						availableHeight = parseInt(window.innerHeight - height, 10);

						if (this.standalone) {
							const { top } = this.$refs.viewport.getBoundingClientRect();
							availableHeight -= (top + 20);
						}
					}

					const totalHeight = (totalRowsCount + this.showSearchRow) * this.rowHeight + this.scrollbarWidth;

					if (totalHeight >= availableHeight) {
						this.viewportHeight = availableHeight;
					} else {
						this.viewportHeight = totalHeight;
					}
				} else if (this.viewportRowCount) {
					this.viewportHeight = this.viewportRowCount * this.rowHeight + this.scrollbarWidth;
				} else if (this.gridViewportHeight) {
					this.viewportHeight = this.gridViewportHeight;
				} else {
					let availableHeight = parseInt(window.innerHeight - height, 10);

					if (this.standalone) {
						const { top } = this.$refs.viewport.getBoundingClientRect();
						availableHeight -= (top + 20);
					}

					this.viewportHeight = availableHeight;
				}
			}

			// 7 parts: 3 before, 1 current (viewport), 3 after
			// this.requestDataLength = this.rowCount * 7;

			if (this.initDone) {
				this.setCssRules(false, searchRowState);
				this.$forceUpdate();
			}
		},
		getRowStyle(index) {
			return `top: ${(index + (this.emptyRowIndexFirst || this.loadedIndexFirst)) * this.rowHeight}px;`;
		},
		getSearchRowStyle() {
			return `
				top: ${this.offsetTop}px;
				background-color: #d8edf9 !important;
				z-index: 100;
			`;
		},
		/* DO NOT USE INSTEAD OF ROW SELECTED, currently works only as ctrl select */
		selectRow(idInfo) {
			const callback = () => {
				const row = this.viewport.find((item) => {
					let equalCount = 0;
					idInfo.forEach((info) => { equalCount += info.id === item[info.name]; });
					return equalCount === idInfo.length;
				});
				const index = this.viewport.indexOf(row) + this.loadedIndexFirst;
				if (row && index >= 0 && index < this.getTotalRowsCount()) {
					this.toggleColumnCheckbox(null, row, index);
				}
			};

			if (this.viewport.length === 0 || (this.viewport.length === 1 && _.isEmpty(this.viewport[0]))) {
				this.loadDataCallbacks.push(callback);
			} else {
				callback();
			}
		},
		selectNextRow() {
			const index = this.selectedRowIndexFrom + 1;
			const cachedIndex = index - this.loadedIndexFirst;
			const row = this.viewport[cachedIndex];

			if (index < this.getTotalRowsCount() && row) {
				this.rowSelected(null, row, index);
			}
		},
		selectPreviousRow() {
			const index = this.selectedRowIndexFrom - 1;
			const cachedIndex = index - this.loadedIndexFirst;
			const row = this.viewport[cachedIndex];

			if (index >= 0 && row) {
				this.rowSelected(null, row, index);
			}
		},
		/* checkShouldCloseDetail() {
			if ((this.checkedAll || this.filterCheckedAll || Object.keys(this.checked).length > 1) && !this.cantCloseDetail) {
				this.$root.$emit('content.close-detail');
			}
		}, */
		rowSelected(event, row, index) {
			const rowID = this.getRowId(row);
			if (rowID === 'undefined') return;

			this.selectedRow = rowID;
			this.selectedRowIndexFrom = index;
			this.$emit('row-selected', row, true);
			// this.checkShouldCloseDetail();
		},
		reloadingProposal(info) {
			if (
				!this.reloadingProposalState && (
					(!_.isEmpty(info.gridName) && info.gridName !== this.gridName) ||
					(!_.isEmpty(info.gridNames) && !info.gridNames.includes(this.gridName))
				)
			) return;

			console.debug('[grid](reloading-proposal)', this.gridName, this.id);
			this.reloadingProposalState = true;
			if (!this._inactive) {
				this.showReloadingProposalStateIcon = true;
				this.mouseOverReloadingProposalState = false;
				this.reloadingProposalDebouncer.emit();
			}
		},
		permissionsReload() {
			this.canChangeGlobalFilter = this.$permissions.checkPermission('lbadmin.grid-global-filters.write');
		},
		viewportWidth() {
			return this.$refs.viewport ? this.$refs.viewport.clientWidth : 0;
		},
		getViewportHeight(subtract = 0) {
			let add = 15;
			const isMessage = (this.showIsLoading || this.isSearching || this.getErrors() || !this.getTotalRowsCount());
			if (isMessage && !this.getTotalRowsCount()) add = 55;
			if (this.gridViewportHeight) {
				return `height: ${this.gridViewportHeight}px;`;
			}
			if (this.gridMaxViewportHeight) {
				let h = this.gridMaxViewportHeight;
				if (isMessage && !this.getTotalRowsCount()) {
					h = 50;
				}
				return `max-height: ${h}px;`;
			}
			if (!this.dynamicHeight) add = 0;
			return `${this.dynamicHeight && !isMessage ? 'max-' : ''}${`height: ${this.viewportHeight - subtract + add}px;`}`;
		},
		updateFilterValueKey() {
			this.filterValueKey = this.$generateUID();
		},
	},
};
