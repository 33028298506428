<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
		:readOnly="readOnly"
	>
		{{ valueLabel }}
	</component>
	<s
		v-else
		:data-cy="currentComponentId"
		:class="classes"
		:style="style"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<span>
			<ValidationProvider
				:ref="inputRef"
				:name="$getLocale(label)"
				:vid="`${$getLocale(label)}-${$generateUID()}`"
				:rules="rules"
				:customMessages="customMessages"
				v-slot="{ invalid, errors }"
				immediate
				:data-required-recommended="requiredRecommended"
			>
				<select
					:data-cy="`${currentComponentId}__select`"
					v-model="data[key]"
					v-bind="bindOptions()"
					:name="attribute.name"
					:title="title || ''"
					:disabled="readOnly"
					:class="{
						'lba-invalid': (invalid && dirty),
						'showWarning': (showRecommendation),
						'not-set': options.allowNull && data[key] == null,
					}"
					@change="onEvent('change')"
					@blur="$emit('blur')"
				>
					<option v-if="options.allowNull" :value="null" :data-cy="`${currentComponentId}__select__optionNull`" class="not-set">
						--{{ $t('notSet') }}--
					</option>
					<option v-for="(option, index) in options.options"
						:key="index"
						:data-cy="`${currentComponentId}__select__option${index}`"
						:value="option.value"
						:selected="data[key] === option.value">
						{{ $getLocale(option.label) }}
					</option>
				</select>
				<template v-if="dirty">
					<span v-for="(err, index) in errors" :key="index" class="lba-messages"
						:data-cy="`${currentComponentId}__error${index}`"
					>
						{{ err }}
					</span>
					<span v-for="(msg, index) in customMessages"
						:key="index" class="lba-messages warning"
						:data-cy="`${currentComponentId}__error${index}`"
					>
						{{ msg }}
					</span>
				</template>
			</ValidationProvider>
		</span>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputSelect',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	computed: {
		valueLabel() {
			const state = _.find(this.options.options, (s) => s.value === this.data[this.key]);
			return (state && this.$getLocale(state.label)) || this.data[this.key];
		},
		showRecommendation() {
			if (this.dirty && this.requiredRecommended && !this.data[this.key]) {
				return true;
			}
			return false;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
};
</script>
