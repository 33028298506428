<template>
	<div id="content">
		<div class="page-header">
			<h1>{{ $t('settings.menu.users') }}</h1>
		</div>
		<div class="page-content">
			<lba-grid
				v-bind:collection="users"
				:rowId="['role_uid']"
				hideableColumns
				ref="grid"
				gridName="lbadmin.users_user"
				:dynamicHeight="false"
			>
				<template v-slot:toolbar="props">
					<lba-toolbar-button
						:parentComponentId="props.parentComponentId"
						componentId="new"
						v-if="!readOnly"
						v-on:click="openNew"
						v-bind:label="$t('new')"
						normalColor
					></lba-toolbar-button>
				</template>
				<template v-slot:columns="props">
					<lba-grid-column
						id="user_name"
						:data-cy="`${props.parentComponentId}__userName`"
						v-bind:label="$t('settings.user.login')"
						sortable
						searchable
						width="300"
					>
						<a @click="openDetail(props.row.role_uid)" :data-cy="`${props.parentComponentId}__userName__openDetail`">
							{{ props.row.user_name }}
						</a>
					</lba-grid-column>
					<lba-grid-column
						id="firstname"
						:data-cy="`${props.parentComponentId}__firstName`"
						:label="$t('settings.user.firstname')"
						sortable
						searchable
						width="150"
					>
						{{ props.row.firstname }}
					</lba-grid-column>
					<lba-grid-column
						id="lastname"
						:data-cy="`${props.parentComponentId}__lastName`"
						v-bind:label="$t('settings.user.lastname')"
						sortable
						searchable
						width="150"
					>
						{{ props.row.lastname }}
					</lba-grid-column>
					<lba-grid-column
						:removed="!showExten"
						id="extension"
						:data-cy="`${props.parentComponentId}__extension`"
						v-bind:label="$t('settings.user.extension')"
						sortable
						searchable
						width="80"
					>
						{{ props.row.extension }}
					</lba-grid-column>
					<lba-grid-column
						id="company"
						:data-cy="`${props.parentComponentId}__company`"
						v-bind:label="$t('settings.user.company')"
						width="120"
						sortable
						searchable
						hidden
					>
						{{ props.row.company }}
					</lba-grid-column>
					<lba-grid-column
						id="email"
						:data-cy="`${props.parentComponentId}__email`"
						v-bind:label="$t('settings.user.email')"
						sortable
						searchable
						width="180"
						hidden
					>
						{{ props.row.email }}
					</lba-grid-column>
					<lba-grid-column
						id="profile_name"
						:data-cy="`${props.parentComponentId}__profileName`"
						v-bind:label="$t('settings.profile')"
						searchable
						width="200"
					>
						{{ getProfiles(props.row.profiles) }}
					</lba-grid-column>
					<lba-grid-column
						id="roles"
						:data-cy="`${props.parentComponentId}__roles`"
						v-bind:label="$t('settings.menu.groups')"
						searchable
						width="200"
					>
						{{ getRoles(props.row.roles) }}
					</lba-grid-column>
					<lba-grid-column
						id="description"
						:data-cy="`${props.parentComponentId}__description`"
						v-bind:label="$t('settings.user.description')"
						sortable
						searchable
						width="250"
					>
						{{ props.row.description }}
					</lba-grid-column>
					<lba-grid-column
						id="lang"
						:data-cy="`${props.parentComponentId}__lang`"
						v-bind:label="$t('settings.user.lang')"
						sortable
						width="80"
						hidden
						searchable
					>
						{{ props.row.lang }}
					</lba-grid-column>
					<lba-grid-column
						id="last_login"
						:data-cy="`${props.parentComponentId}__lastLogin`"
						v-bind:label="$t('settings.user.lastLogin')"
						sortable
						width="150"
						hidden
					>
						{{ getDate(props.row.last_login) }}
					</lba-grid-column>
					<lba-grid-column
						id="created"
						:data-cy="`${props.parentComponentId}__created`"
						v-bind:label="$t('settings.user.created')"
						sortable
						width="150"
						hidden
					>
						{{ getDate(props.row.created) }}
					</lba-grid-column>
					<lba-grid-column
						id="valid_until"
						:data-cy="`${props.parentComponentId}__validUntil`"
						v-bind:label="$t('settings.user.validUntil')"
						sortable
						width="150"
						hidden
					>
						{{ getDate(props.row.valid_until) }}
					</lba-grid-column>
					<lba-grid-column
						id="password_expiration"
						:data-cy="`${props.parentComponentId}__passwordExpiration`"
						v-bind:label="$t('settings.user.passExpiration')"
						sortable
						width="150"
						hidden
					>
						{{ getDate(props.row.password_expiration) }}
					</lba-grid-column>
					<lba-grid-column
						id="auth_method"
						:data-cy="`${props.parentComponentId}__authMethod`"
						v-bind:label="$t('settings.user.authMethod')"
						sortable
						width="70"
						hidden
					>
						{{ getAuthMethod(props.row) }}
					</lba-grid-column>
					<lba-grid-column
						id="can_login"
						:data-cy="`${props.parentComponentId}__canLogin`"
						v-bind:label="$t('settings.user.allowLogin')"
						sortable
						width="150"
						hidden
					>
						<span v-show="props.row.can_login">{{ $t('yes') }}</span>
						<span v-show="!props.row.can_login">{{ $t('no') }}</span>
					</lba-grid-column>
				</template>
			</lba-grid>
		</div> <!-- /.page-content -->
	</div>
</template>

<script>
import UserModel from '../models/User';

export default {
	name: 'Users',
	data() {
		return {
			users: [],
			rowSelectedId: parseInt(this.$route.params.id, 10),
			rowSelected: null,
			rowChecked: null,
			authMethodsList: {
				lba: 'LB Admin',
				ldap: 'LDAP',
				ad: 'Active Directory',
				pam: 'PAM',
				gAuth: 'Google Authenticator',
				agent: 'PBX Agent',
			},
			extraSettings: [],
			showExten: false,
			readOnly: false,
		};
	},
	created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.userModel = new UserModel(this.$http);
		this.users = new this.$Collection(this.userModel, {
			params: {
				_order: 'firstname',
				_order_dir: 'asc',
			},
		});
		this.users.attach(this, 'users');

		this.$http.get('lbadmin/extra-settings').then(async (response) => {
			this.extraSettings = response.data;
			this.extraSettings.map(async (el) => {
				if (el.name === 'extension') {
					this.showExten = true;
				}
			});
		});
		this.permissionsReload();
	},
	methods: {
		permissionsReload() {
			this.readOnly = !this.$permissions.checkPermission('lbadmin.user.write');
		},
		getDate(date) {
			if (date) {
				return this.$d(new Date(date), 'short');
			}

			return null;
		},
		openDetail(id) {
			this.$routerWrap.push({
				name: 'settings-user',
				params: {
					id,
					openNewTab: true,
				},
			});
		},
		openNew() {
			this.$routerWrap.push({
				name: 'settings-user',
				params: {
					id: '+',
					openNewTab: true,
				},
			});
		},
		getRoles(roles) {
			if (!roles || !roles.length) return '';
			const role = [];
			roles.map((el) => {
				if (el.profile_name) {
					role.push(`${el.role_name} (${el.profile_name})`);
				} else {
					role.push(`${el.role_name}`);
				}
			});
			return role.join(', ');
		},
		getAuthMethod(row) {
			if (!row) return null;
			return this.authMethodsList[row.auth_method || 'lba'];
		},
		getProfiles(profiles) {
			const pr = _.map(profiles, (el) => { return el.profile_name; });
			return pr.join(', ');
		},
	},
};
</script>
