<template>
	<component :is="rootTag">
		<span
			v-if="!readOnly"
			class="lbaSelect selectbox userSelectNone"
			role="listbox"
			ref="root"
			:data-cy="`${currentComponentId}__select`"
			:data-values="selectedOptionsValuesList"
			:data-labels="selectedOptionsLabelsList"
		>
			<span
				ref="selectboxValue"
				class="selectboxValue"
				:style="selectBoxValueStyle"
				@click="toggle"
				:data-cy="`${currentComponentId}__toggleExpand`"
			>
				<small style="white-space: pre-wrap;" v-if="isTopPlaceholderSet && !selectedOptions.length">
					{{ topPlaceholder }}
				</small>
				<span
					v-if="!multiple && selectedOptions.length > 0"
					v-html="getLabel(selectedOptions[0])"
					:data-cy="`${currentComponentId}__selectedOption`"
				></span>
				<ul v-if="multiple">
					<li
						v-for="(option, index) in selectedOptions"
						:class="{
							disabled: disableUnknown && option.custom
						}"
						@click.stop="unselectOption(index, option.custom)"
						:key="`${getLabel(option)}-${index}`"
						:data-cy="`${currentComponentId}__selectedOption${index}`"
						v-html="getLabel(option)"
					></li>
				</ul>
			</span>
			<span class="selectboxOptions offScreen">
				<i v-if="!isPlaceholderSet" class="icon-search"></i>
				<input
					:data-cy="`${currentComponentId}__search__inputText`"
					class="selectboxSearch"
					type="text"
					:placeholder="(isPlaceholderSet && placeholder) || $t('search2')"
					v-model="searchValue"
					@input="searchDebouncer.emit"
					@blur="ignoreClick = false"
					ref="input"
				>
				<ul role="presentation" ref="options" :data-cy="`${currentComponentId}__options`"></ul>
			</span>
		</span>
		<span v-else class="lbaSelect userSelectNone" role="listbox" ref="root">
			<span>
				<input
					:data-cy="`${currentComponentId}__selectedOption`"
					type="text"
					v-if="!multiple && selectedOptions.length > 0"
					v-model="selectedOptions[0].label"
					disabled="true"
				>
				<span v-if="multiple">
					<span
						v-for="(option, index) in selectedOptions"
						:key="`${getLabel(option)}-${index}`"
						:data-cy="`${currentComponentId}__selectedOption${index}`"
						class="readonly"
					>{{ getLabel(option) }}</span>
				</span>
			</span>
		</span>
	</component>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: {
			required: false,
		},
		opts: {
			type: Array,
			required: true,
		},
		renderHtmlLabels: {
			type: Boolean,
			required: false,
			default: false,
		},
		multiple: {
			type: Boolean,
			required: false,
			default: true,
		},
		required: Boolean,
		resetSelected: {
			type: Boolean,
			required: false,
			default: false,
		},
		combobox: {
			type: Boolean,
			default: false,
		},
		dontUseSearch: {
			type: Boolean,
			default: false,
		},
		noNotSetOption: {
			type: Boolean,
			default: false,
		},
		maxViewOptions: {
			type: Number,
			default: -1,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
		rootTag: {
			type: String,
			default: 'span',
		},
		placeholder: {
			type: String,
			default: '',
		},
		topPlaceholder: {
			type: String,
			default: '',
		},
		selectBoxValueStyle: {
			type: String,
		},
		disableUnknown: {
			type: Boolean,
			default: false,
		},
		boundaryElementSelector: {
			type: String,
			default: null,
		},
		direction: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			selectboxOptions: null,
			selectboxValue: null,
			selectboxSearch: null,
			presentation: null,
			showAbove: false,
			searchValue: null,
			options: [],
			viewOptions: [],
			selectedNodes: [],
			selectedViewIndex: -1,
			selectedOptions: [],
			ignoreClick: false,
			ignoreMouseOver: false,
			ignoreMouseOverTimeout: null,
			shown: false,
			lastActive: -1,
			rendered: false,
			searchTimeout: null,
			searchCounter: 0,
			initSearch: false,
			searchDebouncer: null,
			hasNull: false,
			boundaryElement: null,
		};
	},
	watch: {
		opts() {
			this.onOptsChanged();
		},
		value() {
			this.prepareSelected();
			this.preparePresentation();
		},
		readOnly() {
			this.prepareSelected();
			this.preparePresentation();
		},
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
				this.$emit('change', value);
			},
		},
		isPlaceholderSet() {
			return this.placeholder && this.placeholder.length > 0;
		},
		isTopPlaceholderSet() {
			return this.topPlaceholder && this.topPlaceholder.length > 0;
		},
		selectedOptionsValuesList() {
			return this.selectedOptions.map((option) => option.value).join('|');
		},
		selectedOptionsLabelsList() {
			return this.selectedOptions.map((option) => option.label).join('|');
		},
	},
	created() {
		this.searchDebouncer = new this.$Debouncer(
			this,
			this.searching,
			null,
			300
		);
	},
	mounted() {
		this.prepareOptions();
		this.prepareSelected();
		this.selectboxOptions = this.$refs.root.querySelector('.selectboxOptions');
		this.selectboxValue = this.$refs.root.querySelector('.selectboxValue');
		this.selectboxSearch = this.$refs.root.querySelector('.selectboxSearch');

		if (this.boundaryElementSelector && this.selectboxValue) {
			this.boundaryElement = this.selectboxValue.closest(this.boundaryElementSelector);
		}

		this.$refs.root.addEventListener('click', () => {
			this.ignoreClick = true;
		});
		document.addEventListener('click', () => {
			if (!this.ignoreClick) {
				this.hide();
			}
			this.ignoreClick = false;
		});
		if (this.$refs.options) {
			this.$refs.options.addEventListener('click', this.presentationEventHandler);
			this.$refs.options.addEventListener('mouseover', this.presentationEventHandler);
			this.$refs.options.addEventListener('mouseleave', this.presentationEventHandler);
		}
		if (this.selectboxSearch) {
			this.selectboxSearch.addEventListener('keydown', (event) => {
				if (event.keyCode === 38) { // arrow up
					if (this.selectedViewIndex > 0) {
						this.selectedViewIndex -= 1;
					}
				} else if (event.keyCode === 40) { // arrow down
					if (this.selectedViewIndex < (this.viewOptions.length - 1)) {
						this.selectedViewIndex += 1;
					}
				} else if (event.keyCode === 13) { // enter
					this.setSelected(this.selectedViewIndex, this.$refs.options.children[this.selectedViewIndex]);

					if (!this.multiple) {
						setTimeout(this.hide, 0);
					} else if (this.combobox) {
						this.searchValue = '';
					}
				} else if (event.keyCode === 33) { // pageup
					this.selectedViewIndex = 0;
				} else if (event.keyCode === 34) { // pagedown
					this.selectedViewIndex = this.$refs.options.children.length - 1;
				} else if (event.keyCode === 27 || event.keyCode === 9) { // esc
					this.hide();
				}

				// set propper scroll when navigating by keyboard
				if (
					event.keyCode === 38 ||
					event.keyCode === 40 ||
					event.keyCode === 33 ||
					event.keyCode === 34
				) {
					const newSelectedNode = this.$refs.options.children[this.selectedViewIndex];

					if (newSelectedNode) {
						let scrollTop = newSelectedNode.offsetTop - newSelectedNode.parentNode.clientHeight;

						if (this.lastActive >= 0) {
							this.$refs.options.children[this.lastActive].classList.remove('active');
						}

						newSelectedNode.classList.add('active');
						this.lastActive = this.selectedViewIndex;

						if (this.showAbove) {
							scrollTop += 25; // row height
						}

						this.ignoreMouseOver = true;
						if (this.ignoreMouseOverTimeout) {
							clearTimeout(this.ignoreMouseOverTimeout);
						}

						this.ignoreMouseOverTimeout = setTimeout(() => {
							this.ignoreMouseOver = false;
						}, 1000);

						newSelectedNode.parentNode.scrollTop = scrollTop;
					}
				}
			});
		}
	},
	methods: {
		onOptsChanged() {
			this.prepareOptions();
			this.prepareSelected();
			this.rendered = false;

			if (this.searchValue != null) {
				this.initSearch = true;
				this.searching();
			}
		},
		getOptionLabel(option) {
			const index = this.getOptionsIndex(option);

			if (index > -1) {
				return this.options[index].label;
			}

			return ' ';
		},
		getOptionsIndex(value) {
			for (let index = 0; index < this.options.length; index += 1) {
				if (this.options[index].value === value) {
					return index;
				}
			}

			return -1;
		},
		getSelectedOptionsIndex(value) {
			for (let index = 0; index < this.selectedOptions.length; index += 1) {
				if (this.selectedOptions[index].value === value) {
					return index;
				}
			}

			return -1;
		},
		prepareOptions() {
			this.options = [];
			this.viewOptions = [];
			this.hasNull = false;

			if (!_.isEmpty(this.opts)) {
				for (let index = 0; index < this.opts.length; index += 1) {
					if (typeof this.opts[index] !== 'object' || this.opts[index] == null) {
						console.error('[LbaSelect] opts must be array of objects as: [{ value, label }]');
					}
					if (this.opts[index].value === null) {
						this.hasNull = true;
					}
					this.options.push(this.opts[index]);

					if (this.maxViewOptions === -1 || this.viewOptions.length < this.maxViewOptions) {
						this.viewOptions.push(index);
					}
				}
			}
			if (!this.hasNull && !this.multiple && !this.required && !this.noNotSetOption) {
				this.options.push({ value: null, label: `-- ${this.$t('notSet')} --` });
			}
			if (!_.isEmpty(this.viewOptions) && !this.dontUseSearch) {
				this.sortViewOptions();
			}
		},
		prepareSelected() {
			if (this.value || this.resetSelected || this.hasNull) {
				const newSelectedOptions = [];

				if (this.multiple) {
					this.internalValue.forEach((value) => {
						const index = this.getSelectedOptionsIndex(value);

						if (index >= 0) {
							newSelectedOptions.push(this.selectedOptions[index]);
						} else {
							const optionIndex = this.getOptionsIndex(value);
							if (optionIndex >= 0) {
								newSelectedOptions.push(this.options[this.getOptionsIndex(value)]);
							} else if (this.combobox) {
								const option = {
									custom: true,
									label: value,
									value,
								};
								newSelectedOptions.push(option);
							}
						}
					});
					this.selectedOptions = newSelectedOptions;
				} else if (this.internalValue != null || this.hasNull) {
					const index = this.getSelectedOptionsIndex(this.internalValue);

					if (index >= 0) {
						newSelectedOptions.push(this.selectedOptions[index]);
					} else {
						const optionIndex = this.getOptionsIndex(this.internalValue);
						if (optionIndex >= 0) {
							newSelectedOptions.push(this.options[this.getOptionsIndex(this.internalValue)]);
						} else if (this.combobox) {
							const option = {
								custom: true,
								label: this.internalValue,
								value: this.internalValue,
							};
							newSelectedOptions.push(option);
						}
					}
					this.selectedOptions = newSelectedOptions;
				} else {
					this.selectedOptions = newSelectedOptions;
				}
			} else if (this.value === null) {
				if ((!this.multiple && !this.required && !this.noNotSetOption) && this.options.length) {
					this.selectedOptions = [this.options[this.options.length - 1]];
				} else {
					this.selectedOptions = [];
				}
			}
		},
		getLabel(option) {
			if (option == null || option.label == null) {
				return '';
			}
			if (option.constructor === Object) {
				return this.$getLocale(option.label);
			}
			return option.label;
		},
		unselectOption(optionIndex, custom) {
			if (this.disableUnknown && custom) return;
			this.ignoreClick = true;
			this.selectedOptions.splice(optionIndex, 1);

			if (this.rendered) {
				const node = this.selectedNodes.splice(optionIndex, 1)[0];

				if (node && node.classList) {
					node.classList.remove('selected');
				}
			}

			this.internalValue = this.selectedOptions.map((selectedOption) => selectedOption.value);
		},
		presentationEventHandler(event) {
			if ((event.type === 'mouseover' || event.type === 'mouseleave') && this.ignoreMouseOver) {
				return;
			}
			if (event.type === 'mouseleave' && this.selectedViewIndex >= 0) {
				if (this.lastActive >= 0) {
					this.$refs.options.children[this.lastActive].classList.remove('active');
					this.lastActive = -1;
				}
				this.selectedViewIndex = -1;
				return;
			}

			let optionNode = event.target;

			while (optionNode && optionNode.tagName !== 'LI') {
				optionNode = optionNode.parentNode;
			}

			// order of options
			const index = Array.prototype.indexOf.call(this.$refs.options.children, optionNode);
			const option = this.options[this.viewOptions[index]];

			if (index !== -1) {
				if (event.type === 'click') {
					this.setSelected(index, optionNode);

					if (!this.multiple) {
						setTimeout(this.hide, 0);
					}
				} else {
					if (this.lastActive >= 0 && this.$refs.options.children[this.lastActive]) {
						this.$refs.options.children[this.lastActive].classList.remove('active');
					}
					if (optionNode) {
						if (!option || !option.custom || !this.disableUnknown) {
							optionNode.classList.add('active');
						}
						this.lastActive = index;
					}
				}

				this.selectedViewIndex = index;
			}
		},
		setSelected(viewIndex, optionNode) {
			const option = this.options[this.viewOptions[viewIndex]];

			if (option && option.custom && this.disableUnknown) return;

			if (viewIndex >= 0) {
				this.setSelectedOption(viewIndex);
				this.setSelectedOptionNode(optionNode);
			} else {
				this.setSelectedComboboxOption();
			}
		},
		setSelectedOption(viewIndex) {
			const option = this.options[this.viewOptions[viewIndex]];
			const index = this.getSelectedOptionsIndex(option.value);

			// already selected
			if (index >= 0) {
				if (this.multiple) {
					this.selectedOptions.splice(index, 1);
					this.internalValue = this.selectedOptions.map((selectedOption) => selectedOption.value);
				}
			} else if (this.viewOptions[viewIndex] != null) {
				if (this.multiple) {
					this.selectedOptions.push(option);
					this.internalValue = this.selectedOptions.map((selectedOption) => selectedOption.value);
				} else {
					this.selectedOptions = [option];
					this.internalValue = option.value;
				}
			}
		},
		setSelectedComboboxOption() {
			if (_.isEmpty(_.trim(this.searchValue))) {
				this.selectedOptions = [];
				this.internalValue = null;
				return;
			}
			if (
				this.combobox &&
				!this.selectedOptions.find((option) => option.value === this.searchValue)
			) {
				let option = this.opts.find((item) => item.value === this.searchValue);
				if (_.isEmpty(option)) {
					option = {
						label: this.searchValue,
						value: this.searchValue,
						custom: true,
					};
				}
				if (this.multiple) {
					this.selectedOptions.push(option);
					this.internalValue = this.selectedOptions.map((selectedOption) => selectedOption.value);
				} else {
					this.selectedOptions = [option];
					this.internalValue = option.value;
				}
			}
		},
		setSelectedOptionNode(optionNode, quiet) {
			if (!optionNode) {
				return;
			}

			const index = this.selectedNodes.indexOf(optionNode);

			if (index !== -1) {
				if (this.multiple) {
					this.selectedNodes.splice(index, 1);
					optionNode.classList.remove('selected');
				}

				if (!quiet) {
					this.show();
				}

				return;
			}

			if (this.multiple) {
				this.selectedNodes.push(optionNode);
			} else {
				if (this.selectedNodes.length > 0) {
					this.selectedNodes[0].classList.remove('selected');
				}

				this.selectedNodes = [optionNode];
			}

			optionNode.classList.add('selected');

			if (!quiet) {
				this.show();
			}
		},
		preparePresentation() {
			if (this.$refs.options) {
				this.$refs.options.innerHTML = '';
			}
			this.selectedNodes = [];

			if (!this.hasNull &&
				!this.required &&
				!this.multiple &&
				!this.noNotSetOption &&
				this.viewOptions.length &&
				this.viewOptions[0] !== this.options.length - 1
			) {
				this.viewOptions.splice(0, 0, this.options.length - 1);
			}

			for (let index = 0; index < this.viewOptions.length; index += 1) {
				const option = this.options[this.viewOptions[index]];
				const optionNode = document.createElement('li');

				if (this.renderHtmlLabels) {
					optionNode.innerHTML = option.label;
				} else {
					optionNode.textContent = this.getLabel(option);
				}

				optionNode.setAttribute('role', 'option');
				optionNode.setAttribute('data-cy', `${this.currentComponentId}__option${index}`);

				if (index === this.selectedViewIndex) {
					optionNode.classList.add('active');
				}

				if (this.getSelectedOptionsIndex(this.options[this.viewOptions[index]].value) !== -1) {
					this.selectedNodes.push(optionNode);
					optionNode.classList.add('selected');
				}

				if (option.custom && this.disableUnknown) {
					optionNode.classList.add('disabled');
				}

				if (this.$refs.options) {
					this.$refs.options.appendChild(optionNode);
				}
			}
		},
		setSelectboxOptionsPosition() {
			let position = 'unset';
			let top = 'unset';
			let bottom = 'unset';
			const rect = this.selectboxValue.getBoundingClientRect();
			const searchHeight = this.selectboxSearch.getBoundingClientRect().height;
			const topSpace = rect.top - 1; // 1 => border
			const bottomSpace = window.innerHeight - rect.bottom - searchHeight - 1; // 1 => border
			const boxRect = this.selectboxOptions.getBoundingClientRect();
			const selectboxValueRect = this.$refs.selectboxValue ? this.$refs.selectboxValue.getBoundingClientRect() : null;
			const pageContent = this.selectboxValue.closest('.page-content');
			let pageContentTop = 0;
			if (pageContent) {
				pageContentTop = pageContent.getBoundingClientRect().top + 1; // 1 => border
				this.showAbove = (topSpace - pageContentTop - pageContent.scrollTop) > bottomSpace;
			} else {
				this.showAbove = topSpace > bottomSpace;
			}

			if (this.boundaryElement) {
				const boundaryRect = this.boundaryElement.getBoundingClientRect();
				const spaceAbove = rect.top - boundaryRect.top;
				const spaceBelow = boundaryRect.bottom - rect.bottom;
				let maxHeight = 0;

				if (spaceBelow > spaceAbove || spaceBelow > 250 || spaceAbove < 100 || this.direction === 'down') {
					this.showAbove = false;
					maxHeight = Math.min(Math.max(parseInt(spaceBelow), 100), 250);

				} else {
					this.showAbove = true;
					maxHeight = Math.min(Math.max(parseInt(spaceAbove), 100), 250);

				}

				this.$refs.options.setAttribute('style', `max-height: ${maxHeight - searchHeight - 5}px`);

			} else {
				if (this.showAbove && (topSpace - pageContentTop - searchHeight) < boxRect.height) {
					this.$refs.options.setAttribute('style', `max-height: ${topSpace - pageContentTop - searchHeight}px`);

				} else {
					this.$refs.options.setAttribute('style', `max-height: ${Math.min(bottomSpace, 250)}px`);

				}
			}

			if (this.$root.browserName !== 'firefox') {
				position = Math.floor(this.selectboxValue.offsetHeight);
			} else if (this.$root.browserName === 'firefox') {
				const tdElement = this.selectboxOptions.closest('td');
				if (tdElement) {
					position = Math.floor(tdElement.offsetHeight);
				}
			}

			if (this.direction === 'down') {
				top = `${position}px`;

			} else if (this.direction === 'up' || this.showAbove) {
				if (this.$root.browserName !== 'firefox') {
					bottom = `${_.get(selectboxValueRect, 'height', 0)}px`;
				} else {
					bottom = '4px'; // padding-top
				}

			} else {
				top = `${position}px`;

			}

			this.selectboxOptions.setAttribute('style', `
				top: ${top};
				bottom: ${bottom};
				width: ${rect.width}px;
			`);
			this.selectboxOptions.classList.remove('offScreen');
		},
		show() {
			if (!this.rendered) {
				this.preparePresentation();
				/* this.selectedViewIndex = this.selectedViewIndex;
				const selectedNode = this.$refs.options.children[this.selectedViewIndex];

				if (selectedNode) {
					selectedNode.classList.add('active');
					this.lastActive = this.selectedViewIndex;
				} */
				this.rendered = true;
			}

			this.$refs.input.focus();
			// because it gets bounding values before it renders - shows old rect.bottom value
			setTimeout(this.setSelectboxOptionsPosition, 0);
			this.ignoreClick = true;
			this.shown = true;
		},
		hide() {
			if (this.selectboxOptions && this.selectboxOptions.classList) {
				this.selectboxOptions.classList.add('offScreen');
			}
			this.selectedViewIndex = -1;
			this.shown = false;

			/* if (this.searchValue) {
				this.setSelectedComboboxOption();
			} */

			this.searchValue = '';
		},
		toggle() {
			if (this.shown && !this.ignoreClick) {
				this.hide();
			} else {
				if (_.isEmpty(_.trim(this.searchValue)) && this.options.length !== this.viewOptions.length && !this.dontUseSearch) {
					if (!this.hasNull && !this.multiple && !this.required && !this.noNotSetOption) {
						this.viewOptions = this.options.map((item, index) => index);
						this.viewOptions.pop(); // remove null value as it will be added in preparePresentation
					} else {
						this.viewOptions = this.options.map((item, index) => index);
					}
					this.sortViewOptions();
					if (this.maxViewOptions >= 0) {
						this.viewOptions.splice(this.maxViewOptions);
					}
					this.preparePresentation();
				}
				this.show();
			}
		},
		sortViewOptions() {
			this.viewOptions.sort((a, b) => {
				const optionA = this.options[a];
				const optionB = this.options[b];
				if (optionA.value === null) return -1;
				if (optionB.value === null) return 1;
				if (this.$normalizeString(this.getLabel(optionA)) < this.$normalizeString(this.getLabel(optionB))) return -1;
				if (this.$normalizeString(this.getLabel(optionA)) > this.$normalizeString(this.getLabel(optionB))) return 1;
				if (optionA.value < optionB.value) return -1;
				if (optionA.value > optionB.value) return 1;
				return 0;
			});
			for (let i = 0; i < this.viewOptions.length; i++) {
				const option = this.options[this.viewOptions[i]];
				if (option.order != null && option.order.constructor === Number) {
					const tmp = this.viewOptions[i];
					this.viewOptions.splice(i, 1, this.viewOptions[option.order]);
					this.viewOptions.splice(option.order, 1, tmp);
				}
			}
		},
		searching() {
			if (this.initSearch) {
				this.initSearch = false;
			} else {
				this.$emit('search-change', this.searchValue);
			}

			this.selectedViewIndex = -1;
			this.viewOptions = [];

			if (!this.dontUseSearch) {
				const searchExpression = this.searchValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
				const filterRegexp = new RegExp(searchExpression.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
				let label = null;

				for (let i = 0; i < this.options.length; i += 1) {
					const option = this.options[i];
					if (option.value === null && (this.multiple || this.required)) {
						continue;
					}

					label = this.getLabel(option).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

					if (this.renderHtmlLabels) {
						label = label.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/gm, ' ');
					}

					if (filterRegexp.test(label) || option.showAlways) {
						this.viewOptions.push(i);
					}
				}
				this.sortViewOptions();
				if (this.maxViewOptions >= 0) {
					this.viewOptions.splice(this.maxViewOptions);
				}
			} else {
				for (let i = 0; i < this.options.length; i += 1) {
					const option = this.options[i];
					if (
						option.value === null && (
							this.multiple ||
							this.required || (
								!this.hasNull &&
								!this.noNotSetOption &&
								this.viewOptions.length
							)
						)
					) {
						continue;
					}
					if (this.maxViewOptions === -1 || this.viewOptions.length < this.maxViewOptions) {
						this.viewOptions.push(i);
					} else {
						break;
					}
				}
			}

			this.preparePresentation();

			/* if (this.viewOptions.length > 0) {
				const selectedNode = this.$refs.options.children[this.selectedViewIndex];
				selectedNode.classList.add('active');
				this.lastActive = this.selectedViewIndex;
			} */
		},
	},
};
</script>
