<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ valueLabel }}
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<lba-switch
			:parentComponentId="currentComponentId"
			v-model="data[key]"
			v-bind="bindOptions()"
			:label1="switchLabel1"
			:label2="switchLabel2"
			:disabled="readOnly"
			style="position: relative; top: -2px;"
			@input="onEvent('input')"
			@change="onEvent('change')"
		></lba-switch>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputSwitch',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			switchLabel1: this.$t('off'),
			switchLabel2: this.$t('on'),
		};
	},
	computed: {
		valueLabel() {
			const state = _.find(this.options.states, (s) => s.value === this.data[this.key]);
			return (state && this.$getLocale(state.label)) || this.data[this.key];
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
		this.prepareSwitch();
	},
	methods: {
		prepareSwitch() {
			if (this.options && this.options.states) {
				const stateOff = _.find(this.options.states, (state) => !state.value);
				const stateOn = _.find(this.options.states, (state) => state.value);
				this.switchLabel1 = this.$getLocale(stateOff && stateOff.label) || this.$t('off');
				this.switchLabel2 = this.$getLocale(stateOn && stateOn.label) || this.$t('on');
			}
		},
	},
};
</script>
