<template>
	<component
		v-if="noInput && _.isEmpty(options.format)"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ $d(new Date(data[key]), 'timeShort') }}
	</component>
	<component
		v-else-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ $d(new Date(data[key]), options.format) }}
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<ValidationProvider
			:ref="inputRef"
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${$generateUID()}`"
			:rules="rules"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<lba-datepicker
				:parentComponentId="currentComponentId"
				v-model="data[key]"
				v-bind="bindOptions()"
				:mode="'time'"
				:readOnly="readOnly"
				:class="{
					'lba-invalid': (invalid && dirty),
					'showWarning': (showRecommendation)
				}"
				@input="onEvent('input')"
				@change="onEvent('change')"
			></lba-datepicker>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</ValidationProvider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputTime',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
		this.prepareTimeDefaultValue();
	},
	methods: {
		prepareTimeDefaultValue() {
			if (this.checkProperty(this.options, 'default') && !this.data[this.key]) {
				const hours = parseInt(this.options.default) / 60;
				const minutes = parseInt(this.options.default) % 60;
				const time = this.$moment().hours(hours).minutes(minutes).second(0);
				this.$set(this.data, this.key, time.toDate());
			}
		},
	},
};
</script>
