/* eslint-disable global-require, no-undef, no-unused-vars */

let LBTray = null;

function isNWjsApp() {
	return (
		typeof process === 'object' &&
    process.versions &&
    'node-webkit' in process.versions
	);
}

if (isNWjsApp()) {
	const win = nw.Window.get();
	let connected = false;

	LBTray = {
		isTransparent() {
			return win.isTransparent;
		},
		onConnectInternal() {
			connected = true;
		},
		onDisconnectInternal() {
			connected = false;
		},
		isConnected() {
			return connected;
		},
	};
}

if (isNWjsApp() && window.location.search !== '?popupWindow') {
	const win = nw.Window.get();
	let windowIsVisible = false;
	const tray = new nw.Tray({ icon: 'favicon.png', title: win.title });
	const popups = {};
	let connected = false;

	const { exec } = require('child_process');

	if (localStorage && localStorage.posX && localStorage.posY) {
		win.moveTo(parseInt(localStorage.posX, 10), parseInt(localStorage.posY, 10));
	}

	if (nw.App.argv.indexOf('--debug') > -1) {
		win.showDevTools();
	}

	let keyboardShortcut = null;

	LBTray = {
		showMainWindow(focus) {
			win.show();
			win.setResizable(false);
			win.setVisibleOnAllWorkspaces(true);
			win.setAlwaysOnTop(true);
			if (focus) {
				win.focus();
			}
			windowIsVisible = true;

			LBTray.onShowPopup();
		},
		hideMainWindow() {
			if (nw.App.manifest.window.show_in_taskbar) {
				win.minimize();
			} else {
				win.hide();
			}
			windowIsVisible = false;

			LBTray.onHidePopup();
		},
		isMainWindowVisible() {
			return windowIsVisible;
		},
		resizableMainWindow(resizable) {
			win.setResizable(resizable);
		},
		exit() {
			const keys = Object.keys(popups);

			for (let key = 0; key < keys.length; key += 1) {
				const p = popups[keys[key]];
				p.close();
			}
			win.close(true);
			nw.App.quit();
		},
		onShowPopup() {
		},
		onHidePopup() {
		},
		onClipboardChange(text) {
		},
		onShortcutPress(shortcut) {
		},
		onConnect() {
		},
		onDisconnect() {
		},
		onConnectInternal() {
			connected = true;
			LBTray.onConnect();
		},
		onDisconnectInternal() {
			connected = false;
			LBTray.onDisconnect();
		},
		isConnected() {
			return connected;
		},
		registerShortcut(shortcut) {
			if (keyboardShortcut) {
				if (keyboardShortcut.key === shortcut) {
					return;
				}
				nw.App.unregisterGlobalHotKey(keyboardShortcut);
			}
			keyboardShortcut = new nw.Shortcut({
				key: shortcut,
				active() {
					LBTray.onShortcutPress(shortcut);
				},
				failed(msg) {
					console.log('fail to register keyboard shortcut', msg);
				},
			});
			nw.App.registerGlobalHotKey(keyboardShortcut);
		},
		changeIcon(icon, tooltip) {
			tray.icon = icon;
			tray.tooltip = tooltip;
		},
		openPopup(name, url, width, height, frame) {
			const options = {
				width,
				height,
				frame: false,
				transparent: true,
				visible_on_all_workspaces: true,
				always_on_top: false,
				show_in_taskbar: false,
			};
			if (frame) {
				options.frame = true;
				options.show_in_taskbar = true;
				options.transparent = false;
			}
			if (
				!localStorage[`popup_${name}_posX`] ||
        !localStorage[`popup_${name}_posY`]
			) {
				localStorage[`popup_${name}_posX`] = 0;
				localStorage[`popup_${name}_posY`] = 0;
			}
			nw.Window.open(url, options, (newPopup) => {
				popups[name] = newPopup;

				newPopup.moveTo(parseInt(localStorage[`popup_${name}_posX`], 10),
					parseInt(localStorage[`popup_${name}_posY`], 10));

				newPopup.on('close', () => {
					localStorage[`popup_${name}_posX`] = this.x;
					localStorage[`popup_${name}_posY`] = this.y;

					delete popups[name];

					this.close(true);
				});
			});
		},
		closePopup(name) {
			if (popups[name]) {
				popups[name].close();
			}
		},
		getVersion() {
			return nw.App.manifest.version;
		},
		getServerURL() {
			return nw.App.manifest.main;
		},
		openExternalBrowser(url) {
			nw.Shell.openExternal(url);
		},
		openExternalApplication(command) {
			exec(command, (err) => {
				if (err) {
					console.log('exec error:', err);
				}
			});
		},
		isTransparent() {
			return win.isTransparent;
		},
		onFocus() {
			document.documentElement.classList.remove('trayBlur');
			document.documentElement.className += ' trayFocus';
		},
		onBlur() {
			document.documentElement.classList.remove('trayFocus');
			document.documentElement.className += ' trayBlur';
		},
	};

	tray.on('click', () => {
		if (LBTray.isMainWindowVisible()) {
			LBTray.hideMainWindow();
		} else {
			LBTray.showMainWindow();
		}
	});
	const menu = new nw.Menu();
	menu.append(new nw.MenuItem({
		label: 'Ukončit',
		click() {
			if (localStorage) {
				localStorage.posX = win.x;
				localStorage.posY = win.y;
			}
			LBTray.exit();
		},
	}));
	tray.menu = menu;

	const clipboard = nw.Clipboard.get();
	let prevClpContent = clipboard.get('text');
	setInterval(() => {
		const clpContent = clipboard.get('text');
		if (clpContent !== prevClpContent) {
			prevClpContent = clpContent;
			LBTray.onClipboardChange(clpContent);
		}
	}, 500);

	win.on('focus', () => {
		LBTray.onFocus();
	});
	win.on('blur', () => {
		LBTray.onBlur();
	});
}

const LBTrayApp = LBTray;

export {
	LBTrayApp as default
};
