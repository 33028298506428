<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<i v-if="data[key]" class="icon-ok"></i>
		<i v-else class="icon-cancel"></i>
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
		class="showInvalidTooltip"
	>
		<small v-if="!oneLine">
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<ValidationProvider
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${$generateUID()}`"
			:ref="inputRef"
			v-slot="{ invalid, errors }"
			:data-required-recommended="requiredRecommended"
			immediate
			:rules="validationRules"
		>
			<label class="checkbox fieldNoBorder" :data-cy="`${currentComponentId}__checkboxLabel`">
					<input
						:data-cy="`${currentComponentId}__inputCheckbox`"
						type="checkbox"
						v-model="data[key]"
						v-bind="bindOptions()"
						:class="{
							'lba-invalid': (invalid && dirty),
							'showWarning': (showRecommendation)
						}"
						:disabled="readOnly"
						@input="onEvent('input')"
						@change="onEvent('change')"
					>
					<i class="icon-ok"></i>
				<span v-if="oneLine" class="label">
					{{ label }}
					<span v-if="isRequired" class="required-sign"></span>
					<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
				</span>
			</label>
			<template v-if="!hasValue && (showRecommendation || invalid) && dirty">
				<span v-for="(err, index) in errors" :key="index"
					class="lba-messages"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</ValidationProvider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputCheckbox',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	computed: {
		hasValue() {
			if (this.data[this.key] === false || this.data[this.key] === undefined) {
				return false;
			}
			return true;
		},
		validationRules() {
			let rules = {};
			if (this.rules.required) {
				rules = { requiredCheckbox: true };
				rules.requiredCheckbox = { checkbox: this.data[this.key] };
			}
			return rules;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
};
</script>
