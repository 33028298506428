export default {
	methods: {
		$addMenuItemStatus(moduleName, name, html) {
			this.$root.$emit('add-menu-item-status', { moduleName, name, html });
		},
		$removeMenuItemStatus(moduleName, name) {
			this.$root.$emit('remove-menu-item-status', { moduleName, name });
		},
	},
};
