<script>
import axios from 'axios';

export default {
	inserted(el) {
		let requestCount = 0; // number of request which did not fail or did not get response yet
		const requestRemove = () => {
			requestCount -= 1;

			if (requestCount < 0) requestCount = 0;
			if (requestCount === 0) el.classList.remove('loading');
		};

		axios.interceptors.request.use((config) => {
			requestCount += 1;
			el.classList.add('loading');
			return config;
		}, (error) => {
			requestRemove();
			return Promise.reject(error);
		});

		axios.interceptors.response.use((response) => {
			requestRemove();
			return response;
		}, (error) => {
			requestRemove();
			return Promise.reject(error);
		});
	},
};
</script>
