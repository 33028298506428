function prepareNewId() {
	const id = (new Date().getTime()) % 1000000000;
	return `+${id}`;
}

function getRouteParams($route, idKey, retype = String) {
	let params = {};
	if ($route.params) {
		params = _.clone($route.params);
		if (idKey && params[idKey]) {
			const idValue = params[idKey];
			params.isNew = (idValue.toString().startsWith('+'));

			if (retype === Number) {
				if (!isNaN(idValue) && Number.isInteger(parseInt(idValue))) {
					params[idKey] = parseInt(idValue);
				}
			}
		}
	}
	if ($route.query) {
		params = _.extend(params, $route.query);
	}
	return params;
}

function addRouteParams(routeParams, params, niceUrl) {
	if (niceUrl) {
		if (!routeParams.params) {
			routeParams.params = params;
		} else {
			routeParams.params = _.extend(routeParams.params, params);
		}
		return routeParams;
	} else {
		routeParams.query = params;
		return routeParams;
	}
}

export default {
	install(Vue) {
		Vue.prototype.$prepareNewId = prepareNewId;
		Vue.prototype.$getRouteParams = getRouteParams;
		Vue.prototype.$addRouteParams = addRouteParams;
	},
};
