<template>
	<lba-dialog-edit
		:parentComponentId="currentComponentId"
		modal
		:title="$t('iconPicker')"
		:name="dialogName"
		@close="$emit('blur')"
		@submit="$emit('input', selectedIcon)"
	>
		<table class="icon-showcase-table">
			<tr
				v-for="row in rowCount"
				:key="row"
			>
				<td
					v-for="column in columnCount"
					:key="`${row}-${column}`"
					:data-r="row - 1"
					:data-c="column - 1"
					:class="{ empty: isEmpty(row, column) }"
					v-tooltip.bottom="getIcon(row, column) === 'icon-blank' ? $t('blankIcon') : null"
					:data-cy="`${currentComponentId}__row${row}__column${column}`"
					@click="selectIcon(row, column)"
				>
					<i :class="getClass(row, column)"></i>
				</td>
			</tr>
		</table>
	</lba-dialog-edit>
</template>

<style>
.empty i {
	cursor: default !important;
}
.icon-blank {
	width: 18px;
	height: 18px;
	border: 1px solid black;
}
.icon-blank.icon-selected {
	border-color: #0077db !important;
}
</style>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	name: 'LbaIconPicker',
	mixins: [ComponentIdentifier],
	props: {
		value: {
			type: String,
		},
		dialogName: {
			type: String,
			required: true,
		},
		allowEmpty: Boolean,
	},
	data() {
		return {
			rowCount: 0,
			columnCount: 0,
			iconList: [
				'icon-menu-module',
				'icon-menu-apps',
				'icon-menu-rows',
				'icon-menu-dashboard',
				'icon-menu-list',
				'icon-menu-task',
				'icon-menu-factory',
				'icon-menu-chart',
				'icon-menu-coins',
				'icon-menu-chart-pie',
				'icon-menu-calls',
				'icon-menu-headset',
				'icon-menu-archive3',
				'icon-menu-archive2',
				'icon-menu-archive',
				'icon-menu-scale',
				'icon-menu-card',
				'icon-menu-heart',
				'icon-menu-servercare-authorization',
				'icon-menu-users',
				'icon-menu-briefcase',
				'icon-menu-servercare-templates',
				'icon-menu-servercare-prices',
				'icon-menu-servercare-bill',
				'icon-menu-abook-companies',
				'icon-target',
				'icon-virtual-machine',
				'icon-mailboxes-whitelist',
				'icon-globe',
				'icon-connected',
			],
			selectedIcon: null,
		};
	},
	created() {
		if (this.allowEmpty) {
			this.iconList.push('icon-blank');
		}
		const root = Math.sqrt(this.iconList.length);
		this.rowCount = Math.round(root);
		this.columnCount = Math.ceil(root);
	},
	methods: {
		selectIcon(row, column) {
			const value = this.getIconValue(row, column);

			if (value !== undefined) {
				this.selectedIcon = value;
			}
		},
		getIconValue(row, column) {
			let value = null;

			if (row == null && column == null) {
				value = this.selectedIcon;
			} else {
				value = this.getIcon(row, column);
			}
			return value === 'icon-blank' ? null : value;
		},
		getIcon(row, column) {
			let icon = null;

			if (row == null && column == null) {
				icon = this.selectedIcon;
			} else {
				icon = this.iconList[(row - 1) * this.columnCount + (column - 1)];
			}
			return icon === null ? 'icon-blank' : icon;
		},
		isEmpty(row, column) {
			const value = this.iconList[(row - 1) * this.columnCount + (column - 1)];
			return value === undefined;
		},
		getClass(row, column) {
			const icon = this.getIcon(row, column);
			const classes = [];

			if (icon === undefined) {
				return classes;
			}

			if (icon === this.value) {
				classes.push('icon-current');
			} else if (icon === this.getIcon()) {
				classes.push('icon-selected');
			}
			classes.push(icon);
			return classes;
		},
	},
};
</script>
