async function fetchCached(vm, request, waitTime = 500) {
	let cancelled = false;
	let firstWarn = true;

	if (vm) {
		vm.$on('hook:beforeDestroy', () => { cancelled = true; });
	}

	/* eslint-disable-next-line no-unmodified-loop-condition */
	while (!cancelled) {
		try {
			const response = await request();

			if (response.status === 202) {
				if (firstWarn && !vm) {
					firstWarn = false;
					console.warn('[fetchCached] fetching from server response cache without vm (no beforeDestroy hook)');
				}
				await $sleep(waitTime);
				continue;
			}
			return response;
		} catch (error) {
			console.error('[fetchCached] error:', error);
			return error;
		}
	}
}

export default {
	install(Vue) {
		Vue.prototype.$fetchCached = fetchCached;
		window.$fetchCached = fetchCached;
	},
};
