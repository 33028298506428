<template>
	<label class="checkbox" :data-cy="`${currentComponentId}__checkboxLabel`">
		<input
			:data-cy="`${currentComponentId}__inputCheckbox`"
			type="checkbox"
			v-model="internalValue"
			:name="name"
			:title="title"
			:disabled="disabled"
			@focus="$emit('focus', $event)"
			@blur="$emit('blur', $event)"
			@change="$emit('change', $event)"
		/>
		<i class="icon-ok"></i>
		<span class="label">
			<i v-if="icon" :class="icon"></i>
			{{ label }}
			<i v-if="tooltip" class="icon-info" v-tooltip="tooltip"></i>
		</span>
	</label>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	name: 'Checkbox',
	mixins: [ComponentIdentifier],
	props: {
		value: {
			type: [Boolean, Number, String],
		},
		name: {
			type: String,
			required: true,
		},
		title: String,
		disabled: Boolean,
		label: String,
		icon: String,
		tooltip: [String, Object],
	},
	computed: {
		internalValue: {
			get() {
				return Boolean(this.value);
			},
			set(value) {
				this.$emit('input', Boolean(value));
				this.$emit('change', Boolean(value));
			},
		},
	},
};
</script>
