<template>
	<span>
		<span id="alertContainer" class="ng-cloak"></span>
		<router-view v-if="$route.fullPath.startsWith('/login')"></router-view>
		<div id="header">
			<div id="headerTop">
				<div id="menuButton" class="userSelectNone" :class="{close: overlay}" @click="toggleOverlay"><i></i>
				</div>
				<h1 id="logo">
					<router-link to="/">
						<span id="logoSmall"><i class="icon-logo"></i></span>
						<span id="logoBig">
							<span id="logoPrefix">{{ appTitlePrefix }}</span>
							<span id="logoSuffix logoSuffix-0">{{ appTitleSuffix }}</span>
						</span>
					</router-link>
				</h1>
			</div>
			<!-- TABY -->
			<div id="headerTabs">
				<lba-tabs
					v-if="userLoaded"
					:keepAlive="$refs.keepAlive"
					:menuModules="menuModules"
					@reloading-change="onTabsReloadingChange"
				/>
			</div>
			<div id="headerRight">
				<div id="headerDisconected" :title="$t('offlineInfo')" :class="{ active: !socketConnected }">
					<i class="icon-alert top-2"></i>&nbsp;
					<span>{{ $t('offline') }}</span>
				</div>
				<div
					id="headerDisconected"
					:title="$t('outOfDateInfo')"
					:class="{ active: !$versions.isActual || modulesChanged }"
					@click="reload()"
				>
					<i class="icon-alert top-2"></i>&nbsp;
					<span>{{ $t('outOfDate') }}</span>
				</div>
				<div
					id="headerDisconected"
					class="warn"
					:title="$t('su.suInfo', { user: this.$user.user_name})"
					:class="{ active: $user.original_user }"
				>
					<i class="icon-alert top-2"></i>&nbsp;
					<span>{{ $t('su.su') }}</span>
				</div>
				<div id="headerPlugins" v-if="$headerPlugins.length && $user.user_name && !$user.is_anonymous_user">
					<template v-for="(headerPlugin) in $headerPlugins">
						<component :key="headerPlugin.id" :is="headerPlugin.component"></component>
					</template>
				</div>
				<div id="user" v-tooltip="$t('userLogin') + $user.user_name">
					<span v-lba-expander.closeOnClick data-cy="app__expandUserMenu">
						<span id="userPhoto">
							<i class="icon-user"></i>
						</span>
						<!-- <span id="userName">
							{{
								($user.firstname || $user.lastname) ? ([
									$user.firstname, $user.lastname
								].join(' ')) : $user.user_name
							}}
						</span> -->
					</span>
					<div id="userMenu" class="headerMenu expand">
						<span id="userPhotoBig">
							<i class="icon-user"></i>
						</span>
						<h4>
							{{
								($user.firstname || $user.lastname) ? ([
									$user.firstname, $user.lastname
								].join(' ')) : $user.user_name
							}}
						</h4>
						<a class="headerMenuItem" @click="openSettings()" data-close data-cy="app__expandUserMenu__openSettings">
							<i class="icon16 icon-settings"></i>
							<span>
								{{ $t('settings.settings') }}
							</span>
						</a>
						<router-link to="/login" v-show="$user.is_anonymous_user" class="headerMenuItem"
							data-cy="app__expandUserMenu__login"
						>
							<i class="icon16 icon-login"></i>
							<span>
								{{ $t('log_in') }}
							</span>
						</router-link>
						<a v-show="$user.allow_su" @click="openSuMode()"
							class="headerMenuItem"
						>
							<i class="icon16 icon-users"></i>
							<span>
								{{ $t('su.switchUser') }}
							</span>
						</a>
						<a v-show="$user.original_user" @click="cancelSuMode()" class="headerMenuItem"
							data-cy="app__expandUserMenu__openDialogSuResetUser"
						>
							<i class="icon16 icon-user"></i>
							<span>
								{{ $t('su.cancel') }}
							</span>
						</a>
						<a class="headerMenuItem" @click="openAbout()" data-cy="app__expandUserMenu__openDialogAbout">
							<i class="icon-logo"></i>
							<span>
								{{ $t('settings.about') }}
							</span>
						</a>
						<a v-show="!$user.is_anonymous_user" @click="logout" class="headerMenuItem" data-cy="app__expandUserMenu__logout">
							<i class="icon16 icon-logout"></i>
							<span>
								{{ $t('log_out') }}
							</span>
						</a>
					</div>
					<!--
						pokud se ma loader zobrazovat, tak se mu musi pridat class .active
						classy .loadingFile a .loadingApp meni barvu loaderu. muzou byt pouzity obe najednou
					-->
					<!--TODO
					<span id="loader" :class="{
							active: app.$loadingIndicator.loading || app.$upload.active,
							loadingApp: app.$loadingIndicator.loading,
							loadingFile: app.$upload.active
						}"
					>
						<svg width="14px" height="14px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
							TODO<circle
								ng-attr-stroke_dashoffset="{{ app.uploadProgress() }}"
								stroke-width="2" cx="7" cy="7" r="5" fill="none" stroke-linecap="round"></circle>
						</svg>
					</span>-->
				</div>
			</div>
		</div>
		<div id="page" v-lba-loading class="" v-if="!$route.fullPath.startsWith('/login') && !$root.loadingLogin">
			<lba-menu v-if="userLoaded" :overlay="overlay" @toggle-overlay="toggleOverlay" v-model="menuModules">
			</lba-menu>
			<div id="menuOverlay" @click="toggleOverlay"></div>
			<div v-if="areTabsReady" id="main" tabindex="0" :class="{ menuOverlay: overlay }">
				<div class="page-info">
					<lba-breadcrumb v-if="userLoaded" main></lba-breadcrumb>
					<lba-history-bar parentComponentId="app"></lba-history-bar>
					<button v-if="showTabCloseButton()"
						data-cy="app__closeCurrentTab"
						class="button-cancel"
						@click.stop="$root.$emit('close-current-tab')">
					</button>
				</div>
				<div v-if="getAnyError()" class="page-content" data-cy="app__viewErrorWrap">
					<div class="error-wrap">
						<h1 v-if="getErrorType() === 'notFound'" data-cy="app__viewNotFound">
							{{ getError() || $t('viewNotFound') }}
						</h1>
						<h1 v-else-if="getErrorType() === 'permissionDenied'" data-cy="app__viewPermissionDenied">
							{{ getError() || $t('viewPermissionDenied') }}
						</h1>
						<h1 v-else-if="getErrorType() === 'serverError'" data-cy="app__viewServerError">
							{{ getError() || $t('viewServerError') }}
						</h1>
						<h1 v-else-if="getErrorType() === 'recordDeleted'" data-cy="app__viewRecordDeleted">
							{{ getError() || $t('viewRecordDeleted') }}
						</h1>
						<h1 v-else-if="getErrorType() === 'missingParameter'" data-cy="app__viewMissingParameter">
							{{ $t('viewMissingParameter', { params: getError() }) }}
						</h1>
						<h1 v-else-if="getErrorType() === 'wrongParameter'" data-cy="app__viewWrongParameter">
							{{ $t('viewWrongParameter', { params: getError() }) }}
						</h1>
					</div>
				</div>
				<lba-keep-alive v-if="userLoaded" ref="keepAlive">
					<router-view
						v-if="!$route.fullPath.startsWith('/login') && !$root.loadingLogin"
						v-show="!getAnyError()"
						:key="getKey()"
						@view-not-found="onError($event, 'notFound')"
						@view-permission-denied="onError($event, 'permissionDenied')"
						@view-server-error="onError($event, 'serverError')"
						@view-record-deleted="onError($event, 'recordDeleted')"
						@view-missing-parameter="onError($event, 'missingParameter')"
						@view-wrong-parameter="onError($event, 'wrongParameter')"
					/>
				</lba-keep-alive>
			</div>
			<!--lba-parse-attr-fix="i18n"-->
			<lba-dialog-modal
				parentComponentId="app"
				componentId="settings"
				name="settings"
				:title="$t('settings.user.settings')"
				@mounted="initSettingsDialog"
				modal
				@close="theme = $user.color_theme || 'theme-basic'"
			>
				<template #default="props">
					<div v-if="settingsScreen === 'gauth'" class="popup-body">
						<h4>{{ $t('settings.user.gauthQrCode') }}</h4>
						<p>{{ $t('settings.user.otp.scanQRCodeInfo') }}</p>
						<svg class="qrcode" v-bind:viewBox="`0 0 ${gauth.qr.size} ${gauth.qr.size}`">
							<path v-bind:d="gauth.qr.path" />
						</svg>
						<br>
						<div style="display: flex;justify-content: center;">
							<input
								class="half"
								:placeholder="$t('token')"
								type="text"
								v-model="testGauthToken"
								:data-cy="`${props.parentComponentId}__testGauth__inputText`"
							>
							<button type="button" class="buttonInverse" @click="testGauth()"
								:data-cy="`${props.parentComponentId}__testGauthToken`"
							>
								{{ $t('settings.testQr') }}
							</button>
							<i
								style="font-size: 20px"
								class="mt-1 ml-2"
								:class="{
									'icon-dialer-netProblem orange': ['notVerified', 'noAuthSecret', 'noToken'].includes(gauthStatus),
									'icon-ok green': gauthStatus === 'verified',
								}"
								v-tooltip="$t(`gAuthStatus.${gauthStatus}`)"
							></i>
						</div>
					</div>
					<div v-else-if="settingsScreen === 'refreshGauth'" class="popup-body">
						<h4>{{ $t('settings.user.gauthQrCode') }}</h4>
						<p>
							<i class="icon-dialer-netProblem red"></i>
							{{ $t('settings.user.refreshGauthWarning') }}</p>
					</div>
					<div v-else class="popup-body" style="padding-bottom: 50px;">
						<ValidationObserver ref="formQuickSetting">
							<form name="formQuickSetting">
								<div class="form" :key="settingsKey">
									<s>
										<small>{{ $t('settings.user.firstname') }}</small>
										<input type="text" v-model="$user.firstname"
											:data-cy="`${props.parentComponentId}__firstName__inputText`"
											@input="addAvoided('firstname', $user.firstname, $t('settings.user.firstname'));"
										>
									</s>
									<s>
										<small>{{ $t('settings.user.lastname') }}</small>
										<input type="text" v-model="$user.lastname"
											:data-cy="`${props.parentComponentId}__lastName__inputText`"
											@input="addAvoided('lastname', $user.lastname, $t('settings.user.lastname'));"
										>
									</s>
									<s>
										<small>{{ $t('settings.user.username') }}</small>
										<input type="text" v-model="$user.user_name" disabled
											:data-cy="`${props.parentComponentId}__userName__inputText`"
										>
									</s>
									<br>
									<s class="settings-lang" v-if="$user.allowedPerUserChanges.allow_language_per_user">
										<small>{{ $t('settings.user.language') }}</small>
										<select name="lang" v-model="$user.lang" :data-cy="`${props.parentComponentId}__language__select`">
											<option value="cs" :data-cy="`${props.parentComponentId}__language__select__optionCs`">
												česky
											</option>
											<option value="en" :data-cy="`${props.parentComponentId}__language__select__optionEn`">
												english
											</option>
										</select>
									</s>
									<s v-if="$user.allowedPerUserChanges.allow_timezone_per_user">
										<small>{{ $t('settings.user.timezone') }}</small>
										<select name="timezone" v-model="$user.timezone"
											:data-cy="`${props.parentComponentId}__timezone__select`"
										>
											<option v-for="(tz, index) in timezoneList" :key="tz"
												:data-cy="`${props.parentComponentId}__timezone__select__option${index}`"
											>{{ tz }}</option>
										</select>
									</s>
									<s v-if="$user.allowedPerUserChanges.allow_color_theme_per_user">
										<small>{{ $t('settings.user.theme') }}</small>
										<select name="theme" v-model="theme"
											:data-cy="`${props.parentComponentId}__theme__select`"
										>
											<option
												v-for="(th, index) in themes"
												:key="`theme-${index}`"
												:value="th.value"
												:data-cy="`${props.parentComponentId}__theme__select__option${index}`"
											>
												{{ th.label }}
											</option>
										</select>
									</s>
									<br>
									<s>
										<small>{{ $t('settings.user.email') }}</small>
										<ValidationProvider
											:name="$t('settings.user.email')"
											v-slot="{ invalid, errors }"
											:rules="{ email: true }"
										>
											<input
												:data-cy="`${props.parentComponentId}__email__inputText`"
												type="text"
												v-model="$user.email"
												@input="addAvoided('email', $user.email, $t('settings.user.email'));"
												:class="{ 'lba-invalid': invalid }">
											<span
												v-for="(err, index) in errors"
												:key="index"
												:data-cy="`${props.parentComponentId}__email__error${index}`"
												class="lba-messages"
											>{{ err }}</span>
										</ValidationProvider>
									</s>
									<s v-if="tfa.length">
										<small>{{ $t('settings.user.tfa') }}</small>
										<select
											@change="settingsChanged()"
											name="tfa"
											v-model="$user.tfa"
											:data-cy="`${props.parentComponentId}__tfa__select`"
										>
											<option :value="null" :data-cy="`${props.parentComponentId}__tfa__select__option0`">
												{{ $t('inactive') }}
											</option>
											<option
												v-for="(type, index) in tfa"
												:key="`tfa-${type.tfa_method}`"
												:value="type.tfa_method"
												:data-cy="`${props.parentComponentId}__tfa__select__option${index+1}`"
											>
												{{ $t(`tfaMethods.${type.tfa_method}`) }}
											</option>
										</select>
									</s>
									<s v-if="$user.tfa === 'gauth'">
										<small>{{ $t('settings.user.gauthQrCode') }}</small>
										<i
											style="cursor: pointer;"
											class="icon-visible mt-2"
											v-tooltip="$t('settings.user.showqr')"
											@click="openGauth()"></i>
									</s>
								</div>
								<div class="form" v-show="$user.extraSettings.length > 0">
									<s class="wide">
										<lba-extra-setting
											v-for="(setting, index) in $user.extraSettings"
											v-bind:key="index"
											:parentComponentId="props.parentComponentId"
											:componentId="`extraSetting${index}`"
											v-bind:setting="setting"
											v-bind:user="$user"
										></lba-extra-setting>
									</s>
								</div>
								<div v-if="$user.auth_method === null || $user.auth_method === 'agent'">
									<h4>
										{{ $t('settings.user.passwordChange') }}
									</h4>
									<div class="form" style="max-width: 544px;">
										<s>
											<small>{{ $t('settings.user.currentPassword') }}</small>
											<lba-input-password
												v-model="$user.current_password"
												name="oldPass"
												:readable="false"
												writeable
												:parentComponentId="props.parentComponentId"
												componentId="oldPassword"
												:rules="passwordRules"
											></lba-input-password>
										</s>
										<ValidationProvider
											:name="$t('settings.user.newPassword')"
											v-slot="{ invalid, errors }"
											:rules="{ regex: passwordRegex, notContains: avoidedItems }"
										>
											<s>
												<small>{{ $t('settings.user.newPassword') }}</small>
												<lba-input-password
													v-model="$user.password"
													name="newPass"
													:readable="false"
													writeable
													:parentComponentId="props.parentComponentId"
													componentId="newPassword"
													:class="{ 'lba-invalid': invalid }"
													@input="testingPassword = $user.password;"
													:rules="passwordRules"
												></lba-input-password>
												<template v-if="invalid">
													<!-- ERRORS FROM PASSWORD MIXIN - PASSWORD RULES -->
													<span
														v-for="(err, index) in passwordErrors"
														:key="`pwe-${index}`"
														:data-cy="`${props.parentComponentId}__password__error${errors.length + index}`"
														class="lba-messages"
													>{{ err }}</span>
													<span
														v-for="(err, index) in errors"
														:key="index"
														:data-cy="`${props.parentComponentId}__password__error${index}`"
														class="lba-messages"
													>{{ err }}</span>
												</template>
											</s>
										</ValidationProvider>
									</div>
								</div>
							</form>
						</ValidationObserver>
					</div>
					<div class="popup-footer">
						<template v-if="settingsScreen === 'refreshGauth'">
							<button type="button" class="buttonRed" @click="openGauth(true)"
								:data-cy="`${props.parentComponentId}__accept-reset-qr`"
							>
								{{ $t('ok') }}
							</button>
							<button type="button" class="button buttonInverse" @click="settingsScreen = 'gauth'"
								:data-cy="`${props.parentComponentId}__decline-reset-qr`"
							>
								{{ $t('cancel') }}
							</button>
						</template>
						<template v-else-if="settingsScreen === 'gauth'" class="popup-body">
							<button type="button" class="button buttonInverse" @click="settingsScreen = 'refreshGauth'"
								:data-cy="`${props.parentComponentId}__reset-qr`"
							>
								{{ $t('settings.user.otp.newQRCode') }}
							</button>
							<button type="button" class="button buttonInverse" @click="hideQr()"
								:data-cy="`${props.parentComponentId}__hide-qr`"
							>
								{{ $t('settings.user.otp.hideQr') }}
							</button>
						</template>
						<template v-else>
							<button type="button" class="button" name="confirm" @click="onSubmit(false)"
								:data-cy="`${props.parentComponentId}__ok`"
							>
								{{ $t('ok') }}
							</button>
							<button type="button" class="button buttonInverse" @click="$root.$emit('dialog-close', 'settings');"
								:data-cy="`${props.parentComponentId}__closeDialog`"
							>
								{{ $t('close') }}
							</button>
						</template>
					</div>
				</template>
			</lba-dialog-modal>
		</div>
		<!-- FORCE CHANGE PASSWORD  -->
		<lba-dialog-modal
			parentComponentId="app"
			componentId="forcePasswordChange"
			name="force_password_change"
			:title="$t('settings.user.passwordChange')"
			@mounted="initSettingsDialog"
			modal
			withoutClose
		>
			<template #default="props">
				<div class="popup-body">
					<ValidationObserver ref="formForcedPassChange">
						<form name="formForcedPassChange">
							<div class="form" style="max-width: 544px; padding: 40px 0;">
								<s>
									<small>{{ $t('settings.user.currentPassword') }}</small>
									<!-- <input v-model="$user.current_password" type="password" name="oldPass"> -->
									<lba-input-password v-model="current_password" name="oldPass" :readable="false" writeable
										:parentComponentId="props.parentComponentId" componentId="oldPassword"
									></lba-input-password>
								</s>
								<ValidationProvider
									:name="$t('settings.user.newPassword')"
									v-slot="{ invalid, errors }"
									:rules="{ regex: passwordRegex, notContains: avoidedItems }"
								>
									<s>
										<small>{{ $t('settings.user.newPassword') }}</small>
										<lba-input-password
											v-model="password"
											name="newPass"
											:readable="false"
											writeable
											:parentComponentId="props.parentComponentId"
											componentId="newPassword"
											:class="{ 'lba-invalid': invalid }"
											@input="testingPassword = password;"
											:rules="passwordRules"
										></lba-input-password>
										<template v-if="invalid">
											<!-- ERRORS FROM PASSWORD MIXIN - PASSWORD RULES -->
											<span
												v-for="(err, index) in passwordErrors"
												:key="`pwe-${index}`"
												:data-cy="`${props.parentComponentId}__password__error${errors.length + index}`"
												class="lba-messages"
											>{{ err }}</span>
											<span
												v-for="(err, index) in errors"
												:key="index"
												:data-cy="`${props.parentComponentId}__password__error${index}`"
												class="lba-messages"
											>{{ err }}</span>
										</template>
									</s>
								</ValidationProvider>
							</div>
						</form>
					</ValidationObserver>
				</div>
				<div class="popup-footer">
					<button type="button" class="buttonBig" @click="onForcedPassChange" :disabled="!current_password || !password"
						:data-cy="`${props.parentComponentId}__changePassword`"
					>
						{{ $t('changePassword') }}
					</button>
					<button type="button" class="buttonBig buttonRed" @click="logout" :data-cy="`${props.parentComponentId}__logout`">
						{{ $t('logout') }}
					</button>
				</div>
			</template>
		</lba-dialog-modal>
		<lba-dialog-modal name="idle_dialog" parentComponentId="app" componentId="idle">
			<div class="popup-header" style="height: 40px;">
			</div>
			<div class="popup-body">
				<div class="delay">
					<div class="time-spinner"></div>
				</div>
				<div
					class="mb-5"
				>{{ $t('settings.user.idleDialog') }} {{ $d(new Date(this.idleCountdownTime), 'timeDiff') }} {{ $t('minutes') }}</div>
			</div>
		</lba-dialog-modal>
		<lba-dialog-modal name="suElsewhere_dialog" parentComponentId="app" componentId="suElsewhere" modal withoutClose>
			<template #default="props">
				<div class="popup-header">
					<h3>{{ $t('su.windowBlocked') }}</h3>
				</div>
				<div class="popup-body" style="max-width: 500px;">
					{{ $t('su.userChanged') }}
				</div>
				<div class="popup-footer">
					<button type="button" class="buttonBig" @click="reload"
						:data-cy="`${props.parentComponentId}__reload`"
					>
						{{ $t('su.reload') }}
					</button>
				</div>
			</template>
		</lba-dialog-modal>
		<lba-dialog-modal name="about" class="about" :title="$t('settings.about')" parentComponentId="app" componentId="about">
			<template #default="props">
				<div class="popup-body">
					<h2
						style="font-size: 2rem; margin-bottom: 10px; margin-top: 0"
					>{{ appTitlePrefix }}<span class="blue">{{ appTitleSuffix }}</span></h2>
					<span style="display: inline-block;">{{ $t('settings.version') + ` : ${about.version}` }}</span>
					<span
						v-if="isClipboardAvailable()"
						@click.stop="copyModulesVersions"
						class="hover-pointer"
						style="margin-left: 10px;"
						v-tooltip="$t('settings.copyVersionsToClipboard')"
					>
						<i class="icon-clone"></i>
					</span>
					<p class="note mb-2 mt-3">{{ $t('settings.modules') }}:</p>
					<p
						v-for="(mod, index) in about.modules"
						:key="`about-module-${index}`"
					>
						<strong>
							{{ `${$t(`${mod.module}.menu.${mod.module}`)}  -  ${mod.version}` }}
						</strong>
					</p>
					<p class="note mt-4">
						Copyright © {{ new Date().getFullYear() }} LinuxBox.cz, s.r.o., {{ $t('settings.copyright') }}<br><br>
						<a href="https://www.linuxbox.cz/" target="_blank">www.linuxbox.cz</a>
					</p>
				</div>
				<div class="popup-footer">
					<button type="button" class="buttonBig" v-lba-dialog-close="'about'"
						:data-cy="`${props.parentComponentId}__closeDialog`"
					>
						{{ $t('ok') }}
					</button>
				</div>
			</template>
		</lba-dialog-modal>
		<!-- SU user -->
		<lba-dialog-modal
			parentComponentId="app"
			componentId="suMode"
			name="suMode"
			:title="$t('su.switchUser')"
			modal
		>
			<template #default="props">
				<div class="popup-body">
					<p style="max-width: 500px">
						{{ $t('su.switchWarning') }}
					</p>
					<br>
					<s>
						<small>{{ $t('settings.user.user') }}</small>
						<select
							name="suUser"
							v-model="suUser"
							:data-cy="`${props.parentComponentId}__suUser__select`"
						>
							<option
								v-for="(user, index) in suUsers"
								:key="`suUser-${user.user_name}`"
								:value="user.user_name"
								:data-cy="`${props.parentComponentId}__suUser__select__option${index}`"
							>
								{{ user.user_name }}
							</option>
						</select>
					</s>
				</div>
				<div class="popup-footer">
					<button type="button" class="button" @click="setSuMode()"
						:data-cy="`${props.parentComponentId}__confirm_su_user__button`"
					>
						{{ $t('ok') }}
					</button>
					<button type="button" class="button buttonInverse" @click="closeSuMode()"
						:data-cy="`${props.parentComponentId}__decline_su_user__button`"
					>
						{{ $t('cancel') }}
					</button>
				</div>
			</template>
		</lba-dialog-modal>
		<portal-target
			id="rootPortalTarget"
			name="root"
			multiple
		></portal-target>
	</span>
</template>

<style>
/* .page-info {
	@change="portalTargetChange"
	height: 32px;
} */
</style>

<script>
import UserModel from './models/User';
import ColorThemes from './mixins/ColorThemes';
import TabErrors from './mixins/TabErrors';
import PasswordMixin from './mixins/Password';

export default {
	data() {
		return {
			state: false,
			timezoneList: [],
			overlay: false,
			userLoaded: false,
			idleCountdownInterval: null,
			idleCountdownTime: 0,
			idleStartTime: null,
			menuModules: null,
			tabs: null,
			rootPortalTarget: null,
			observer: null,
			closeListener: null,
			dirtyTabs: [],
			tabsReloading: false,
			currentPath: this.$route.path,
			userModel: null,
			areTabsReady: false,
			current_password: null,
			password: null,
			socketConnected: true,
			appTitlePrefixDefault: 'LinuxBox',
			appTitleSuffixDefault: 'Admin4',
			appTitlePrefix: null,
			appTitleSuffix: null,
			about: [],
			theme: 'theme-basic',
			themes: null,
			modulesChanged: false,
			tfa: [],
			gauth: {
				size: 47,
				path: '',
			},
			settingsScreen: null,
			settingsKey: this.$generateUID(),
			avoidedItems: [],
			avoidedItemsWhole: {},
			testGauthToken: '',
			gauthStatus: 'none',
			suSocket: null,
			suUser: null,
			suUsers: [],
			suElsewhere: false,
		};
	},
	mixins: [ColorThemes, TabErrors, PasswordMixin],
	watch: {
		$route() {
			if (!this._inactive) {
				const { route } = this.$router.resolve({ name: this.$route.matched[0].name, params: this.$route.params });

				if (this.currentPath.startsWith('/login') && !route.path.startsWith('/login')) {
					this.reloadWhenPermissionDenied();
				}
				this.currentPath = route.path;
			}
		},
		theme(newValue) {
			this.themes.map((th) => {
				document.body.classList.remove(th.value);
			});
			document.body.classList.add(newValue);
			this.$root.$emit('theme-changed');
		},
		suElsewhere(newValue) {
			if (newValue === true) {
				this.$root.$emit('dialog-open', { name: 'suElsewhere_dialog' });
			} else {
				this.$root.$emit('dialog-close', 'suElsewhere_dialog');
			}
		},
	},
	async created() {
		this.$root.app = this;
		this.$user.is_dark_theme = this.isDarkTheme(this.theme);
		document.body.classList.add(this.theme);
		if (typeof LBTray === 'object') {
			document.body.classList.add('tray');
		}
		// function to prevent closing tab
		this.closeListener = function (e) {
			e.preventDefault();
			e.returnValue = '';
		};

		this.$root.$once('user-loaded', this.init);
		this.$root.$once('servers-loaded', this.onServerLoaded);
		this.$root.$listen('tabs.ready', this.tabsReady, this);
		this.$root.$listen('socket.state-changed', (state) => { this.socketConnected = state; }, this);
		this.$root.$listen('user-loaded', this.userUpdated, this);
		this.$root.$listen('socket-opened', this.userUpdated, this);
		this.$root.$listen('user-idle', this.onIdle, this);
		this.$root.$listen('user-active', this.onActive, this);
		this.$root.$listen('go-fullscreen', this.onGoFullscreen, this);
		this.$root.$listen('exit-fullscreen', this.onExitFullscreen, this);
		this.$root.$listen('prevent-closing', this.preventClosing, this);
		this.$root.$listen('allow-closing', this.allowClosing, this);
		this.$root.$listen('allow-closing-keys', this.allowClosingKeys, this);
		this.$root.$listen('permissions.modules-changed', () => { this.modulesChanged = true; }, this);
		this.$root.$listen('dialog-open', this.onDialogOpen, this);

		document.addEventListener('fullscreenchange', this.onFullscreenChange);
		document.addEventListener('mozfullscreenchange', this.onFullscreenChange);
		document.addEventListener('MSFullscreenChange', this.onFullscreenChange);
		document.addEventListener('webkitfullscreenchange', this.onFullscreenChange);

		this.$user.queryAction('timezoneList').then((data) => {
			this.timezoneList = data;
		}).catch((err) => {
			console.error('Timezone list load error:', err);
		});

	},
	mounted() {
		this.$notify.setContainer();
		this.rootPortalTarget = document.getElementById('rootPortalTarget');

		this.observer = new MutationObserver(this.rootPortalTargetMutation);
		this.observer.observe(this.rootPortalTarget, { childList: true });

		this.userModel = new UserModel(this.$http);
	},
	async beforeDestroy() {
		if (this.suSocket) {
			await this.suSocket.unsubscribe();
			this.suSocket = null;
		}
	},
	methods: {
		showTabCloseButton() {
			return !this.$route.meta.hideCloseButton && this.tabs && this.tabs.showCloseButton(this.tabs.activeTab);
		},
		onTabsReloadingChange(state) {
			this.tabsReloading = state;
		},
		rootPortalTargetMutation(mutations) {
			if (mutations.find((mutation) => mutation.addedNodes.length > 0)) {
				const IDs = Array.prototype.map.call(this.rootPortalTarget.children, (child) => child.id);
				this.$root.$emit('portal-target.change', IDs);
			}
		},
		tabsReady() {
			this.tabs = this.$root.$tabs;
			this.areTabsReady = true;
		},
		getKey(route = this.$route) {
			let path = null;

			if (route.matched.length < 2) {
				path = route.path;
			} else {
				const matched = route.matched[0];
				const resolved = this.$router.resolve({ name: matched.name, params: route.params });
				path = resolved.route.path;
			}

			if (this.tabsReloading) {
				const uid = this.$generateUID();
				path += uid;
				setTimeout(async () => {
					await this.$nextTick();
					this.$root.$emit('keep-alive.remove-cache', path);
					await this.$nextTick();
					this.tabsReloading = false;
				}, 0);
			}
			return path;
		},
		init() {
			this.userLoaded = true;

			// su socket start
			if (!this.suSocket && (this.$user.allow_su || this.$user.original_user)) {
				const attrs = {
					uid: this.$user.role_uid,
					original_user: this.$user.original_user,
				};
				this.suSocket = this.$socket.on('/su/switched', attrs, (message) => {
					console.log('su socket message: ', message);
					if (message.uid !== null) {
						this.suElsewhere = true;
					} else {
						this.suElsewhere = false;
					}
				});
			}
		},
		onServerLoaded() {
			this.refreshPasswordServerSettings();
			this.checkServers();
			const serverSettings = _.first(this.$root.servers);

			// nastaveni favicony podle tematu (favicony musi byt vsechny ve slozce public/images/ a musi mit jmeno favicon-jmenoTematu.png)
			const theme = this.$root.servers[0].theme || 'linuxbox';
			document.querySelector(`link[rel*='icon']`).href = `/images/favicon-${theme}.png`;

			if (serverSettings && serverSettings.app_title_defined) {
				this.appTitlePrefix = serverSettings.app_title_prefix || null;
				this.appTitleSuffix = serverSettings.app_title_suffix || null;

			} else {
				this.appTitlePrefix = this.appTitlePrefixDefault;
				this.appTitleSuffix = this.appTitleSuffixDefault;

			}
			document.title = `${this.appTitlePrefix || ''}${this.appTitleSuffix || ''}`;
		},
		initSettingsDialog() {
			this.initColorThemes();
			if (this.$user.force_password_change === 'true' || this.$user.force_password_change === true) {
				this.$root.$emit('dialog-open', { name: 'force_password_change' });
			}
		},
		checkServers() {
			this.$root.servers.forEach((server) => {
				// is timeDiff enabled?
				if (server.timeDiff && server.timeDiff > 10000 && server.timeCheckEnabled) {
					this.$notify.warn(this.$t('badClientTime'));
				}
				// shoul I ask for notifications permissions on start?
				if (server.askNotifications && !this.$desktopNotify.isInitialized) {
					this.$desktopNotify.init();
				}
			});
		},
		toggleOverlay() {
			this.overlay = !this.overlay;
			this.$root.$emit('toggle-overlay', this.overlay);
		},
		async reloadWhenPermissionDenied() {
			await this.$nextTick();
			if (
				this.getErrorType() === 'permissionDenied' ||
				this.$root.$tabs.anyTabViewError(this.$root.$tabs.activeTab, 'permissionDenied')
			) {
				this.$root.$emit('tabs.reload', true);
			}
		},
		userUpdated() {
			this.theme = this.$user.color_theme || 'theme-basic';
			this.$user.is_dark_theme = this.isDarkTheme(this.theme);
			this.$root.$emit('theme-changed');
			this.$forceUpdate();
			this.initSettingsDialog();
			this.addAvoided('firstname', this.$user.firstname, this.$t('settings.user.firstname'));
			this.addAvoided('lastname', this.$user.lastname, this.$t('settings.user.lastname'));
			this.addAvoided('login', this.$user.id, this.$t('settings.user.login'));
			this.addAvoided('email', this.$user.email, this.$t('settings.user.email'));
		},
		toggle(state) {
			this.state = state;
		},
		logout() {
			this.theme = 'theme-basic';
			this.$user.is_dark_theme = this.isDarkTheme(this.theme);
			this.$root.$emit('theme-changed');
			// clear temp variables for force password change
			this.password = null;
			this.current_password = null;
			this.$root.$emit('dialog-close', 'force_password_change');
			// logout user
			this.$user.logout();
			this.$routerWrap.push({ name: 'login' });
		},
		reload() {
			window.location.reload();
		},
		async onSubmit(force = false) {
			if (!force) {
				const valid = await this.$refs.formQuickSetting.validate();
				if (!valid) {
					this.$root.$emit('content.validationFailed');
					return;
				}
			} else {
				const valid = await this.$refs.formForcedPassChange.validate();
				if (!valid) {
					this.$root.$emit('content.validationFailed');
					return;
				}
			}
			this.$user.color_theme = this.theme;
			this.$user.is_dark_theme = this.isDarkTheme(this.theme);

			this.$user.updateUser().then(() => {
				this.$user.load(false).then(() => {
					if (this.$user.current_password && this.$user.password) {
						delete this.$user.current_password;
						delete this.$user.password;
						this.logout();
						this.$root.$emit('dialog-close', 'force_password_change');
						this.$notify.success(this.$t('passChanged'));
					} else {
						this.$root.$emit('content.saved', { reload: false });
					}
					this.$root.$emit('dialog-close', 'settings');
					this.$root.$emit('user.updated');
					this.$root.$emit('user.settings-updated');
				});
			// already handled in $http interceptors
			}).catch(() => {});
		},
		onIdle() {
			if (!this.$user.user_name || this.$user.is_anonymous_user) {
				return;
			}
			this.$root.$emit('dialog-open', { name: 'idle_dialog' });
			this.idleCountdownTime = 300000;
			this.idleStartTime = new Date();
			this.idleCountdownInterval = setInterval(() => {
				this.idleCountdownTime = this.idleStartTime.getTime() + 300000 - new Date().getTime();
				if (this.idleCountdownTime < 1) {
					this.$root.$emit('dialog-close', 'idle_dialog');
					clearInterval(this.idleCountdownInterval);
					this.idleCountdownInterval = null;
					this.logout();
				}
			}, 1000);
		},
		onActive() {
			this.$root.$emit('dialog-close', 'idle_dialog');
			if (this.idleCountdownInterval) {
				clearInterval(this.idleCountdownInterval);
			}
			this.idleStartTime = null;
		},
		onFullscreenChange() {
			this.$root.setIsFullscreen();
			if (!this.$root.isFullscreen) {
				document.body.classList.remove('fullscreen');
			}
		},
		onGoFullscreen() {
			document.body.classList.add('fullscreen');

			if (this.$root.isFullscreen) return;
			const { documentElement } = document;
			if (documentElement.requestFullscreen) {
				documentElement.requestFullscreen();
			} else if (documentElement.mozRequestFullScreen) {
				documentElement.mozRequestFullScreen();
			} else if (documentElement.webkitRequestFullScreen) {
				documentElement.webkitRequestFullScreen();
			} else if (documentElement.msRequestFullscreen) {
				documentElement.msRequestFullscreen();
			}
		},
		onExitFullscreen() {
			document.body.classList.remove('fullscreen');

			if (!this.$root.isFullscreen) return;
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		},
		preventClosing(tabInfo) {
			if (!this.dirtyTabs.length) {
				window.addEventListener('beforeunload', this.closeListener, true);
			}
			if ((this.dirtyTabs.indexOf(tabInfo) === -1)) {
				this.dirtyTabs.push(tabInfo);
			}
		},
		allowClosing(tabInfo) {
			this.dirtyTabs = this.dirtyTabs.filter((el) => el !== tabInfo);
			if (!this.dirtyTabs.length) {
				window.removeEventListener('beforeunload', this.closeListener, true);
			}
		},
		allowClosingKeys(keys) {
			this.dirtyTabs = this.dirtyTabs.filter((path) => !keys.includes(path));
			if (!this.dirtyTabs.length) {
				window.removeEventListener('beforeunload', this.closeListener, true);
			}
		},
		async openSettings() {
			await this.$user.load(false);
			let resp = await this.userModel.getAuth();
			this.tfa = _.filter(resp.data.tfa_methods, 'enabled');
			resp = await this.userModel.getAvailableExtens(this.$user.role_uid);
			this.$user.available_extensions = resp.data;

			this.$root.$emit('dialog-open', { name: 'settings' });
			this.settingsScreen = null;
		},
		onForcedPassChange() {
			this.$user.password = this.password;
			this.$user.current_password = this.current_password;
			this.password = null;
			this.current_password = null;
			this.onSubmit(true);
		},
		async openAbout() {
			const resp = await this.$http.get(`lbadmin/about`);
			this.about = resp.data;
			this.$root.$emit('dialog-open', { name: 'about' });
		},
		async openGauth(renew = false) {
			await this.$user.load(false);
			this.userModel.getGauthQr(renew)
				.then((resp) => {
					this.gauth = resp.data;
					this.$user.tfa_secret = this.gauth.token;
					this.settingsScreen = 'gauth';
				});
		},
		hideQr() {
			this.settingsScreen = null;
			this.$user.tfa = 'gauth';
			this.gauthStatus = 'none';
			this.testGauthToken = null;
		},
		async settingsChanged() {
			await this.$nextTick();
			this.settingsKey = this.$generateUID();
		},
		addAvoided(key, value, label) {
			this.avoidedItemsWhole[key] = { value, label };

			const arr = [];
			_.forEach(this.avoidedItemsWhole, (el) => {
				if (el.value && el.value.length > 2) arr.push(el.value);
			});

			this.avoidedItems = arr;
		},
		testGauth() {
			this.userModel.testGauthToken({ token: this.testGauthToken, auth_secret: this.gauth.token })
				.then((resp) => {
					const status = resp.data.status;
					if (['verified', 'noToken', 'noAuthSecret', 'notVerified'].includes(status)) {
						this.gauthStatus = status;
					} else {
						this.gauthStatus = 'none';
					}
				});
		},
		onDialogOpen(opts) {
			if (opts.name === 'settings') {
				this.initColorThemes();
			}
		},
		async openSuMode() {
			this.suUser = this.$user.suUser || null;

			const resp = await this.userModel.getSuUsers();
			this.suUsers = resp.data;

			this.$root.$emit('dialog-open', { name: 'suMode' });
		},
		cancelSuMode() {
			this.userModel.resetSu();
			this.reload();
		},
		setSuMode() {
			// set su mode
			if (this.$user.suUser === null) {
				this.userModel.resetSu();
			} else {
				this.userModel.setSuUser(this.suUser)
					.then((err) => {
						console.error(err);
						this.$notify.success(this.$t('suSuccess'));
						this.$user.suUser = this.suUser;
						this.$root.$emit('dialog-close', 'suMode');
						this.reload();
					})
					.catch((err) => {
						console.error(err);
						this.$notify.warn(this.$t('suError'));
						this.suUser = this.$user.suUser;
					});
			}
		},
		closeSuMode() {
			this.suUser = this.$user.suUser;
			this.$root.$emit('dialog-close', 'suMode');
		},
		isClipboardAvailable() {
			return (navigator && navigator.clipboard);
		},
		copyModulesVersions() {
			if (this.about && this.about.version) {
				const versions = [
					`url: ${window.location.href}`,
					`browser: ${(navigator && navigator.userAgent) || '-'}`,
					`user: ${(this.$user && this.$user.user_name) || '-'}`,
					`versions:`,
					`  lbadmin: ${this.about.version}`,
				];
				if (this.about.modules) {
					_.forEach(this.about.modules, (module) => {
						versions.push(`  ${module.module}: ${module.version}`);
					});
				}
				const versionsJoin = versions.join('\n');
				if (navigator && navigator.clipboard) {
					navigator.clipboard.writeText(versionsJoin);
					this.$notify.success(this.$t('settings.copied'));
				}
			}
		},
	},
};
</script>
