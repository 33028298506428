/*
Object2D
{
	x: Number,
	y: Number,
	width: Number,
	height: Number,
}
*/
function detectRectangleWindowCollision(rect, box = { x: 0, y: 0, width: window.innerWidth, height: window.innerHeight }) {
	if (!rect) {
		return null;
	}

	let left = false;
	let right = false;
	let top = false;
	let bottom = false;

	if (rect.x < box.x) {
		left = true;
	}
	if (rect.x + rect.width > box.x + box.width) {
		right = true;
	}

	if (rect.y < box.y) {
		top = true;
	}
	if (rect.y + rect.height > box.y + box.height) {
		bottom = true;
	}

	if (left || right || top || bottom) {
		return { left, right, top, bottom };
	}

	return null;
}

function handleRectangleWindowCollision(rect, collision, box = { x: 0, y: 0, width: window.innerWidth, height: window.innerHeight }) {
	if (!collision) {
		return rect;
	}

	if (collision.left) {
		rect.x = box.x;
	} else if (collision.right) {
		rect.x = box.width - rect.width;
	}

	if (collision.top) {
		rect.y = box.y;
	} else if (collision.bottom) {
		rect.y = box.height - rect.height;
	}

	return rect;
}

export default {
	install(Vue) {
		Vue.prototype.$detectRectWindowCollision = detectRectangleWindowCollision;
		Vue.prototype.$handleRectWindowCollision = handleRectangleWindowCollision;
	},
};
