function getRecommendedProviders(observer, recommendedProviders = []) {
	_.forEach(observer.refs, (provider, key) => {
		if (provider.$el.getAttribute('data-required-recommended')) {
			recommendedProviders.push({ provider, key: provider.name || '' });
		}
	});
	_.forEach(observer.observers, (nestedObserver) => {
		getRecommendedProviders(nestedObserver, recommendedProviders);
	});
	return recommendedProviders;
}

function getEmptyRecommendedKeys(observer) {
	const recommendedProviders = getRecommendedProviders(observer);
	const recommendedKeys = [];
	const multiCheckboxGroups = {};
	_.forEach(recommendedProviders, ({ provider, key }) => {
		const multiCheckboxGroup = provider.$el.getAttribute('data-multi-checkbox');
		if (!_.isEmpty(multiCheckboxGroup)) {
			if (_.isEmpty(multiCheckboxGroups[multiCheckboxGroup])) {
				multiCheckboxGroups[multiCheckboxGroup] = [];
			}
			multiCheckboxGroups[multiCheckboxGroup].push(provider);
		} else if (
			provider.value == null ||
			([Array, Object, String].includes(provider.value.constructor) && _.isEmpty(provider.value))
		) {
			recommendedKeys.push(key);
		}
	});
	_.forEach(multiCheckboxGroups, (group, groupKey) => {
		if (!_.some(group, (provider) => provider.value)) {
			recommendedKeys.push(groupKey);
		}
	});
	return recommendedKeys;
}

export default {
	install(Vue) {
		Vue.prototype.$getRecommendedProviders = getRecommendedProviders;
		Vue.prototype.$getEmptyRecommendedKeys = getEmptyRecommendedKeys;
	},
};
