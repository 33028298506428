<template>
	<portal to="root">
		<div v-if="visible" v-show="isActive" class="popup-modal" :class="{ ...rootClass, animate }"
			:data-cy="currentComponentId"
			@click="onNodeClick"
		>
			<div class="popup-dialog">
				<div class="popup-content" @click="onContentNodeClick" :class="classList" :style="popupBodyStyle"
					:data-cy="`${currentComponentId}__popup-content`"
				>
					<i v-if="!withoutClose" class="popupClose icon-cancel" v-on:click="close" :data-cy="`${currentComponentId}__close`"></i>
					<div v-if="title && !titleHtml" class="popup-header">
						<h2>{{ title }}</h2>
					</div>
					<div v-if="!title && titleHtml" class="popup-header">
						<h2 v-html="titleHtml"></h2>
					</div>
					<slot :parentComponentId="`${currentComponentId}__slotDefault`"></slot>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
import Dialog from '../mixins/Dialog';

export default {
	mixins: [ComponentIdentifier, Dialog],
	props: {
		name: {
			type: String,
			required: true,
		},
		modal: {
			type: Boolean,
			required: false,
			default: false,
		},
		title: String,
		titleHtml: String,
		classList: {
			type: Array,
		},
		popupBodyStyle: String,
		withoutClose: {
			type: Boolean,
			required: false,
			default: false,
		},
		closeOnEscape: {
			type: Boolean,
			default: true,
		},
		onOpen: {
			type: Function,
			required: false,
			default: () => {},
		},
		onClose: {
			type: Function,
			required: false,
			default: () => {},
		},
	},
	data() {
		return {
			visible: false,
			animate: false,
			active: false,
			node: null,
			contentNode: null,
			ignoreClick: false,
		};
	},
	mounted() {
		this.$root.$listen('dialog-open', this.onDialogOpen, this);
		this.$root.$listen('dialog-close', this.onDialogClose, this);

		if (this.closeOnEscape) {
			document.addEventListener('keydown', this.onKeyDown);
		}
	},
	beforeDestroy() {
		if (this.closeOnEscape) {
			document.removeEventListener('keydown', this.onKeyDown);
		}
	},
	methods: {
		onKeyDown(event) {
			if (event.key === 'Escape' && this.visible) {
				this.close();
			}
		},
		onNodeClick() {
			if (!this.modal && !this.ignoreClick) {
				this.close();
			}
		},
		onContentNodeClick() {
			this.ignoreClick = true;
		},
		onDialogOpen(arg) {
			if (arg.name === this.name) {
				this.open();
			}
		},
		onDialogClose(name) {
			if (name === this.name) {
				this.close();
			}
		},
		open() {
			if (this.visible) return;
			// console.debug('open dialog:', this.name);
			this.animate = false;
			this.visible = true;
			setTimeout(() => {
				this.animate = true;
			}, 0);
			this.onOpen();
			this.$emit('open', this.name);
		},
		close(event, quiet) {
			if (!this.visible) return;
			// console.debug('close dialog:', this.name);
			const closeTimeout = (quiet) ? 0 : 200;
			this.animate = false;
			setTimeout(() => {
				this.visible = false;
			}, closeTimeout);
			this.onClose();
			this.$emit('close', this.name);
		},
	},
};
</script>
