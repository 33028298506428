<template>
	<component :is="noDialog ? 'div' : 'lba-dialog'" :name="name" modal class="lba-dnd-two-lists"
		:parentComponentId="noDialog ? null : currentComponentId" :data-cy="noDialog ? currentComponentId : null"
		@open="checkSearch"
	>
		<h4 class="mb-4" v-if="!_.isEmpty(dialogTitle)">{{ dialogTitle }}</h4>
		<div class="row" style="height: calc(100% - 140px)">
			<div class="col-5 column" :style="listMaxHeightStyle">
				<div class="title-wrapper">
					<label class="checkbox">
						<input type="checkbox" v-model="isCheckedAll1" @change="toggleCheckAll(1)"
							:data-cy="`${currentComponentId}__checkedAll1__inputCheckbox`"
						>
						<i class="icon-ok"></i>
						<span class="label">{{ listTitle1 }}</span>
					</label>
				</div>
				<input
					:data-cy="`${currentComponentId}__search1__inputText`"
					class="search-input"
					type="text"
					:placeholder="$t('search')"
					v-model="searchValue1"
					@input="searchInput(1)"
				>
				<div class="dnd-list-wrapper" ref="slotList1">
					<lba-dnd-list2
						:parentComponentId="currentComponentId"
						componentId="list1"
						v-if="!isSearching1"
						class="lba-wrapper"
						ref="list1"
						:list.sync="list1"
						:minHeight="minHeight"
						:group="`${name}-dnd-group`"
						:staticListItems="staticListItems1"
						:staticListOrder="staticListOrder1"
						:draggedItemClass="draggedItemClass1"
						@change="change(1, $event)"
						@drag-start="$emit('drag-start', 1)"
						@drag-end="$emit('drag-end', 1)"
						@checkbox-change="onCheckboxChange(1, $event)"
						@mutation="setCheckedItems(1)"
					>
						<template v-slot:list>
							<slot name="list1" :list="list1" :parentComponentId="`${currentComponentId}__slotList1`"></slot>
						</template>
					</lba-dnd-list2>
					<lba-dnd-list2
						:parentComponentId="currentComponentId"
						componentId="searchingList1"
						v-else
						class="lba-wrapper"
						ref="list1"
						:list.sync="searchList1"
						:minHeight="minHeight"
						:group="`${name}-dnd-group`"
						:staticListItems="staticListItems1"
						:staticListOrder="staticListOrder1"
						:draggedItemClass="draggedItemClass1"
						@change="changeSearchList(1, $event)"
						@drag-start="$emit('drag-start', 1)"
						@drag-end="$emit('drag-end', 1)"
						@checkbox-change="onCheckboxChange(1, $event)"
						@mutation="setCheckedItems(1)"
					>
						<template v-slot:list>
							<slot name="list1" :list="searchList1" :parentComponentId="`${currentComponentId}__slotSearchingList1`"></slot>
						</template>
					</lba-dnd-list2>
				</div>
			</div>

			<div class="col-2" :style="listMaxHeightStyle">
				<div class="move-buttons">
					<button type="button" class="buttonInverse" @click="moveDirection()"
						v-tooltip="$t('moveSelectedRight')"
						:data-cy="`${currentComponentId}__moveToList2`"
					>
						<i class="icon-collapse-all" style="transform: rotate(90deg); left: -3px;"></i>
					</button>
					<br><br>
					<button type="button" class="buttonInverse" @click="moveDirection(false)"
						v-tooltip="$t('moveSelectedLeft')"
						:data-cy="`${currentComponentId}__moveToList1`"
					>
						<i class="icon-collapse-all" style="transform: rotate(-90deg); left: 0px;"></i>
					</button>
				</div>
			</div>

			<div class="col-5 column" :style="listMaxHeightStyle">
				<div class="title-wrapper">
					<label class="checkbox">
						<input type="checkbox" v-model="isCheckedAll2" @change="toggleCheckAll(2)"
							:data-cy="`${currentComponentId}__checkedAll2__inputCheckbox`"
						>
						<i class="icon-ok"></i>
						<span class="label">{{ listTitle2 }}</span>
					</label>
				</div>
				<input
					:data-cy="`${currentComponentId}__search2__inputText`"
					class="search-input"
					type="text"
					:placeholder="$t('search')"
					v-model="searchValue2"
					@input="searchInput(2)"
				>
				<div class="dnd-list-wrapper" ref="slotList2">
					<lba-dnd-list2
						:parentComponentId="currentComponentId"
						componentId="list2"
						v-if="!isSearching2"
						class="lba-wrapper"
						ref="list2"
						:list.sync="list2"
						:minHeight="minHeight"
						:group="`${name}-dnd-group`"
						:staticListItems="staticListItems2"
						:staticListOrder="staticListOrder2"
						:draggedItemClass="draggedItemClass2"
						@change="change(2, $event)"
						@drag-start="$emit('drag-start', 2)"
						@drag-end="$emit('drag-end', 2)"
						@checkbox-change="onCheckboxChange(2, $event)"
						@mutation="setCheckedItems(2)"
					>
						<template v-slot:list>
							<slot name="list2" :list="list2" :parentComponentId="`${currentComponentId}__slotList2`"></slot>
						</template>
					</lba-dnd-list2>
					<lba-dnd-list2
						:parentComponentId="currentComponentId"
						componentId="searchingList2"
						v-else
						class="lba-wrapper"
						ref="list2"
						:list.sync="searchList2"
						:minHeight="minHeight"
						:group="`${name}-dnd-group`"
						:staticListItems="staticListItems2"
						:staticListOrder="staticListOrder2"
						:draggedItemClass="draggedItemClass2"
						@change="changeSearchList(2, $event)"
						@drag-start="$emit('drag-start', 2)"
						@drag-end="$emit('drag-end', 2)"
						@checkbox-change="onCheckboxChange(2, $event)"
						@mutation="setCheckedItems(2)"
					>
						<template v-slot:list>
							<slot name="list2" :list="searchList2" :parentComponentId="`${currentComponentId}__slotSearchingList2`"></slot>
						</template>
					</lba-dnd-list2>
				</div>
			</div>
		</div>
		<div class="popupButtons" v-if="!noDialog">
			<button type="button" class="buttonBig" @click="$emit('save')"
				:data-cy="`${currentComponentId}__save`"
			>{{ $t('ok') }}</button>
			<button type="button" class="buttonBig buttonInverse" @click="$emit('cancel')" v-lba-dialog-close.name="name"
				:data-cy="`${currentComponentId}__cancel`"
			>{{ $t('cancel') }}</button>
		</div>
	</component>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		name: {
			type: String,
			required: true,
		},
		dialogTitle: {
			type: String,
			default: '',
		},
		list1: {
			type: Array,
			required: true,
		},
		list2: {
			type: Array,
			required: true,
		},
		listTitle1: {
			type: String,
			required: false,
			default: 'List 1',
		},
		listTitle2: {
			type: String,
			required: false,
			default: 'List 2',
		},
		staticListItems1: {
			type: Boolean,
			required: false,
			default: true,
		},
		staticListItems2: {
			type: Boolean,
			required: false,
			default: false,
		},
		staticListOrder1: {
			type: Boolean,
			required: false,
			default: true,
		},
		staticListOrder2: {
			type: Boolean,
			required: false,
			default: false,
		},
		draggedItemClass1: {
			type: String,
			default: 'lba-dragged',
		},
		draggedItemClass2: {
			type: String,
			default: 'lba-dragged',
		},
		noDialog: {
			type: Boolean,
			required: false,
			default: false,
		},
		searchDelay: {
			type: Number,
			required: false,
			default: 0,
		},
		minHeight: {
			type: String,
			default: '100%',
		},
		listMaxHeight: {
			type: String,
			default: '100%',
		},
		sort: {
			type: Function,
			default: (item1, item2) => {
				if ($getLocale(item1.label).toLowerCase() < $getLocale(item2.label).toLowerCase()) { return -1; }
				if ($getLocale(item1.label).toLowerCase() > $getLocale(item2.label).toLowerCase()) { return 1; }
				return 0;
			},
		},
	},
	data() {
		return {
			searchList1: [],
			searchList2: [],
			searchValue1: null,
			searchValue2: null,
			searchDebouncer1: null,
			searchDebouncer2: null,

			checkedList1: [],
			checkedList2: [],
			isCheckedAll1: false,
			isCheckedAll2: false,
		};
	},
	computed: {
		usingDebouncer() {
			return this.searchDelay > 0;
		},
		isSearching1() {
			return this.searchValue1 != null && this.searchValue1 !== '';
		},
		isSearching2() {
			return this.searchValue2 != null && this.searchValue2 !== '';
		},
		listMaxHeightStyle() {
			if (!_.isEmpty(this.listMaxHeight)) {
				return `max-height: ${this.listMaxHeight};`;
			}
			return null;
		},
	},
	created() {
		if (this.usingDebouncer) {
			this.searchDebouncer1 = new this.$Debouncer(
				this,
				this.search,
				null,
				this.searchDelay
			);
			this.searchDebouncer2 = new this.$Debouncer(
				this,
				this.search,
				null,
				this.searchDelay
			);
		}
	},
	mounted() {
		let noId = false;
		_.forEach(this.list1, (el) => { if (!el.id) noId = true; });
		if (noId) {
			console.warn(`[lba-drag-and-drop-two-lists] - ${this.currentComponentId}` +
				` - no ID in "list1", searching will not work properly.`);
		}
		noId = false;
		_.forEach(this.list2, (el) => { if (!el.id) noId = true; });
		if (noId) {
			console.warn(`[lba-drag-and-drop-two-lists] - ${this.currentComponentId}` +
				` - no ID in "list2", searching will not work properly.`);
		}
	},
	methods: {
		pushUniqueItem(list, item) {
			if (list.indexOf(item) < 0) {
				list.push(item);
			}
		},
		removeUniqueItem(list, item) {
			const index = list.indexOf(item);

			if (index >= 0) {
				list.splice(index, 1);
			}
		},
		change(listNumber, event) {
			let updateList = listNumber === 1 ? this.list1 : this.list2;
			const staticListOrder = listNumber === 1 ? this.staticListOrder1 : this.staticListOrder2;
			const listName = `list${listNumber}`;
			const change = {};
			change[listName] = event;

			if (staticListOrder && event.added && updateList) {
				updateList.sort(this.sort);
			}

			updateList = _.uniq(updateList);

			this.$emit(`update:${listName}`, updateList);
			this.$emit('change', change);
			if (this.noDialog) {
				this.$emit('save');
			}
		},
		changeSearchList(listNumber, event) {
			let list = listNumber === 1 ? this.list1 : this.list2;
			const listName = `list${listNumber}`;
			const change = {};
			change[listName] = event;

			if (event.added) {
				// add to original list
				list.push(event.added);
			} else if (event.removed) {
				// remove from original list
				let indexToRemove;
				if (!event.removed.id) {
					const keys = Object.keys(event.removed);
					let id = '';
					_.forEach(keys, (key) => {
						id += event.removed[key];
					});
					indexToRemove = list.findIndex((item) => {
						let id2 = '';
						_.forEach(keys, (key) => {
							id2 += item[key];
						});
						return id2 === id;
					});
				} else {
					indexToRemove = list.findIndex((item) => item.id === event.removed.id);
				}

				if (indexToRemove > -1) {
					list.splice(indexToRemove, 1);
				}
			}

			list = _.uniq(list);

			this.$emit(`update:${listName}`, list);
			this.$emit('change', change);
			if (this.noDialog) {
				this.$emit('save');
			}
		},
		moveDirection(moveRight = true) {
			const moveItemIndexes = [];
			const slotList = moveRight ? this.$refs.slotList1 : this.$refs.slotList2;
			const listNumber = moveRight ? 1 : 2;
			const list1 = moveRight ? this.list1 : this.list2;
			const list2 = moveRight ? this.list2 : this.list1;
			const listName1 = moveRight ? 'list1' : 'list2';
			const listName2 = moveRight ? 'list2' : 'list1';
			const staticListItems1 = moveRight ? this.staticListItems1 : this.staticListItems2;
			const staticListItems2 = moveRight ? this.staticListItems2 : this.staticListItems1;
			const staticListOrder2 = moveRight ? this.staticListOrder2 : this.staticListOrder1;
			const isSearching1 = moveRight ? this.isSearching1 : this.isSearching2;
			const isSearching2 = moveRight ? this.isSearching2 : this.isSearching1;

			slotList.querySelectorAll('[lba-dnd-list-checkbox]').forEach((checkbox, index) => {
				if (checkbox.checked) {
					moveItemIndexes.push(index);
					checkbox.checked = false;
				}
			});

			if (this.isSearching1 || this.isSearching2) {
				this.moveSearchList(
					list1, list2, staticListItems1, staticListItems2,
					isSearching1, isSearching2, listName1, listName2,
					moveItemIndexes, moveRight, staticListOrder2
				);
			} else {
				this.move(
					list1, staticListItems1, list2, staticListItems2,
					listName1, listName2, moveItemIndexes, staticListOrder2
				);
			}

			if (moveRight) {
				this.isCheckedAll1 = false;
				this.checkedList1 = [];
			} else {
				this.isCheckedAll2 = false;
				this.checkedList2 = [];
			}

			this.setCheckedItems(listNumber);
		},
		move(
			list1, staticListItems1, list2, staticListItems2,
			listName1, listName2, moveItemIndexes, staticListOrder2
		) {
			const moveItems = [];
			let listChanged1 = false;
			let listChanged2 = false;

			for (let i = moveItemIndexes.length - 1; i >= 0; i -= 1) {
				moveItems.push(list1[moveItemIndexes[i]]);

				if (!staticListItems1) {
					list1.splice(moveItemIndexes[i], 1);
					listChanged1 = true;
				}
			}

			if (!staticListItems2) {
				for (let i = moveItems.length - 1; i > -1; i -= 1) {
					list2.push(moveItems[i]);
					listChanged2 = true;
				}
			}

			this.emitListChange(
				list1, list2, listName1, listName2, listChanged1, listChanged2, staticListOrder2, moveItems
			);
		},
		moveSearchList(
			list1, list2, staticListItems1, staticListItems2,
			isSearching1, isSearching2, listName1, listName2,
			moveItemIndexes, moveRight = true, staticListOrder2
		) {
			const moveItems = [];
			const searchList1 = moveRight ? this.searchList1 : this.searchList2;
			const searchList2 = moveRight ? this.searchList2 : this.searchList1;
			// const staticListOrder2 = moveRight ? this.staticListOrder2 : this.staticListOrder1;
			let listChanged1 = false;
			let listChanged2 = false;

			for (let i = moveItemIndexes.length - 1; i >= 0; i -= 1) {
				let removeIndex = -1;

				if (isSearching1) {
					moveItems.push(searchList1[moveItemIndexes[i]]);
					removeIndex = list1.indexOf(searchList1[moveItemIndexes[i]]);
				} else {
					moveItems.push(list1[moveItemIndexes[i]]);
					removeIndex = moveItemIndexes[i];
				}

				if (!staticListItems1) {
					searchList1.splice(moveItemIndexes[i], 1);
					list1.splice(removeIndex, 1);
					listChanged1 = true;
				}
			}

			if (!staticListItems2) {
				for (let i = moveItems.length - 1; i > -1; i -= 1) {
					if (isSearching2) {
						searchList2.push(moveItems[i]);
					}

					list2.push(moveItems[i]);
					listChanged2 = true;
				}
			}

			this.emitListChange(
				list1, list2, listName1, listName2, listChanged1, listChanged2, staticListOrder2, moveItems
			);
		},
		emitListChange(
			list1, list2, listName1, listName2, listChanged1, listChanged2, staticListOrder, moveItems
		) {
			const change = {};

			if (listChanged1) {
				change[listName1] = { removed: moveItems };
				this.$emit(`update:${listName1}`, list1);
				this.$emit('change', change);
				if (this.noDialog) {
					this.$emit('save');
				}
				this.searchInput(1);
			}

			if (listChanged2) {
				change[listName2] = { added: moveItems };

				if (staticListOrder && list2) {
					list2.sort(this.sort);
				}

				this.$emit(`update:${listName2}`, list2);
				this.$emit('change', change);
				if (this.noDialog) {
					this.$emit('save');
				}
				this.searchInput(2);
			}
		},
		searchInput(listNumber) {
			if (this.usingDebouncer) {
				const debouncer = listNumber === 1 ? this.searchDebouncer1 : this.searchDebouncer2;
				debouncer.emit(listNumber);
			} else {
				this.search(listNumber);
			}
		},
		search(listNumber) {
			let searchList = [];
			const list = listNumber === 1 ? this.list1.slice() : this.list2.slice();
			const searchValue = listNumber === 1 ? this.searchValue1 : this.searchValue2;
			const useSearchList = searchValue !== '' && searchValue != null;

			if (useSearchList) {
				searchList = list.filter((item) =>
					this.$localeIncludes(
						$getLocale(item.label).toLowerCase(),
						searchValue.toLowerCase(),
						{ usage: 'search', sensitivity: 'base' }
					)
				);
				searchList.sort(this.sort);
			}

			if (listNumber === 1) {
				this.searchList1 = _.uniq(searchList);
			} else {
				this.searchList2 = _.uniq(searchList);
			}
		},
		toggleCheckAll(listNumber) {
			let list = listNumber === 1 ? this.list1 : this.list2;
			const state = listNumber === 1 ? this.isCheckedAll1 : this.isCheckedAll2;
			const slotList = listNumber === 1 ? this.$refs.slotList1 : this.$refs.slotList2;
			const checkedList = listNumber === 1 ? this.checkedList1 : this.checkedList2;
			const isSearching = listNumber === 1 ? this.isSearching1 : this.isSearching2;

			if (isSearching && listNumber === 1) {
				list = this.searchList1;
			} else if (isSearching && listNumber === 2) {
				list = this.searchList2;
			}

			slotList.querySelectorAll('[lba-dnd-list-checkbox]').forEach((checkbox, elementIndex) => {
				const index = elementIndex;

				if (index >= 0) {
					const item = list[index];

					if (state) {
						this.pushUniqueItem(checkedList, item);
					} else {
						this.removeUniqueItem(checkedList, item);
					}

					checkbox.checked = state;
				}
			});
		},
		setCheckedItems(listNumber) {
			const originalListLength = listNumber === 1 ? this.list1.length : this.list2.length;
			const usingSearchList = listNumber === 1 ? this.isSearching1 : this.isSearching2;
			const slotList = listNumber === 1 ? this.$refs.slotList1 : this.$refs.slotList2;
			const checkedList = listNumber === 1 ? this.checkedList1 : this.checkedList2;
			let list;

			if (usingSearchList) {
				list = listNumber === 1 ? this.searchList1 : this.searchList2;
			} else {
				list = listNumber === 1 ? this.list1 : this.list2;
			}

			slotList.querySelectorAll('[lba-dnd-list-checkbox]').forEach((checkbox, elementIndex) => {
				const index = elementIndex - (usingSearchList * originalListLength);

				if (index >= 0) {
					const item = list[index];

					if (checkedList.includes(item)) {
						checkbox.checked = true;
					} else {
						checkbox.checked = false;
					}
					checkbox.parentElement.classList.add('static');
					setTimeout(() => {
						checkbox.parentElement.classList.remove('static');
					}, 100);
				}
			});
		},
		onCheckboxChange(listNumber, info) {
			let item;
			const list = listNumber === 1 ? this.list1 : this.list2;
			const searchList = listNumber === 1 ? this.searchList1 : this.searchList2;
			const checkedList = listNumber === 1 ? this.checkedList1 : this.checkedList2;
			const isSearching = listNumber === 1 ? this.isSearching1 : this.isSearching2;

			if (info.event.target.checked) {
				if (isSearching) {
					item = searchList[info.index];
				} else {
					item = list[info.index];
				}

				this.pushUniqueItem(checkedList, item);
			} else {
				if (isSearching) {
					item = searchList[info.index];
				} else {
					item = list[info.index];
				}

				this.removeUniqueItem(checkedList, item);
			}
		},
		async checkSearch() {
			await this.$nextTick();
			if (this.isSearching1) this.searchInput(1);
			if (this.isSearching2) this.searchInput(2);
		},
	},
};
</script>
