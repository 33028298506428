export default {
	data() {
		return {
			ctrlDown: false,
			shiftDown: false,
		};
	},
	created() {
		document.addEventListener('keydown', this.onKeyDown);
		document.addEventListener('keyup', this.onKeyUp);
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.onKeyDown);
		document.removeEventListener('keyup', this.onKeyUp);
	},
	methods: {
		onKeyDown(event) {
			if (event.key === 'Control') {
				this.ctrlDown = true;
			}

			if (event.key === 'Shift') {
				this.shiftDown = true;
			}
		},
		onKeyUp(event) {
			if (event.key === 'Control') {
				this.ctrlDown = false;
			}

			if (event.key === 'Shift') {
				this.shiftDown = false;
			}
		},
	},
};
