<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<i :class="data[key]"></i>
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<span class="icon-sample" v-if="!readOnly">
			<i v-if="data[key]"
				:key="'icon' + elementKey"
				:class="[data[key], 'hover-pointer']"
				:data-cy="`${currentComponentId}__openDialogIcon`"
				v-lba-dialog-open="id"
				v-tooltip="$t('crm.changeIcon')">
			</i>
			<button v-else type="button"
				:data-cy="`${currentComponentId}__openDialogButton`"
				v-lba-dialog-open="id"
				class="buttonTransparent"
			>{{ $t('set') }}</button>
			<lba-icon-picker
				:parentComponentId="currentComponentId"
				v-model="data[key]"
				:dialogName="id"
				:disabled="readOnly"
				@input="onEvent('input')"
				@change="onEvent('change')"
			></lba-icon-picker>
		</span>
		<span class="icon-sample" v-else>
			<span v-if="data[key]" :class="[data[key]]" :data-cy="`${currentComponentId}__sample`">
			</span>
		</span>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputIcon',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
};
</script>
