export default class {
	constructor($http) {
		this.$http = $http;
	}

	query(params) {
		return this.$http.get('lbadmin/import-records', { params });
	}

	get(uid) {
		return this.$http.get(`lbadmin/import-records/${uid}`);
	}

	save(uid, params) {
		return this.$http.post(`lbadmin/import-records/${uid}`, { params });
	}

	create(params) {
		return this.$http.post(`lbadmin/import-records`, { params });
	}

	removeData(uid, params) {
		return this.$http.delete(`lbadmin/import-records-data/${uid}`, { data: params });
	}
};
