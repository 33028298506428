export default class {
	constructor() {
		// must be root
		this.vm = null;
		this.changed = null;
		this.detailActive = null;
		this.fullscreenActive = null;
	}

	init(vm) {
		this.vm = vm;

		this.vm.$on('content.saved', (options = {}) => {
			if (options.broadcast) {
				return;
			}

			this.changed = false;

			// show notification about successful save unles explicitly disabled
			if (options.notify !== false) {
				this.vm.$notify.success(this.vm.$t('form.saved'));
			}

			// send reload event - parent list (or grid) shoud reload itself unless explicitly disabled
			if (options.reload !== false) {
				this.vm.$emit('content.reload', options.emitter);
			}

			if (options.goBack) {
				const { activeTab } = this.vm.$tabs;
				const breadcrumbLength = activeTab.breadcrumbs.length;

				// if has some parent route
				if (breadcrumbLength > 1) {
					const breadcrumb = activeTab.breadcrumbs[breadcrumbLength - 2];

					if (!breadcrumb.disabled && breadcrumb.initDone !== false) {
						this.vm.$routerWrap.push({ name: breadcrumb.name, params: breadcrumb.params });
					}
				}
			}
		});

		this.vm.$on('content.saveFailed', () => {
			this.vm.$notify.warn(this.vm.$t('form.save_failed'));
		});

		this.vm.$on('content.validationFailed', () => {
			this.vm.$notify.warn(this.vm.$t('form.validation_failed'));
		});

		this.vm.$on('content.removed', (options = {}) => {
			// send reload event - parent list (or grid) shoud reload itself unless explicitly disabled
			if (options.reload !== false) {
				this.vm.$emit('content.reload', options.emitter);
			}

			// show notification about successful save unless explicitly disabled
			if (options.notify !== false) {
				this.vm.$notify.success(this.vm.$t('form.removed'));
			}

			// go to parent route unless explicitly disabled
			if (options.noBack !== true) {
				const { activeTab } = this.vm.$tabs;
				const breadcrumbLength = activeTab.breadcrumbs.length;

				// if has some parent route
				if (breadcrumbLength > 1) {
					const breadcrumb = activeTab.breadcrumbs[breadcrumbLength - 2];

					if (!breadcrumb.disabled && breadcrumb.initDone !== false) {
						this.vm.$routerWrap.push({ name: breadcrumb.name, params: breadcrumb.params });
					}
				} /* else if (options.closeTabIfNoParent === true) {
					this.vm.$emit('close-current-tab');
				} */
			}
		});

		this.vm.$on('content.removeFailed', () => {
			this.vm.$notify.warn(this.vm.$t('form.removeFailed'));
		});

		this.vm.$on('content.changed', () => {
			this.changed = true;
		});

		this.vm.$on('$stateChangeSuccess', (toState) => {
			if (toState.data && toState.data.pageType) {
				this.detailActive = toState.data.pageType === 'detail';
			} else {
				this.detailActive = null;
			}

			if (toState.data && toState.data.pageClass) {
				this.fullscreenActive = toState.data.pageClass === 'fullscreen';
			} else {
				this.fullscreenActive = null;
			}

			this.changed = false;
		});
	}
}
