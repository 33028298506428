<template>
	<div id="content" v-if="loaded">
		<div class="page-header">
			<h1>{{ $t('settings.settings') }}</h1>
			<div class="page-controls">
				<button
					type="button"
					name="btn-save"
					class="button"
					:disabled="!dirty"
					@click="save">
					{{ $t('save') }}
				</button>
				<span @click="tabReload" data-cy="options__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"></i>
				</span>
			</div>
		</div>
		<div
			class="page-content"
			id="pbx-options"
			@scroll="setScroll"
		>
			<ValidationObserver ref="form">
				<form name="form" lba-size-class="{'contentWide': 700}">

					<lba-section
						:key="'general'"
						:title="$t('general.general')"
						slotType="form"
						:componentId="`general`"
						expanded
					>
						<s>
							<small>{{ $t('general.defaultLanguage') }}</small>
							<select
								name="defaultLanguage"
								v-model="options.default_language"
								:data-cy="`options__defaultLanguage__select`"
								:disabled="!configurable"
								@change="toChange('default_language')"
							>
								<option value="cs" :data-cy="`options__defaultLanguage__select__optionCs`">
									česky
								</option>
								<option value="en" :data-cy="`options__defaultLanguage__select__optionEn`">
									english
								</option>
							</select>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="options.allow_language_per_user"
									:data-cy="`options__allowLanguagePerUser__enabled__inputCheckbox`"
									name="allowLanguagePerUser"
									:disabled="!configurable"
									@change="toChange('allow_language_per_user')"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`allowChangePerUser`) }}
								</span>
							</label>
						</s>
						<s>
							<small>{{ $t('general.defaultTimezone') }}</small>
							<select name="defaultTimezone" v-model="options.default_timezone"
								:data-cy="`options__defaultTimezone__select`"
								:disabled="!configurable"
								@change="toChange('default_timezone')"
							>
								<option v-for="(tz, index) in timezoneList" :key="tz"
									:data-cy="`options__defaultTimezone__select__option${index}`"
								>{{ tz }}</option>
							</select>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="options.allow_timezone_per_user"
									:data-cy="`options__allowTimezonePerUser__enabled__inputCheckbox`"
									name="allowTimezonePerUser"
									:disabled="!configurable"
									@change="toChange('allow_timezone_per_user')"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`allowChangePerUser`) }}
								</span>
							</label>
						</s>
						<s>
							<small>{{ $t('general.defaultColorTheme') }}</small>
							<select name="defaultColorTheme" v-model="options.default_color_theme"
								:data-cy="`options__defaultColorTheme__select`"
								:disabled="!configurable"
								@change="toChange('default_color_theme')"
							>
								<option v-for="(theme, index) in themes" :key="`theme-${index}`"
									:data-cy="`options__defaultColorTheme__select__option${index}`"
									:value="theme.value"
								>{{ theme.label }}</option>
							</select>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="options.allow_color_theme_per_user"
									:data-cy="`options__allowColorThemePerUser__enabled__inputCheckbox`"
									name="allowColorThemePerUser"
									:disabled="!configurable"
									@change="toChange('allow_color_theme_per_user')"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`allowChangePerUser`) }}
								</span>
							</label>
						</s>
						<br><br>
						<s>
							<small>{{ $t('settings.anonymousAccess') }}</small>
							<lba-switch
								name="anonymousAccess"
								:label1="$t('no')"
								:label2="$t('yes')"
								:disabled="!configurable"
								v-model="options.anonymous_access"
								@input="toChange('anonymous_access')"
								class="mt-2 mb-2"
								:class="{ 'disabled': !configurable }"
								:componentId="`anonymousAccess`"
								:parentComponentId="`options`"
							></lba-switch>
						</s>
						<s>
							<small>
								{{ $t('settings.cookieMaxAge') }}
							</small>
							<div style="display: flex;">
								<ValidationProvider
									class="half"
									:name="$t('settings.cookieMaxAge')"
									v-slot="{ invalid, errors }"
									:rules="{ min_value: 1, numeric: true }"
									title=""
								>
									<input
										type="number"
										v-model="options.cookie_max_age_base"
										:data-cy="`options__cookieMaxAgeBase__input`"
										name="cookieMaxAgeBase"
										:disabled="!configurable"
										@input="toChange('cookie_max_age')"
										min="1"
										:class="{ 'lba-invalid': invalid }"
									>
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`options__cookieMaxAgeBase__input__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</ValidationProvider>
								<select class="half" name="cookie_max_age_multiplier" v-model="options.cookie_max_age_multiplier"
									:data-cy="`options__cookieMaxAgeMultiplier__select`"
									:disabled="!configurable"
									@change="toChange('cookie_max_age')"
								>
									<option v-for="(mp, index) in multipliers" :key="`mp-${mp.value}`"
										:data-cy="`options__cookieMaxAgeMultiplier__select__option${index}`"
										:value="mp.value"
									>{{ mp.label }}</option>
								</select>
							</div>
						</s>
					</lba-section>
					<lba-section
						:title="$t('settings.login')"
						slotType="form"
						:componentId="`login`"
						expanded
					>
						<s>
							<small>
								{{ $t('settings.loginBanEnabled') }}
								<i class="icon-info" v-tooltip="$t(`settings.loginBanEnabledInfo`)"></i>
							</small>
							<lba-switch
								name="loginBanEnabled"
								:label1="$t('no')"
								:label2="$t('yes')"
								:disabled="!configurable"
								v-model="options.login_ban_enabled"
								@input="toChange('login_ban_enabled')"
								class="mt-2 mb-2"
								:class="{ 'disabled': !configurable }"
								:componentId="`loginBanEnabled`"
								:parentComponentId="`options`"
							></lba-switch>
						</s>
						<s>
							<small>{{ $t('settings.loginBanAttempts') }}</small>
							<input
								type="number"
								v-model="options.login_ban_attempts"
								:data-cy="`options__loginBanAttempts__input`"
								name="loginBanAttempts"
								:disabled="!configurable || !options.login_ban_enabled"
								@input="toChange('login_ban_attempts')"
							>
						</s>
						<s>
							<small>{{ $t('settings.loginBanTimeout') }}</small>
							<div style="display: flex;">
								<ValidationProvider
									class="half"
									:name="$t('settings.loginBanTimeout')"
									v-slot="{ invalid, errors }"
									:rules="{ min_value: 1, numeric: true }"
									title=""
								>
									<input
										type="number"
										v-model="options.login_ban_timeout_base"
										:data-cy="`options__loginBanTimeoutBase__input`"
										name="login_ban_timeout_base"
										:disabled="!configurable || !options.login_ban_enabled"
										@input="toChange('login_ban_timeout')"
										min="1"
										:class="{ 'lba-invalid': invalid }"
									>
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`options__loginBanTimeoutBase__input__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</ValidationProvider>
								<select class="half" name="login_ban_timeout_multiplier" v-model="options.login_ban_timeout_multiplier"
									:data-cy="`options__loginBanTimeoutMultiplier__select`"
									:disabled="!configurable || !options.login_ban_enabled"
									@change="toChange('login_ban_timeout')"
								>
									<option v-for="(mp, index) in multipliers" :key="`mp-${mp.value}`"
										:data-cy="`options__loginBanTimeoutMultiplier__select__option${index}`"
										:value="mp.value"
									>{{ mp.label }}</option>
								</select>
							</div>
						</s>
						<br><br>
						<s>
							<small>{{ $t('settings.idleLogoutEnabled') }}</small>
							<lba-switch
								name="idleLogoutEnabled"
								:label1="$t('no')"
								:label2="$t('yes')"
								:disabled="!configurable"
								v-model="options.idle_logout_enabled"
								@input="toChange('idle_logout_enabled')"
								class="mt-2 mb-2"
								:class="{ 'disabled': !configurable }"
								:componentId="`idleLogoutEnabled`"
								:parentComponentId="`options`"
							></lba-switch>
						</s>
						<s>
							<small>{{ $t('settings.idleLogoutTime') }}</small>
							<div style="display: flex;">
								<ValidationProvider
									class="half"
									:name="$t('settings.idleLogoutTime')"
									v-slot="{ invalid, errors }"
									:rules="{ min_value: 1, numeric: true }"
									title=""
								>
									<input
										type="number"
										v-model="options.idle_logout_time_base"
										:data-cy="`options__idleLogoutTimeBase__input`"
										name="idle_logout_time_base"
										:disabled="!configurable || !options.idle_logout_enabled"
										@input="toChange('idle_logout_time')"
										min="1"
										:class="{ 'lba-invalid': invalid }"
									>
									<span
										v-for="(err, index) in errors"
										:key="index"
										:data-cy="`options__idleLogoutTimeBase__input__error${index}`"
										class="lba-messages"
									>{{ err }}</span>
								</ValidationProvider>
								<select class="half" name="idle_logout_time_multiplier" v-model="options.idle_logout_time_multiplier"
									:data-cy="`options__idleLogoutTimeMultiplier__select`"
									:disabled="!configurable || !options.idle_logout_enabled"
									@change="toChange('idle_logout_time')"
								>
									<option v-for="(mp, index) in multipliers" :key="`mp-${mp.value}`"
										:data-cy="`options__idleLogoutTimeMultiplier__select__option${index}`"
										:value="mp.value"
									>{{ mp.label }}</option>
								</select>
							</div>
						</s>
						<br><br>
						<s>
							<small>{{ $t('settings.loginPasswordStrengrth') }}</small>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="password.lowercase"
									:data-cy="`options__loginPasswordStrengrth__lowercase__inputCheckbox`"
									name="lowercase"
									:disabled="!configurable"
									@change="password.dirty = true; dirty = true;"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`settings.lowercasePassword`) }}
								</span>
							</label>
							<br>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="password.uppercase"
									:data-cy="`options__loginPasswordStrengrth__uppercase__inputCheckbox`"
									name="uppercase"
									:disabled="!configurable"
									@change="password.dirty = true; dirty = true;"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`settings.uppercasePassword`) }}
								</span>
							</label>
							<br>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="password.number"
									:data-cy="`options__loginPasswordStrengrth__number__inputCheckbox`"
									name="uppercase"
									:disabled="!configurable"
									@change="password.dirty = true; dirty = true;"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`settings.numberPassword`) }}
								</span>
							</label>
							<br>
							<label class="checkbox">
								<input
									type="checkbox"
									v-model="password.special"
									:data-cy="`options__loginPasswordStrengrth__special__inputCheckbox`"
									name="uppercase"
									:disabled="!configurable"
									@change="password.dirty = true; dirty = true;"
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`settings.specialPassword`) }}
									<i class="icon-info" v-tooltip="$t(`settings.specialPasswordInfo`)"></i>
								</span>
							</label>
						</s>
						<s>
							<small>{{ $t('settings.passwordLength') }}</small>
							<ValidationProvider
									:name="$t('settings.passwordLength')"
									v-slot="{ invalid, errors }"
									:rules="{ min_value: 5, numeric: true }"
									title=""
								>
								<input
									type="number"
									v-model="password.length"
									:data-cy="`options__loginPasswordStrengrth__passwordLength__input`"
									name="loginBanTimeout"
									:disabled="!configurable"
									@input="password.dirty = true; dirty = true;"
									:class="{ 'lba-invalid': invalid }"
									min="5"
								>
								<span
									v-for="(err, index) in errors"
									:key="index"
									:data-cy="`options__loginPasswordStrengrth__passwordLength__input__error${index}`"
									class="lba-messages"
								>{{ err }}</span>
							</ValidationProvider>
						</s>
						<s>
							<small>
								{{ $t('settings.easyToGuess') }}
								<i class="icon-info" v-tooltip="$t(`settings.easyToGuessInfo`)"></i>
							</small>
							<lba-switch
								name="easyToGuess"
								:label1="$t('no')"
								:label2="$t('yes')"
								:disabled="!configurable"
								v-model="password.easy_to_guess"
								@input="password.dirty = true; dirty = true;"
								class="mt-2 mb-2"
								:class="{ 'disabled': !configurable }"
								:componentId="`easyToGuess`"
								:parentComponentId="`options__loginPasswordStrengrth`"
							></lba-switch>
						</s>
					</lba-section>
					<lba-section
						:title="$t('settings.customTitle')"
						slotType="form"
						:componentId="`customTitle`"
						expanded
					>
						<s>
							<small>{{ $t('settings.appTitlePrefix') }}</small>
							<input
								type="text"
								v-model="options.app_title_prefix"
								:data-cy="`options__appTitlePrefix__input`"
								name="appTitlePrefix"
								:disabled="!configurable"
								@input="toChange('app_title_prefix')"
							>
						</s>
						<s>
							<small>{{ $t('settings.appTitleSuffix') }}</small>
							<input
								type="text"
								v-model="options.app_title_suffix"
								:data-cy="`options__appTitleSuffix__input`"
								name="appTitleSuffix"
								:disabled="!configurable"
								@input="toChange('app_title_suffix')"
							>
						</s>
						<br>
						<s>
							<small>{{ $t('settings.preview') }}</small>
							<div class="title-preview">
								<span id="logoBig" v-if="isTitle">
									<span id="logoPrefix">{{ options.app_title_prefix }}</span>
									<span id="logoSuffix logoSuffix-0">{{ options.app_title_suffix }}</span>
								</span>
								<span id="logoBig" v-else>
									<span id="logoPrefix">LinuxBox</span>
									<span id="logoSuffix logoSuffix-0">Admin4</span>
								</span>
							</div>
						</s>
					</lba-section>
					<!-- AUTH METHODS ENABLING -->
					<lba-section
						:title="$t('authMethods.authMethods')"
						slotType="form"
						:componentId="`authMethods`"
						expanded
					>
						<table class="content-table mt-1">
							<thead>
								<tr>
									<th style="width: 40px;display: flex;justify-content: center;">
										{{ $t('settings.priority') }}
										<i class="icon-info" v-tooltip="$t('authMethods.importPriorityInfo')"></i>
									</th>
									<th style="width: 220px">{{ $t('authMethods.enableAuthMethod') }}</th>
									<th style="width: 220px">{{ $t('authMethods.enableImport') }}</th>
								</tr>
							</thead>
						</table>
						<div
							:key="'auth00'"
							style="display: flex; align-items: center;"
						>
							<i class="icon-draggable" style="color: #c5c5c5"></i>
							<b class="mr-4 ml-4">1</b>
							<label class="checkbox size-1">
								<input
									type="checkbox"
									name="lbadmin"
									checked
									disabled
								>
								<i class="icon-ok"></i>
								<span class="label">
									{{ $t(`authMethods.lbadmin`) }}
								</span>
							</label>
							<label
								class="checkbox"
							>
								<input
									type="checkbox"
									name="import-lbadmin"
									disabled
								>
								<i class="icon-ok"></i>
							</label>
						</div>
						<lba-dnd-list2
							:list.sync="auth_methods"
							@drag-start="dirty = true"
							@drag-end="dirty = false; authOrder()"
							@update:list="authOrder()"
							style="width: 100%;"
							:componentId="`authMethods`"
							:parentComponentId="`options`"
						>
							<template v-slot:list>
								<div
									v-for="(auth, index) in auth_methods"
									:key="'auth' + index"
									style="display: flex; align-items: center;"
								>
									<i class="icon-draggable" style="cursor: move;"></i>
									<b class="mr-4 ml-4">{{ auth.priority + 1 }}</b>
									<label
										class="checkbox size-1"
									>
										<input
											type="checkbox"
											v-model="auth.enabled"
											:data-cy="`options__auth-method__${auth.auth_method}__enabled__inputCheckbox`"
											:name="auth.auth_method"
											:disabled="!configurable"
											@change="enableAuth(auth.auth_method)"
										>
										<i class="icon-ok"></i>
										<span class="label">
											{{ $t(`authMethods.${auth.auth_method}`) }}
										</span>
									</label>
									<label
										class="checkbox"
									>
										<input
											type="checkbox"
											v-model="auth.import"
											:data-cy="`options__auth-method__${auth.auth_method}__import__inputCheckbox`"
											:name="`import-${auth.auth_method}`"
											:disabled="!configurable || !auth.enabled"
											@change="authChanged(auth.auth_method)"
										>
										<i class="icon-ok"></i>
									</label>
								</div>
							</template>
						</lba-dnd-list2>
					</lba-section>
					<!-- AUTH METHODS SETTINGS -->
					<lba-section
						v-if="ldap.enabled"
						:title="$t('authMethods.ldapConfig.ldapConfig')"
						slotType="form"
						:componentId="`ldapConfig`"
						expanded
					>
						<s>
							<small>{{ $t('authMethods.ldapConfig.url') }}</small>
							<input
								:data-cy="`options__ldapConfiguration__url__input`"
								type="text"
								name="ldap_url"
								@input="dirty = true"
								v-model="ldap.configuration.url"
								:disabled="!configurable"
								@update="updateLdap()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.ldapConfig.base') }}</small>
							<input
								:data-cy="`options__ldapConfiguration__base__input`"
								type="text"
								name="ldap_base"
								@input="dirty = true"
								v-model="ldap.configuration.base"
								:disabled="!configurable"
								@update="updateLdap()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.ldapConfig.defaultProfile') }}</small>
							<select
								name="defaultLdapProfile"
								v-model="ldap.profile"
								:data-cy="`options__defaultLdapProfile__select`"
								:disabled="!configurable"
								@change="updateLdap()"
							>
								<option
									:data-cy="`options__defaultLdapProfile__select__option0`"
									:value="null"
								>{{ $t('blank') }}</option>
								<option v-for="(profile, index) in profiles" :key="`profile-${index}`"
									:data-cy="`options__defaultLdapProfile__select__option${index+1}`"
									:value="profile.profile_uid"
								>{{ profile.profile_name }}</option>
							</select>
						</s>
					</lba-section>
					<lba-section
						v-if="ad.enabled"
						:title="$t('authMethods.adConfig.adConfig')"
						slotType="form"
						:componentId="`activeDirectoryConfig`"
						expanded
					>
						<s>
							<small>{{ $t('authMethods.adConfig.url') }}</small>
							<input
								:data-cy="`options__adConfiguration__url__input`"
								type="text"
								name="ad_url"
								@input="dirty = true"
								v-model="ad.configuration.url"
								:disabled="!configurable"
								@update="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.base') }}</small>
							<input
								:data-cy="`options__adConfiguration__base__input`"
								type="text"
								name="ad_base"
								@input="dirty = true"
								v-model="ad.configuration.base"
								:disabled="!configurable"
								@update="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.search') }}</small>
							<input
								:data-cy="`options__adConfiguration__search__input`"
								type="text"
								name="ad_search"
								@input="dirty = true"
								v-model="ad.configuration.search"
								:disabled="!configurable"
								@update="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.ca') }}</small>
							<input
								:data-cy="`options__adConfiguration__ca__input`"
								type="text"
								name="ad_ca"
								@input="dirty = true"
								v-model="ad.configuration.ca"
								:disabled="!configurable"
								@update="updateAd()"
							/>
						</s>
						<br>
						<s>
							<small>{{ $t('authMethods.adConfig.login') }}</small>
							<input
								:data-cy="`options__adConfiguration__login__input`"
								type="text"
								name="ad_login"
								@input="dirty = true"
								v-model="ad.configuration.login"
								:disabled="!configurable"
								@update="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.password') }}</small>
							<lba-input-password
								:parentComponentId="`options`"
								componentId="ad-password"
								v-model="ad.configuration.password"
								:hasPassword="ad.configuration.t_password"
								:writeable="configurable"
								:readable="configurable"
								@input="dirty = true"
								@update="updateAd()"
							></lba-input-password>
						</s>
						<br>
						<s>
							<small>{{ $t('authMethods.adConfig.email') }}</small>
							<input
								:data-cy="`options__adConfiguration__attrs-email__input`"
								type="text"
								name="ad_attrs_email"
								@input="dirty = true"
								v-model="ad.configuration.attrs.email"
								:disabled="!configurable"
								@update="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.firstName') }}</small>
							<input
								:data-cy="`options__adConfiguration__attrs-firstName__input`"
								type="text"
								name="ad_attrs_email"
								@input="dirty = true"
								v-model="ad.configuration.attrs.firstname"
								:disabled="!configurable"
								@update="updateAd()"
							/>
						</s>
						<s>
							<small>{{ $t('authMethods.adConfig.lastName') }}</small>
							<input
								:data-cy="`options__adConfiguration__attrs-lastName__input`"
								type="text"
								name="ad_attrs_email"
								@input="dirty = true"
								v-model="ad.configuration.attrs.lastname"
								:disabled="!configurable"
								@update="updateAd()"
							/>
						</s>
						<br>
						<s>
							<small>{{ $t('authMethods.adConfig.defaultProfile') }}</small>
							<select
								name="defaultAdProfile"
								v-model="ad.profile"
								:data-cy="`options__defaultAdProfile__select`"
								:disabled="!configurable"
								@change="updateAd()"
							>
								<option
									:data-cy="`options__defaultAdProfile__select__option0`"
									:value="null"
								>{{ $t('blank') }}</option>
								<option v-for="(profile, index) in profiles" :key="`profile-${index}`"
									:data-cy="`options__defaultAdProfile__select__option${index+1}`"
									:value="profile.profile_uid"
								>{{ profile.profile_name }}</option>
							</select>
						</s>
					</lba-section>
					<lba-section
						v-if="pam.enabled"
						:title="$t('authMethods.pamConfig.pamConfig')"
						slotType="form"
						:componentId="`pamConfig`"
						expanded
					>
						<s>
							<small>{{ $t('authMethods.pamConfig.defaultProfile') }}</small>
							<select
								name="defaultPamProfile"
								v-model="pam.profile"
								:data-cy="`options__defaultLdapProfile__select`"
								:disabled="!configurable"
								@change="updatePam()"
							>
								<option
									:data-cy="`options__defaultPamProfile__select__option0`"
									:value="null"
								>{{ $t('blank') }}</option>
								<option v-for="(profile, index) in profiles" :key="`profile-${index}`"
									:data-cy="`options__defaultPamProfile__select__option${index+1}`"
									:value="profile.profile_uid"
								>{{ profile.profile_name }}</option>
							</select>
						</s>
					</lba-section>
					<!-- 2FA METHODS ENABLING -->
					<lba-section
						v-if="tfa_methods.length > 0"
						:title="$t('tfaMethods.tfaMethods')"
						slotType="form"
						:componentId="`tfaMethods`"
						expanded
					>
						<label
							v-for="(auth, index) in tfa_methods"
							:key="`tfa-${index}`"
							class="checkbox mr-3"
						>
							<input
								type="checkbox"
								v-model="auth.enabled"
								:data-cy="`options__2fa-method__${auth.tfa_method}__enabled__inputCheckbox`"
								:name="auth.tfa_method"
								:disabled="!configurable"
								@change="enableTfa(auth.tfa_method)"
							>
							<i class="icon-ok"></i>
							<span class="label">
								{{ $t(`tfaMethods.${auth.tfa_method}`) }}
							</span>
						</label>
					</lba-section>
				</form>
			</ValidationObserver>
		</div>
	</div>
</template>

<script>
import OptionsModel from '../models/Options';
import ProfilesModel from '../models/Profile';
import OnScroll from '../mixins/OnScroll';
import Page from '../mixins/Page';
import ColorThemes from '../mixins/ColorThemes';

export default {
	name: 'Options',
	data() {
		return {
			loaded: false,
			dirty: false,
			optionsModel: null,
			profilesModel: null,
			options: {
				default_language: 'cs',
				allow_language_per_user: true,
				default_timezone: 'Europe/Prague',
				allow_timezone_per_user: true,
				default_color_theme: 'theme-basic',
				allow_color_theme_per_user: true,
				cookie_max_age: 2678400000,
			},
			auth_methods: [],
			tfa_methods: [],
			changed_auth: {
				auth: [],
				tfa: [],
			},
			old_options: {},
			changed: [],
			configurable: false,
			timezoneList: [],
			themes: [],
			ldap: {},
			ad: {},
			pam: {},
			profiles: null,
			password: {
				lowercase: false,
				uppercase: false,
				number: false,
				special: false,
				easy_to_guess: false,
				length: 8,
			},
			cookie_max_age: {},
			multipliers: [
				{ label: this.$t('settings.days'), value: 86400000 },
				{ label: this.$t('settings.hours'), value: 3600000 },
				{ label: this.$t('settings.minutes'), value: 60000 },
				{ label: this.$t('settings.seconds'), value: 1000 },
			],
		};
	},

	computed: {
		isTitle() {
			return !(['', null, undefined].includes(this.options.app_title_prefix) &&
				['', null, undefined].includes(this.options.app_title_suffix));
		},
	},

	watch: {
		dirty(newValue) {
			this.$root.$emit('tab-editted', newValue);
		},
	},

	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.permissionsReload();
		this.optionsModel = new OptionsModel(this.$http);
		this.profilesModel = new ProfilesModel(this.$http);

		this.initColorThemes();

		await this.fetch();

		this.$user.queryAction('timezoneList').then((data) => {
			this.timezoneList = data;
		});

		this.loaded = true;
	},

	mixins: [OnScroll, Page, ColorThemes],

	methods: {
		permissionsReload() {
			this.configurable = this.$permissions.checkPermission('lbadmin.global-settings.write');
		},
		async fetch() {
			// let resp = await this.optionsModel.getAuth();
			let resp = await this.optionsModel.getOptions();
			this.auth_methods = resp.data.auth_methods.auth_methods;
			this.tfa_methods = resp.data.auth_methods.tfa_methods;

			_.forEach(this.auth_methods, (auth) => {
				if (auth.auth_method === 'ldap') {
					this.ldap = _.cloneDeep(auth);
					if (!this.ldap.configuration) {
						this.ldap.configuration = { url: null, base: null };
					}

				} else if (auth.auth_method === 'ad') {
					this.ad = _.cloneDeep(auth);
					if (!this.ad.configuration) {
						this.ad.configuration = {
							url: null,
							base: null,
							login: null,
							password: null,
							search: null,
							ca: null,
							attrs: {
								email: null,
								firstname: null,
								lastname: null,
							},
						};

					} else {
						const attrs = {
							email: null,
							firstname: null,
							lastname: null,
						};
						this.ad.configuration.attrs = { ...attrs, ...this.ad.configuration.attrs };
					}
				} else if (auth.auth_method === 'pam') {
					this.pam = _.cloneDeep(auth);

				}
			});

			this.options = resp.data.settings;
			await this.calcIntervals();
			this.old_options = _.cloneDeep(resp.data);

			if (this.options.login_password_strength) {
				const oldPassword = _.cloneDeep(this.password);
				try {
					this.password = { ...this.password, ...JSON.parse(this.options.login_password_strength) };
				} catch {
					this.password = oldPassword;
				}
			}

			resp = await this.profilesModel.getProfilesToSet();
			this.profiles = resp.data;
		},
		toChange(key) {
			this.dirty = true;
			this.changed.push(key);
			this.changed = _.uniq(this.changed);
		},
		async save() {
			const valid = await this.$refs.form.validate();

			if (!valid) {
				this.$root.$emit('content.validationFailed');
				return;
			}

			const opts = {};
			const intervals = ['idle_logout_time', 'cookie_max_age', 'login_ban_timeout'];
			// save only changed options
			_.forEach(this.changed, (el) => {
				// console.log(el, this.options[`${el}_base`], this.options[`${el}_multiplier`]);
				if (intervals.includes(el)) {
					this.options[el] = ((this.options[`${el}_base`] || 1) * (this.options[`${el}_multiplier`] || 86400000));
				}

				if (this.options[el] !== this.old_options[el]) {
					opts[el] = this.options[el];
				}
			});

			if (this.password.dirty) {
				delete this.password.dirty;
				opts.login_password_strength = JSON.stringify(this.password);
			}

			const auth = [];
			const tfa = [];

			_.forEach(this.changed_auth.tfa, (type) => {
				tfa.push(_.find(this.tfa_methods, { tfa_method: type }));
			});
			_.forEach(this.changed_auth.auth, (type) => {
				auth.push(_.find(this.auth_methods, { auth_method: type }));
			});

			// console.log('saving: ', { options: opts, auth, tfa });

			this.optionsModel.setOptions({ options: opts, auth, tfa })
				.then((response) => {
					this.$root.$emit('content.saved');
					this.dirty = false;
					this.fetch();
				})
				.catch(() => {
					this.$root.$emit('content.saveFailed');
				});
		},
		enableAuth(auth) {
			this.authChanged(auth);

			this.dirty = true;
			if (auth === 'ldap') {
				this.ldap.enabled = !this.ldap.enabled;
				if (!this.ldap.enabled) {
					this.ldap.import = false;
				}

			} else if (auth === 'ad') {
				this.ad.enabled = !this.ad.enabled;
				if (!this.ad.enabled) {
					this.ad.import = false;
				}

			} else if (auth === 'pam') {
				this.pam.enabled = !this.pam.enabled;
				if (!this.ad.enabled) {
					this.ad.import = false;
				}

			}
		},
		enableTfa(auth) {
			if (!this.changed_auth.tfa.includes(auth)) {
				this.changed_auth.tfa.push(auth);

			}
			this.dirty = true;
		},
		updateLdap() {
			this.authChanged('ldap');
			this.dirty = true;
			this.auth_methods.map((el) => {
				if (el.auth_method === 'ldap') {
					el.configuration = this.ldap.configuration;
					el.profile = this.ldap.profile;
				}
			});
		},
		updatePam() {
			this.authChanged('pam');
			this.dirty = true;
			this.auth_methods.map((el) => {
				if (el.auth_method === 'pam') {
					el.profile = this.pam.profile;
				}
			});
		},
		updateAd() {
			this.authChanged('ad');
			this.dirty = true;
			this.auth_methods.map((el) => {
				if (el.auth_method === 'ad') {
					el.configuration = this.ad.configuration;
					el.profile = this.ad.profile;

				}
			});
		},
		authChanged(auth) {
			this.dirty = true;
			if (!this.changed_auth.auth.includes(auth)) {
				this.changed_auth.auth.push(auth);

			}
		},
		authOrder() {
			this.dirty = true;
			_.forEach(this.auth_methods, (auth, index) => {
				auth.priority = index + 1;
				this.authChanged(auth.auth_method);
			});
		},
		calcIntervals() {
			return new Promise((resolve, reject) => {
				for (let i = 0; i < this.multipliers.length; i++) {
					const mp = this.multipliers[i];
					// cookie max age
					if (!this.options.cookie_max_age_multiplier && (this.options.cookie_max_age / mp.value) >= 1) {
						this.options.cookie_max_age_multiplier = mp.value;
						this.options.cookie_max_age_base = Math.round(this.options.cookie_max_age / mp.value);
					}
					// login ban timeout
					if (!this.options.login_ban_timeout_multiplier && (this.options.login_ban_timeout / mp.value) >= 1) {
						this.options.login_ban_timeout_multiplier = mp.value;
						this.options.login_ban_timeout_base = Math.round(this.options.login_ban_timeout / mp.value);
					}
					// idle logout time
					if (!this.options.idle_logout_time_multiplier && (this.options.idle_logout_time / mp.value) >= 1) {
						this.options.idle_logout_time_multiplier = mp.value;
						this.options.idle_logout_time_base = Math.round(this.options.idle_logout_time / mp.value);
					}
				}
				resolve();
			});
		},
	},
};
</script>
