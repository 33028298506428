export default class {
	phoneNumber(phoneNumber) {
		const cleaned = ('' + phoneNumber).replace(/\s+/g, '');
		const match = cleaned.match(/^(.*)?(\d{3})(\d{3})(\d{3})$/);
		if (match) {
			return [
				match[1],
				' ',
				match[2],
				' ',
				match[3],
				' ',
				match[4],
			].join('');
		} else {
			return phoneNumber;
		}
	}
}
