<template>
	<td
		v-if="exist && visible"
		ref="column"
		class="grid-column"
		:data-id="id"
		:class="[colPosition]"
		:title="columnTitle"
		:style="style + cellStyle"
	>
		<span v-if="showMobileLabel()" class="column-name display-mobile">{{ $getLocale(label) }}</span>
		<slot></slot>
	</td>
</template>

<script>
function isVNodeEmpty(vnode) {
	if (vnode.tag === 'i' || (_.isEmpty(vnode.tag) && !_.isEmpty(_.trim(vnode.text)))) return false;
	return isElementEmpty(vnode.elm);
}
function isElementEmpty(element) {
	return (
		element == null || (
			element.localName !== 'i' && (
				(
					_.isEmpty(element.children) &&
					_.isEmpty(_.trim(element.innerText))
				) || (
					!_.isEmpty(element.children) &&
					!_.find(element.children, (child) => !isElementEmpty(child))
				)
			)
		)
	);
}

export default {
	props: {
		id: String,
		label: [String, Object],
		width: {
			type: [Number, String],
			default: '0',
		},
		minWidth: Number,
		sortable: Boolean,
		searchable: {
			type: Boolean,
			default: false,
		},
		tooltip: [String, Object],
		icon: String,
		title: [String, Object], // title is passed to grid and used in header
		columnTitle: [String],
		after: String,
		// template: String,
		// editTemplate: Boolean,
		// searchTemplate: Boolean,
		// clearButton: Boolean,
		// persitent: Boolean,
		hidden: Boolean,
		removed: Boolean,
		initPosition: null,
		headerStyle: String,
		headerClass: [String, Object, Array],
		cellStyle: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			position: null,
			visible: true,
			exist: true,
			style: '',
			ph: '',
		};
	},
	computed: {
		colPosition() {
			return `grid-column-${this.position}`;
		},
	},
	watch: {
		label() {
			if (_.isEmpty(this.title)) {
				this.$parent.$emit('column-updated', { label: this.label, title: this.label, id: this.id });
			} else {
				this.$parent.$emit('column-updated', { label: this.label, id: this.id });
			}
		},
		title() {
			this.$parent.$emit('column-updated', { title: this.title, id: this.id });
		},
	},
	beforeMount() {
		if (!this.$parent.initDone && this.$parent.columnsProperties[this.id] == null) {
			this.$parent.$emit('column-loaded', this);
		}
		this.position = this.$parent.getPosition(this.id);
		this.visible = this.$parent.getVisibility(this.id);

		if (this.removed) {
			this.exist = false;
		} else {
			this.exist = !this.$parent.isColumnRemoved(this.id);
		}
		if (this.id === 'actions') {
			this.$parent.$on('actions-style-updated', this.updateStyle);
			this.position = 9999;
		}
	},
	mounted() {
		this.updateProperties();
		this.$forceUpdate();
	},
	updated() {
		this.updateProperties();
	},
	beforeDestroy() {
		this.$parent.$off('actions-style-updated', this.updateStyle);
	},
	methods: {
		updateProperties() {
			if (this.id === 'actions') {
				this.position = 9999;
			} else {
				this.position = this.$parent.getPosition(this.id);
			}
			this.visible = this.$parent.getVisibility(this.id);

			if (this.removed) {
				this.exist = false;
			} else {
				this.exist = !this.$parent.isColumnRemoved(this.id);
			}

			if (this.id === 'actions') {
				this.updateStyle();
			}
		},
		updateStyle() {
			this.style = this.$parent.getActionsStyle();
			this.ph = this.$refs.column.parentElement.clientHeight;
			this.$refs.column.setAttribute('style', `${this.style} height: ${this.ph}px;`);
		},
		showMobileLabel() {
			// content of column is not empty
			return (
				!_.isEmpty(this.$slots) &&
				!_.isEmpty(this.$slots.default) &&
				!_.isEmpty(this.$slots.default[0]) &&
				!isVNodeEmpty(this.$slots.default[0])
			);
		},
	},
};
</script>
