<template>
	<span class="selectbox userSelectNone input" role="listbox" ref="root">
		<input
			:data-cy="`${currentComponentId}__inputText`"
			class="comboboxSearch"
			type="text"
			:placeholder="$t('search2')"
			v-model="input"
			v-on:input="search"
			v-on:blur="ignoreClick = false"
			ref="input"
			v-bind:required="required"
			v-bind:disabled="disabled"
			v-on:blur.once="onTouch"
			@blur="$emit('blur')"
			v-bind:class="{
				'ng-invalid': !valid && dirty
			}"
		>
		<span class="selectboxOptions offScreen" ref="optionsSpan" :data-cy="`${currentComponentId}__optionsSpan`">
			<ul role="presentation" ref="options" :data-cy="`${currentComponentId}__options`"></ul>
		</span>
	</span>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		value: {
			type: [String, Number],
			required: false,
			default: '',
		},
		opts: {
			type: Array,
			required: false,
			default: () => [],
		},
		renderHtmlLabels: {
			type: Boolean,
			required: false,
			default: false,
		},
		required: {
			type: Boolean,
			required: false,
			defualt: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			defualt: false,
		},
		validator: {
			type: Object,
			required: false,
			default: null,
		},
	},
	watch: {
		opts() {
			this.prepareOptions();
			this.show(null, false);
		},
		value() {
			this.input = this.internalValue;
		},
		input() {
			this.setValid();
		},
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set(value) {
				let newValue = value;

				if (this.isNull(value)) {
					newValue = undefined;
				}

				this.$emit('input', newValue);
			},
		},
	},
	data() {
		return {
			input: this.internalValue,
			selectboxOptions: null,
			comboboxSearch: null,
			presentation: null,
			showAbove: false,
			options: [],
			viewOption: [],
			selectedIndex: null,
			ignoreClick: false,
			ignoreMouseOver: false,
			ignoreMouseOverTimeout: null,
			shown: false,
			lastActive: null,
			valid: false,
			dirty: false,
		};
	},
	mounted() {
		this.prepareOptions();
		this.comboboxSearch = this.$refs.root.querySelector('.comboboxSearch');
		this.selectboxOptions = this.$refs.root.querySelector('.selectboxOptions');
		this.presentation = this.$refs.options;

		this.$refs.root.addEventListener('click', () => {
			this.ignoreClick = true;
		});
		document.addEventListener('click', () => {
			if (!this.ignoreClick) {
				this.hide();
			}
			this.ignoreClick = false;
		});
		this.presentation.addEventListener('click', this.presentationEventHandler);
		this.presentation.addEventListener('mouseover', this.presentationEventHandler);
		this.presentation.addEventListener('mouseleave', this.presentationEventHandler);
		this.comboboxSearch.addEventListener('keydown', (event) => {
			if (event.keyCode === 38) { // arrow up
				if (this.selectedIndex > 0) {
					this.selectedIndex -= 1;
				}
			} else if (event.keyCode === 40) { // arrow down
				if (this.selectedIndex < (this.viewOption.length - 1) && this.selectedIndex !== null) {
					this.selectedIndex += 1;
				}
			} else if (event.keyCode === 13) { // enter
				this.setSelectedOption(this.selectedIndex);
				this.comboboxSearch.blur();
				setTimeout(this.hide, 0);
			} else if (event.keyCode === 33) { // pageup
				this.selectedIndex = 0;
			} else if (event.keyCode === 34) { // pagedown
				this.selectedIndex = this.presentation.children.length - 1;
			} else if (event.keyCode === 27 || event.keyCode === 9) { // esc
				this.hide();
				return;
			}

			// set propper scroll when navigating by keyboard
			if (
				event.keyCode === 38 ||
        event.keyCode === 40 ||
        event.keyCode === 33 ||
        event.keyCode === 34
			) {
				this.selectedIndex = this.selectedIndex || 0;
				const newSelectedNode = this.presentation.children[this.selectedIndex];

				if (newSelectedNode) {
					// row height
					const scrollTop = newSelectedNode.offsetTop -
            newSelectedNode.parentNode.clientHeight + 25;

					if (this.lastActive) {
						this.lastActive.classList.remove('active');
					}

					newSelectedNode.classList.add('active');
					this.lastActive = newSelectedNode;
					this.ignoreMouseOver = true;

					if (this.ignoreMouseOverTimeout) {
						clearTimeout(this.ignoreMouseOverTimeout);
					}

					this.ignoreMouseOverTimeout = setTimeout(() => {
						this.ignoreMouseOver = false;
					}, 1000);

					newSelectedNode.parentNode.scrollTop = scrollTop;
				}
			}
		});
		this.setSelectboxOptionsPosition(null, false);
		this.setValid();
	},
	methods: {
		isNull(value) {
			return value == null || value === '';
		},
		onTouch() {
			this.dirty = true;

			if (this.validator) {
				this.validator.$touch();
			}
		},
		setValid() {
			if (this.validator) {
				this.valid = !this.validator.$invalid;
			} else if (this.required && this.isNull(this.input)) {
				this.valid = false;
			} else {
				this.valid = true;
			}
		},
		getOptionsIndex(value) {
			for (let index = 0; index < this.options.length; index += 1) {
				if (this.options[index].value === value) {
					return index;
				}
			}

			return -1;
		},
		prepareOptions() {
			this.options = [];
			this.viewOptions = [];

			for (let index = 0; index < this.opts.length; index += 1) {
				this.options.push(this.opts[index]);
				this.viewOptions.push(index);
			}
		},
		presentationEventHandler(event) {
			if (event.type === 'mouseover' && this.ignoreMouseOver) {
				return;
			}

			if (event.type === 'mouseleave' && this.lastActive) {
				this.lastActive.classList.remove('active');
				this.lastActive = null;
				this.selectedIndex = null;
				return;
			}

			let optionNode = event.target;

			while (optionNode && optionNode.tagName !== 'LI') {
				optionNode = optionNode.parentNode;
			}

			// order of options
			const index = Array.prototype.indexOf.call(this.presentation.children, optionNode);

			if (index !== -1) {
				if (event.type === 'click') {
					this.setSelectedOption(index);
					setTimeout(this.hide, 0);
				} else {
					if (this.lastActive) {
						this.lastActive.classList.remove('active');
					}
					if (optionNode) {
						optionNode.classList.add('active');
						this.lastActive = optionNode;
					}
				}

				this.selectedIndex = index;
			}
		},
		setSelectedOption(optionIndex) {
			if (this.viewOption[optionIndex] != null) {
				this.internalValue = this.options[this.viewOption[optionIndex]].value;
				this.input = this.options[this.viewOption[optionIndex]].value;
			}
		},
		showOptions() {
			this.presentation.innerHTML = '';

			for (let i = 0; i < this.viewOption.length; i += 1) {
				const option = this.options[this.viewOption[i]];
				const optionNode = document.createElement('li');

				if (this.renderHtmlLabels) {
					optionNode.innerHTML = option.label;
				} else {
					optionNode.textContent = option.label;
				}

				optionNode.setAttribute('role', 'option');
				optionNode.setAttribute('data-cy', `${this.currentComponentId}__option${i}`);

				if (i === this.selectedIndex) {
					optionNode.classList.add('active');
				}

				this.presentation.appendChild(optionNode);
			}
		},
		setSelectboxOptionsPosition(event, enableShow = true) {
			let position = null;
			let top = null;
			let bottom = null;
			const rect = this.comboboxSearch.getBoundingClientRect();
			const topSpace = rect.top;
			const bottomSpace = window.innerHeight - rect.bottom;
			const boxRect = this.selectboxOptions.getBoundingClientRect();
			this.showAbove = topSpace > bottomSpace;
			const contentOptions = document.getElementById('content-options');
			let contentOptionsTop = 0;

			if (contentOptions) {
				contentOptionsTop = contentOptions.getBoundingClientRect().top;
			}

			if (this.showAbove && (topSpace - contentOptionsTop) < boxRect.height) {
				this.presentation.setAttribute('style', `max-height: ${topSpace - contentOptionsTop}px`);
			} else {
				this.presentation.setAttribute('style', 'max-height: 250px');
			}

			if (this.showAbove) {
				bottom = `${0}px`;
			} else {
				position = Math.floor(this.comboboxSearch.offsetHeight);
				top = `${position}px`;
			}

			this.selectboxOptions.setAttribute('style', `
        top: ${top};
        bottom: ${bottom};
        width: ${rect.width}px;
      `);

			if (this.viewOption.length > 0 && enableShow) {
				this.selectboxOptions.classList.remove('offScreen');
				this.ignoreClick = true;
				this.shown = true;
			}
		},
		show(event, enableShow = true) {
			this.showOptions();
			// because it gets bounding values before it renders - shows old rect.bottom value
			setTimeout(() => {
				this.setSelectboxOptionsPosition(null, enableShow);
			}, 0);
		},
		hide() {
			this.selectboxOptions.classList.add('offScreen');
			this.selectedIndex = null;
			this.shown = false;
		},
		search() {
			this.internalValue = this.input;
			this.viewOption = [];
			const searchExpression = this.input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			const filterRegexp = new RegExp(`(^|\\s)${searchExpression.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')}`, 'i');
			let label = null;

			for (let i = 0; i < this.options.length; i += 1) {
				const option = this.options[i];
				label = option.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

				if (this.renderHtmlLabels) {
					label = label.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/gm, ' ');
				}

				if (filterRegexp.test(label)) {
					this.viewOption.push(i);
				}
			}

			if (this.input && this.viewOption.length > 0) {
				this.show();
			} else {
				this.hide();
			}
		},
	},
};
</script>
