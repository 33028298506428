export default function (className, lastClassName) {
	const wrappedItems = [];
	const classNameItems = Array.from(document.getElementsByClassName(className));
	let items = classNameItems;

	if (lastClassName != null) {
		items = classNameItems.concat(Array.from(document.getElementsByClassName(lastClassName)));
	}

	let prevItem = {};
	let currItem = {};

	for (let i = 0; i < items.length; i += 1) {
		currItem = items[i].getBoundingClientRect();

		if (prevItem && prevItem.top < currItem.top) {
			wrappedItems.push(items[i]);
		}

		prevItem = currItem;
	}

	return wrappedItems;
}
