/* eslint no-underscore-dangle: 0 */
export default {
	data() {
		return {
			// unique lba-grid-column will push itself here
			columns: [],
			columnsProperties: {},
			columnsCount: null,
			resizeColumn: null,
			resizingLastColumn: false,
			resizingColumn: false,
			unOrderedColumns: [],
		};
	},
	created() {
		this.$on('column-loaded', this.setColumnsProperties);
		this.$on('column-updated', this.updateColumnProperties);
	},
	mounted() {
		this.columnsCount = this.$scopedSlots.columns({ row: {} }).length + this.multiEnabled;
		document.addEventListener('mousemove', this.mouseMoveResize, true);
		this.setupColumns();
	},
	methods: {
		getPosition(id) {
			return this.columnsProperties[id] ? this.columnsProperties[id].pos : null;
		},
		getVisibility(id) {
			return this.columnsProperties[id] ? this.columnsProperties[id].visible : null;
		},
		isColumnRemoved(id) {
			return this.columnsProperties[id] ? this.columnsProperties[id].removed : null;
		},
		getSortedColumnsKeys() {
			const keys = Object.keys(this.columnsProperties);

			for (let key = keys.length - 1; key > 0; key -= 1) {
				if (this.columnsProperties[keys[key]].removed) {
					keys.splice(key, 1);
				}
			}

			keys.sort((a, b) => {
				if (this.columnsProperties[a].pos < this.columnsProperties[b].pos) {
					return -1;
				}

				if (this.columnsProperties[a].pos > this.columnsProperties[b].pos) {
					return 1;
				}

				return 0;
			});

			return keys;
		},
		getColumnProps(column) {
			const columnProps = {
				id: column.id,
				after: column.after,
				label: column.label,
				title: column.title || column.label,
				absoluteWidth: column.absoluteWidth || column.width,
				width: column.width || null,
				dragStartWidth: column.width,
				minWidth: column.minWidth || 40,
				// template: column.template || null,
				// editTemplate: column.editTemplate || null,
				// searchTemplate: column.searchTemplate,
				// clearButton: column.clearButton !== false,
				sortable: column.sortable === true,
				searchable: column.searchable === true,
				visible: column.hidden !== true,
				resizable: column.resizable !== false,
				// persitent: column.persistent === true,
				removed: column.removed === true,
				tooltip: column.tooltip,
				icon: column.icon,
			};
			return columnProps;
		},
		getCSSRulesAndWidths() {
			let css = '';
			const keys = Object.keys(this.columnsProperties);
			const availableWidth = this.$refs.viewport.offsetWidth - this.scrollbarWidth;
			const dynamicCols = [];
			const columnsWidths = {};
			let usedWidth = 0;
			let dynamicSum = 0;
			let availableDynamicWidth = 0;
			let leftDynamicWidth = 0;
			let totalRowWidth = 16; // width of more actions on right side

			for (let i = 0; i < keys.length; i += 1) {
				const columnProps = this.columnsProperties[keys[i]];
				const { width } = columnProps;
				let { absoluteWidth } = columnProps;

				if (!columnProps.removed && columnProps.visible && columnProps.id !== 'actions') {
					if (width.toString().includes('%')) {
						dynamicCols.push(columnProps);
						dynamicSum += parseFloat(width, 10);
					} else {
						usedWidth += parseFloat(absoluteWidth, 10);
						columnsWidths[keys[i]] = absoluteWidth;
						absoluteWidth += 'px';
						css += `
						#grid-${this.id} .grid-column-${columnProps.pos}
						{
								width: ${absoluteWidth};
								order: ${columnProps.pos};
						}\n`;
					}
				} else if (columnProps.id === 'actions') {
					if (width.toString().includes('%')) {
						console.error(
							'[LbaGrid] column actions does not support width set in %, setting width to 90px',
							this.gridName || this.gridId
						);
					}
					absoluteWidth += 'px';
					css += `
					#grid-${this.id} .grid-column-9999
					{
							width: ${absoluteWidth} !important;
							order: ${columnProps.pos};
					}\n`;
				}
			}

			totalRowWidth += usedWidth;
			availableDynamicWidth = availableWidth - usedWidth;
			const originalAvailableDynamicWidth = availableDynamicWidth;
			let i = dynamicCols.length - 1;

			while (i >= 0) {
				const column = dynamicCols[i];
				// is minimal with bigger than current calculated width?
				if (column.minWidth > (
					originalAvailableDynamicWidth * (parseFloat(column.width, 10) / (dynamicSum / 100)))
				) {
					// fix dynamic width sum - this column has absolute width now
					dynamicSum -= parseFloat(column.width, 10);
					// set new width values
					column.absoluteWidth = column.minWidth.toString();
					totalRowWidth += column.minWidth;
					css += `
					#grid-${this.id} .grid-column-${column.pos}
					{
							width: ${column.absoluteWidth}px;
							order: ${column.pos};
					}\n`;
					// recalculate available with for dynamic cols
					availableDynamicWidth -= column.minWidth;
					columnsWidths[column.id] = column.absoluteWidth;
					// this column is no longer dynamicaly sized
					dynamicCols.splice(i, 1);
				}

				i -= 1;
			}

			leftDynamicWidth = availableDynamicWidth;
			const len = dynamicCols.length;

			for (i = 0; i < len; i += 1) {
				const column = dynamicCols[i];
				const dynamicWidth = parseFloat(column.width, 10) / (dynamicSum / 100);
				let absWidth = Math.round(availableDynamicWidth * (dynamicWidth / 100));

				if (absWidth > leftDynamicWidth) {
					absWidth = leftDynamicWidth;
				} else if (i === len - 1) {
					absWidth = leftDynamicWidth;
				}

				const width = Math.max(absWidth, column.minWidth);
				totalRowWidth += width;
				column.absoluteWidth = width.toString();
				css += `
					#grid-${this.id} .grid-column-${column.pos}
					{
							width: ${column.absoluteWidth}px;
							order: ${column.pos};
					}\n`;
				leftDynamicWidth -= absWidth;
				columnsWidths[column.id] = column.absoluteWidth;
			}

			return { css, columnsWidths, totalRowWidth };
		},
		setCssRules(init, searchRowState) {
			// if (
			// 	init ||
			// 	this.dynamicHeight ||
			// 	searchRowState != null ||
			// 	this.lastViewportHeight < this.viewportHeight
			// ) {
			// 	let height = this.viewportHeight;

			// 	if (!this.dynamicHeight && !this.standalone) {
			// 		height += searchRowState * this.rowHeight;
			// 	}
			// 	// row: no data found
			// 	if (this.getTotalRowsCount() === 0 && height < this.rowHeight + this.showSearchRow * this.rowHeight) {
			// 		height += this.rowHeight;
			// 	}

			// 	if (this.$refs && this.$refs.viewport) {
			// 		this.$refs.viewport.setAttribute('style', `height: ${height}px;`);
			// 	}
			// }
			// this.scrollDown();
			const { css, totalRowWidth } = this.getCSSRulesAndWidths();

			if (this.styleSheet.styleSheet) {
				this.styleSheet.cssText = css;
			} else {
				this.styleSheet.textContent = css;
			}

			this.headerWidth = totalRowWidth;
			this.$refs.header.setAttribute(
				'style',
				`left: ${this.headerLeft}px; width: ${this.headerWidth}px;`
			);
		},
		getColumnPosition(index) {
			return `grid-column-${index}`;
		},
		setExtraColumns() {
			if (this.extraColumns != null) {
				console.error(
					'[LbaGrid] property extraColumns is depracated, check docs on how to use extra columns.',
					this.gridName || this.gridId
				);
				for (let i = 0; i < this.extraColumns.length; i += 1) {
					const extraColumn = _.cloneDeep(this.extraColumns[i]);

					// remove columns that should be removed
					if (extraColumn.remove) {
						if (_.isEmpty(this.columnsProperties[extraColumn.id])) {
							console.warn(
								'[LbaGrid](setExtraColumns) trying to remove non existing column with extraColumn:', extraColumn.id
							);
							continue;
						}
						const { pos } = this.columnsProperties[extraColumn.id];
						this.columnsProperties[extraColumn.id].removed = true;
						const keys = Object.keys(this.columnsProperties);

						for (let j = 0; j < keys.length; j += 1) {
							if (this.columnsProperties[keys[j]].pos > pos) {
								this.columnsProperties[keys[j]].pos -= 1;
							}
						}
					// add columns to specified place (after defined columnId)
					} else {
						// extra column were registered only by prop not slot
						if (_.isEmpty(this.columnsProperties[extraColumn.id])) {
							this.columnsProperties[extraColumn.id] = this.getColumnProps(extraColumn);
						}

						const columnAfter = this.columnsProperties[extraColumn.after];
						let position = null;

						if (columnAfter && columnAfter.pos != null) {
							position = columnAfter.pos;
						} else {
							position = extraColumn.pos;
						}

						const keys = Object.keys(this.columnsProperties);

						for (let j = 0; j < keys.length; j += 1) {
							if (this.columnsProperties[keys[j]].pos >= position + 1) {
								this.columnsProperties[keys[j]].pos += 1;
							}
						}

						this.columnsProperties[extraColumn.id].pos = position;
					}
				}
			}
		},
		prepareColumnsOrder() {
			let maxPosition = 0;
			Object.keys(this.columnsProperties).forEach((columnKey) => {
				const column = this.columnsProperties[columnKey];
				if (column.pos == null) {
					return;
				}
				if (column.pos > maxPosition) {
					maxPosition = column.pos;
				}
				const columnAfter = this.columnsProperties[column.after];

				if (columnAfter && columnAfter.pos != null && column.pos != null) {
					const oldPosition = column.pos;
					// imagine it like bricks, if you move one out, all of above must fall
					Object.keys(this.columnsProperties).forEach((key) => {
						if (this.columnsProperties[key].pos > oldPosition) {
							this.columnsProperties[key].pos -= 1;
						}
					});

					const newPosition = columnAfter.pos;
					// now we push one brick in, so all of above must go up
					Object.keys(this.columnsProperties).forEach((key) => {
						if (this.columnsProperties[key].pos > newPosition) {
							this.columnsProperties[key].pos += 1;
						}
					});

					column.pos = newPosition + 1;
				}
			});

			let currentPosition = maxPosition + 1;

			this.unOrderedColumns.forEach((columnKey) => {
				const column = this.columnsProperties[columnKey];
				column.pos = currentPosition;
				column.defaultPos = currentPosition;

				const columnAfter = this.columnsProperties[column.after];

				if (columnAfter && columnAfter.pos != null && column.pos != null) {
					const oldPosition = column.pos;
					// imagine it like bricks, if you move one out, all of above must fall
					Object.keys(this.columnsProperties).forEach((key) => {
						if (this.columnsProperties[key].pos > oldPosition) {
							this.columnsProperties[key].pos -= 1;
						}
					});

					const newPosition = columnAfter.pos;
					// now we push one brick in, so all of above must go up
					Object.keys(this.columnsProperties).forEach((key) => {
						if (this.columnsProperties[key].pos > newPosition) {
							this.columnsProperties[key].pos += 1;
						}
					});

					column.pos = newPosition + 1;
				} else {
					currentPosition += 1;
				}
			});

			if (
				this.columnsProperties.actions && (
					this.columnsProperties.actions.pos == null ||
					this.columnsProperties.actions.pos < currentPosition
				)
			) {
				const columnCount = Object.keys(this.columnsProperties).length;
				const actionPos = Math.max(columnCount, currentPosition);
				this.columnsProperties.actions.pos = actionPos;
				this.columnsProperties.actions.defaultPos = actionPos;
			}
		},
		checkColumnsOrder() {
			const positions = new Map();
			const duplicateKeys = [];
			_.forEach(this.columnsProperties, (column, key) => {
				if (['action', 'checkbox'].includes(column.id) && !column.label) return;
				if (positions.has(column.pos)) {
					duplicateKeys.push(`${positions.get(column.pos)} - ${key}(${column.pos})`);
				} else {
					positions.set(column.pos, key);
				}
			});
			if (!_.isEmpty(duplicateKeys)) {
				console.error(`[LbaGrid] columns contain duplicate positions: ${duplicateKeys.join(', ')}`, this.gridName || this.gridId);
			}
		},
		setColumnsProperties(column) {
			this.$set(this.columnsProperties, column.id, this.getColumnProps(column));

			this.columnsProperties[column.id].defaultWidth = column.width;
			this.columnsProperties[column.id].defaultAbsoluteWidth = column.absoluteWidth || column.width;
			this.columnsProperties[column.id].defaultMinWidth = column.minWidth || 40;
			this.columnsProperties[column.id].defaultVisibility = column.hidden !== true;
			this.columnsProperties[column.id].headerStyle = column.headerStyle || null;
			this.columnsProperties[column.id].headerClass = column.headerClass || null;

			if (!isNaN(parseInt(column.initPosition))) {
				this.columnsProperties[column.id].pos = parseInt(column.initPosition);
				this.columnsProperties[column.id].defaultPos = parseInt(column.initPosition);
			} else if (column.id === 'actions') {
				this.columnsProperties[column.id].pos = this.columnsCount;
				this.columnsProperties[column.id].defaultPos = this.columnsCount;
			} else {
				this.unOrderedColumns.push(column.id);
			}

			this.setupColumns();
		},
		updateColumnProperties(column) {
			Object.keys(column).forEach((key) => {
				if (key !== 'id') {
					this.columnsProperties[column.id][key] = column[key];
				}
			});
		},
		async setupColumns() {
			if (Object.keys(this.columnsProperties).length === this.columnsCount && !this.initDone) {
				this.prepareColumnsOrder();
				await this.initColumnsSettings();
				this.checkColumnsOrder();

				const interval = setInterval(() => {
					if (this.$refs.viewport) {
						clearInterval(interval);

						this.setExtraColumns();
						this.setCssRules(true);
						this.initDone = true;
						this.$emit('init-done');
						this.$forceUpdate();
						this.columnsSettingsInitResolve();
					}
				}, 50);
			}
		},
		columnResize(event, column) {
			this.resizingColumn = true;
			const main = document.getElementById('main');
			let style = main.getAttribute('style') || '';
			style += 'user-select: none;';
			main.setAttribute('style', style);
			this.dragStartX = event.pageX;
			this.resizeColumn = this.columnsProperties[column.id];
			this.resizeColumn.dragStartWidth = event.target.parentElement.offsetWidth;
			this.resizingLastColumn = _.every(this.columnsProperties, (item) => item.pos <= this.resizeColumn.pos || item.id === 'actions');
		},
		mouseUpHandler() {
			if (this.resizingColumn) {
				if (this.settingsDebouncer) {
					this.settingsDebouncer.emit();
				}

				this.resizingColumn = false;
				this.resizingLastColumn = false;
				const main = document.getElementById('main');

				const style = main.getAttribute('style') || '';
				main.setAttribute('style', style.replace('user-select: none;', ''));
				this.ignoreSort = true;
				setTimeout(() => {
					this.ignoreSort = false;
				}, 10);
			}
		},
		mouseMoveResize(event) {
			if (!this.resizingColumn) {
				return;
			}

			const widthDiff = event.pageX - this.dragStartX;

			const newColumnWidth = this.resizeColumn.dragStartWidth + widthDiff;

			if (newColumnWidth < Math.max(this.resizeColumn.minWidth, 0)) {
				return;
			}

			if (this.resizingLastColumn) {
				const scrollTo = this.headerWidth - this.$refs.viewport.clientWidth;
				this.$refs.viewport.scroll(scrollTo, this.offsetTop);
			}

			requestAnimationFrame(() => {
				this.resizeColumn.width = newColumnWidth;
				this.resizeColumn.absoluteWidth = newColumnWidth;
				this.setCssRules();
			});
		},
		columnSort(column) {
			if (column.sortable && !this.ignoreSort) {
				this.collection.toggleOrderBy(column.id);
				this.orderBy = this.collection.params._order;
				this.orderDirection = this.collection.params._order_dir;
				this.fetchCollection();
				return {
					column,
					orderBy: this.orderBy,
					orderDirection: this.orderDirection,
				};
			}
			return null;
		},
	},
};
