<template>
	<div>
		<ValidationObserver ref="form">
			<form name="form" lba-size-class="{'contentWide': 700}">
				<lba-section
					:parentComponentId="currentComponentId"
					v-bind:title="$t('settings.user.user')"
					slotType="form"
					class="size-6"
					expanded
				>
					<s class="size-3">
						<small>{{ $t('settings.user.firstname') }}</small>
						<input
							type="text"
							name="firstname"
							v-model="user.firstname"
							:disabled="readOnly"
							@input="dirty = true; addAvoided('firstname', user.firstname, $t('settings.user.firstname'));"
							:data-cy="`${currentComponentId}__firstName__inputText`"
						>
					</s>
					<s class="size-3">
						<small>{{ $t('settings.user.lastname') }}</small>
						<input
							type="text"
							name="lastname"
							v-model="user.lastname"
							:disabled="readOnly"
							@input="dirty = true; addAvoided('lastname', user.lastname, $t('settings.user.lastname'));"
							:data-cy="`${currentComponentId}__lastName__inputText`"
						>
					</s>
					<ValidationProvider
						:name="$t('settings.user.login')"
						v-slot="{ invalid, errors }"
						:rules="{ required: true, regex: '^[-@a-z0-9_.]+$' }"
					>
						<s class="size-3">
							<small>{{ $t('settings.user.login') }} <span class="required-sign"></span></small>
							<input type="text" name="user_name"
								:data-cy="`${currentComponentId}__userName__inputText`"
								v-model="user.user_name"
								:disabled="user.id"
								@input="dirty = true; addAvoided('login', user.user_name, $t('settings.user.login'));"
								:class="{ 'lba-invalid': invalid && dirty }"
							>
							<template v-if="dirty">
								<span
									v-for="(err, index) in errors"
									:key="index"
									:data-cy="`${currentComponentId}__userName__error${index}`"
									class="lba-messages"
								>{{ err }}</span>
							</template>
						</s>
					</ValidationProvider>
					<!-- <s v-if="groups != null && groups.length > 0">
						<small>{{ $t('settings.user.parentRoles') }}</small> -->
						<!--fuzzy-search="true"-->
						<!-- <lba-select
							v-bind:opts="groups.map((value) => { return { value: value.id, label: value.name }; })"
							v-model="$v.schema.groups.$model"
							v-bind:multiple="true"
						></lba-select>
					</s> -->
					<s class="size-3">
						<small>{{ $t('settings.user.company') }}</small>
						<input type="text" name="company" v-model="user.company" :disabled="readOnly" @input="dirty = true"
							:data-cy="`${currentComponentId}__company__inputText`"
						>
					</s>
					<ValidationProvider
						:name="$t('settings.user.email')"
						v-slot="{ invalid, errors }"
						:rules="{ email: true }"
					>
						<s class="size-3">
							<small>{{ $t('settings.user.email') }}</small>
							<input
								:data-cy="`${currentComponentId}__email__inputText`"
								type="text"
								name="email"
								v-model="user.email"
								:disabled="readOnly"
								@input="dirty = true; addAvoided('email', user.email, $t('settings.user.email'));"
								:class="{ 'lba-invalid': invalid && dirty }"
							>
							<template v-if="dirty">
								<span
									v-for="(err, index) in errors"
									:key="index"
									:data-cy="`${currentComponentId}__email__error${index}`"
									class="lba-messages"
								>{{ err }}</span>
							</template>
						</s>
					</ValidationProvider>
					<s class="size-3" v-if="$user.allowedPerUserChanges.allow_language_per_user">
						<small>{{ $t('settings.user.language') }}</small>
						<select name="lang" v-model="user.lang" :disabled="readOnly" @change="dirty = true"
							:data-cy="`${currentComponentId}__language__select`"
						>
							<option value="cs" :data-cy="`${currentComponentId}__language__select__optionCs`">česky</option>
							<option value="en" :data-cy="`${currentComponentId}__language__select__optionEn`">english</option>
						</select>
					</s>
					<s class="size-3" v-if="$user.allowedPerUserChanges.allow_timezone_per_user">
						<small>{{ $t('settings.user.timezone') }}</small>
						<select name="timezone" v-model="user.timezone" :disabled="readOnly" @change="dirty = true"
							:data-cy="`${currentComponentId}__timezone__select`"
						>
							<option v-for="(tz, index) in this.timezoneList"
								v-bind:key="index"
								v-bind:value="tz"
								:data-cy="`${currentComponentId}__timezone__select__option${index}`"
							>
								{{ tz }}
							</option>
						</select>
					</s>
					<s class="size-6">
						<small>{{ $t('settings.user.description') }}</small>
						<textarea name="description" v-model="user.description" :disabled="readOnly" @input="dirty = true"
							:data-cy="`${currentComponentId}__description__textarea`"
						></textarea>
					</s>
					<div v-if="!hideCredentials">
						<div class="fieldset fieldsetBottom">
							<s class="block">
								<label
									v-if="user.createAgent !== undefined"
									class="checkbox"
									:data-cy="`${currentComponentId}__createAgent__checkboxLabel`"
								>
									<input
										:data-cy="`${currentComponentId}__createAgent__inputCheckbox`"
										type="checkbox"
										name="createAgent"
										v-model="user.createAgent"
										:disabled="readOnly"
										@change="dirty = true">
									<i class="icon-ok"></i>
									<span class="label">{{ $t('settings.user.createAgent') }}</span>
								</label>
								<button
									:data-cy="`${currentComponentId}__showAgentInNewTab`"
									v-if="user.agent_uid"
									class="buttonIcon m-2"
									type="button"
									@click="openAgent"
								>
									<i class="icon-new-tab"></i>{{ $t('settings.user.showAgent') }}
								</button>
								<label class="checkbox" :data-cy="`${currentComponentId}__canLogin__checkboxLabel`">
									<input
										:data-cy="`${currentComponentId}__canLogin__inputCheckbox`"
										type="checkbox"
										name="can_login"
										v-model="user.can_login"
										:disabled="readOnly"
										@change="dirty = true">
									<i class="icon-ok"></i>
									<span class="label">{{ $t('settings.user.allowLogin') }}</span>
								</label>
							</s>
							<s class="block">
								<label class="checkbox" :data-cy="`${currentComponentId}__forcePasswordChange__checkboxLabel`">
									<input type="checkbox" name="force_password_change"
										:data-cy="`${currentComponentId}__forcePasswordChange__inputCheckbox`"
										v-model="user.force_password_change"
										:disabled="readOnly"
										@change="dirty = true"
									>
									<i class="icon-ok"></i>
									<span class="label">{{ $t('settings.forcePasswordChange') }}</span>
								</label>
							</s>
							<s class="block">
								<label class="checkbox" :data-cy="`${currentComponentId}__requireExten__checkboxLabel`">
									<input type="checkbox" name="requireExten"
										:data-cy="`${currentComponentId}__requireExten__inputCheckbox`"
										v-model="user.exten_login"
										:disabled="readOnly"
										@change="dirty = true"
									>
									<i class="icon-ok"></i>
									<span class="label">{{ $t('settings.requireExten') }}</span>
								</label>
							</s>
							<s class="size-3" v-if="!hideAuthMethod">
								<small>{{ $t('settings.user.authMethod') }}</small>
								<select name="auth_method" v-model="user.auth_method" :disabled="readOnly" @change="dirty = true"
									:data-cy="`${currentComponentId}__authMethod__select`"
								>
									<option
										v-bind:key="`authMethod-0`"
										v-bind:value="null"
										:data-cy="`${currentComponentId}__authMethod__select__option0`"
									>
										{{ $t(`authMethods.lbadmin`) }}
									</option>
									<option v-for="(auth, index) in authMethodsList"
										v-bind:key="`authMethod-${index+1}`"
										v-bind:value="auth.auth_method"
										:data-cy="`${currentComponentId}__authMethod__select__option${index+1}`"
									>
										{{ $t(`authMethods.${auth.auth_method}`) }}
									</option>
								</select>
							</s>
							<s v-if="tfaMethodsList.length && !hideAuthMethod">
								<small>{{ $t('settings.user.tfa') }}</small>
								<select
									name="tfa"
									v-model="user.tfa"
									@change="dirty = true"
									:data-cy="`${currentComponentId}__tfa__select`"
									:disabled="readOnly"
								>
									<option :value="null" :data-cy="`${currentComponentId}__tfa__select__option0`">
										{{ $t('inactive') }}
									</option>
									<option
										v-for="(type, index) in tfaMethodsList"
										:key="`tfa-${type.tfa_method}`"
										:value="type.tfa_method"
										:data-cy="`${currentComponentId}__tfa__select__option${index+1}`"
									>
										{{ $t(`tfaMethods.${type.tfa_method}`) }}
									</option>
								</select>
							</s>
							<s class="size-3">
								<small>{{ $t('settings.user.validUntil') }}</small>
								<lba-datepicker
									:parentComponentId="currentComponentId"
									componentId="validUntil"
									v-model="user.valid_until"
									mode="date"
									:initEmpty="false"
									:format="'D.M.YYYY'"
									:readOnly="readOnly"
									@input="dirty = true"
								></lba-datepicker>
							</s>
							<ValidationProvider
								:name="$t('settings.user.password')"
								v-slot="{ invalid, errors }"
								:rules="{
									required: (showPasswd && !user.password_t),
									regex: passwordRegex,
									notContains: avoidedItems,
								}"
							>
								<s class="size-3" v-if="showPasswd">
									<small>
										{{ $t('settings.user.password') }}
										<span v-if="showPasswd && !user.password_t" class="required-sign"></span>
									</small>
									<lba-input-password
										:parentComponentId="currentComponentId"
										componentId="password"
										v-model="user.password"
										:hasPassword="user.password_t"
										:required="true"
										:invalid="invalid"
										:writeable="!readOnly"
										:readable="!readOnly"
										@input="dirty = true; testingPassword = user.password;"
										:class="{ 'lba-invalid': invalid && dirty }"
										:rules="passwordRules"
									></lba-input-password>
									<template v-if="dirty">
										<!-- ERRORS FROM PASSWORD MIXIN - PASSWORD RULES -->
										<span
											v-for="(err, index) in passwordErrors"
											:key="`pwe-${index}`"
											:data-cy="`${currentComponentId}__password__error${errors.length + index}`"
											class="lba-messages"
										>{{ err }}</span>
										<span
											v-for="(err, index) in errors"
											:key="index"
											:data-cy="`${currentComponentId}__password__error${index}`"
											class="lba-messages"
										>{{ err }}</span>
									</template>
								</s>
							</ValidationProvider>
							<s class="size-3" v-if="showPasswd">
								<small>{{ $t('settings.user.passExpiration') }}</small>
								<lba-datepicker
									:parentComponentId="currentComponentId"
									componentId="passwordExpiration"
									v-model="user.password_expiration"
									mode="date"
									:initEmpty="false"
									:format="'D.M.YYYY'"
									:readOnly="readOnly"
									@input="dirty = true"
								></lba-datepicker>
							</s>
							<s class="size-3" v-if="user.id">
								<small>{{ $t('settings.user.lastLogin') }}</small>
								<span v-if="user.last_login">{{ $d(new Date(user.last_login), 'long') }}</span>
								<span v-else>{{ $t('settings.user.notLoggedYet') }}</span>
							</s>
						</div>
					</div>
				</lba-section>
				<div v-if="!hideExtra">
					<lba-section v-if="extraSettings && extraSettings.length > 0"
						:parentComponentId="currentComponentId"
						componentId="extraSettings"
						v-bind:title="$t('settings.user.extra')"
						slotType="form" expanded
					>
						<lba-extra-setting
							v-for="(setting, index) in extraSettings"
							:parentComponentId="currentComponentId"
							:componentId="`extraSetting${index}`"
							:key="index"
							:setting="setting"
							:user="user"
							@dirty="dirty = true"
							:disabled="readOnly"
						></lba-extra-setting>
					</lba-section>
				</div>
			</form>
		</ValidationObserver>
		<slot :parentComponentId="`${currentComponentId}__slotDefault`"></slot>
		<!-- <lba-dialog name="settings-update-auth-qrcode" v-on:close="resetOTPDialog" :parentComponentId="currentComponentId">
			<div v-if="otpDialog.step === 0" class="col1" style="margin: 0 auto">
				<h4>{{ $t('settings.user.passwordChange') }}</h4>
				<div class="col2">
					<p>{{ $t('settings.user.otp.resetOtpInfo') }}</p>
					<div class="popupButtons">
						<button type="button" class="buttonBig" :disabled="otpDialog.loading"
							@click="requestQRCode" :data-cy="`${currentComponentId}__QRCode__request`"
						>
							<i class="icon16 icon-ok"></i>&nbsp;
							<span>{{ $t('settings.user.otp.understand') }}</span>
						</button>
					</div>
				</div>
			</div>
			<div v-if="otpDialog.step === 1" class="col1" style="margin: 0 auto">
				<h4>{{ $t('settings.user.otp.enterPassword') }}</h4>
				<div class="form">
					<s>
						<small class="alignLeft">{{ $t('password') }}</small>
						<input type="password" v-model="otpDialog.password" :data-cy="`${currentComponentId}__QRCode__inputPassword`">
					</s>
				</div>
				<div class="popupButtons">
					<button type="button" class="buttonBig" v-bind:disabled="otpDialog.loading" v-on:click="requestQRCode"
						:data-cy="`${currentComponentId}__QRCode__request`"
					>
						<i class="icon16 icon-ok"></i>&nbsp;
						<span>{{ $t('settings.user.otp.loadQRCode') }}</span>
					</button>
				</div>
			</div>
			<div v-if="otpDialog.step === 2" class="col1" style="margin: 0 auto">
				<h4>{{ $t('settings.user.otp.newQRCode') }}</h4>
				<div class="content">
					<p>{{ $t('scanQRCodeInfo') }}</p>
					<svg class="qrcode" v-bind:viewBox="`0 0 ${otpDialog.qr.size} ${otpDialog.qr.size}`">
						<path v-bind:d="otpDialog.qr.path" />
					</svg>
				</div>
			</div>
		</lba-dialog> -->
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
import PasswordMixin from '../mixins/Password';
import OptionsModel from '../models/Options';

export default {
	name: 'LbaUserForm',
	mixins: [ComponentIdentifier, PasswordMixin],
	props: {
		user: {
			type: Object,
			default: null,
		},
		model: Object,
		groups: Array,
		extraSettings: Array,
		expanded: Boolean,
		hideAuthMethod: Boolean,
		hideExtra: Boolean,
		hideCredentials: Boolean,
		isDirty: Boolean,
		invalidPass: {
			type: Boolean,
			default: false,
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			authMethodsList: [],
			tfaMethodsList: [],
			// otpDialog: {
			// 	loading: false,
			// 	step: 1,
			// 	password: '',
			// },
			userEditingHimself: false,
			timezoneList: [],
			loaded: false,
			validationDebouncer: null,
			dirty: false,
			validating: false,
			optionsModel: null,
			avoidedItems: [],
			avoidedItemsWhole: {},
		};
	},
	computed: {
		showPasswd() {
			return (['null', 'gAuth', 'agent'].includes(this.user.auth_method || 'null'));
		},
	},
	watch: {
		dirty(newValue) {
			if (newValue === true) {
				this.pageEditted();
			}
		},
		user: {
			immediate: true,
			deep: true,
			handler(newValue) {
				this.validationDebouncer?.emit();
			},
		},
		isDirty(newValue) {
			this.dirty = newValue;
		},
	},
	async created() {
		this.model.queryAction('timezoneList').then((response) => {
			this.timezoneList = response;
		});

		this.optionsModel = new OptionsModel(this.$http);
		const resp = await this.optionsModel.getAuth();
		this.authMethodsList = _.filter(resp.data.auth_methods, 'enabled');
		this.tfaMethodsList = _.filter(resp.data.tfa_methods, 'enabled');

		this.validationDebouncer = new this.$Debouncer(
			this,
			this.validate,
			null,
			200
		);

		this.addAvoided('firstname', this.user.firstname, this.$t('settings.user.firstname'));
		this.addAvoided('lastname', this.user.lastname, this.$t('settings.user.lastname'));
		this.addAvoided('login', this.user.login, this.$t('settings.user.login'));
		this.addAvoided('email', this.user.email, this.$t('settings.user.email'));
	},
	methods: {
		pageEditted() {
			this.$emit('dirty');
		},
		resetOTPDialog() {
			this.otpDialog = {
				loading: false,
				step: 1,
				password: '',
			};
		},
		async requestQRCode() {
			// show new QR code only if current user is editing himself
			if (!this.userEditingHimself) {
				this.resetOTPDialog();
				this.$emit('dialog-close', 'settings-update-auth-qrcode');
				return;
			}

			this.otpDialog.loading = true;
			const response = await this.model.otpQrCode({ password: this.otpDialog.password });
			this.otpDialog.step = 2;
			this.otpDialog.password = '';
			this.otpDialog.imageDataUrl = response.imageDataUrl;
		},
		openAgent() {
			this.$routerWrap.push({
				name: 'pbx-agent',
				params: { id: this.user.agent_uid, openNewTab: true },
			});
		},
		async validate() {
			if (this.validating) return;
			this.validating = true;
			this.user.valid = await this.$refs.form.validate();
			this.validating = false;
		},
		addAvoided(key, value, label) {
			this.avoidedItemsWhole[key] = { value, label };

			const arr = [];
			_.forEach(this.avoidedItemsWhole, (el) => {
				if (el.value && el.value.length > 2) arr.push(el.value);
			});

			this.avoidedItems = arr;
		},
	},
};
</script>
