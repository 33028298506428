// const lerp = (f0, f1, t) => (1 - t) * f0 + t * f1;
const clamp = (val, min, max) => Math.max(min, Math.min(val, max));

export class DragScroll {
	constructor(obj) {
		this.$el = document.querySelector(obj.el);
		this.$wrap = this.$el.querySelector(obj.wrap);
		this.$items = this.$el.querySelectorAll(obj.item);
		this.$bar = this.$el.querySelector(obj.bar);
		this.dispatchedMouseEvent = false;
		this.dsopatchnedActivedEvent = false;
		this.init();
	}

	init = () => {
		this.progress = 0;
		this.speed = 0;
		this.oldX = 0;
		this.x = 0;
		this.playrate = 0;
		this.bindings();
		this.events();
		this.calculate();
		// this.raf();
	}

	bindings = () => {
		[
			'events',
			'calculate',
			'handleWheel',
			'move',
			'handleTouchStart',
			'handleTouchMove',
			'handleTouchEnd',
			// 'raf',
		].forEach((i) => { this[i] = this[i].bind(this); });
	}

	calculate = () => {
		this.progress = 0;
		let maxWrapWidth = 0;
		if (this.$items && this.$items.length) {
			for (let i = 0; i < this.$items.length; i++) {
				maxWrapWidth += this.$items[i].clientWidth;
			}
			maxWrapWidth += 40;
		}
		this.wrapWidth = maxWrapWidth || this.$items[0].clientWidth * this.$items.length;
		this.$wrap.style.width = `${this.wrapWidth}px`;
		this.maxScroll = this.wrapWidth - this.$el.clientWidth;
	}

	handleWheel = (e) => {
		e.preventDefault();
		this.progress += e.deltaY;
		this.move();
	}

	handleTouchStart = (e) => {
		e.preventDefault();
		this.dragging = true;
		this.startX = e.clientX || e.touches[0].clientX;
		this.$el.classList.add('dragging');
	}

	handleTouchMove = (e) => {
		if (!this.dragging) return false;

		if (!this.dispatchedMouseEvent) {
			document.dispatchEvent(new CustomEvent(
				'sliderDragScroll-scroll', { detail: { scroll: true } }
			));
			this.dispatchedMouseEvent = true;
		}
		const x = e.clientX || e.touches[0].clientX;
		this.progress += (this.startX - x) * 2.5;
		this.startX = x;
		this.move();
	}

	handleTouchEnd = (e) => {
		this.dragging = false;
		this.$el.classList.remove('dragging');
		this.dispatchedMouseEvent = false;
		document.dispatchEvent(new CustomEvent(
			'sliderDragScroll-scroll', { detail: { scroll: false } }
		));
	}

	handleKeyUp = (e) => {
		if (e.code === 'ArrowLeft') {
			if ((this.progress - this.$el.clientWidth) > 0) {
				this.progress -= this.$el.clientWidth;
			} else {
				this.progress = 0;
			}
		} else if (e.code === 'ArrowRight') {
			if ((this.progress + this.$el.clientWidth) < this.maxScroll) {
				this.progress += this.$el.clientWidth;
			} else {
				this.progress = this.maxScroll;
			}
		}
	}

	handleMouseEnter = (e) => {
		document.dispatchEvent(new CustomEvent(
			'sliderDragScroll-activate', { detail: { activate: true } }
		));
		window.addEventListener('keyup', this.handleKeyUp);
		this.$el.addEventListener('wheel', this.handleWheel);
	}

	handleMouseLeave = (e) => {
		document.dispatchEvent(new CustomEvent(
			'sliderDragScroll-activate', { detail: { activate: false } }
		));
		window.removeEventListener('keyup', this.handleKeyUp);
		this.$el.removeEventListener('wheel', this.handleWheel);
	}

	move = () => {
		this.progress = clamp(this.progress, 0, this.maxScroll);
	}

	events = () => {
		window.addEventListener('resize', this.calculate);
		window.addEventListener('touchmove', this.handleTouchMove);
		window.addEventListener('touchend', this.handleTouchEnd);
		window.addEventListener('mouseup', this.handleTouchEnd);
		this.$el.addEventListener('touchstart', this.handleTouchStart);
		this.$el.addEventListener('mousedown', this.handleTouchStart);
		this.$el.addEventListener('mousemove', this.handleTouchMove);
		this.$el.addEventListener('mouseenter', this.handleMouseEnter);
		this.$el.addEventListener('mouseleave', this.handleMouseLeave);
		document.body.addEventListener('mouseleave', this.handleTouchEnd);
	}

	removeScrollListeners = () => {
		window.removeEventListener('resize', this.calculate);
		window.removeEventListener('touchmove', this.handleTouchMove);
		window.removeEventListener('touchend', this.handleTouchEnd);
		window.removeEventListener('mouseup', this.handleTouchEnd);
		this.$el.removeEventListener('touchstart', this.handleTouchStart);
		this.$el.removeEventListener('mousedown', this.handleTouchStart);
		this.$el.removeEventListener('mousemove', this.handleTouchMove);
		this.$el.removeEventListener('mouseenter', this.handleMouseEnter);
		this.$el.removeEventListener('mouseleave', this.handleMouseLeave);
		document.body.removeEventListener('mouseleave', this.handleTouchEnd);
	}
}
