<template>
	<component
		v-if="noInput && options.lbadmin_link"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<a :data-cy="`${currentComponentId}__link`" @click.stop="$routerWrap.push(data[key].value)">
			{{ data[key].label }}
		</a>
	</component>
	<component
		v-else-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		<a :data-cy="`${currentComponentId}__href`" :href="data[key]" target="_blank">
			<i class="icon-new-tab"></i>
		</a>
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<ValidationProvider
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${$generateUID()}`"
			:rules="rules"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<span
				class="has-icon input-link"
				:class="{
					'lba-invalid': (invalid && dirty),
					'showWarning': (showRecommendation)
				}"
				style="border: none"
			>
				<input
					:data-cy="`${currentComponentId}__input`"
					type="text"
					v-model="data[key]"
					v-bind="bindOptions()"
					:class="{
						'lba-invalid': (invalid && dirty),
						'showWarning': (showRecommendation)
					}"
					class="input-has-icon"
					:disabled="readOnly"
					@input="onEvent('input', parseString)"
					@change="onEvent('change', parseString)"
				>
				<template v-if="data[key] && data[key] !== ''">
					<a :data-cy="`${currentComponentId}__href`" :href="data[key]" target="_blank">
						<i class="icon-new-tab"></i>
					</a>
				</template>
				<template v-else>
					<i class="icon-new-tab disabled"></i>
				</template>
			</span>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</ValidationProvider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputLink',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
	},
	methods: {
		parseString() {
			if (!this.data[this.key]) {
				this.data[this.key] = null;
			}
		},
	},
};
</script>
