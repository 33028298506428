<script>
function isRegExp(v) {
	return Object.prototype.toString.call(v) === '[object RegExp]';
}

function isDef(v) {
	return v !== undefined && v !== null;
}

function isAsyncPlaceholder(node) {
	return node.isComment && node.asyncFactory;
}

function getFirstComponentChild(children) {
	if (Array.isArray(children)) {
		for (let i = 0; i < children.length; i += 1) {
			const c = children[i];
			if (isDef(c) && (isDef(c.componentOptions) || isAsyncPlaceholder(c))) {
				return c;
			}
		}
	}
	return null;
}

function remove(arr, item) {
	if (arr.length) {
		const index = arr.indexOf(item);
		if (index > -1) {
			return arr.splice(index, 1);
		}
	}
	return null;
}

function getComponentKey(vnode, componentOptions) {
	return vnode.key == null
	// same constructor may get registered as different local components
	// so cid alone is not enough (#3269)
		? componentOptions.Ctor.cid + (componentOptions.tag ? `::${componentOptions.tag}` : '')
		: vnode.key;
}

function matches(pattern, name) {
	if (Array.isArray(pattern)) {
		return pattern.indexOf(name) > -1;
	}
	if (typeof pattern === 'string') {
		return pattern.split(',').indexOf(name) > -1;
	}
	if (isRegExp(pattern)) {
		return pattern.test(name);
	}
	/* istanbul ignore next */
	return false;
}

function pruneCacheEntry(cache, key, keys, current) {
	const cached = cache[key];
	if (cached) { // && (!current || cached.data.key !== current.data.key)) {
		console.debug('[LbaKeepAlive] destroy:', key);
		cached.componentInstance.$destroy();
	}
	/* eslint-disable-next-line no-param-reassign */
	cache[key] = null;
	remove(keys, key);
}

function pruneCache(keepAliveInstance, filter) {
	const { cache, keys, _vnode } = keepAliveInstance;
	const cacheKeys = Object.keys(cache);
	for (let i = 0; i < cacheKeys.length; i += 1) {
		const key = cacheKeys[i];
		const cachedNode = cache[key];
		if (cachedNode) {
			if (key && !filter(key)) {
				pruneCacheEntry(cache, key, keys, _vnode);
			}
		}
	}
}

const patternTypes = [String, RegExp, Array];

export default {
	name: 'LbaKeepAlive',
	props: {
		include: patternTypes,
		exclude: patternTypes,
		max: [String, Number],
	},

	created() {
		this.cache = Object.create(null);
		this.keys = [];
		this.$root.$listen('keep-alive.remove-cache', this.removeCache, this);
		this.$root.$listen('keep-alive.remove-caches', this.removeCaches, this);
		this.$on('removeCache', this.removeCache);
	},

	destroyed() {
		const cacheKeys = Object.keys(this.cache);
		for (let i = 0; i < cacheKeys.length; i += 1) {
			const key = cacheKeys[i];
			pruneCacheEntry(this.cache, key, this.keys);
		}
	},

	mounted() {
		this.$watch('include', (val) => {
			pruneCache(this, (cacheKey) => matches(val, cacheKey));
		});
		this.$watch('exclude', (val) => {
			pruneCache(this, (cacheKey) => !matches(val, cacheKey));
		});
	},

	methods: {
		removeCache(key) {
			pruneCache(this, (cacheKey) => !matches(key, cacheKey));
			this.$root.$emit('allow-closing', key);
		},
		removeCaches(keys) {
			keys.forEach((key) => {
				pruneCache(this, (cacheKey) => !matches(key, cacheKey));
			});
			this.$root.$emit('allow-closing-keys', keys);
		},
	},

	render() {
		const slot = this.$slots.default;
		const vnode = getFirstComponentChild(slot);
		const componentOptions = vnode && vnode.componentOptions;
		if (componentOptions) {
			// check pattern
			const cacheKey = getComponentKey(vnode, componentOptions);
			const { include, exclude } = this;

			if (
				// not included
				(include && (!cacheKey || !matches(include, cacheKey))) ||
				// excluded
				(exclude && cacheKey && matches(exclude, cacheKey))
			) {
				return vnode;
			}

			const { cache, keys } = this;
			const key = getComponentKey(vnode, componentOptions);
			if (cache[key]) {
				vnode.componentInstance = cache[key].componentInstance;
				// make current key freshest
				remove(keys, key);
				keys.push(key);
			} else {
				cache[key] = vnode;
				keys.push(key);
				// prune oldest entry
				if (this.max && keys.length > parseInt(this.max, 10)) {
					/* eslint-disable-next-line no-underscore-dangle */
					pruneCacheEntry(cache, keys[0], keys, this._vnode);
				}
			}

			vnode.data.keepAlive = true;
		}
		return vnode || (slot && slot[0]);
	},
};
</script>
