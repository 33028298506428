<template>
	<content-options v-if="!loading">
		<template v-slot:content>
			<div class="page-header">
				<h1>{{ importExportSetting.label }}</h1>

				<div class="page-controls">
					<button
						v-if="!isNew"
						class="buttonTransparent"
						data-cy="importExportSetting__openDialogRemove"
						@click.stop="openRemoveDialog"
					>
						{{ $t('remove') }}
					</button>
					<button
						class="button"
						data-cy="importExportSetting__save"
						@click.stop="saveSetting"
						:disabled="!dirty"
					>
						{{ $t('save') }}
					</button>
					<span v-if="!isNew" @click="$root.$emit('tabs.reload')" data-cy="importExportSetting__reload">
						<i class="icon-reset" v-tooltip="$t('refreshData')"
						></i>
					</span>
				</div>
			</div>

			<div class="page-content">
				<ValidationObserver ref="form">
					<lba-section
						parentComponentId="importExportSetting"
						componentId="formatSettings"
						expanded
						class="form-fieldset"
						:title="$t('formatSettings')"
					>
						<template #default="props">
							<ValidationProvider
								:name="$t('name')"
								v-slot="{ invalid, errors }"
								:rules="{ required: true }"
							>
								<s>
									<small>{{ $t('name') }}</small>
									<input
										:data-cy="`${props.parentComponentId}__name__inputText`"
										type="text"
										v-model="importExportSetting.label"
										:class="{ 'lba-invalid': invalid && dirty }"
										@change="dirty = true"
									>
									<template v-if="dirty">
										<span
											v-for="(err, index) in errors"
											:key="index"
											:data-cy="`${props.parentComponentId}__name__error${index}`"
											class="lba-messages"
										>{{ err }}</span>
									</template>
								</s>
							</ValidationProvider>
							<ValidationProvider
								:name="$t('forTable')"
								v-slot="{ invalid, errors }"
								:rules="{ required: true }"
							>
								<s>
									<small>{{ $t('forTable') }}</small>
									<select
										v-model="importExportSetting.name"
										:class="{ 'lba-invalid': invalid && dirty }"
										@change="dirty = true"
										:data-cy="`${props.parentComponentId}__forTable__select`"
									>
										<option
											v-for="(table, tindex) in tableNames"
											:key="tindex"
											:value="table.value"
										>{{ table.label }}</option>
									</select>
									<template v-if="dirty">
										<span
											v-for="(err, index) in errors"
											:key="index"
											:data-cy="`${props.parentComponentId}__forTable__error${index}`"
											class="lba-messages"
										>{{ err }}</span>
									</template>
								</s>
							</ValidationProvider>
							<br>
							<s style="width: 160px;">
								<small>{{ $t('fileType') }}</small>
								<label class="checkbox radio" :data-cy="`${props.parentComponentId}__fileType__radioLabel`">
									<input
										:data-cy="`${props.parentComponentId}__fileTypeCsv__inputRadio`"
										type="radio"
										value="csv"
										v-model="importExportSetting.file_type"
										:name="`${Math.random()}.file-type`"
										@change="dirty = true"
									>
									<span class="checkmark"></span>
									<span class="label">CSV</span>
								</label>
								<label class="checkbox radio" :data-cy="`${props.parentComponentId}__fileTypeXlsx__radioLabel`">
									<input
										:data-cy="`${props.parentComponentId}__fileTypeXlsx__inputRadio`"
										type="radio"
										value="xlsx"
										v-model="importExportSetting.file_type"
										:name="`${Math.random()}.file-type`"
										@change="dirty = true"
									>
									<span class="checkmark"></span>
									<span class="label">XLSX</span>
								</label>
							</s>
							<s class="half">
								<small>{{ $t('containsHeader') }}</small>
								<lba-switch
									:parentComponentId="props.parentComponentId"
									componentId="containsHeader"
									v-model="importExportSetting.contains_header"
									class="mb-2 mt-2"
									:name="`${Math.random()}.switch-header`"
									:label1="$t('no')"
									:label2="$t('yes')"
									@input="dirty = true"
								/>
							</s>
							<s v-if="importExportSetting.file_type === 'xlsx'">
								<small>
									{{ $t('dateFormat') }}
									<i class="icon-tooltip" v-tooltip="$t('dateFormatHelp')"></i>
								</small>
								<input
									:data-cy="`${props.parentComponentId}__dateFormat__inputText`"
									type="text"
									v-model="importExportSetting.date_format"
									@input="onDateFormatChange(); dirty = true;"
								>
							</s>
							<s v-if="importExportSetting.file_type === 'xlsx'">
								<small>
									{{ $t('dateFormatExample') }}
								</small>
								{{ moment().format(importExportSetting.date_format || 'DD.MM.YYYY HH:mm:ss') }}
							</s>
							<template v-if="_.isEmpty(importExportSetting.file_type) || importExportSetting.file_type === 'csv'">
								<s class="half">
									<small>{{ $t('columnDelimiter') }}</small>
									<select v-model="importExportSetting.column_delimiter" @change="dirty = true"
										:data-cy="`${props.parentComponentId}__columnDelimiter__select`"
									>
										<option v-for="(delimiter, index) in columnDelimiters" :key="index" :value="delimiter.value"
											:data-cy="`${props.parentComponentId}__columnDelimiter__select__option${index}`"
										>
											{{ delimiter.label }}
										</option>
									</select>
								</s>
								<s class="half">
									<small>{{ $t('quoteCharacter') }}</small>
									<select v-model="importExportSetting.quote_character" @change="dirty = true"
										:data-cy="`${props.parentComponentId}__quoteCharacter__select`"
									>
										<option v-for="(character, index) in quoteCharacters" :key="index" :value="character.value"
											:data-cy="`${props.parentComponentId}__quoteCharacter__select__option${index}`"
										>
											{{ character.label }}
										</option>
									</select>
								</s>
								<s class="half">
									<small>{{ $t('encoding') }}</small>
									<select v-model="importExportSetting.encoding" @change="dirty = true"
										:data-cy="`${props.parentComponentId}__encoding__select`"
									>
										<option v-for="(encode, index) in encodings" :key="index" :value="encode"
											:data-cy="`${props.parentComponentId}__encoding__select__option${index}`"
										>
											{{ encode }}
										</option>
									</select>
								</s>
							</template>
						</template>
					</lba-section>

					<lba-section
						parentComponentId="importExportSetting"
						componentId="columnMapping"
						expanded
						class="form-fieldset"
						:title="$t('columnMapping')"
					>
						<template #default="sectionProps">
							<template v-if="isNew">
								<ValidationProvider
									:name="$t('forTable')"
									v-slot="{ invalid, errors }"
									:rules="{ required: true }"
								>
									<s>
										<small>{{ $t('forTable') }}</small>
										<select
											:data-cy="`${sectionProps.parentComponentId}__forTable__select`"
											v-model="importExportConfigurationUid"
											:class="{ 'lba-invalid': invalid && dirty }"
											@change="dirty = true; prepareImportExportConfiguration()"
										>
											<option
												v-for="(configuration, index) in importExportConfigurations"
												:key="index"
												:data-cy="`${sectionProps.parentComponentId}__forTable__select__option${index}`"
												:value="configuration.def_import_export_uid"
											>
												{{ getTableName(configuration.name) }}
											</option>
										</select>
										<template v-if="dirty">
											<span
												v-for="(err, index) in errors"
												:key="index"
												:data-cy="`${sectionProps.parentComponentId}__forTable__select__error${index}`"
												class="lba-messages"
											>{{ err }}</span>
										</template>
									</s>
								</ValidationProvider>
								<br>
							</template>

							<lba-grid
								v-if="!isNew || !_.isEmpty(importExportConfiguration)"
								ref="grid"
								gridName="lbadmin.def_import_export_settings-header_map"
								hideableColumns
								:collection="collection"
							>
								<template v-slot:toolbar="props">
									<button @click="newEntry" :data-cy="`${props.parentComponentId}__new`">
										{{ $t('new') }}
									</button>
								</template>

								<template v-slot:columns="props">
									<lba-grid-column
										id="title"
										:data-cy="`${props.parentComponentId}__title`"
										:label="$t('internalName')"
										width="200"
									>
										{{ props.row.title }}
									</lba-grid-column>
									<lba-grid-column
										id="header"
										:data-cy="`${props.parentComponentId}__header`"
										:label="`${$t('columnNameInFile')} / ${$t('columnOrderInFile')}`"
										width="330"
										sortable
									>
										<template v-if="importExportSetting.contains_header">
											{{ props.row.header }}
										</template>
										<template v-else>
											{{ isNumber(props.row.header) ? `${$t('column')} ${props.row.header + 1}` : '' }}
										</template>
									</lba-grid-column>
									<lba-grid-column
										id="actions"
										:data-cy="`${props.parentComponentId}__actions`"
										:label="$t('actions')"
										width="60"
										class="actions"
										style="justify-content: right"
									>
										<a
											src="#"
											style="flex: 1"
											:data-cy="`${props.parentComponentId}__actions__editEntry`"
											@click.stop="editEntry(props.row)"
										>
											<i class="icon-edit"
												v-tooltip="$t('edit')">
											</i>
										</a>
										<a
											v-if="customColumns.length > 1 && props.row.removable"
											src="#"
											:data-cy="`${props.parentComponentId}__actions__openDialogRemoveEntry`"
											@click.stop="openRemoveEntry(props.row)"
										>
											<i class="icon-delete"
												v-tooltip="$t('remove')"
											>
											</i>
										</a>
									</lba-grid-column>
								</template>
							</lba-grid>

							<lba-dialog-modal
								:parentComponentId="sectionProps.parentComponentId"
								componentId="removeEntry"
								name="removeEntry"
								:title="$t('removeImportExportSetting')"
							>
								<template #default="props">
									<div class="popup-footer">
										<button class="buttonRed" type="button" @click="remove"
											:data-cy="`${props.parentComponentId}__remove`"
										>
											{{ $t('remove') }}
										</button>
										<button class="buttonInverse" type="button" v-lba-dialog-close="'removeEntry'"
											:data-cy="`${props.parentComponentId}__closeDialog`"
										>
											{{ $t('cancel') }}
										</button>
									</div>
								</template>
							</lba-dialog-modal>

							<lba-dialog-edit
								:parentComponentId="sectionProps.parentComponentId"
								componentId="editHeaderMapEntry"
								name="editHeaderMapEntry"
								:title="$t('edit')"
								@submit="saveHeaderMapRow"
							>
								<template #default="props">
									<div class="form-fieldset" style="width: 600px;">
										<s>
											<small>{{ $t('column') }}</small>
											<span>{{ editedMapColumn.title }}</span>
										</s>
										<s>
											<template v-if="importExportSetting.contains_header">
												<small>{{ $t('columnNameInFile') }}</small>
												<input type="text" v-model="editedMapColumn.header"
													:data-cy="`${props.parentComponentId}__columnNameInFile__inputText`"
												>
											</template>
											<template v-else>
												<small>{{ $t('columnOrderInFile') }}</small>
												<input type="number" v-model="editedMapColumn.header" min="1"
													:data-cy="`${props.parentComponentId}__columnOrderInFile__inputNumber`"
												>
											</template>
										</s>
									</div>
								</template>
							</lba-dialog-edit>
							<lba-dialog-modal
								:parentComponentId="sectionProps.parentComponentId"
								componentId="removeHeaderMapEntry"
								name="removeHeaderMapEntry"
								:title="$t('remove')"
							>
								<template #default="props">
									<div class="popup-footer">
										<button class="buttonRed" type="button" @click="removeHeaderMapRow"
											:data-cy="`${props.parentComponentId}__remove`"
										>
											{{ $t('remove') }}
										</button>
										<button class="buttonInverse" type="button" v-lba-dialog-close="'removeHeaderMapEntry'"
											:data-cy="`${props.parentComponentId}__closeDialog`"
										>
											{{ $t('cancel') }}
										</button>
									</div>
								</template>
							</lba-dialog-modal>
						</template>
					</lba-section>
				</ValidationObserver>
			</div>
		</template>
	</content-options>
</template>

<script>
import ImportExportSettingsModel from '../models/ImportExportSettings';
import ImportExportConfigurationsModel from '../models/ImportExportConfigurations';

export default {
	data() {
		return {
			dirty: false,
			loading: true,
			importExportSettingsModel: null,
			importExportConfigurationsModel: null,
			importExportSetting: {
				def_import_export_setting_uid: null,
				def_import_export_uid: null,
				column_delimiter: ';',
				quote_character: '"',
				encoding: 'UTF-8',
				contains_header: null,
				date_format: null,
				type: null,
				file_type: null,
				header_map: null,
			},
			importExportConfiguration: {},
			importExportConfigurations: [],
			columnDelimiters: [
				{ label: this.$t('tab'), value: `\\t` },
				{ label: `,`, value: `,` },
				{ label: `;`, value: `;` },
				{ label: `-- ${this.$t('autoDetect')} --`, value: '' },
			],
			quoteCharacters: [
				{ label: `"`, value: `"` },
				{ label: `'`, value: `'` },
				{ label: `-- ${this.$t('blank')} --`, value: null },
			],
			encodings: [
				'UTF-8',
				'IBM866',
				'ISO-8859-2',
				'ISO-8859-3',
				'ISO-8859-4',
				'ISO-8859-5',
				'ISO-8859-6',
				'ISO-8859-7',
				'ISO-8859-8',
				'ISO-8859-8-I',
				'ISO-8859-10',
				'ISO-8859-13',
				'ISO-8859-14',
				'ISO-8859-15',
				'ISO-8859-16',
				// 'KOI8-R',
				// 'KOI8-U',
				'macintosh',
				'windows-874',
				'windows-1250',
				'windows-1251',
				'windows-1252',
				'windows-1253',
				'windows-1254',
				'windows-1255',
				'windows-1256',
				'windows-1257',
				'windows-1258',
				'x-mac-cyrillic',
				// 'GBK',
				// 'gb18030',
				// 'Big5',
				// 'EUC-JP',
				// 'ISO-2022-JP',
				// 'Shift_JIS',
				// 'EUC-KR',
				'replacement',
				'UTF-16BE',
				'UTF-16LE',
				'x-user-defined',
			],
			customColumn: null,
			collection: null,
			headerMapRowToRemove: null,
			editNewHeaderMapRow: false,
			importExportConfigurationUid: null,
			editedMapColumn: {},
			headerMap: [],
		};
	},
	computed: {
		isNew() {
			return _.isEmpty(this.$route.params.id) || this.$route.params.id === '+';
		},
		/* columns() {
			let first = true;
			return this.importExportConfiguration.columns.filter((column) => {
				if (first && column.custom) {
					first = false;
					return true;
				}
				return !column.custom;
			});
		}, */
		customColumns() {
			if (this.customColumn) {
				return this.headerMap.filter((m) => m.column === this.customColumn.name);
			}
			return [];
		},
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		if (this.$route.name === 'import-setting') {
			this.importExportSetting.type = 'IMPORT';
		} else {
			this.importExportSetting.type = 'EXPORT';
		}
		this.importExportSettingsModel = new ImportExportSettingsModel(this.$http);
		this.importExportConfigurationsModel = new ImportExportConfigurationsModel(this.$http);

		this.collection = new this.$Collection({ query: this.fakeQuery });
		this.collection.attach(this, 'collection');

		this.importExportConfigurations = (await this.importExportConfigurationsModel.query()).data;
		this.tableNames = (await this.importExportSettingsModel.tableList()).data;

		if (!_.isEmpty(this.$route.params.id) && this.$route.params.id !== '+') {
			const result = await this.importExportSettingsModel.get(this.$route.params.id);
			this.importExportSetting = result.data;
			this.importExportSetting.column_delimiter = this.importExportSetting.column_delimiter || '';

			this.importExportConfiguration = this.importExportConfigurations.find(
				(configuration) => configuration.def_import_export_uid === this.importExportSetting.def_import_export_uid
			);
			this.prepareColumns();
		}

		this.loading = false;
	},
	methods: {
		onDateFormatChange() {
			if (_.isEmpty(_.trim(this.importExportSetting.date_format))) {
				this.importExportSetting.date_format = null;
			}
		},
		getTableName(name) {
			const splittedByDot = name.split('.');
			const splittedByUnderscore = splittedByDot[1].split('_');
			return `${splittedByDot[0].toUpperCase()} - ${splittedByUnderscore.join(' ')}`;
		},
		prepareColumns() {
			this.headerMap = [];
			const headerMap = this.importExportSetting.header_map || [];
			this.importExportConfiguration.columns.columns.forEach((column) => {
				const mappedColumn = headerMap.find((m) => m.column === column.name);
				// console.log('mapped:', mappedColumn && mappedColumn.header, column.name);

				// not in header -> add it so user can map it
				if (!mappedColumn) {
					column.mapped = null;
					this.headerMap.push({ header: null, column: column.name });
				} else {
					column.mapped = mappedColumn.header;
					this.headerMap.push({ header: mappedColumn.header, column: column.name });
				}

				if (this.importExportConfiguration.validation_schema.required) {
					column.required = this.importExportConfiguration.validation_schema.required.includes(column.name);
				} else {
					column.required = false;
				}

				if (column.custom) {
					if (mappedColumn) {
						mappedColumn.custom = true;
					}

					this.customColumn = column;
				}
			});

			if (this.customColumn) {
				headerMap.forEach((mappedColumn) => {
					// console.log(mappedColumn);
					if (mappedColumn.column === this.customColumn.name) {
						const mapped = this.headerMap.find((m) => m.header === mappedColumn.header && m.column === mappedColumn.column);
						// console.log('custom:', mappedColumn.header, mapped && mapped.column);

						if (!mapped) {
							this.headerMap.push({ header: mappedColumn.header, column: this.customColumn.name });
						}
					}
				});
			}
		},
		prepareImportExportConfiguration() {
			const configuration = this.importExportConfigurations.find(
				(conf) => conf.def_import_export_uid === this.importExportConfigurationUid
			);
			this.importExportSetting.def_import_export_uid = this.importExportConfigurationUid;

			if (configuration) {
				this.importExportConfiguration = configuration;
				this.prepareColumns();
			}
		},
		addCustomColumn() {
			const customEmpty = this.headerMap.find((m) => m.header == null && m.column === this.customColumn.name);
			let mapped = null;

			if (customEmpty) {
				console.warn('another custom empty exist');
				return mapped;
			}

			mapped = { header: null, column: this.customColumn.name };
			this.headerMap.push(mapped);
			return mapped;
		},
		removeCustomColumn(customColumn) {
			const index = this.headerMap.findIndex((m) => m.header === customColumn.header && m.column === customColumn.header);

			if (index >= 0) {
				this.headerMap.splice(index, 1);
			}
		},

		fakeQuery(params) {
			const result = { headers: {} };

			result.data = _.cloneDeep(this.headerMap);
			result.data.forEach((column, index) => {
				const configurationColumn = this.importExportConfiguration.columns.columns.find((c) => c.name === column.column);
				column.id = index;
				column.title = this.$getLocale(configurationColumn.title);

				if (configurationColumn.custom) {
					column.removable = true;
				}
			});

			if (params.search) {
				result.data = result.data.filter((column) => (
					(column.header && column.header.includes(params.search)) ||
					column.column.includes(params.search)
				));
			}

			if (params.offset == null) {
				result.headers = { 'x-lbadmin-count': result.data.length };
			}

			if (params._order === 'header' && params._order_dir) {
				let f = -1;
				let s = 1;

				if (params._order_dir === 'asc') {
					f = 1;
					s = -1;
				}

				result.data.sort((a, b) => {
					if (a.header < b.header) return f;
					if (a.header > b.header) return s;
					return 0;
				});
			}
			return result;
		},

		async saveSetting() {
			this.dirty = true;
			const valid = await this.$refs.form.validate();

			if (!valid) {
				this.$root.$emit('content.validationFailed');
				return;
			}

			this.dirty = false;
			this.importExportSetting.header_map = this.headerMap;

			if (_.isEmpty(this.$route.params.id) || this.$route.params.id === '+') {
				this.importExportSetting = (await this.importExportSettingsModel.create(this.importExportSetting)).data;
				this.$notify.success(this.$t('form.saved'));
				this.$routerWrap.push({
					name: 'import-export-setting',
					params: { id: this.importExportSetting.def_import_export_setting_uid },
				});
			} else {
				this.importExportSetting = (await this.importExportSettingsModel.save(
					this.$route.params.id,
					this.importExportSetting
				)).data;
				this.prepareColumns();
				this.$notify.success(this.$t('form.saved'));
				this.$refs.grid.reload(this.$refs.grid.id);
			}
		},
		async saveHeaderMap() {
			if (_.isEmpty(this.$route.params.id) || this.$route.params.id === '+') {
				this.saveSetting();
			} else {
				this.importExportSetting = (await this.importExportSettingsModel.save(
					this.$route.params.id,
					{ header_map: this.headerMap }
				)).data;
				this.prepareColumns();
				this.$notify.success(this.$t('form.saved'));
				this.$refs.grid.reload(this.$refs.grid.id);
			}
		},

		openRemoveDialog() {
			this.$root.$emit('dialog-open', { name: 'removeEntry' });
		},
		async remove() {
			if (this.importExportSetting.def_import_export_setting_uid == null) return;

			try {
				await this.importExportSettingsModel.remove(this.importExportSetting.def_import_export_setting_uid);
				this.$notify.success(this.$t('form.removed'));
			} catch (error) {
				this.$notify.warn(this.$t('form.removeFailed'));
			}

			this.$routerWrap.push({ name: 'import-export-settings' });
		},

		isNumber(value) {
			if (value != null && value !== '' && !isNaN(value)) {
				return true;
			}
			return false;
		},
		newEntry() {
			if (this.addCustomColumn()) {
				this.editNewHeaderMapRow = true;
				this.editedMapColumn = { title: this.customColumn.title, header: null, column: this.customColumn.name, custom: true };
				this.$root.$emit('dialog-open', { name: 'editHeaderMapEntry' });
			}
		},
		editEntry(row) {
			this.editNewHeaderMapRow = false;
			this.editedMapColumn = _.cloneDeep(row);

			if (!this.importExportSetting.contains_header && this.isNumber(this.editedMapColumn.header)) {
				this.editedMapColumn.header += 1;
			}

			this.$root.$emit('dialog-open', { name: 'editHeaderMapEntry' });
		},

		async saveHeaderMapRow() {
			let edited = null;

			if (this.editedMapColumn.id != null) {
				edited = this.headerMap[this.editedMapColumn.id];
			} else {
				edited = this.headerMap[this.headerMap.length - 1];
			}

			if (!edited) {
				console.error('column does not exist', this.editedMapColumn);
				return;
			}

			edited.header = this.editedMapColumn.header;

			if (!this.importExportSetting.contains_header) {
				if (this.isNumber(edited.header)) {
					edited.header -= 1;
				} else {
					edited.header = null;
				}
			}

			await this.saveHeaderMap();
		},
		openRemoveEntry(row) {
			this.headerMapRowToRemove = row;
			this.$root.$emit('dialog-open', { name: 'removeHeaderMapEntry' });
		},
		async removeHeaderMapRow() {
			this.headerMap.splice(this.headerMapRowToRemove.id, 1);
			await this.saveHeaderMap();
			this.$root.$emit('dialog-close', 'removeHeaderMapEntry');
		},
	},
};
</script>
