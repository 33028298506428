export default class {
	constructor($http) {
		this.$http = $http;
	}

	query(params) {
		return this.$http.get('lbadmin/logged-in', { params });
	}

	get(id) {
		return this.$http.get(`lbadmin/logged-in/${id}`);
	}
}
