<template>
	<s
		class="block"
		:class="{
			'wide': setting.name === 'signature' || setting.type === 'radio' || (setting.type === 'boolean' && setting.in_line)
		}"
		v-if="showSetting()"
	>
		<small v-if="!setting.in_line">
			{{ $t(setting.label || ('settings.user.' + setting.name)) }}
			<i v-if="setting.info" class="icon-info" v-tooltip="$t(setting.info)"></i>
		</small>
		<template v-if="setting.name === 'extension'">
			<select name="extension" v-model="user[setting.name]"
				:data-cy="`${currentComponentId}__extension__select`"
				v-on:change="$emit('dirty')"
				:disabled="disabled"
			>
				<option :key="'null'" :value="null" :data-cy="`${currentComponentId}__extension__select__optionNull`">
					{{ $t('settings.user.none') }}
				</option>
				<option v-for="(el, index) in user.available_extensions" :key="el.exten" :value="el.exten"
					:data-cy="`${currentComponentId}__extension__select__option${index}`"
				>
					{{ `${el.exten} - ${el.name}` }}
				</option>
			</select>
		</template>
		<template v-else-if="setting.name === 'signature'">
			<lba-ckeditor v-on:input="$emit('dirty')" name="signature" v-model="user[setting.name]" :disabled="disabled"
				:parentComponentId="currentComponentId" componentId="signature"
			></lba-ckeditor>
		</template>
		<template v-else-if="setting.name === 'agent_login_with_user'">
			<select name="agent_login_with_user" v-model="user[setting.name]"
				:data-cy="`${currentComponentId}__agentLoginWithUser__select`"
				v-on:change="$emit('dirty')"
				:disabled="disabled"
			>
				<option :key="'null'" :value="null" :data-cy="`${currentComponentId}__agentLoginWithUser__select__optionNull`">
					{{ $t('settings.user.manual') }}
				</option>
				<option :key="'withUser'" :value="'withUser'"
					:data-cy="`${currentComponentId}__agentLoginWithUser__select__optionWithUser`"
				>
					{{ $t('settings.user.withUser') }}
				</option>
			</select>
		</template>
		<template v-else-if="setting.name === 'grid_time_format'">
			<select
				name="grid_time_format"
				v-model="user[setting.name]"
				:disabled="disabled"
				@change="$emit('dirty')"
			>
				<option
					v-for="(option, okey) in setting.options"
					:key="okey"
					:value="option"
				>
					{{ $t(`${okey}`) || option }}
				</option>
			</select>
		</template>
		<template v-else>
			<!-- <input v-if="setting.type === 'string'" type="text" v-model="user[setting.name]"
				:name="setting.name" v-on:change="$emit('dirty')"
			> -->
			<s v-if="setting.type === 'string'" class="two block">
				<textarea v-model="user[setting.name]"
					:data-cy="`${currentComponentId}__textarea`"
					:name="setting.name" v-on:change="$emit('dirty')" :disabled="disabled"
				></textarea>
			</s>
			<label v-if="setting.type === 'boolean'" class="checkbox">
				<input type="checkbox" v-model="user[setting.name]"
					:data-cy="`${currentComponentId}__inputCheckbox`"
					:name="setting.name" v-on:change="$emit('dirty')"
					:disabled="disabled"
				>
				<i class="icon-ok"></i>
				<span v-if="setting.in_line" class="label">
					{{ $t(setting.label || ('settings.user.' + setting.name)) }}
					<i v-if="setting.info" class="icon-info" v-tooltip="$t(setting.info)"></i>
				</span>
			</label>
			<select v-if="setting.type === 'list'" v-model="user[setting.name]"
				:data-cy="`${currentComponentId}__select`"
				:name="setting.name" v-on:change="$emit('dirty')" :disabled="disabled"
			>
				<option v-for="(value, key) in setting.options" v-bind:key="key" v-bind:value="key"
					:data-cy="`${currentComponentId}__select__option${key}`"
				>
					{{ value }}
				</option>
			</select>
			<template v-if="setting.type === 'radio'">
				<label
					v-for="(option, key) in setting.options"
					:key="`${option.value}-${key}`"
					class="checkbox radio"
					:data-cy="`${currentComponentId}__radio`"
				>
					<input
						:data-cy="`${currentComponentId}__radio__input__${option.value}`"
						type="radio"
						:value="option.value"
						v-model="user[setting.name]"
						@change="$emit('dirty')"
						:disabled="disabled"
						:name="setting.name"
					>
					<span class="checkmark"></span>
					<span class="label">{{ $getLocale(option.label) }}</span>
				</label>
			</template>
		</template>
	</s>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		setting: Object,
		user: Object,
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		if (this.setting.type === 'boolean') {
			this.user[this.setting.name] = (this.user[this.setting.name] === 'true' || this.user[this.setting.name] === true);
		}
	},
	methods: {
		showSetting() {
			return !(this.setting.name === 'agent_login_with_user' && !this.user.agent_uid);
		},
	},
};
</script>
