import ComponentIdentifier from '../../../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		showRecommended: Boolean,
	},
	data() {
		return {
			options: null,
			data: null,
			key: null,
			rules: {},
			customMessages: {},
			readOnly: false,
			callback: null,
			classes: 'none',
			style: '',
			label: '',
			title: '',
			titleHint: null,
			id: null,
			elementKey: 0,
			inputRef: this.$generateUID(),
			icon: null,
			action: null,
			selectOptions: [],
			oneLine: false,
			originalValue: null,
			requiredRecommended: false,
		};
	},
	computed: {
		isRequired() {
			return this.rules && this.rules.required;
		},
		isRequiredGroup() {
			return this.rules && !_.isEmpty(this.rules.requiredGroup) && !_.isEmpty(this.rules.requiredGroup.paths);
		},
		titleTooltip() {
			return this.$getLocale(this.options.title_hint);
		},
		showRecommendation() {
			if (this.dirty && this.requiredRecommended && !this.data[this.key] && this.showRecommended) {
				return true;
			}
			return false;
		},
	},
	methods: {
		callValidate() {
			if (this.$refs && !_.isEmpty(this.$refs[this.inputRef])) {
				this.$refs[this.inputRef].validate();
				return true;
			}
			return false;
		},
		prepareComponent() {
			this.id = this.$generateUID();
			this.$set(this, 'options', this.attribute.options);
			this.data = this.attribute.data;
			this.key = this.attribute.key;
			this.icon = this.attribute.icon;
			this.action = this.attribute.action;
			this.originalValue = _.cloneDeep(this.data[this.key]);
			this.lastValue = _.cloneDeep(this.data[this.key]);
			this.callback = (this.options && this.options.callback) || '';
			this.hideSettings = this.options.hideSettings || false;
			this.oneLine = this.options.oneLine || false;
			this.rules = {};
			this.required = false;
			if (this.options.validationRules) {
				this.rules = this.options.validationRules;

				if (this.isRequiredGroup) {
					this.$set(this.rules.requiredGroup, 'data', this.data);
				}
			}

			this.rules.required = !!this.options.required;
			if (this.options.validation) {
				this.rules.regex = this.options.validation;
			}

			if (this.options.validationCustomMessages) {
				this.customMessages = this.options.validationCustomMessages;
			}

			if (this.options.attributes) {
				_.each(Object.keys(this.options.attributes), (key) => {
					if (key === 'options') {
						if (_.isEmpty(this.options.attributes.options)) {
							return;
						}
						const validOption = _.find(this.options.attributes.options, (opt) => {
							return opt.label !== null && opt.value !== null;
						});

						if (!validOption) {
							return;
						}
					}
					this.options[key] = this.options.attributes[key];
				});
			}

			this.classes = this.options.class || [];
			this.style = this.options.style || '';
			this.label = $getLocale(this.options.label);

			if (!this.options.options) {
				this.options.options = [];
			}
			this.selectOptions = this.options.options;

			this.readOnly = (!!this.attribute.readOnly) || (!!this.options.read_only);

			if (this.attribute.options.requiredRecommended && !this.rules.required) {
				this.requiredRecommended = true;
				this.attribute.options.invalidClass = 'input lba-invalid';
				this.customMessages.requiredRecommended = this.$t(
					'validation.requiredRecommended', { attribute: this.attribute.options.label }
				);
			}
		},
		checkProperty(data, key) {
			return (data && Object.prototype.hasOwnProperty.call(data, key));
		},
		prepareDefaultValue() {
			if (this.data[this.key] != null) return;
			if (this.checkProperty(this.options, 'default')) {
				this.$set(this.data, this.key, this.options.default);
			}
		},
		onEvent(event, componentOnChange, skipValueCheck = false) {
			if (
				!skipValueCheck &&
				((this.originalValue === undefined && this.data[this.key] === undefined) ||
				(
					_.isEqual(this.originalValue, this.data[this.key]) &&
					_.isEqual(this.lastValue, this.data[this.key]) &&
					event !== 'change'
				))
			) {
				return;
			}
			if (componentOnChange) {
				componentOnChange();
			}
			this.lastValue = _.cloneDeep(this.data[this.key]);
			if (this.rules.requiredGroup && event === 'change') {
				this.$refs[this.inputRef].$_veeObserver.validate();
			}
			this.$emit(event, this.data[this.key]);
			this.elementKey += 1;
			this.$forceUpdate();
		},
		bindOptions(extended) {
			return _.extend({
				name: this.options.name,
				placeholder: this.options.placeholder || this.options.placeholder_hint || '',
				disabled: this.readOnly,
				title: this.options.title || '',
				initEmpty: !!this.options.initEmpty,
				format: this.options.format,
			}, extended || {});
		},
	},
};
