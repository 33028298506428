<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ data[key] }}
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<ValidationProvider
			:ref="inputRef"
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${$generateUID()}`"
			:rules="rules"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<input
				:data-cy="`${currentComponentId}__inputNumber`"
				type="number"
				v-model="data[key]"
				v-bind="bindOptions(numberOptions)"
				class="showInvalidTooltip"
				:class="{
					'lba-invalid': (invalid && dirty),
					'showWarning': (showRecommendation)
				}"
				:disabled="readOnly"
				@input="onEvent('input', parseNumber)"
				@change="onEvent('change', parseNumber)"
				@blur="$emit('blur')"
			>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</ValidationProvider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputNumber',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	data() {
		return {
			numberOptions: {},
			inputUid: '',
		};
	},
	computed: {
		showRecommendation() {
			if (this.dirty && this.requiredRecommended && this.data[this.key] == null && this.showRecommended) {
				return true;
			}
			return false;
		},
	},
	created() {
		this.prepareComponent();
		this.prepareDefaultValue();
		this.prepareNumberOptions();
	},
	methods: {
		parseNumber() {
			const number = parseFloat(this.data[this.key]);
			if (isNaN(number)) {
				this.data[this.key] = undefined;
				this.$emit('dirty');
				return;
			}
			this.data[this.key] = number;
		},
		prepareNumberOptions() {
			let tooltip = null;

			if (this.options) {
				if (this.options.ui_type === 'number') {
					if ('min' in this.options) {
						this.numberOptions.min = this.options.min;
						this.rules.min_value = this.options.min;
						tooltip = 'inputNumberMin';
					}
					if ('max' in this.options) {
						this.numberOptions.max = this.options.max;
						this.rules.max_value = this.options.max;
						tooltip = 'inputNumberMax';
					}

					if ('min' in this.options && 'max' in this.options) {
						tooltip = 'inputNumberMinMax';
					}
					if ('indentificator' in this.options) {
						this.inputUid = ` ${this.options.indentificator} `;
					}
				}
				if (!this.options.title_hint && tooltip) {
					this.options.title_hint = this.$t(tooltip, this.numberOptions);
				}
			}
		},
	},
};
</script>
