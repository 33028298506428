<template>
	<button
		class="button buttonDropdown"
		style="margin-right: 0px;"
		:disabled="!disabled"
	>
		<span
			name="save"
			:disabled="!disabled"
			@click.stop="$emit('saveAndExit')"
		>
			{{ $t('saveAndExit') }}
		</span>
		<template>
			<span
				v-lba-expander.closeOnClick class="expander"
			>
				<i class="icon-caret-down"></i>
			</span>
			<div
				v-if="disabled"
				class="action-dropdown expand"
				style="right: -2px;"
				data-close
			>
				<span
					name="save"
					class="action-dropdown-item"
					@click.stop="$emit('save')"
				>
					{{ $t('save') }}
				</span>
			</div>
		</template>
	</button>
</template>
<script>
export default {
	name: 'LbaSaveExitButton',
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
