<template>
	<div v-if="!loading" id="content">
		<div class="page-header">
			<h1>
				{{ $t("settings.user.user") }}
				<span class="gray">{{ meta.name }}</span>
			</h1>
			<div class="page-controls" >
				<button
					v-if="!isNew && !readOnly"
					name="openRemoveDialog"
					class="buttonTransparent"
					data-cy="user__remove"
					@click.stop="deleteUser"
				>
					{{ $t('remove') }}
				</button>

				<button
					v-if="!readOnly"
					name="save"
					class="button"
					data-cy="user__save"
					@click.stop="submit"
					:disabled="!dirty"
				>
					{{ $t('save') }}
				</button>
				<span v-if="!isNew" @click="tabReload" data-cy="user__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"
					></i>
				</span>
			</div>
		</div>
		<div class="page-content">
			<masonry
				:cols="{default: 2, 1000: 1}"
				:gutter="{default: '20px', 1000: '15px'}"
			>
				<lba-user-form
					parentComponentId="user"
					componentId="settings"
					:user="user"
					:model="userModel"
					:groups="groups"
					:extraSettings="extraSettings"
					:invalidPass="invalidPass"
					:readOnly="readOnly"
					:isDirty="dirty"
					expanded
					ref="form"
					@dirty="dirty = true"
				></lba-user-form>
				<ValidationObserver ref="form2">
					<form name="form2" lba-size-class="{'contentWide': 700}">
						<lba-section v-bind:title="$t('settings.profileTeams')"
							v-bind:slotType="'form'" expanded
							parentComponentId="user"
							componentId="profileTeams"
						>
							<template #default="props">
								<s class="size-4">
									<small>
										{{ $t('settings.profiles') }}
										<span class="required-sign"></span>
									</small>
									<ValidationProvider
										:name="$t('settings.profiles')"
										v-slot="{ invalid, errors }"
										:rules="{ required: true }"
									>
										<lba-select
											style="width: -webkit-fill-available;"
											v-model="user.profiles"
											:opts="labeledProfiles"
											:multiple="true"
											:renderHtmlLabels="true"
											:rootTag="'div'"
											noNotSetOption
											disableUnknown
											:combobox="true"
											@change="dirty = true"
											:class="{ 'lba-invalid': invalid && dirty }"
											:readOnly="readOnly"
										></lba-select>
										<template v-if="dirty">
											<span
												v-for="(err, index) in errors"
												:key="index"
												:data-cy="`${props.parentComponentId}__profileName__error${index}`"
												class="lba-messages"
											>{{ err }}</span>
										</template>
									</ValidationProvider>
								</s>
								<br>
								<s class="size-4 mt-3" v-if="!_.isEmpty(user.roles) || !_.isEmpty(filterGroups)">
									<small>
										{{ $t('settings.groups') }}
									</small>
									<table
										class="content-table wide"
									>
										<tr>
											<th style="width: 200px;">{{ $t('settings.group') }}</th>
											<th>{{ $t('settings.profile') }}</th>
											<th style="width: 20px;text-align: center;padding: 0;"></th>
										</tr>
										<tr
											v-for="(team, teamIndex) in user.roles" :key="teamIndex"
										>
											<td>
												<a
													:key="`team-${teamIndex}`"
													@click="openTeam(team.role_uid)"
													:data-cy="`user_team_${teamIndex}__openMember`"
												>
													{{ team.role_name }}
												</a>
											</td>
											<td
												style="display: flex; align-items: center;flex-direction: column; min-height: 1.5em;"
											>
												<lba-select
													style="width: 100%;"
													v-model="team.profiles"
													:opts="labeledProfiles"
													:multiple="true"
													:renderHtmlLabels="true"
													:rootTag="'div'"
													noNotSetOption
													disableUnknown
													:combobox="true"
													@change="dirty = true"
													:readOnly="readOnly"
													:topPlaceholder="$t('settings.inherited', { profiles: getProfilesName(savedProfiles) })"
												></lba-select>
											</td>
											<td style="width: 20px;text-align: center;padding: 5px;">
												<a
													v-if="!readOnly"
													v-on:click="removeGroup(teamIndex)"
													:data-cy="`${props.parentComponentId}__role${teamIndex}__remove`"
													:title="$t('remove')"
												>
													<i class="icon-delete"></i>
												</a>
											</td>
										</tr>
									</table>
								</s>
								<br>
								<s v-if="!_.isEmpty(filterGroups) && !readOnly" class="mt-2">
									<select
										:data-cy="`${props.parentComponentId}__newGroup__select`"
										name="newGroup"
										@change="addGroup"
										v-model="addedGroup"
									>
										<option
											:value="null"
											:data-cy="`${props.parentComponentId}__newGroup__select__optionNull`"
										>
											{{ $t('settings.addGroup') }}
										</option>
										<option
											v-for="(group, index) in filterGroups"
											:value="group"
											:key="group.role_uid"
											:data-cy="`${props.parentComponentId}__newGroup__select__option${index}`"
										>
											{{ group.role_name }}
										</option>
									</select>
								</s>
								<br>
								<s class="wide mt-3" v-if="user.inherited_roles && user.inherited_roles.length">
									<small>
										{{ $t('settings.groupsAll') }}
										<i class="icon-info" v-tooltip="$t('settings.groupsAllInfo')"></i>
									</small>
									<table
										class="content-table wide"
									>
										<tr>
											<th style="width: 200px;">{{ $t('settings.group') }}</th>
											<th>{{ $t('settings.profile') }}</th>
										</tr>
										<tr
											v-for="(team, teamIndex) in user.inherited_roles" :key="teamIndex"
										>
											<td>
												<a :key="`i-${teamIndex}`" @click="openTeam(team.role_uid)"
													:data-cy="`${props.parentComponentId}__groupsAll__openTeam`"
												>
													{{ team.role_name }}
												</a>
											</td>
											<td
												style="display: flex; align-items: start;flex-direction: column;"
											>
												{{ getProfilesName(team.profiles) }}
											</td>
										</tr>
									</table>
								</s>
							</template>
						</lba-section>
					</form>
				</ValidationObserver>
			</masonry>
		</div>

		<lba-dialog-modal
			parentComponentId="user"
			componentId="confirmDelete"
			name="user-confirm-delete"
			:title="$t('settings.user.deleteUser')"
			modal
		>
			<template #default="props">
				<div class="popup-footer">
					<button type="button" class="buttonBig buttonRed" v-on:click="deleteUserConfirm"
						:data-cy="`${props.parentComponentId}__remove`"
					>
						<span>{{ $t('settings.delete') }}</span>
					</button>
				</div>
			</template>
		</lba-dialog-modal>
	</div>
</template>

<script>
import Titles from '../mixins/Titles';
import Page from '../mixins/Page';
import UserModel from '../models/User';
import ProfileModel from '../models/Profile';
import GroupModel from '../models/Group';

export default {
	name: 'User',
	mixins: [Titles, Page],
	data() {
		return {
			user: {
				id: null,
				user_name: null,
				description: null,
				can_login: true,
				last_login: null,
				valid_until: null,
				password: null,
				password_t: null,
				password_expiration: null,
				auth_method: null,
				firstname: null,
				lastname: null,
				company: null,
				email: null,
				lang: this.$i18n.locale,
				timezone: null,
				groups: [],
				group_names: [],
				modules: [],
				roles: [],
				is_anonymous_user: null,
				extension: null,
				allow_su: null,
				profile_uid: null,
				createAgent: true,
			},
			modules: [],
			groups: [],
			profiles: [],
			allProfiles: [],
			profileModel: null,
			groupModel: null,
			userLoaded: false,
			extraSettings: [],
			dirty: false,
			addedGroup: null,
			loading: true,
			isNew: true,
			readOnly: false,
			meta: {
				name: this.$t('settings.newUser'),
				path: 'settings-user',
				tooltipPrefix: this.$t('settings.user.user'),
			},
			invalidPass: false,
			tabRefresh: false,
			savedProfiles: [],
		};
	},
	watch: {
		dirty(newValue) {
			this.$root.$emit('tab-editted', newValue);
		},
		$route() {
			if (!this._inactive) this.tabRefresh = true;
		},
	},
	computed: {
		filterGroups() {
			// remove teams I am already in
			if (this.user.roles) {
				const myTeams = this.user.roles.map((el) => {
					return el.role_name;
				});
				return this.groups.filter((el) => {
					if (!myTeams.includes(el.role_name)) return el;
				});
			}
			return this.groups;
		},
		labeledProfiles() {
			return _.map(this.profiles, (el) => {
				return { label: el.profile_name, value: el.profile_uid, custom: el.custom || false };
			});
		},
	},
	activated() {
		if (this.tabRefresh) this.userReload();
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.$root.$listen('user.settings-updated', this.userReload, this);
		this.isNew = (this.$route.params.id === '+');
		this.userModel = new UserModel(this.$http);
		this.profileModel = new ProfileModel(this.$http);
		this.groupModel = new GroupModel(this.$http);
		await this.fetch();
		this.permissionsReload();
		this.loading = false;
	},
	methods: {
		async fetch() {
			let response = null;
			if (this.$route.params.id != null && !this.isNew) {
				response = await this.userModel.get(this.$route.params.id).catch(() => {
					this.$routerWrap.push({ name: 'settings-user', params: { id: '+' } });
				});

				this.user = response.data;
				this.userLoaded = true;
				if (this.user.firstname && this.user.lastname) {
					this.meta.name = `${this.user.firstname} ${this.user.lastname}`;
				} else if (this.user.id) {
					this.meta.name = this.user.id;
				}
				this.refreshTitle();
			}

			response = await this.$http.get('lbadmin/extra-settings');
			this.extraSettings = response.data;
			this.extraSettings.map(async (el) => {
				if (el.name === 'extension') {
					// load available extensions if there is "extension" extra setting
					response = await this.userModel.getAvailableExtens(this.user.role_uid);
					this.user.available_extensions = response.data;
				}
			});

			// get all profiles
			let resp = await this.profileModel.query({ all: true });
			this.allProfiles = resp.data;
			// get profiles to set
			resp = await this.profileModel.getProfilesToSet();
			this.profiles = resp.data;

			// get all teams
			resp = await this.groupModel.query();
			this.groups = resp.data;

			_.forEach(this.user.profiles, (el) => {
				const found = _.find(this.profiles, (eel) => {
					return eel.profile_uid === el.profile_uid;
				});
				if (!found) this.profiles.push({ profile_name: el.profile_name, profile_uid: el.profile_uid, custom: true });
			});

			_.forEach(this.user.roles, (role) => {
				_.forEach(role.profiles, (el) => {
					const found = _.find(this.profiles, (eel) => {
						return eel.profile_uid === el.profile_uid;
					});
					if (!found) this.profiles.push({ profile_name: el.profile_name, profile_uid: el.profile_uid, custom: true });
				});
			});

			this.profiles = _.sortBy(this.profiles, ['profile_name']);

			this.user.profiles = _.map(this.user.profiles, (el) => { return el.profile_uid; });
			this.savedProfiles = _.cloneDeep(this.user.profiles);

			_.forEach(this.user.roles, (role) => {
				role.profiles = _.map(role.profiles, (el) => { return el.profile_uid; });
			});
		},
		permissionsReload() {
			this.readOnly = !this.$permissions.checkPermission('lbadmin.user.write');
		},
		userReload() {
			if (!this.dirty) {
				this.tabReload(true);
				this.tabRefresh = false;
			}
		},
		async submit() {
			this.dirty = true;
			const valid = await this.$refs.form2.validate();
			if (!valid || !this.user.valid || !this.user.profiles.length) {
				this.$root.$emit('content.validationFailed');
				return;
			}

			// change dates to string
			let value = this.user.valid_until;
			if (value && typeof value === 'object') {
				this.user.valid_until = this.user.valid_until.toISOString();
			}
			value = this.user.password_expiration;
			if (value && typeof value === 'object') {
				this.user.password_expiration = this.user.password_expiration.toISOString();
			}
			const user = _.cloneDeep(this.user);

			user.profiles = _.map(user.profiles, (el) => {
				return { profile_uid: el };
			});

			_.forEach(user.roles, (role) => {
				role.profiles = _.map(role.profiles, (el) => {
					return { profile_uid: el };
				});
			});

			if (this.user.id == null) {
				this.userModel.createUser(user).then(async (response) => {
					this.dirty = false;
					this.$root.$emit('content.saved');
					this.$root.$emit('grid.reload', 'lbadmin.users_user');
					await this.$nextTick();
					this.$routerWrap.push({ name: 'settings-user', params: { id: response.data.role_uid || '+' } });
				}).catch(() => {
					this.$root.$emit('content.saveFailed');
				});
			} else {
				this.userModel.saveUser(user).then(() => {
					this.dirty = false;
					this.savedProfiles = _.cloneDeep(this.user.profiles);
					this.$root.$emit('content.saved');
					this.$root.$emit('grid.update-row', {
						gridName: 'lbadmin.users_user',
						row: this.user,
					});
				}).catch(() => {
					this.$root.$emit('content.saveFailed');
				});
			}
		},
		deleteUser() {
			this.$root.$emit('dialog-open', { name: 'user-confirm-delete' });
		},
		deleteUserConfirm() {
			this.$root.$emit('tab-editted', false);
			this.userModel.remove(this.user.role_uid).then(() => {
				this.$root.$emit('dialog-close', 'user-confirm-delete');
				this.$root.$emit('content.removed', { noBack: true });
				this.$root.$emit('grid.row-deleted', { gridName: 'lbadmin.users_user', uid: this.user.role_uid });
				this.$root.$emit('close-current-tab');
			}).catch(() => {
				this.$root.$emit('content.removeFailed');
			});
		},
		addGroup() {
			this.dirty = true;
			if (!this.user.roles) this.user.roles = [];
			this.user.roles.push({
				role_name: this.addedGroup.role_name,
				role_uid: this.addedGroup.role_uid,
				profiles: [],
			});
			this.addedGroup = null;
		},
		removeGroup(index) {
			this.dirty = true;
			this.user.roles.splice(index, 1);
		},
		getMyProfileName() {
			let profileName = '';
			if (this.user.profile_uid) {
				this.profiles.forEach((profile) => {
					if (profile.profile_uid === this.user.profile_uid) {
						profileName = ` (${profile.profile_name})`;
					}
				});
			}
			return profileName;
		},
		openTeam(id) {
			this.$routerWrap.push({
				name: 'settings-group',
				params: {
					id,
					openNewTab: true,
				},
			});
		},
		getProfilesName(profiles) {
			const profilesNames = [];
			_.forEach(profiles, (profile_uid) => {
				const result = _.find(this.allProfiles, { profile_uid: profile_uid });
				if (result && result.profile_name) {
					profilesNames.push(result.profile_name);
				} else {
					profilesNames.push(profile_uid);
				}
			});
			return profilesNames.join(', ');
		},
	},
};
</script>
