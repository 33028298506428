var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-fieldset"},[_c('s',{staticClass:"size-6"},[_c('small',[_vm._v(_vm._s(_vm.$t('translationType')))]),_c('label',{staticClass:"checkbox radio",attrs:{"data-cy":(_vm.currentComponentId + "__forAllLangs__radioLabel")}},[_c('input',{attrs:{"data-cy":(_vm.currentComponentId + "__forAllLangs__inputRadio"),"type":"radio","value":"forAllLangs"},domProps:{"checked":_vm.translationType === 'forAllLangs'},on:{"change":function($event){return _vm.$emit('change', 'forAllLangs')}}}),_c('span',{staticClass:"checkmark"}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('forAllLangs')))])]),_c('label',{staticClass:"checkbox radio",attrs:{"data-cy":(_vm.currentComponentId + "__pathToLocale__radioLabel")}},[_c('input',{attrs:{"data-cy":(_vm.currentComponentId + "__pathToLocale__inputRadio"),"type":"radio","value":"pathToLocale"},domProps:{"checked":_vm.translationType === 'pathToLocale'},on:{"change":function($event){return _vm.$emit('change', 'pathToLocale')}}}),_c('span',{staticClass:"checkmark"}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('pathToLocale')))])])]),_c('br'),(_vm.internalValue.constructor === Object)?[(_vm.translationType === 'forAllLangs')?[_vm._l((_vm.$i18n.availableLocales),function(locale,index){return [_c('s',{key:index,staticClass:"size-6"},[_c('small',[_vm._v(_vm._s(_vm.$getLocale(_vm.localeTitle) || _vm.$t('translation'))+" ["+_vm._s(locale.toUpperCase())+"]")]),_c('ValidationProvider',{ref:("locale" + (locale.toUpperCase())),refInFor:true,attrs:{"name":((_vm.$getLocale(_vm.localeTitle) || _vm.$t('translation')) + " [ " + (locale.toUpperCase()) + " ]"),"rules":{ required: _vm._.isEmpty(_vm.internalValue.translated) },"customMessages":{
							required: _vm.$t(
								'validation.requiredIfOtherEmpty',
								null,
								{
									attribute: ((_vm.$getLocale(_vm.localeTitle) || _vm.$t('translation')) + " [" + (locale.toUpperCase()) + "]"),
									attribute2: _vm.$t('defaultTranslation'),
								}
							)
						}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
						var errors = ref.errors;
						var invalid = ref.invalid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue[locale]),expression:"internalValue[locale]"}],class:{ 'lba-invalid': invalid && _vm.dirty },attrs:{"data-cy":(_vm.currentComponentId + "__forAllLangs" + index + "__inputText"),"type":"text"},domProps:{"value":(_vm.internalValue[locale])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.internalValue, locale, $event.target.value)},function($event){return _vm.$emit('value-input')}],"change":function($event){return _vm.$emit('value-change')}}}),(_vm.dirty)?_vm._l((errors),function(err,errorIndex){return _c('span',{key:errorIndex,staticClass:"lba-messages",attrs:{"data-cy":(_vm.currentComponentId + "__forAllLangs" + index + "__error" + errorIndex)}},[_vm._v(_vm._s(err))])}):_vm._e()]}}],null,true)})],1),_c('br',{key:("br-" + index)})]})]:[_c('ValidationProvider',{ref:"pathToLocale",attrs:{"name":_vm.$t('pathToLocale'),"rules":{
					required: _vm._.isEmpty(_vm.internalValue.translated),
					pathToLocale: true,
				},"customMessages":{
					required: _vm.$t(
						'validation.requiredIfOtherEmpty',
						null,
						{ attribute: _vm.$t('pathToLocale'), attribute2: _vm.$t('defaultTranslation') }
					)
				}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var errors = ref.errors;
				var invalid = ref.invalid;
return [_c('s',{staticClass:"size-3"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('pathToLocale'))+" "),_c('span',{staticClass:"required-sign"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue.translate),expression:"internalValue.translate"}],class:{ 'lba-invalid': invalid && _vm.dirty },attrs:{"data-cy":(_vm.currentComponentId + "__pathToLocale__inputText"),"type":"text"},domProps:{"value":(_vm.internalValue.translate)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.internalValue, "translate", $event.target.value)},function($event){return _vm.$emit('value-input')}],"change":function($event){return _vm.$emit('value-change')}}}),(_vm.dirty)?_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages",attrs:{"data-cy":(_vm.currentComponentId + "__pathToLocale__error" + index)}},[_vm._v(_vm._s(err))])}):_vm._e()],2),_c('s',{staticClass:"size-3"},[_c('small',[_vm._v(_vm._s(_vm.$t('translated')))]),(!invalid)?_c('span',{attrs:{"data-cy":(_vm.currentComponentId + "__pathToLocaleTranslated")}},[_vm._v(" "+_vm._s(_vm.$t(_vm.internalValue.translate))+" ")]):_vm._e()])]}}],null,false,1779129041)})],_c('s',{staticClass:"size-6"},[_c('small',[_vm._v(_vm._s(_vm.$t('defaultTranslation')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue.translated),expression:"internalValue.translated"}],attrs:{"data-cy":(_vm.currentComponentId + "__defaultTranslation__inputText"),"type":"text"},domProps:{"value":(_vm.internalValue.translated)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.internalValue, "translated", $event.target.value)},function($event){return _vm.$emit('value-input')}],"change":function($event){return _vm.$emit('value-change')}}})])]:[_c('lba-locale-edit')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }