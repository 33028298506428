<template>
	<div id="content" v-if="loaded">
		<div class="page-header">
			<h1>
				{{ $t("settings.profile") }}
				<span class="gray">{{ meta.name }}</span>
			</h1>
			<div class="page-controls">
				<button
					v-if="!isNew && !readOnly"
					name="openRemoveDialog"
					class="buttonTransparent"
					data-cy="profile__remove"
					@click.stop="deleteProfile"
				>
					{{ $t('remove') }}
				</button>

				<button
					v-if="!readOnly"
					name="save"
					class="button"
					data-cy="profile__save"
					@click.stop="submit"
					:disabled="!dirty"
				>
					{{ $t('save') }}
				</button>
				<span v-if="!isNew" @click="tabReload" data-cy="profile__reload">
					<i class="icon-reset" v-tooltip="$t('refreshData')"
					></i>
				</span>
			</div>
		</div>
		<div class="page-content">
			<masonry
				:cols="{default: 2, 1000: 1}"
				:gutter="{default: '20px', 1000: '15px'}"
			>
				<div>
					<template
						v-for="(section, index) in permissions"
					>
						<lba-section
							v-if="(profile.permissions.includes(
								section.acl_type_uid) &&
								(section.subpermissions.length || (section.menu && section.menu.length))) ||
									section.acl_type_module === 'lbadmin'"
							:key="`section-${index}`"
							parentComponentId="profile"
							:componentId="`permission${index}`"
							:id="`module-${section.acl_type_module}`"
							:title="getSectionTitle(section.acl_type_module)"
							slotType="form"
							expanded
							scrollable
						>
							<template v-if="!readOnly" v-slot:controls="{ openSection, parentComponentId }">
								<span
									v-lba-expander.closeOnClick
									class="fieldsetMenuExpander"
									:data-cy="`${parentComponentId}__expandMenu`"
									@click="openSection()"
								>
									<i class="icon-plus-circle" v-tooltip="$t('options')"></i>
								</span>
								<div class="fieldsetMenu expand" data-close>
									<span class="link"
										:data-cy="`${parentComponentId}__expandMenu__checkAll`"
										@click="markItems('section', true, section)"
									>
										{{ $t('checkAll') }}
									</span>
									<span class="link"
										:data-cy="`${parentComponentId}__expandMenu__uncheckAll`"
										@click="markItems('section', false, section)"
									>
										{{ $t('uncheckAll') }}
									</span>
								</div>
							</template>
							<template #default="props">
								<s v-if="section.menu && section.menu.length" class="size-6">
									<span>
										{{ $t('settings.menuItems') }}
									</span>
									<table
										class="content-table wide stripedOdd mt-1"
									>
										<tr>
											<th>{{ $t('settings.item') }}</th>
											<th style="text-align: center; width: 65px;">
												<span
													style="display: flex; justify-content: center;"
												>
													{{ $t('settings.access') }}
													<template v-if="!readOnly">
														<i
															v-lba-expander.closeOnClick
															class="icon-plus-circle pl-1 fieldsetMenuExpander"
															v-tooltip="$t('options')"
														></i>
														<div class="fieldsetMenu expand" data-close>
															<span class="link"
																:data-cy="`${props.parentComponentId}__menuItems__checkAll`"
																@click="markItems('menu', true, section.menu)"
															>
																{{ $t('checkAll') }}
															</span>
															<span class="link"
																:data-cy="`${props.parentComponentId}__menuItems__uncheckAll`"
																@click="markItems('menu', false, section.menu)"
															>
																{{ $t('uncheckAll') }}
															</span>
														</div>
													</template>
												</span>
											</th>
										</tr>
										<template v-for="(menu, index) in section.menu">
											<tr
												v-if="menu.acl_type_uid"
												:key="`menu-${index}`"
											>
												<td>{{ getName(menu) }}</td>
												<td style="text-align: center;">
													<label
														class="checkbox"
														:key="`menu-${menu.acl_type_object_name}-${index}`"
														:data-cy="`${props.parentComponentId}__menuItems__item${index}__checkboxLabel`"
													>
														<input
															:data-cy="`${props.parentComponentId}__menuItems__item${index}__inputCheckbox`"
															type="checkbox"
															v-model="profile.permissions"
															:value="menu.acl_type_uid"
															v-on:change="dirty = true"
															:disabled="!menu.acl_type_uid || readOnly"
														>
														<i class="icon-ok"></i>
													</label>
												</td>
											</tr>
										</template>
									</table>
								</s>

								<s v-if="section.subpermissions.length" class="size-6 mt-5">
									<span>
										{{ $t('settings.modulePermissions') }}
									</span>

									<table
										class="content-table wide stripedOdd mt-1"
									>
										<thead>
											<tr>
												<th>{{ $t('settings.permName') }}</th>
												<template
													v-for="(permType, pindex) in permissionTypes"
												>
													<th
														:key="`header-${pindex}`"
														v-if="isPermInSubpermissions(section.subpermissions, permType.type)"
														style="text-align: center; width: 65px;"
													>
														<span
															style="display: flex; justify-content: center;"
														>
															{{ permType.label }}
															<template v-if="!readOnly">
																<i
																	v-lba-expander.closeOnClick
																	class="icon-plus-circle pl-1 fieldsetMenuExpander"
																	v-tooltip="$t('options')"></i>
																<div class="fieldsetMenu expand" data-close>
																	<span class="link"
																		@click="markItems(
																			'type', true, permType.type, section.subpermissions
																		)"
																		:data-cy="
																			`${props.parentComponentId}__modulePermissions` +
																			`__permission${pindex}__checkAll`
																		"
																	>
																		{{ $t('checkAll') }}
																	</span>
																	<span class="link"
																		@click="markItems(
																			'type', false, permType.type, section.subpermissions
																		)"
																		:data-cy="
																			`${props.parentComponentId}__modulePermissions` +
																			`__permission${pindex}__uncheckAll`
																		"
																	>
																		{{ $t('uncheckAll') }}
																	</span>
																</div>
															</template>
														</span>
													</th>
												</template>
											</tr>
										</thead>

										<tbody>
											<tr
												v-for="(permission, i) in getSubpermissions(section.subpermissions)"
												:key="`perm-${index}-${i}`"
											>
												<td>{{ permission.name }}</td>
												<template
													v-for="(permType, pindex) in permissionTypes"
												>
													<td
														:key="`row-${pindex}-${index}`"
														v-if="isPermInSubpermissions(section.subpermissions, permType.type)"
														style="text-align: center;">

														<label
															v-if="permission[permType.type]"
															class="checkbox"
															:key="`menu-${permission[permType.type].acl_type_uid}-${index}`"
															:data-cy="
																`${props.parentComponentId}__subpermission${i}` +
																`__permission${pindex}__checkboxLabel`
															"
														>
															<input
																:data-cy="
																	`${props.parentComponentId}__subpermission${i}` +
																	`__permission${pindex}__inputCheckbox`
																"
																type="checkbox"
																:id="permission[permType.type].acl_type_name"
																:value="permission[permType.type].acl_type_uid"
																v-model="profile.permissions"
																v-on:change="dirty = true"
																:disabled="readOnly"
															>
															<i class="icon-ok"></i>
														</label>
													</td>
												</template>
											</tr>
										</tbody>
									</table>
								</s>
							</template>
						</lba-section>
					</template>
				</div>
				<div>
					<ValidationObserver ref="form">
						<form name="form" lba-size-class="{'contentWide': 700}">
							<lba-section
								parentComponentId="profile"
								componentId="settings"
								:title="$t('settings.profileSettings')"
								slotType="form"
								expanded
							>
								<template #default="props">
									<ValidationProvider
										:name="$t('settings.profileName')"
										v-slot="{ invalid, errors }"
										:rules="{ required: true }"
									>
										<s>
											<small>
												{{ $t('settings.profileName') }}
												<span class="required-sign"></span>
											</small>
											<input
												:data-cy="`${props.parentComponentId}__profileName__inputText`"
												type="text"
												name="profile_name"
												@input="dirty = true"
												v-model="profile.profile_name"
												:class="{ 'lba-invalid': invalid && dirty }"
												:disabled="readOnly"
											/>
											<template v-if="dirty">
												<span
													v-for="(err, index) in errors"
													:key="index"
													:data-cy="`${props.parentComponentId}__profileName__error${index}`"
													class="lba-messages"
												>{{ err }}</span>
											</template>
										</s>
									</ValidationProvider>
									<!--<s>
										<small>
											{{ $t('settings.profileModule') }}
										</small>
										<input
											type="text"
											name="profile_module"
											@change="dirty = true"
											v-model="profile.profile_module"
											:class="{ 'lba-invalid': invalid && dirty }"
										/>
										<template v-if="dirty">
											<span
												v-for="(err, index) in errors"
												:key="index"
												class="lba-messages"
											>{{ err }}</span>
										</template>
									</s> -->
									<s>
										<small>
											{{ $t('settings.profileEnabled') }}
										</small>
										<lba-switch
											:parentComponentId="props.parentComponentId"
											componentId="visible"
											name="profile_visible"
											:label1="$t('no')"
											:label2="$t('yes')"
											v-model="profile.profile_visible"
											@input="dirty = true"
											class="mt-2 mb-2"
											:disabled="readOnly"
										></lba-switch>
									</s>
								</template>
							</lba-section>
						</form>
					</ValidationObserver>
					<lba-section
						parentComponentId="profile"
						componentId="userModules"
						:key="`section-modules-all`"
						:title="$t('settings.user.modules')"
						slotType="form"
						expanded
					>
						<template #default="props">
							<template v-for="(section, index) in permissions">
								<template v-if="section.acl_type_module !== 'lbadmin'">
									<label
										class="checkbox"
										style="margin-right: 10px;"
										:key="`module-check-${index}`"
										:data-cy="`${props.parentComponentId}__section${index}__checkboxLabel`"
									>
										<input
											:data-cy="`${props.parentComponentId}__section${index}__inputCheckbox`"
											type="checkbox"
											v-model="profile.permissions"
											:value="section.acl_type_uid"
											v-on:change="dirty = true"
											:disabled="readOnly"
										>
										<i class="icon-ok"></i>
										<span class="label">
											{{
												$t(`${section.acl_type_module}.menu.${section.acl_type_module}`) ||
												section.acl_type_module
											}}
										</span>
									</label>
									<a
										v-if="(profile.permissions.indexOf(section.acl_type_uid) >= 0) &&
											((section.menu && section.menu.length) ||
											(section.subpermissions &&
											section.subpermissions.length))"
										:data-cy="`${props.parentComponentId}__section${index}__scrollTo`"
										@click.stop="scrollTo(`module-${section.acl_type_module}`)"
										v-tooltip="$t('jumpOnItem')"
										:key="`module-jump-${index}`"
									>
										<i  class="icon-settings" style="top: 5px;"></i>
									</a>
									<br :key="`module-br-${index}`">
								</template>
							</template>
						</template>
					</lba-section>

					<lba-section
						parentComponentId="profile"
						componentId="userProfileRights"
						:key="`section-profiles-all`"
						:title="$t('settings.user.profilesRights')"
						slotType="form"
						expanded
					>
						<template v-if="!readOnly" v-slot:controls="{ openSection, parentComponentId }">
							<span
								v-lba-expander.closeOnClick
								class="fieldsetMenuExpander"
								:data-cy="`${parentComponentId}__expanderProfiles`"
								@click="openSection()"
							>
								<i class="icon-plus-circle" v-tooltip="$t('options')"></i>
							</span>
							<div class="fieldsetMenu expand" data-close>
								<span class="link"
									:data-cy="`${parentComponentId}__expanderProfiles__checkAll`"
									@click="markItems('profiles', true)"
								>
									{{ $t('checkAll') }}
								</span>
								<span class="link"
									:data-cy="`${parentComponentId}__expanderProfiles__uncheckAll`"
									@click="markItems('profiles', false)"
								>
									{{ $t('uncheckAll') }}
								</span>
							</div>
						</template>
						<template #default="props">
							<p>{{ $t('settings.user.profilesRightsInfo') }}</p>
							<template v-for="(prof, index) in allProfiles">
								<label
									class="checkbox"
									style="margin-right: 10px;"
									:key="`profile-check-${index}`"
									:data-cy="`${props.parentComponentId}__profile${index}__checkboxLabel`"
								>
									<input
										:data-cy="`${props.parentComponentId}__profile${index}__inputCheckbox`"
										type="checkbox"
										v-model="profile.allowed"
										:value="prof.profile_uid"
										v-on:change="dirty = true"
										:disabled="isProfAllowed(prof) || readOnly"
									>
									<i class="icon-ok"></i>
									<span class="label">
										{{ prof.profile_name }}
									</span>
								</label>
								<br :key="`profile-check-br-${index}`">
							</template>
						</template>
					</lba-section>

					<lba-section
						v-if="!profile.profile_uid"
						parentComponentId="profile"
						componentId="profileRightsToAdministrateMe"
						:key="`section-profiles-allow-me`"
						:title="$t('settings.user.profileRightsToAdministrateMe')"
						slotType="form"
						expanded
					>
						<template #default="props">
							<p>{{ $t('settings.user.profileRightsToAdministrateMeInfo') }}</p>
							<template v-for="(prof, index) in $user.allow_set_profiles">
								<label
									class="checkbox"
									style="margin-right: 10px;"
									:key="`profile-allow-me-check-${index}`"
									:data-cy="`${props.parentComponentId}__profile${index}__checkboxLabel`"
								>
									<input
										:data-cy="`${props.parentComponentId}__profile${index}__inputCheckbox`"
										type="checkbox"
										v-model="profile.allow_myself_to"
										:value="prof.profile_uid"
										v-on:change="dirty = true"
									>
									<i class="icon-ok"></i>
									<span class="label">
										{{ prof.profile_name }}
									</span>
								</label>
								<br :key="`profile-check-br-${index}`">
							</template>
						</template>
					</lba-section>
				</div>
			</masonry>
		</div>
		<lba-confirm-remove-dialog
			parentComponentId="profile"
			componentId="confirmDelete"
			name="profile-confirm-delete"
			:title="$t('settings.deleteProfile')"
			:message="`${profile.profile_name} ?`"
			:on-confirm="deleteProfileConfirm"
		></lba-confirm-remove-dialog>
	</div>
</template>

<script>
import Titles from '../mixins/Titles';
import Page from '../mixins/Page';
import ProfileModel from '../models/Profile';

export default {
	name: 'Profile',
	mixins: [Titles, Page],
	data() {
		return {
			profile: {
				permissions: [],
				profile_visible: true,
				allowed: [],
				allow_myself_to: [],
			},
			allProfiles: [],
			allowedProfiles: [],
			permissions: [],
			loaded: false,
			dirty: false,
			profileModel: null,
			permissionTypes: [
				{
					type: 'read',
					label: this.$t('settings.permRead'),
				},
				{
					type: 'write',
					label: this.$t('settings.permWrite'),
				},
				{
					type: 'export',
					label: this.$t('settings.permExport'),
				},
				{
					type: 'import',
					label: this.$t('settings.permImport'),
				},
				{
					type: 'wallboard',
					label: this.$t('settings.permWallboard'),
				},
				{
					type: 'run',
					label: this.$t('settings.permRun'),
				},
				{
					type: 'all',
					label: this.$t('settings.permAll'),
				},
				{
					type: 'enable',
					label: this.$t('settings.permEnable'),
				},
			],
			isNew: true,
			readOnly: false,
			meta: {
				name: this.$t('settings.newProfile'),
				path: 'settings-profile',
				tooltipPrefix: this.$t('settings.profile'),
			},
		};
	},
	watch: {
		dirty(newValue) {
			this.$root.$emit('tab-editted', newValue);
		},
	},
	async created() {
		if (this.$checkDestroyed(this, false)) {
			return;
		}
		this.$root.$listen('permissions-reload', this.permissionsReload, this);
		this.isNew = (this.$route.params.id === '+');
		this.profileModel = new ProfileModel(this.$http);

		// get all available permissions
		let resp = await this.profileModel.getTypes();
		this.permissions = resp.data;

		// get all profiles
		resp = await this.profileModel.query({ all: true });
		this.allProfiles = resp.data;

		// get profiles I am allowed to add
		resp = await this.profileModel.getProfilesToSet();
		this.allowedProfiles = resp.data;

		await this.fetch();
		this.loaded = true;

		// sort permissions
		await this.sortPerms();

		this.permissionsReload();
	},
	methods: {
		permissionsReload() {
			this.readOnly = !this.$permissions.checkPermission('lbadmin.profile.write');
		},
		async fetch() {
			if (this.$route.params.id != null && this.$route.params.id !== '+') {
				const response = await this.profileModel.get(this.$route.params.id);
				this.profile = response.data;
				this.meta.name = this.profile.profile_name;
				this.refreshTitle();
			} else {
				this.dirty = true;
				if (this.$route.params.clone) {
					const response = await this.profileModel.get(this.$route.params.clone);
					this.profile.permissions = response.data.permissions;
				}
			}
		},
		async submit() {
			this.dirty = true;
			const valid = await this.$refs.form.validate();
			if (!valid) {
				this.$root.$emit('content.validationFailed');
				return;
			}

			this.profileModel
				.save(this.profile)
				.then(async (response) => {
					this.$root.$emit('content.saved');
					this.dirty = false;
					await this.$nextTick();

					this.meta.name = this.profile.profile_name;
					this.refreshTitle();

					if (this.$route.params.id !== response.data.resid) {
						this.$routerWrap.push({
							name: 'settings-profile',
							params: { id: response.data.resid },
						});
					}
				})
				.catch(() => {
					this.$root.$emit('content.saveFailed');
				});
		},
		deleteProfile() {
			this.$root.$emit('dialog-open', { name: 'profile-confirm-delete' });
		},
		deleteProfileConfirm() {
			this.$root.$emit('tab-editted', false);
			this.profileModel
				.delete(this.profile.profile_uid)
				.then(() => {
					this.$root.$emit('close-current-tab');
					this.$root.$emit('content.removed');
				})
				.catch(() => {
					this.$root.$emit('content.removeFailed');
				});
		},
		getSubpermissions(subpermissions) {
			// when trying to sort directly subpermissions -> infinite render loop
			const subPermissions = _.cloneDeep(subpermissions);
			const sub = {};
			subPermissions.sort((a, b) => {
				if (a.acl_type_object_name < b.acl_type_object_name) return -1;
				if (a.acl_type_object_name > b.acl_type_object_name) return 1;
				return 0;
			});
			subPermissions.forEach((el) => {
				if (!sub[el.acl_type_object_name]) sub[el.acl_type_object_name] = { name: el.acl_type_object_name };
				sub[el.acl_type_object_name][el.acl_type_perm] = { acl_type_uid: el.acl_type_uid, acl_type_name: el.acl_type_name };
			});
			return sub;
		},
		async sortPerms() {
			// sort permissions according to menu position
			const newPerms = await _.cloneDeep(this.$user.menuModulesSorted);
			this.permissions.forEach((perm) => {
				if (newPerms.indexOf(perm.acl_type_module) === -1) {
					newPerms.push(perm);
				} else {
					newPerms[newPerms.indexOf(perm.acl_type_module)] = perm;
				}
			});
			this.permissions = newPerms;

			// sort menu items according to menu position
			const helper = {};
			const translations = {};
			this.$user.wholeMenu.forEach((e) => {
				if (e.dynamic) return;
				// create key in helper and translations if it is not there yet
				if (!helper[e.module]) {
					helper[e.module] = [];
					translations[e.module] = [];
				}

				if (e.name === 'administration') {
					// list throught all administration items
					e.items.forEach((item) => {
						if (item.dynamic) return;
						if (!item.items || item.items.length === 0) {
							// put simple item to helper key and its translations to translations key
							if (!helper[item.group]) {
								helper[item.group] = [];
								translations[item.group] = [];
							}
							helper[item.group].push(item.name);
							translations[item.group].push(`a;${item.translatedName}`);
						} else {
							item.items.forEach((i) => {
								// create key in helper and translations if it is not there yet
								if (!helper[i.group]) {
									helper[i.group] = [];
									translations[i.group] = [];
								}
								// put simple item to helper key and its translations to translations key
								helper[i.group].push(i.name);
								translations[i.group].push(`a;${item.translatedName} - ${i.translatedName}`);
							});
						}
					});
				} else {
					if (!e.items || e.items.length === 0) {
						// put simple item to helper key and its translations to translations key
						helper[e.module].push(e.name);
						translations[e.module].push(e.translatedName);
					} else {
						e.items.forEach((item) => {
							if (item.dynamic) return;
							// put simple item to helper key and its translations to translations key
							helper[e.module].push(item.name);
							translations[e.module].push(`${e.translatedName} - ${item.translatedName}`);
						});
					}
				}
			});

			this.permissions.forEach((perm) => {
				perm.menu.forEach((menuItem) => {
					if (helper[perm.acl_type_module]) {
						const index = helper[perm.acl_type_module].indexOf(menuItem.acl_type_object_name.replace('menu.', ''));
						if (index !== -1) {
							// create new menu items into permissions.menu (acl_types)
							helper[perm.acl_type_module][index] = menuItem;
							helper[perm.acl_type_module][index].translatedName = translations[perm.acl_type_module][index];
						} else {
							// item from permissions in DB was not in menu files
							helper[perm.acl_type_module].push(menuItem);
						}
					}
				});
				perm.menu = helper[perm.acl_type_module];
			});
		},
		getName(menu) {
			if (menu.translatedName && menu.translatedName.includes('a;')) {
				return this.$t(`administration`) + ' - ' + menu.translatedName.replace('a;', '');
			} else {
				return menu.translatedName || menu.acl_type_object_name || menu;
			}
		},
		isPermInSubpermissions(subpermissions, type_perm) {
			const a = subpermissions.filter((el) => {
				if (el.acl_type_perm === type_perm) return el;
			});
			return (a.length > 0);
		},
		getSectionTitle(name) {
			if (name === 'lbadmin') {
				return 'LbAdmin';
			}
			return this.$t(`${name}.menu.${name}`);
		},
		isProfAllowed(profile) {
			const allowed = this.allowedProfiles.find((el) => el.profile_uid === profile.profile_uid);
			return !(allowed && allowed.profile_uid === profile.profile_uid && profile.profile_uid !== this.profile.profile_uid);
		},
		markMenuItems(data) {
			_.forEach(data, (p) => {
				if (p.acl_type_uid && !_.find(this.profile.permissions, (e) => e === p.acl_type_uid)) {
					this.profile.permissions.push(p.acl_type_uid);
				}
			});
		},
		unmarkMenuItems(data) {
			_.forEach(data, (p) => {
				_.remove(this.profile.permissions, (e) => (p.acl_type_uid && e === p.acl_type_uid));
			});
		},
		markModuleItems(data, type) {
			_.forEach(data, (p) => {
				if (type && p.acl_type_perm !== type) {
					return;
				}
				if (!_.find(this.profile.permissions, (e) => (p.acl_type_uid && e === p.acl_type_uid))) {
					this.profile.permissions.push(p.acl_type_uid);
				}
			});
		},
		unmarkModuleItems(data, type) {
			_.forEach(data, (p) => {
				if (type && p.acl_type_perm !== type) {
					return;
				}
				_.remove(this.profile.permissions, (e) => (p.acl_type_uid && e === p.acl_type_uid));
			});
		},
		markProfiles() {
			_.forEach(this.allowedProfiles, (p) => {
				if (!_.find(this.profile.allowed, (e) => (p.profile_uid && e === p.profile_uid))) {
					this.profile.allowed.push(p.profile_uid);
				}
			});
		},
		unmarkProfiles() {
			_.forEach(this.allowedProfiles, (p) => {
				_.remove(this.profile.allowed, (e) => (p.profile_uid && e === p.profile_uid));
			});
		},
		markItems(type, state, data1, data2) {
			this.dirty = true;
			if (type === 'menu') {
				if (state) {
					this.markMenuItems(data1);
				} else {
					this.unmarkMenuItems(data1);
				}
			} else if (type === 'type') {
				if (state) {
					this.markModuleItems(data2, data1);
				} else {
					this.unmarkModuleItems(data2, data1);
				}
			} else if (type === 'section') {
				if (state) {
					this.markMenuItems(data1.menu);
					this.markModuleItems(data1.subpermissions, null);
				} else {
					this.unmarkMenuItems(data1.menu);
					this.unmarkModuleItems(data1.subpermissions, null);
				}
			} else if (type === 'profiles') {
				if (state) {
					this.markProfiles();
				} else {
					this.unmarkProfiles();
				}
			}
			this.$forceUpdate();
		},
		scrollTo(id) {
			const el = document.getElementById(id);
			if (!el) return;
			el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		},
	},
};
</script>
