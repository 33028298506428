export default {
	methods: {
		timeSince(date, short = true) {
			if (date && date.constructor === String) {
				date = new Date(date);
			}
			const seconds = Math.floor((new Date() - date) / 1000);
			const divTable = [
				{
					value: 31536000,
					label: 'years',
				},
				{
					value: 2592000,
					label: 'months',
				},
				{
					value: 86400,
					label: 'days',
				},
				{
					value: 3600,
					label: 'hours',
				},
				{
					value: 60,
					label: 'minutes',
				},
			];
			let step = 0;
			let since = seconds;

			while (divTable[step]) {
				const interval = seconds / divTable[step].value;
				if (interval > 1) {
					since = Math.floor(interval);
					break;
				}
				step += 1;
			}

			const localePath = `crm.timeSince.${short ? 'short' : 'long'}.${divTable[step] ? divTable[step].label : 'seconds'}`;

			if (short) {
				return `${since}${this.$t(localePath)}`;
			}

			return this.$tc(localePath, since);
		},
	},
};
